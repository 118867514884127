import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import PlacesAutocomplete from "react-places-autocomplete";

import { Box, Typography } from "@mui/material";

import {
  AutocompleteDropdownContainer,
  SuggestionContainer,
  TextFieldContainer,
  DetourField,
} from "./routeStyles";

function InputSearchBox(props) {
  const {
    name,
    error,
    detour,
    setValue,
    maxWidth,
    cleanField,
    placeholder,
    setDetourAddress,
  } = props;

  const [isSucess, setIsSucess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [address, setAddress] = useState("");

  useEffect(() => {
    cleanField(setAddress);
  }, [cleanField]);

  const handleChange = (value) => {
    isSucess && setIsSucess(false);
    setAddress(value);
    setValue(value);
  };

  const handleFocus = () => {
    !showSuggestions && setShowSuggestions(true);
    setIsError(false);
  };

  const handleSetPlace = (place) => {
    if (!address.length) {
      setIsError(true);
      return;
    }

    if (place) {
      setAddress(place);
      setValue(place);
      if (detour) setDetourAddress(place);
    }

    setShowSuggestions(false);
    setIsError(false);
    setIsSucess(true);
  };

  const setError = () => {
    if (error) {
      return {
        backgroundColor: "rgba(255,192,203,1)",
        borderColor: "red",
      };
    } else {
      return null;
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(InputValue) => handleChange(InputValue)}
      onSelect={(p) => handleSetPlace(p)}
      onError={() => console.log("Google API error")}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <TextFieldContainer component="div" style={setError()}>
          <DetourField
            type="search"
            isDetour={detour}
            {...getInputProps({
              name,
              placeholder: placeholder,
              onBlur: () => handleSetPlace(),
              onFocus: () => handleFocus(),
            })}
          />
          {(isError || error) && (
            <Typography variant="h7" noWrap>
              Por favor, indique um ponto de {placeholder}
            </Typography>
          )}
          {suggestions.length > 0 && showSuggestions && (
            <AutocompleteDropdownContainer component="div">
              {suggestions.map((suggestion, index) => (
                <>
                  <SuggestionContainer
                    key={`suggestion-${index}`}
                    component="div"
                    padding={suggestion.active && "3px 0"}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <Box component="span">{suggestion.description}</Box>
                  </SuggestionContainer>
                  <hr />
                </>
              ))}
            </AutocompleteDropdownContainer>
          )}
        </TextFieldContainer>
      )}
    </PlacesAutocomplete>
  );
}

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("INFO")(state)
      ? getFormValues("INFO")(state)
      : [],
    submitError: state.routesAnalysis.fetchError,
  };
};

export default connect(mapStateToProps, null)(InputSearchBox);
