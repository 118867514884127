export const Types = {
  ADD_DRAWING: "drawings/ADD_DRAWING",
  REMOVE_DRAWING: "drawings/REMOVE_DRAWING",
  REMOVE_ALL_DRAWINGS: "drawings/REMOVE_ALL_DRAWINGS",
};

export const Creators = {
  addDrawing: (drawing) => ({
    type: Types.ADD_DRAWING,
    payload: drawing,
  }),
  removeDrawing: (drawingId) => ({
    type: Types.REMOVE_DRAWING,
    payload: drawingId,
  }),
  removeAllDrawings: () => ({
    type: Types.REMOVE_ALL_DRAWINGS,
  }),
};
