import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import { Typography } from "@mui/material";
import {
  InfoWindowComponent,
  IncidentTextTitle,
  IncidentTextTime,
  IncidentTitle,
  IncidentItem,
  AlertPaper,
} from "./styles";

const CustomClippingMarker = ({ marker, clusterer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = { hour: "2-digit", minute: "2-digit" };

  const onToggleOpen = () => {
    setIsOpen((previous) => !previous);
  };

  return (
    <Marker
      noRedraw
      title={marker.title || ""}
      key={marker.id}
      position={marker.location}
      icon={{ url: marker.type.icon, optimized: false }}
      onClick={onToggleOpen}
      clusterer={clusterer}
    >
      {isOpen && (
        <InfoWindowComponent options={{ maxWidth: 400 }}>
          <AlertPaper color="primary" onClick={onToggleOpen}>
            <IncidentItem component="div">
              <IncidentTitle primary={marker.title} />
              {marker.startedAt && (
                <IncidentTextTime
                  primary={
                    "Início em " +
                    new Date(marker.startedAt).toLocaleDateString(
                      "pt-BR",
                      options
                    )
                  }
                />
              )}
              {marker.occurredAt && (
                <IncidentTextTime
                  primary={
                    "Ocorrido em " +
                    new Date(marker.occurredAt).toLocaleDateString(
                      "pt-BR",
                      options
                    )
                  }
                />
              )}
              {marker.category && marker.type && (
                <IncidentTextTitle
                  primary="Natureza:"
                  secondary={`${marker.category.title} / ${marker.type.type}`}
                />
              )}
              {marker.address && (
                <IncidentTextTitle
                  primary="Endereço:"
                  secondary={marker.address}
                />
              )}
              <IncidentTextTitle
                primary="Descrição:"
                secondary={marker.description}
              />
              {marker.links.length > 0 && (
                <IncidentTextTitle
                  primary="Fonte:"
                  secondary={
                    <Typography
                      component="a"
                      target="_blank"
                      href={marker.links[0].url}
                      rel="noopener noreferrer"
                    >
                      {marker.links[0].media.name +
                        ` (${marker.links[0].media.mediaGroups.groupName})`}
                    </Typography>
                  }
                />
              )}
            </IncidentItem>
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
};

export default CustomClippingMarker;
