import React from "react";
import { useSelector } from "react-redux";

import { DraggableContainer as Container } from "../routeStyles";
import DraggableCard from "./Index";

const DraggableContainer = () => {
  const informations = useSelector((state) =>
    state.routesAnalysis.informations.filter((data) => data.isDraggable)
  );

  return (
    <Container>
      {informations.map((route, idx) => (
        <DraggableCard
          key={(idx + " - ", route.start_address, " - ", route.end_address)}
          timestampId={route.id}
        />
      ))}
    </Container>
  );
};

export default DraggableContainer;
