import { IconButton, Toolbar, styled } from "@mui/material";

export const CustomToolBar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "expanded",
})(({ expanded }) => ({
  display: "block",
  padding: "0 !important",
  marginTop: "30px",
  zIndex: "6",
  marginRight: 0,
  width: "58px",
  right: expanded ? "300px" : 0,
  position: "absolute",
  top: "100px",
}));

export const ActionButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  color: selected ? "#212121" : "#fff",
  backgroundColor: selected ? "#fff" : "#212121",
  marginRight: "16px",
  marginLeft: "10px",
  float: "left",
  padding: "15px 15px",
  borderRadius: "0px",
  marginBottom: "10px",
  boxShadow: "1px 1px 5px black",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "white",
    color: "#212121",
  },
  "&:focus": {
    outline: "none",
  },
}));

export const ExitButton = styled(IconButton)(() => ({
  color: "#fff",
  backgroundColor: "#1CA495",
  marginRight: "16px",
  marginLeft: "10px",
  float: "left",
  padding: "15px 15px",
  borderRadius: "0px",
  marginTop: "30vh",
  boxShadow: "1px 1px 5px black",

  transition: "all .3s linear",
  "&:hover": {
    color: "#212121",
    backgroundColor: "#1CA495",
  },
  "&:focus": {
    outline: "none",
  },
}));
