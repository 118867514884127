import {
  Alarm,
  Close,
  Folder,
  ListAlt,
  Explore,
  Timeline,
  DeleteOutline,
  KeyboardArrowUp,
  KeyboardArrowDown,
  OutlinedFlagSharp,
} from "@mui/icons-material";
import {
  Box,
  styled,
  Drawer,
  Button,
  Divider,
  TextField,
  IconButton,
  Typography,
  FormControl,
  ListItemText,
  OutlinedInput,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";

export const MainDrawer = styled(Drawer)(() => ({
  width: "350px",
  flexShrink: 0,
  ".MuiDrawer-paper": {
    width: "350px",
    color: "white",
    background: "#212121",
    visibility: "visible",
    overflow: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#212121",
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      backgroundColor: "#212121",
      paddingRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,.3)",
      backgroundColor: "#424242",
    },
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  marginBottom: "8px",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  color: "#fff",
  background: "#1A1C1D",
  minHeight: "64px",
  position: "fixed",
  width: "350px",
  zIndex: 9,
}));

export const TitleText = styled(Typography)(() => ({
  width: "100%",
}));

export const ActionButton = styled(IconButton)(() => ({
  color: "#fff",
}));

export const MainSideContent = styled(Box)(() => ({
  flex: "1",
  width: "350px",
  padding: "65px 0",
  background: "#212121",
}));

export const SliderContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
}));

export const SaveRouteButton = styled(IconButton)(() => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "1rem",
  fontWeight: "bold",
  padding: "15px 0",
  width: "86%",
  borderRadius: "3px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "#fff",
  color: "#000",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#555555",
    color: "#fff",
  },
  "& svg": {
    padding: "0 20px",
  },
}));

export const RemoveRouteButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "black",
  color: "white",
  fontSize: "1.2rem",
  fontWeight: "bold",
  padding: "15px 0",
  width: "86%",
  borderRadius: "3px",
  border: "white solid 1px",
  cursor: "pointer",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#555555",
    border: "#555555 solid 1px",
  },
  "& svg": {
    padding: "0 20px",
  },
}));

export const OpenListButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "1rem",
  fontWeight: "bold",
  padding: "15px 0",
  width: "86%",
  borderRadius: "3px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "white",
  color: "black",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#555555",
    color: "white",
  },
  "& svg": {
    padding: "0 20px",
  },
}));

export const FolderIcon = styled(Folder)(() => ({
  color: "#000",
}));

export const RadiusWarning = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  color: "#E5E5E5",
  padding: "10px",
}));

export const ButtonContainer = styled(Box)(() => ({
  width: "100%",
  padding: "10px 0",
  display: "flex",
  justifyContent: "center",
}));

export const SideFooter = styled(Box)(() => ({
  width: "350px",
  left: 0,
  bottom: "10px",
  padding: "10px 0",
  textAlign: "center",
  background: "#212121",
  color: "#fff",
}));

export const ExploreIcon = styled(Explore)(() => ({
  margin: "0 7px",
  fontSize: "2.5rem",
}));

export const DeleteOutlineIcon = styled(DeleteOutline)(() => ({
  color: "#fff",
}));

export const ListAltIcon = styled(ListAlt)(() => ({
  color: "#fff",
}));

export const RouteContainer = styled(Box)(() => ({
  position: "relative",

  width: "300px",
  margin: "20px 0",
  padding: 0,

  display: "flex",
  flexDirection: "column",

  color: "#FFF",

  borderRadius: "2px",
  boxShadow: "0px 0px 0.3rem #000",
}));

export const RouteInfo = styled(Box)(() => ({
  margin: "20px 0",
  position: "relative",
}));

export const UnitName = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "1.5rem",
  margin: "20px 0",
}));

export const FlexBox = styled(Box)(() => ({
  display: "flex",
}));

export const RouteDetail = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  position: "relative",
  textAlign: "left",
  padding: "5px 20px",
  "& div": {
    padding: "0 5px",
  },
}));

export const ContainerFlag = styled(Box)(() => ({
  paddingLeft: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderLeft: "1px solid #545454",
}));

export const FlagSharpIcon = styled(OutlinedFlagSharp)(() => ({
  marginLeft: "20px",
  color: "#1CA495",
  fontSize: "24px",
}));

export const MoreDetailContainer = styled(Box)(() => ({
  color: "#1CA495",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const AddressContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "display",
})(({ display }) => ({
  display,
  position: "absolute",
  top: "-280%",
  left: "10%",
  backgroundColor: "#c0c0c0",
  color: "#000000",
  textAlign: "center",
  padding: "0.5px 2.8px",
  borderRadius: "6px",
  border: "none",
  pointerEvents: "none",
}));

export const EllipsisContainer = styled(Box)(() => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "25ch",
  whiteSpace: "nowrap",
  cursor: "pointer",
}));

export const LineIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ backgroundColor }) => ({
  width: "15px",
  height: "15px",
  borderRadius: "2px",
  backgroundColor,
}));

export const ActionContainer = styled(Box)(() => ({
  marginTop: "20px",
  backgroundColor: "#555",
  color: "#000",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  height: "50px",
  borderRadius: "0 0 2px 2px",
}));

export const ActiveButton = styled("button")(() => ({
  backgroundColor: "#555",
  border: "none",
}));

export const DetourContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "15px 0 30px",
}));

export const CustomFormControl = styled(FormControl)(() => ({
  margin: "15px",
}));

export const SelectInput = styled(OutlinedInput)(() => ({
  color: "#fff",
}));

export const DetourTextContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  width: "80%",
  margin: "15px 0",
}));

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  width: width && width,
  margin: "0 5px",
  boxShadow: "0 1px 1px 0",

  "& fieldset": {
    border: "none",
  },

  "& label": {
    color: "#fff !important",
    marginBottom: "3px",
  },

  "& div": {
    marginTop: "5px",
  },

  "& input": {
    color: "#fff",
    border: "#757575 solid 0.25px",
  },
}));

export const ListText = styled(ListItemText)(() => ({
  "& p": {
    color: "#fff",
  },
}));

export const ErrorMessageContainer = styled(Box)(() => ({
  animationName: "$fadeIn",
  animationDuration: "1300ms",

  backgroundColor: "red",
  width: "100%",
  height: "25px",
  padding: "6px",
  textAlign: "start",
  borderRadius: "2px",
  opacity: 0.65,
  display: "flex",
  alignItems: "center",
}));

export const TextFieldContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "-7px",
  marginLeft: "10px",
}));

export const DetourField = styled("input", {
  shouldForwardProp: (prop) => prop !== "isDetour",
})(({ isDetour }) => ({
  flex: "1",
  width: isDetour ? "200px" : "240px",
  backgroundColor: isDetour ? "#212121" : "#fff",
  color: isDetour ? "#fff" : "#e5e5e5",
  border: "none",
  outline: "none",
  borderRadius: isDetour ? "3px" : "6px",
  boxShadow: "0 1px 1px 0",
  padding: "16.5px 20px",
  margin: "4px 0",
  height: "30px",
  "& fieldset": {
    border: "none",
  },
}));

export const AutocompleteDropdownContainer = styled(Box)(() => ({
  boxShadow: "1px 1px 4px 1px",
  display: "inline",
  backgroundColor: "#fff",
  color: "#000",
  alignSelf: "center",
  maxWidth: "280px",
  height: "auto",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  position: "absolute",
  marginTop: "55px",
  zIndex: 20,
}));

export const SuggestionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "padding",
})(({ padding }) => ({
  transition: "all .3s",
  "&:hover": {
    padding,
  },
}));

export const LoadingPaper = styled(Box)(() => ({
  position: "absolute",
  top: "0",
  left: "0",

  width: "100%",
  height: "100%",

  backgroundColor: "rgba(0, 0, 0, .75)",
  zIndex: 5,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const DraggableContainer = styled("div")(() => ({
  position: "absolute",
  bottom: "5%",
  right: "5%",
  zIndex: 10,

  display: "flex",
}));

export const InformationContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  position: "relative",

  marginTop: "8px",
}));

export const VerticalLine = styled("div")(() => ({
  width: "2px",
  height: "12px",
  backgroundColor: "#c0c0c0",

  position: "absolute",
  left: "4.47px",
  top: "12px",
}));

export const Dot = styled("div")(() => ({
  width: "11px",
  height: "11px",
  borderRadius: "50%",
  marginRight: "7px",

  backgroundColor: "#FFF",
  border: "none",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > div": {
    width: "5px",
    height: "5px",

    borderRadius: "50%",

    backgroundColor: "#c0c0c0",
  },
}));

export const Line = styled(Divider)(() => ({
  width: "100%",
  margin: "8px 0",
  alignSelf: "center",
}));

export const FloatAddress = styled(Box)(() => ({
  position: "absolute",
  bottom: "-280%",
  left: "35%",

  width: "200px",
  height: "auto",
  padding: "8px",

  borderRadius: "6px",
  border: "none",
  textAlign: "center",
  backgroundColor: "#FFF",
  color: "#1A1C1D",
  pointerEvents: "none",
  zIndex: 15,
}));

export const DraggablePaper = styled(Box)(() => ({
  width: "280px",
  height: "140px",

  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  boxShadow: "0px 0px 0.3rem #000000",
  color: "#FFF",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",

  position: "relative",

  fontWeight: "700",

  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "transparent",

  "& + div": {
    marginLeft: "12px",
  },

  "& button": {
    background: "transparent",
    border: "none",
    outline: "none",
  },

  "& header": {
    alignSelf: "flex-start",
    padding: "4px",
    width: "65%",
    height: "20%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#000000",
    fontWeight: "500",

    "& > div": {
      display: "flex",
      alignItems: "center",

      "& svg": {
        fontSize: "25px",
        marginRight: "4px",
      },
    },
  },

  "& > button": {
    position: "absolute",
    top: "2.5px",
    right: "3.5px",
    color: "#000000",
    cursor: "pointer",
    fontSize: "15px",
  },
}));

export const MainContainer = styled("div")(() => ({
  width: "100%",
  flex: "1",
  flexGrow: "1",
  padding: "6px",
  boxSizing: "border-box",

  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",

  backgroundColor: "rgba(26, 28, 29, 0.5)",
  color: "#FFF",

  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",

  "& svg": {
    cursor: "pointer",
  },

  "& > div": {
    paddingLeft: "4px",
    marginBottom: "4px",
  },

  "& footer": {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& div": {
      display: "flex",
      alignItems: "center",
    },

    "& svg": {
      color: "#FFF",
      fontSize: "25px",
    },

    "& button": {
      marginRight: "7px",
      border: "none",
      outline: "none",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  transition: "all .3s linear",
  "&:hover": {
    cursor: "move",
    zIndex: "10",
  },
}));

export const PDFContainer = styled(PDFViewer)(() => ({
  height: "75vh",
  width: "100%",
  border: "none",
}));

export const ContentPaper = styled(DialogContent)(() => ({
  background: "#212121",
}));

export const ActionPaper = styled(DialogActions)(() => ({
  background: "#212121",
}));

export const Progress = styled(CircularProgress)(() => ({
  color: "#fff",
}));

export const KeyboardArrowUpIcon = styled(KeyboardArrowUp)(() => ({
  color: "#1CA495",
}));

export const KeyboardArrowDownIcon = styled(KeyboardArrowDown)(() => ({
  color: "#1CA495",
}));

export const AlarmIcon = styled(Alarm)(() => ({
  color: "#000",
}));

export const TimelineIcon = styled(Timeline)(() => ({
  color: "#000",
}));

export const CloseIcon = styled(Close)(() => ({
  color: "#000",
}));
