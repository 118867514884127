import React from "react";
import { connect } from "react-redux";
import {
  Info as InfoIcon,
  Videocam as VideoCam,
  Explore as ExploreIcon,
  SelectAll as SelectAllIcon,
  Equalizer as EqualizerIcon,
  TrendingUp as TrendingUpIcon,
  FindInPage as FindInPageIcon,
} from "@mui/icons-material";

import { ActionButton, CustomToolBar } from "./leftMenuStyles";

import { Creators as FormActions } from "../../store/actions/menu";

const LeftMenuTabs = (props) => {
  const {
    care,
    rotas,
    cameras,
    analise,
    clusters,
    informacoes,
    rotasEvasao,
    animateMenu,
    crisisModeUnits,
  } = props;

  const permissions = localStorage.getItem("permissions")?.split(",") ?? [];

  let marginLeft = 0;

  if (care || clusters || analise || informacoes || cameras) {
    marginLeft = "300px";
  }

  if (rotas || rotasEvasao) {
    marginLeft = "350px";
  }

  return (
    <CustomToolBar left={marginLeft}>
      <ActionButton
        edge="start"
        color="inherit"
        selected={clusters}
        onClick={() => animateMenu("ABRIR_MENU_CLUSTERS")}
      >
        <SelectAllIcon />
      </ActionButton>

      <ActionButton
        edge="start"
        color="inherit"
        selected={cameras}
        onClick={() => animateMenu("ABRIR_MENU_CAMERAS")}
      >
        <VideoCam />
      </ActionButton>

      <ActionButton
        edge="start"
        color="inherit"
        selected={informacoes}
        onClick={() => animateMenu("ABRIR_MENU_INFORMACOES")}
      >
        <InfoIcon />
      </ActionButton>

      <ActionButton
        edge="start"
        color="inherit"
        selected={analise}
        onClick={() => animateMenu("ABRIR_MENU_ANALISE")}
      >
        <FindInPageIcon />
      </ActionButton>

      <ActionButton
        edge="start"
        color="inherit"
        selected={rotas}
        onClick={() => animateMenu("ABRIR_MENU_ROTAS")}
      >
        <ExploreIcon />
      </ActionButton>

      {permissions.includes("can-view-care-incidents") && (
        <ActionButton
          edge="start"
          color="inherit"
          selected={care}
          onClick={() => animateMenu("ABRIR_MENU_CARE")}
        >
          <EqualizerIcon />
        </ActionButton>
      )}

      {crisisModeUnits.length && (
        <ActionButton
          edge="start"
          color="inherit"
          selected={rotasEvasao}
          onClick={() => animateMenu("ABRIR_MENU_ROTAS_EVASAO")}
        >
          <TrendingUpIcon />
        </ActionButton>
      )}
    </CustomToolBar>
  );
};

const mapStateToProps = (state) => {
  return {
    rotas: state.menu.rotas,
    analise: state.menu.analise,
    cameras: state.menu.cameras,
    clusters: state.menu.clusters,
    care: state.menu.careIncidents,
    informacoes: state.menu.informacoes,
    rotasEvasao: state.menu.rotasEvasao,
    crisisModeUnits: state.crisisMode.units,
  };
};

const mapDispatchtoProps = {
  ...FormActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(LeftMenuTabs);
