export const Types = {
  TOGGLE_MODE: "crisisMode/TOGGLE_MODE",
  CHANGE_ROUTE_MODE: "crisisMode/CHANGE_ROUTE_MODE",
  TOGGLE_UPDATE: "crisisMode/UPDATE",
  OPEN_POPUP: "crisisMode/OPEN_POPUP",
  SET_CRISIS_MODE_ROUTES: "crisisMode/SET_CRISIS_MODE_ROUTES",
  UPDATE_ROUTE: "crisisMode/UPDATE_ROUTE",
  DELETE_ROUTE: "crisisMode/DELETE_ROUTE",
};

export const Creators = {
  toggleMode: (unit_id) => ({
    type: Types.TOGGLE_MODE,
    payload: unit_id,
  }),
  changeRouteMode: (data) => ({
    type: Types.CHANGE_ROUTE_MODE,
    payload: data,
  }),
  toggleUpdate: (data) => ({
    type: Types.TOGGLE_UPDATE,
    payload: data,
  }),
  openPopup: (data) => ({
    type: Types.OPEN_POPUP,
    payload: data,
  }),
  setCrisisModeRoutes: (data) => ({
    type: Types.SET_CRISIS_MODE_ROUTES,
    payload: data,
  }),
  updateRoute: (id) => ({
    type: Types.UPDATE_ROUTE,
    payload: id,
  }),
  deleteRoute: (id) => ({
    type: Types.DELETE_ROUTE,
    payload: id,
  }),
};
