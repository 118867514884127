import { enumLanguages } from "../../common/language";

export const language = {
  [enumLanguages.pt]: {
    description: "Descrição",
    coordanates: "Coordenadas",
    category: "Categoria",
    neighborhood: "Bairro",
    city: "Município",
    date: "Data",
    typicity: "Tipicidade",
    occurenceDate: "Data da Ocorrêcia",
    occurenceTitle: "Título da Ocorrência",
    typicityFilter: "Filtro de Tipicidade",
    occurenceFilter: "Filtro de Ocorrência",
    exportData: "Exportar dados",
    hideDatas: "Ocultar tablea de dados",
    seeDatas: "Visualizar tabela de dados",
    dawn: "Madrugada",
    morning: "Manhã",
    afternoon: "Tarde",
    night: "Noite",
    ocurrences: "Ocorrências",
    sources: "Mídias",
    title: "Dashboard - Análise e Inteligência",
    highestOcurrence: "Maior Ocorrência",
    timePeriod: "Turno",
    comparative: "Comparativo",
    seeMap: "Ver no mapa",
    weekday: "Dia da Semana",
    sunday: "Domingo",
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    saturday: "Sábado",
  },
  [enumLanguages.en]: {
    description: "Description",
    coordanates: "Coordenates",
    category: "Category",
    neighborhood: "Neighborhood",
    city: "City",
    date: "Date",
    typicity: "Typicity",
    occurenceDate: "Occurence Date",
    occurenceTitle: "Occurence Title",
    typicityFilter: "Typicity Filter",
    occurenceFilter: "Occurence Filter",
    exportData: "Export datas",
    hideDatas: "Hide data",
    seeDatas: "See data",
    dawn: "Dawn",
    morning: "Morning",
    afternoon: "Afternoon",
    night: "Night",
    ocurrences: "Occurences",
    sources: "Sources",
    title: "Dashboard - Analysis and Intelligence",
    highestOcurrence: "Highest Occurrence",
    timePeriod: "Time Period",
    comparative: "Comparative",
    seeMap: "See map",
    weekday: "Weekday",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
};
