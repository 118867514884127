import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import map from "./map";
import Main from "./main";
import menu from "./menu";
import auth from "./auth";
import areas from "./areas";
import inputs from "./inputs";
import filters from "./filters";
import detours from "./detours";
import ocurrences from "./ocurrences";
import categories from "./categories";
import interestPoints from "./interestPoints";
import riskAreas from "./riskAreas";
import publicEntities from "./public-entities";
import publicEntityTypes from "./publicEntityTypes";
import areaInformationTypes from "./areaInformationTypes";
import routesAnalysis from "./routesAnalysis";
import customerUnitsAreas from "./unitsAreas";
import drawingReducer from "./drawing";
import drawingAnalyses from "./drawingAnalyses";
import crisisMode from "./crisisMode";
import markersDistance from "./markersDistance";
import careReducer from "./care";

const rootReducer = combineReducers({
  map,
  Main,
  menu,
  auth,
  areas,
  inputs,
  filters,
  detours,
  categories,
  ocurrences,
  interestPoints,
  riskAreas,
  publicEntities,
  publicEntityTypes,
  areaInformationTypes,
  routesAnalysis,
  customerUnitsAreas,
  drawing: drawingReducer,
  drawingAnalyses,
  crisisMode,
  markersDistance,
  care: careReducer,
  form: formReducer,
});

export default rootReducer;
