import React, { Component } from "react";
import LoadingScreen from "../screenLoading";

import { loginSSO } from "../../store/actions/auth"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"

class LoginSSO extends Component {

  componentDidMount = () => {
      
    const { location, history, loginSSO } = this.props

    const query = new URLSearchParams(location.search)
    
    if (!query.has("sso")) {
      history.push("/login")
    } else if (query.has("sso") && query.has("access_token")) {
      loginSSO(query.get("sso"), query.get("access_token"))
    } else if (query.has("sso")) {
      window.location.href = `${
        process.env.REACT_APP_SSO_URL
      }/login?sso=${query.get("sso")}&redirect=${process.env.REACT_APP_URL_APP}login/sso`
    }
  }

  render() {
    return <LoadingScreen sso={true} />
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginSSO }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginSSO);