import { Close, LocationOn } from "@mui/icons-material";
import { Box, Typography, styled } from "@mui/material";
import { InfoWindow } from "@react-google-maps/api";

export const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ModalTitle = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const IconContainer = styled(Box)(() => ({
  borderRadius: "50%",
  backgroundColor: "#F74E5D",
  width: "35px",
  height: "35px",
  marginRight: "12px",
}));

export const LocationOnIcon = styled(LocationOn)(() => ({
  fontSize: "2.2rem",
  margin: "4px",
  color: "#fff",
}));

export const UnitName = styled(Typography)(() => ({
  color: "#fff",
  fontSize: "1.3rem",
  fontWeight: "bold",
  paddingRight: "15px",
  borderRight: "white solid 0.5px",
}));

export const UnitAddress = styled(Typography)(() => ({
  color: "#fff",
  fontSize: "1rem",
  paddingLeft: "15px",
}));

export const AnimatedCircle = styled(Box)(() => ({
  animationName: "$animatedCircle",
  animationDuration: "1300ms",
  animationIterationCount: "infinite",
  animationDirection: "alternate",

  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundColor: "rgba(242, 0, 0, 0.5)",
  borderColor: "#d32323",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  cursor: "pointer",
}));

export const CustomInfoWindow = styled(InfoWindow)(() => ({
  overflow: "hidden",
}));

export const InfoContainer = styled(Box)(() => ({
  width: "300px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  padding: "3px",

  position: "relative",

  "& header": {
    position: "relative",
    marginBottom: "5.5px",

    display: "flex",
    alignItems: "center",

    "& h2": {
      zIndex: "5",
      marginRight: "0.5rem",
    },

    "& svg": {
      transform: "translateY(-15%)",
    },
  },

  "& table": {
    borderSpacing: "0.25rem",

    "& td": {
      verticalAlign: "top",
    },

    "& td:first-of-type": {
      fontWeight: "bold",
    },
  },
}));

export const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",

  marginTop: "20px",

  borderTop: "1px solid rgba(0, 0, 0, 0.2)",
  paddingTop: "5.5px",
}));

export const CloseIcon = styled(Close)(() => ({
  color: "#fff",
}));

export const CustomLocationOn = styled(LocationOn)(
  ({ top, right, left, fontSize }) => ({
    top,
    left,
    right,
    fontSize,
    position: "absolute",
  })
);

export const ActionButton = styled("button")(() => ({
  width: "135px",
  height: "34px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "#212121",
  color: "#FFF",
  outline: "none",
  border: "none",
  borderRadius: "3px",
  fontWeight: "500",

  cursor: "pointer",

  transition: "all .3s linear",
  "& svg": {
    fontSize: "20px",
    marginRight: "4px",

    transition: "font-size .2s",
  },

  "&:hover": {
    "& svg": {
      fontSize: "25px",
    },
  },
}));

export const CrisisModuleButton = styled("button")(() => ({
  backgroundColor: "red",
}));
