import React from "react";

import { AutoComplete, AutoCompleteField } from "./drawerCareStyles";

const Autocomplete = ({ data = [], onChange, name }) => {
  return (
    <AutoComplete
      clearText="Limpar"
      options={data.map(({ id, descr }) => ({
        id,
        label: descr,
      }))}
      getOptionLabel={(option) => option?.label ?? ""}
      onChange={(e, v) => onChange(v, name)}
      renderInput={(params) => <AutoCompleteField {...params} />}
    />
  );
};

export default Autocomplete;
