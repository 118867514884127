import axios from "axios";
axios.defaults.headers.common["Content-Type"] = "application/json";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_V1_URL,
});

const userToken = localStorage.getItem("token");

if (userToken) {
  Axios.defaults.headers.common["Authorization"] = "Bearer " + userToken;
} else {
  delete Axios.defaults.headers.common["Authorization"];
}

Axios.interceptors.response.use(undefined, (err) => {
  let res = err.response;
  if (res.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    if (!localStorage.getItem("@sso_integration")) {
      localStorage.removeItem("refreshToken");
    }
  }
  return Promise.reject(err);
});

export default Axios;
