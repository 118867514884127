import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InfoWindow } from "@react-google-maps/api";

import { Box, Typography } from "@mui/material";
import { DeleteSweep as DeleteSweepIcon } from "@mui/icons-material";

import { InnerContainer, DeleteButton } from "./styles";

import { Creators as DrawingCreators } from "../../../store/actions/drawing";
import { Creators as DrawingAnalysesCreators } from "../../../store/actions/drawingAnalyse";

const BoxFilters = (props) => {
  const {
    overlays,
    callback,
    removeDrawing,
    removeDrawingAnalyse,
    markersCoordinates,
  } = props;

  const [visibleOverlays, setVisibleOverlays] = useState([]);

  useEffect(() => {
    overlays &&
      setVisibleOverlays(overlays.filter((overlay) => overlay.isVisible));
  }, [overlays]);

  const handleDrop = (id, dropFunction) => {
    removeDrawing(id);
    removeDrawingAnalyse(id);
    dropFunction();
    callback(id);
  };

  const renderDistanceInfo = (elemntId) => {
    const info = markersCoordinates.find(({ id }) => id == elemntId);

    if (info) {
      return (
        <Typography component="h4">
          {`Distância do ponto de origem: ${info.distance}m.`}
        </Typography>
      );
    }
  };

  return (
    <>
      {visibleOverlays.map(({ id, position, dropFunction }) => (
        <InfoWindow onCloseClick={() => callback(id)} position={position}>
          <InnerContainer component="div">
            {renderDistanceInfo(id)}
            <Box component="footer">
              <DeleteButton
                type="button"
                onClick={() => handleDrop(id, dropFunction)}
              >
                <DeleteSweepIcon />
                Excluir desenho
              </DeleteButton>
            </Box>
          </InnerContainer>
        </InfoWindow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    markersCoordinates: state.markersDistance.markersCoordinates,
  };
};

const mapDispatchToProps = {
  ...DrawingCreators,
  ...DrawingAnalysesCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxFilters);
