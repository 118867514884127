import React from "react";
import { connect } from "react-redux";
import { Marker, Polyline } from "@react-google-maps/api";

const RoutePolyline = ({ drawingRoutes = [], crisisModeRoutes = [] }) => {
  const routes = [...drawingRoutes, ...crisisModeRoutes];

  return (
    <React.Fragment>
      {routes.map((element, index) => (
        <Polyline
          key={`drawing-route-${index}`}
          visible={element.isVisible}
          path={element.decodedPolyline}
          options={{
            strokeColor: element.lineColor ?? "yellow",
            strokeWeight: element.isBold || element.isOnFocus ? 8 : 5,
            zIndex: element.isBold || element.isOnFocus ? 5 : 1,
          }}
        />
      ))}

      {routes
        .filter(({ isVisible }) => isVisible)
        .map(({ origin, destination }) => [
          <Marker noRedraw position={origin} zIndex={10} />,
          <Marker noRedraw position={destination} zIndex={10} />,
        ])}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  drawingRoutes: state.routesAnalysis.informations?.filter(
    ({ crisisModule }) => !crisisModule
  ),
  crisisModeRoutes: state.crisisMode.routes?.filter(
    ({ crisisModule }) => crisisModule
  ),
});

export default connect(mapStateToProps, null)(RoutePolyline);
