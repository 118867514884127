import api from "./apiV1";

export const getAreas = ({ filters }) => {
  let areas = api.request({
    url: "areas",
    method: "GET",
    params: filters,
  });

  return areas;
};
