import { Types } from "../actions/crisisMode";

const INITIAL_STATE = {
  update: false,
  routeMode: 0,
  units: [],
  openPopup: false,
  routes: [],
};

export default function crisisMode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.TOGGLE_MODE:
      return {
        ...state,
        openPopup: !state.units.includes(action.payload),
        update: state.units.includes(action.payload),
        units: state.units.includes(action.payload)
          ? state.units.filter((unit) => unit !== action.payload)
          : [...state.units, action.payload],
      };
    case Types.CHANGE_ROUTE_MODE:
      return {
        ...state,
        routeMode: action.payload,
      };
    case Types.TOGGLE_UPDATE:
      return {
        ...state,
        update: action.payload,
      };
    case Types.OPEN_POPUP:
      return {
        ...state,
        openPopup: action.payload,
      };
    case Types.SET_CRISIS_MODE_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case Types.UPDATE_ROUTE:
      return {
        ...state,
        routes: [
          ...state.routes.filter(({ id }) => id !== action.payload.id),
          action.payload,
        ],
      };
    case Types.DELETE_ROUTE:
      return {
        ...state,
        routes: [...state.routes.filter(({ id }) => id !== action.payload)],
      };
    default:
      return state;
  }
}
