import api from "./apiV1";

export const saveRoute = (data) => {
  return api.request({
    url: "routes",
    method: "POST",
    data,
  });
};

export const getRoutes = () => {
  return api.request({
    url: "routes",
    method: "GET",
  });
};

export const deleteRoute = (id) => {
  return api.request({
    url: `routes/${id}`,
    method: "DELETE",
  });
};
