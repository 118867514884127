import { call, put } from "redux-saga/effects";

import { getCustomerUnitsAreas as getCustomerUnitsAreasRequest } from "../services/unitsAreas";
import { Creators as customerUnitsAreas } from "../actions/unitsAreas";
import { flattenDeep } from "lodash";

export default function* getCustomerUnitsAreas(filters) {
  try {
    const list = yield call(getCustomerUnitsAreasRequest, filters);

    list.data = list.data
      .filter((unit) => unit.polygon)
      .map((element) => {
        const allCoordinates = flattenDeep(element.polygon.coordinates).map(
          (encodedCoordinate) => {
            const decodedPath =
              window.google.maps.geometry.encoding.decodePath(
                encodedCoordinate
              );

            return decodedPath.map(({ lat, lng }) => {
              return [lng(), lat()];
            });
          }
        );

        element.turfPolygonsCoordinates = allCoordinates;

        return element;
      });

    yield put(customerUnitsAreas.getCustomerUnitsAreasSucess(list));
  } catch (error) {
    yield put(customerUnitsAreas.getCustomerUnitsAreasFailure(error));
  }
}
