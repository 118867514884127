import { Types } from "../actions/detour";
import { detoursMode } from "../../common/enums";

const INITIAL_STATE = {
  crosshairForDetour: false,
  infosForDetour: [],
  mode: detoursMode.waypoint,
};

export default function coordsForDetour(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SELECT_DETOUR_POINT:
      return {
        ...state,
        crosshairForDetour: true,
      };
    case Types.CANCEL_SELECT_DETOUR:
      return {
        ...state,
        crosshairForDetour: false,
      };
    case Types.ADD_INFOS_FOR_DETOUR:
      return {
        ...state,
        infosForDetour: action.payload,
      };
    case Types.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
}
