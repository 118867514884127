import React from "react";
import { Marker, Polygon } from "@react-google-maps/api";
import { parseArea } from "../../../common/polygonAnalysisHelper";
import Areas from "./Area";

const opts = require("./polyOptions");

const AreaLayer = ({
  areaChild,
  hideMarker,
  markerProps,
  polygonProps,
  showChildAreas,
  handleInfoWindow,
}) => {
  const { options, area, type, color } = polygonProps;

  const renderPointIfExists = () => {
    if (markerProps.location && !hideMarker) {
      const { location } = markerProps;

      return (
        <Marker
          noRedraw
          position={{ lat: location[1], lng: location[0] }}
          title={markerProps.name}
          icon={markerProps.icon}
          onClick={handleInfoWindow}
        />
      );
    }
  };

  const renderPolyIfExistsElsePoint = () => {
    if (type) {
      const { location } = markerProps;

      if (Array.isArray(area)) {
        return area.map((item, index) => {
          return [
            renderPointIfExists(),
            <Polygon
              key={`area-layer-polygon-${index}`}
              style={{ cursor: "pointer" }}
              paths={parseArea(Array.isArray(item) ? item[0] : item)}
              options={opts[options](color)}
              onClick={!location && handleInfoWindow}
            />,
          ];
        });
      } else {
        return [
          renderPointIfExists(),
          <Polygon
            style={{ cursor: "pointer" }}
            paths={parseArea(area)}
            options={opts[options](color)}
            onClick={!location && handleInfoWindow}
          />,
        ];
      }
    } else {
      return renderPointIfExists();
    }
  };

  const hasChildAreas = () => {
    return Boolean(areaChild);
  };

  return (
    <>
      {renderPolyIfExistsElsePoint()}
      {showChildAreas &&
        hasChildAreas() &&
        areaChild.map((area) => {
          if (["riskArea", "subArea"].includes(options)) {
            return (
              <Areas
                id={area.riskZone.id}
                key={area.name + " - sub-areas - " + area.id}
                name={area.name}
                layerType={"subArea"}
                geomType={area.polygon?.type}
                point={area.point || {}}
                icon={area.icon}
                areaInformations={area.areaInformation}
                coordinates={area.polygon.coordinates}
              />
            );
          }
        })}
    </>
  );
};

export default AreaLayer;
