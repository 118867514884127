import React, { useCallback, useEffect, useState } from "react";
import {
  Marker,
  Circle,
  Polygon,
  Polyline,
  GoogleMap,
  Rectangle,
  TrafficLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { toast } from "material-react-toastify";
import ScriptingHeatmap from "./ScriptingHeatmap";

import { containerStyle } from "./styles";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";

import { Creators as Filters } from "../../store/actions/filters";
import { Creators as Detours } from "../../store/actions/detour";
import { Creators as Maps } from "../../store/actions/map";
import { Creators as RoutesAnalysis } from "../../store/actions/routesAnalysis";
import { Creators as Drawings } from "../../store/actions/drawing";
import { Creators as crisisModeActions } from "../../store/actions/crisisMode";

import mapsStyles from "./MapStyles.json";
import Markers from "./markers";
import Areas from "../Map/Areas/Area";
import RoutePolyline from "../SideMenu/DrawerRoutes/RoutePolyline";
import DrawingManager from "./Drawing";
import DrawingManagerAnalyses from "./DrawingAnalyses";
import CustomInfoWindow from "./Areas/CustomInfoWindow";
import { Creators as FormActions } from "../../store/actions/menu";
import RoutesList from "./EvasionPoints/RoutesList";
import StopoverModal from "../SideMenu/DrawerRoutes/StopoverModal";

import routeColors from "../../common/RouteColors";
import { detoursMode } from "../../common/enums";

import Geocode from "react-geocode";
import { searchAddress } from "../../common/googleHelper";
import CareOccurrenceMarkers from "./CareOccurrenceMarkers";
import CareInstallationMarkers from "./CareInstallationMarkers";
import { UnitMarker } from "./styles";

const circleToPolygon = require("circle-to-polygon");

Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY);
Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");
let MyMapComponent = (props) => {
  const {
    mode,
    areas,
    marker,
    mapType,
    userData,
    menuRotas,
    riskAreas,
    formFields,
    ocurrences,
    crisisMode,
    routeCoords,
    rotasEvasao,
    trafficEvents,
    interestPoints,
    infoFormFields,
    publicEntities,
    careOccurrences,
    occurrenceTypes,
    showUnitsLimits,
    mapCoordsCenter,
    searchingMarker,
    drawingsAnalyses,
    activeOcurrences,
    criminalFactions,
    publicEntityTypes,
    settingsFormFields,
    crosshairForDetour,
    customerUnitsAreas,
    markersCoordinates,
    criminalFactionsTypes,
    interestPointsByCustomer,
    riskAreasIdsToViewPoligon,
    publicEntitiesIdsToViewPoligon,
    removeAllDrawings,
    updateRoutes,
    toggleUpdate,
    animateMenu,
    addDrawing,
    cleanAll,
  } = props;

  const [map, setMap] = useState(null);
  const [circleVirtual, setCircleVirtual] = useState(null);
  const [extraMarkers, setExtraMarkers] = useState([]);
  const [dataInfoWindow, setDataInfoWindow] = useState({});
  const [infoWindowActive, setinfoWindowActive] = useState(false);
  const [openStopoverModal, setOpenStopoverModal] = useState(false);
  const [stopoverCoordinates, setStopoverCoordinates] = useState({});

  const [cleanUpMap, setCleanUpMap] = useState(false);

  useEffect(() => {
    setExtraMarkers([]);

    if (formFields.radius && formFields.coords) {
      setCircleVirtual(
        circleToPolygon(
          [formFields.coords.lng, formFields.coords.lat],
          formFields.radius
        )
      );
    } else {
      setCircleVirtual(null);
    }

    const asyncSearchAddress = async () => {
      if (routeCoords) {
        try {
          const date = new Date();
          const infosForDetour = {
            coords: routeCoords,
            isBold: false,
            unitName: routeCoords.unitName,
            isOnFocus: false,
            lineColor: routeColors.length ? routeColors.shift() : "#000000",
            unitAddress: routeCoords.unitName,
            isDraggable: false,
            crisisModule: routeCoords?.crisisMode,
            radius: formFields.radius || null,
          };

          const { drawing, extraInformation } = await searchAddress(
            date.getTime(),
            infosForDetour,
            []
          );

          updateRoutes(extraInformation);
          addDrawing(drawing);
        } catch {
          toast.error("Falha na consulta da rota");
        }
      }
    };

    asyncSearchAddress();
  }, [formFields.radius, formFields.coords, formFields.areaType, routeCoords]);

  useEffect(() => {
    if (crisisMode.units.length > 0) {
      handleEvasionPoints();
    } else {
      handleCleanUpEvasionPoints();
    }
  }, [crisisMode.units]);

  useEffect(() => {
    if (crisisMode.update) {
      handleUpdateEvasionPoints();
    }
  }, [crisisMode.update]);

  const handleEvasionPoints = () => {
    const destinations = [];
    let origin = {};

    crisisMode.units.forEach((unit) => {
      origin = customerUnitsAreas.find(
        (item) => Number(item.id) === Number(unit)
      );

      destinations.push(
        userData.units.find((item) => Number(item.area_id) === Number(unit))
      );
    });

    animateMenu("ABRIR_MENU_ROTAS_EVASAO");
    Promise.all([
      destinations.forEach(async ({ evasion_points }) => {
        await evasion_points.forEach(async (element) => {
          const { extraInformation } = await searchAddress(
            parseInt(Math.random() * 9999999),
            {
              coords: {
                origin: {
                  lat: origin.point.coordinates[1],
                  lng: origin.point.coordinates[0],
                },
                destination: {
                  lat: Number(element.Latitude),
                  lng: Number(element.Longitude),
                },
              },
              isBold: false,
              isOnFocus: false,
              lineColor: "#000",
              isDraggable: false,
              crisisModule: true,
              unitAddress: element.address,
              unitName: element.evasion_point,
            },
            []
          );

          updateRoutes(extraInformation);
        });
      }),
    ]);
  };

  const handleCleanUpEvasionPoints = () => {
    if (!cleanUpMap) {
      cleanAll();
      removeAllDrawings();
      toggleUpdate(false);
      setCleanUpMap(true);
    }
  };

  const handleUpdateEvasionPoints = () => {
    setCleanUpMap(false);
    handleCleanUpEvasionPoints();
  };

  const customerUnitsAreasCoords = customerUnitsAreas.reduce((accu, curr) => {
    accu[curr.id] = curr;

    return accu;
  }, {});

  const libraries = ["geometry", "places", "drawing"];

  const { isLoaded } = useJsApiLoader({
    id: "monitoring-map",
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    language: "pt-BR",
    libraries,
  });

  const activeIncidents = [];

  activeOcurrences.incidents.forEach((element) => {
    const filteredItems = element.filter(({ type }) =>
      occurrenceTypes.includes(type.categoryId)
    );

    if (filteredItems.length > 0) {
      activeIncidents.push(filteredItems);
    }
  });

  const publicEntityTypesIndexed = publicEntityTypes.reduce((accu, curr) => {
    accu[curr.id] = curr;

    return accu;
  }, {});

  const criminalFactionsIndexed = criminalFactionsTypes.reduce((accu, curr) => {
    accu[curr.id] = curr;

    return accu;
  }, {});

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const iconURL = (unitId) => {
    const unit = userData.units.find(({ area_id }) => area_id == unitId);

    const unitIcon = unit.icon
      ? unit.icon
      : process.env.REACT_APP_UNIT_DEFAULT_ICON;
    const crisisModeIcon = process.env.REACT_APP_UNIT_CRISIS_MODE_ICON;

    let url;

    if (crisisMode.units.length > 0) {
      const validation = crisisMode.units.find((unit) => unit === unitId);

      url = validation ? crisisModeIcon : unitIcon;
    } else {
      url = unitIcon;
    }

    return url;
  };

  const handleInfoWindow = (data) => {
    const unit = userData.units.find(
      (unit) => Number(unit.area_id) === data.id
    );

    setDataInfoWindow({
      id: data.id,
      name: data.name,
      address: unit.address,
      lat: data.point.coordinates[1],
      lng: data.point.coordinates[0],
      areaInformation: data.areaInformation,
    });

    setinfoWindowActive(true);
  };

  const onCloseWindow = () => {
    setinfoWindowActive(false);
    setDataInfoWindow({});
  };

  const renderPolygon = (id, coordinates) => {
    return coordinates.map((element, index) =>
      Array.isArray(element)
        ? element.map((item, subItemIndex) =>
            polygonItem(`${id}-${index}-${subItemIndex}`, item)
          )
        : polygonItem(`${id}-${index}`, element)
    );
  };

  const polygonItem = (id, path) => {
    if (typeof path === "string")
      return (
        <Polygon
          key={id}
          path={window.google.maps.geometry.encoding.decodePath(path)}
          options={{
            clickable: true,
            strokeColor: "#6554c0",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#6554c0",
            fillOpacity: 0.5,
          }}
        />
      );
  };

  const markerProvider = (index, position, radius, bounds, path) => {
    return (
      <Marker
        noRedraw
        key={`marker-${index}`}
        position={{
          lat: position.lat,
          lng: position.lng,
        }}
      />
    );
  };

  const circleProvider = (index, position, radius, bounds, path) => {
    return (
      <Circle
        key={`circle-${index}`}
        center={{
          lat: position.lat,
          lng: position.lng,
        }}
        radius={radius}
      />
    );
  };

  const rectangleProvider = (index, position, radius, bounds, path) => {
    return <Rectangle key={`rectangule-${index}`} bounds={bounds} />;
  };

  const polygonProvider = (index, position, radius, bounds, path) => {
    return (
      <Polygon
        key={`polygon-${index}`}
        path={window.google.maps.geometry.encoding.decodePath(path)}
      />
    );
  };

  const polylineProvider = (index, position, radius, bounds, path) => {
    return (
      <Polyline
        key={`polyline-${index}`}
        path={window.google.maps.geometry.encoding.decodePath(path)}
      />
    );
  };

  const renderPolyline = (points) => {
    const coordinates = points.map((point) => {
      return { lat: point.to.lat, lng: point.to.lng };
    });

    return <Polyline path={coordinates} />;
  };

  const addDetourInRoute = async (param) => {
    if (param.routeCoords) {
      const waypoints = props.waypoints
        .filter(
          (waypointFilter) => waypointFilter.timestampId === param.timestampId
        )
        .map((waypoint) => ({
          location: waypoint.location,
          stopover: false,
        }));

      try {
        const infosForDetour = {
          coords: param.coords,
          isBold: param.isBold,
          unitName: param.unitName,
          isOnFocus: param.isOnFocus,
          lineColor: param.lineColor,
          unitAddress: param.unitAddress,
          isDraggable: param.isDraggable,
          crisisModule: param.crisisModule,
          radius: props.formFields.radius || 1000,
        };
        const { drawing, extraInformation } = await searchAddress(
          param.timestampId,
          infosForDetour,
          waypoints
        );

        props.updateRoute(extraInformation);
        props.updateRoutes(extraInformation);

        props.removeDrawing(props.timestampId);
        props.addDrawing(drawing);
      } catch {
        toast.error("Falha na consulta da rota");
      }
    }
  };

  const handleClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    if ((menuRotas || rotasEvasao) && crosshairForDetour) {
      if (props.detoursMode === detoursMode.stopover) {
        setOpenStopoverModal(true);
        setStopoverCoordinates({ lat, lng });
      } else {
        props.change(["coordsForDetour"], { lat, lng });

        const response = await Geocode.fromLatLng(lat, lng);
        const { formatted_address, place_id } = response.results[0];

        props.addWaypointAction({
          id: place_id,
          timestampId: this.props.infosForDetour.timestampId,
          location: formatted_address,
          position: {
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
          },
        });
        addDetourInRoute(props.infosForDetour);
      }
    }

    props.searchingMarker &&
      props.change(["coords"], {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    props.searchingMarker && props.activateCoords("MARKER_SELECTED");
    props.searchingMarker &&
      props.mapCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
  };

  const googleItemsProvider = {
    ["marker"]: markerProvider,
    ["circle"]: circleProvider,
    ["rectangle"]: rectangleProvider,
    ["polygon"]: polygonProvider,
    ["polyline"]: polylineProvider,
  };

  if (!isLoaded) {
    return <></>;
  }

  const MAPS_DEFAULT_OPTIONS = {
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    draggableCursor:
      searchingMarker || ((menuRotas || rotasEvasao) && crosshairForDetour)
        ? "crosshair"
        : null,
    disableDefaultUI: true,
    styles: mapsStyles,
  };

  return (
    <GoogleMap
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={MAPS_DEFAULT_OPTIONS}
      mapContainerStyle={containerStyle}
      center={mapCoordsCenter}
      mapTypeId={mapType}
      onClick={(e) => handleClick(e)}
    >
      {formFields.Units &&
        publicEntities?.map((publicEntity, index) => {
          return (
            <Areas
              key={`area-public-entity-${publicEntity.id}-${index}`}
              id={publicEntity.id}
              publicEntityId={publicEntity.publicEntityId}
              name={publicEntity.area.name}
              layerType={"publicEntity"}
              geomType={
                publicEntitiesIdsToViewPoligon.includes(publicEntity.id) &&
                publicEntity.area.polygon?.type
              }
              point={publicEntity.area.point || {}}
              icon={publicEntity.publicEntity.icon}
              updateDate={publicEntity.updatedAt}
              color={
                publicEntityTypesIndexed[publicEntity.publicEntityId].color
              }
              areaInformations={publicEntity.area.areaInformation}
              coordinates={publicEntity.area.polygon?.coordinates}
              nextUpdate={publicEntity.area?.nextUpdate}
            />
          );
        })}
      {formFields.Units &&
        riskAreas?.map((riskArea, index) => {
          return (
            <Areas
              key={`risk-areas-${riskArea.riskZone.id}-${index}`}
              id={riskArea.riskZone.id}
              name={riskArea.name}
              layerType={"riskArea"}
              geomType={
                !riskAreasIdsToViewPoligon.includes(riskArea.riskZone.id) &&
                riskArea.polygon?.type
              }
              point={riskArea.point || {}}
              icon={riskArea.icon}
              color={
                criminalFactionsIndexed[riskArea.riskZone.criminalFactionId]
                  ?.color
              }
              areaInformations={riskArea.areaInformation}
              coordinates={riskArea.polygon.coordinates}
            />
          );
        })}
      {formFields.Units &&
        criminalFactions?.map((riskArea, index) => {
          return (
            <Areas
              key={`riskzones-${riskArea.riskZone.id}-${index}`}
              id={riskArea.riskZone.id}
              name={riskArea.name}
              layerType={"riskArea"}
              hideMarker={infoFormFields?.hideCriminalFactionsMarkers || false}
              geomType={
                !riskAreasIdsToViewPoligon.includes(riskArea.riskZone.id) &&
                riskArea.polygon?.type
              }
              point={riskArea.point || {}}
              icon={riskArea.icon}
              color={
                criminalFactionsIndexed[riskArea.riskZone.criminalFactionId]
                  ?.color
              }
              areaInformations={riskArea.areaInformation}
              coordinates={riskArea.polygon.coordinates}
            />
          );
        })}
      {interestPoints?.length > 0 && (
        <Markers
          incidents={[]}
          clippings={[]}
          interestPoints={interestPoints}
          interestPointsByCustomer={[]}
          mode={mode}
        />
      )}
      {interestPointsByCustomer?.length > 0 && (
        <Markers
          incidents={[]}
          clippings={[]}
          interestPoints={[]}
          interestPointsByCustomer={interestPointsByCustomer}
          mode={mode}
        />
      )}
      {formFields.Units &&
        customerUnitsAreas.map((item) => {
          return (
            formFields.Units.includes(item.id) && (
              <UnitMarker
                noRedraw
                key={`unit-marker-${item.id}`}
                position={{
                  lat: item.point.coordinates[1],
                  lng: item.point.coordinates[0],
                }}
                title={item.name}
                icon={{
                  url: iconURL(item.id),
                  optimized: true,
                }}
                onClick={() => handleInfoWindow(item)}
              />
            )
          );
        })}
      {formFields.Units && infoWindowActive && (
        <CustomInfoWindow
          id={dataInfoWindow.id}
          title={dataInfoWindow.name}
          position={{
            lat: dataInfoWindow.lat,
            lng: dataInfoWindow.lng,
          }}
          onClose={onCloseWindow}
          areaInformations={
            dataInfoWindow.areaInformation.length > 0
              ? dataInfoWindow.areaInformation
              : [
                  {
                    informationTypeId: 7,
                    information: dataInfoWindow.address,
                  },
                ]
          }
          layerType={"cluster"}
        />
      )}
      {formFields.Units &&
        showUnitsLimits &&
        customerUnitsAreas.map((item) => {
          return (
            formFields.Units.includes(item.id) && (
              <Areas
                key={item.id}
                id={item.id}
                layerType={"cluster"}
                geomType={item.polygon?.type}
                coordinates={item.polygon?.coordinates}
              />
            )
          );
        })}
      {!settingsFormFields?.manchaTermica &&
        (ocurrences?.incidents?.length > 0 ||
          ocurrences?.clippings?.length > 0) && (
          <Markers
            incidents={ocurrences.incidents}
            clippings={ocurrences.clippings}
            interestPointsByCustomer={[]}
            interestPoints={[]}
            mode={mode}
          />
        )}
      <RoutesList />
      {!settingsFormFields.manchaTermica &&
        activeIncidents.length > 0 && [
          <Markers
            incidents={activeIncidents}
            clippings={[]}
            interestPoints={[]}
            interestPointsByCustomer={[]}
            mode={mode}
          />,
          activeIncidents.map((element) =>
            element
              .filter(({ polygon }) => polygon)
              .map(({ id, polygon }) => renderPolygon(id, polygon.coordinates))
          ),
        ]}
      {drawingsAnalyses?.map((item, index) =>
        googleItemsProvider[item.type] ? (
          googleItemsProvider[item.type](
            index,
            item.props.position,
            item.props.radius,
            item.props.bounds
          )
        ) : (
          <></>
        )
      )}

      {<DrawingManager />}

      {<DrawingManagerAnalyses />}

      {formFields.areaType !== "radius" &&
        formFields.areaType !== false &&
        formFields.areaType !== undefined &&
        !searchingMarker &&
        areas?.length > 0 &&
        areas.map((item, index) => {
          return (
            <Areas
              key={item.id + index}
              name={item.name}
              cluster={false}
              layerType={"subArea"}
              coordinates={item.polygon.coordinates}
              geomType={item.polygon.type}
            />
          );
        })}

      {marker && formFields.coords && formFields.radius && (
        <Circle
          center={{ lat: formFields.coords.lat, lng: formFields.coords.lng }}
          radius={formFields.radius}
        />
      )}

      {trafficEvents && <TrafficLayer autoUpdate />}

      <CareInstallationMarkers />

      {!settingsFormFields.manchaTermica && <CareOccurrenceMarkers />}

      {settingsFormFields.manchaTermica && (
        <ScriptingHeatmap
          activeIncidents={activeIncidents}
          incidents={ocurrences.incidents}
          clippings={ocurrences.clippings}
          careOccurrences={careOccurrences}
        />
      )}

      {markersCoordinates.length > 1 && renderPolyline(markersCoordinates)}

      {marker && formFields.coords && (
        <Marker
          noRedraw
          title="Marcador"
          position={{
            lat: formFields.coords.lat,
            lng: formFields.coords.lng,
          }}
          icon={{
            url: "https://geoanalytics.grupounicad.com.br/images/location.png",
            optimized: true,
          }}
        />
      )}

      {props.waypoints?.map(({ position }, index) => (
        <Marker
          noRedraw
          key={`detour-point-${index}`}
          position={{
            lat: position.latitude,
            lng: position.longitude,
          }}
        />
      ))}

      <StopoverModal
        open={openStopoverModal}
        closeModal={() => setOpenStopoverModal(false)}
        coordinates={stopoverCoordinates}
      />

      {crisisMode.units?.map((unit, index) => (
        <Circle
          key={`circle-${index}`}
          center={{
            lat: customerUnitsAreasCoords[unit].point.coordinates[1],
            lng: customerUnitsAreasCoords[unit].point.coordinates[0],
          }}
          radius={1000}
          options={{ fillColor: `#FF0000` }}
        />
      ))}

      <RoutePolyline />
    </GoogleMap>
  );
};

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    infoFormFields: getFormValues("INFO")(state)
      ? getFormValues("INFO")(state)
      : [],
    settingsFormFields: getFormValues("SETTINGS")(state)
      ? getFormValues("SETTINGS")(state)
      : [],
    occurrenceTypes: state.ocurrences.occurenceTypesIds,
    trafficEvents: state.ocurrences.trafficEvents,
    searchingMarker: state.filters.searchingByMarker,
    mapCoordsCenter: state.map.mapCenter,
    zoomMarker: state.map.zoomMarker,
    marker: state.filters.marker,
    mapType: state.Main.mapType,
    areas: state.areas.areas,
    mode: state.Main.mode,
    routeCoords: state.routesAnalysis.routeCoords,
    customerUnitsAreas: state.customerUnitsAreas.customerUnitsAreas,
    showUnitsLimits: state.customerUnitsAreas.showUnitsLimits,
    drawings: state.drawing.drawings,
    drawingsAnalyses: state.drawingAnalyses.drawingsAnalyses,
    crosshairForDetour: state.detours.crosshairForDetour,
    detoursMode: state.detours.mode,
    waypoints: state.routesAnalysis.waypoints,
    infosForDetour: state.detours.infosForDetour,
    menuRotas: state.menu.rotas,
    rotasEvasao: state.menu.rotasEvasao,
    userData: state.auth.userData,
    crisisMode: state.crisisMode,
    criminalFactionsTypes: state.riskAreas.criminalFactions,
    publicEntityTypes: state.publicEntityTypes.publicEntityTypes,
    publicEntitiesIdsToViewPoligon:
      state.publicEntities.publicEntitiesIdsToViewPoligon,
    markersCoordinates: state.markersDistance.markersCoordinates,
    riskAreasIdsToViewPoligon: state.riskAreas.riskAreasIdsToViewPoligon,
    careOccurrences: state.care.occurrences,
  };
};

MyMapComponent = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(MyMapComponent);

const mapDispatchtoProps = {
  ...Maps,
  ...Filters,
  ...RoutesAnalysis,
  ...Drawings,
  ...Detours,
  ...crisisModeActions,
  ...FormActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(MyMapComponent);
