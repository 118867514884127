import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, getFormValues } from "redux-form";

import { Creators as Auth } from "../../store/actions/auth";

import { VectorDiv, LoginFooter, LoginMain, FormContainer } from "./styles";
import vectorLeft from "../../assets/images/vector-left.svg";
import LoginForm from "./LoginForm";
import ForgotForm from "./ForgotForm";

export let Login = (props) => {
  const year = new Date().getFullYear();
  const [forgotPassword, setForgotPassword] = useState(false);

  const openForgotPasswordForm = () => {
    setForgotPassword((state) => !state);
  };

  const Copyright = () => {
    return (
      <LoginFooter>
        <p>
          Desenvolvido por UNICADTEC <strong>{year}©</strong>. Todos os direitos
          reservados.
        </p>
      </LoginFooter>
    );
  };

  return (
    <>
      <LoginMain>
        <FormContainer>
          {forgotPassword ? (
            <ForgotForm openForgotPasswordForm={openForgotPasswordForm} />
          ) : (
            <LoginForm openForgotPasswordForm={openForgotPasswordForm} />
          )}
        </FormContainer>
        <VectorDiv>
          <img src={vectorLeft} />
        </VectorDiv>
        <Copyright />
      </LoginMain>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("LOGIN_FORM")(state),
  };
};

const mapDispatchtoProps = (dispatch) => bindActionCreators(Auth, dispatch);

Login = reduxForm({ form: "LOGIN_FORM" })(Login);

export default connect(mapStateToProps, mapDispatchtoProps)(Login);
