import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

import {
  LegendText,
  LegendIcon,
  GraphicBox,
  LegendItem,
  GraphicTotal,
  GraphicTitle,
  GraphicLegend,
} from "./dashboardStyles";

function Graphic(props) {
  const { datas, title, colors, addFilter, compareGraphic } = props;

  const [rows, setRows] = useState([]);
  const [occurrencesTotal, setOccurrencesTotal] = useState(0);

  useEffect(() => {
    setRows(datas);
    setOccurrencesTotal(
      datas.map(({ value }) => value).reduce((cur, acc) => cur + acc)
    );
  }, [datas]);

  const renderText = (row) => {
    const { name, value } = row;

    return `(${value}) ${name}`;
  };

  return (
    <GraphicBox component="div">
      <GraphicTitle noWrap variant="h4" component="h3">
        {title}
      </GraphicTitle>
      <GraphicLegend component="div">
        <Box component="div" width={250} height={250}>
          <GraphicTotal component="div">{occurrencesTotal}</GraphicTotal>
          <ResponsiveContainer>
            <PieChart width={250} height={250}>
              <Pie
                data={rows}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                innerRadius={50}
                fill="#8884d8"
                label
              >
                {rows.map((row, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index] || "#545454"}
                    onClick={() => addFilter(row, compareGraphic)}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box component="span" style={{ margin: "15px 0" }}>
          {rows.map((row, index) => (
            <LegendItem key={index}>
              <LegendIcon background={colors[index] || "#545454"} />
              <LegendText
                component="div"
                onClick={() => addFilter(row, compareGraphic)}
              >
                {renderText(row)}
              </LegendText>
            </LegendItem>
          ))}
        </Box>
      </GraphicLegend>
    </GraphicBox>
  );
}

export default Graphic;
