import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import { Box } from "@mui/material";

import {
  InfoWindowComponent,
  IncidentTextTitle,
  IncidentTextTime,
  ListContainer,
  AlertPaper,
} from "./styles";

const CustomCareMarker = (props) => {
  const {
    id,
    icon,
    site,
    descr,
    status,
    address,
    regional,
    position,
    criticality,
    description,
    installation,
    incidentType,
    occurrenceType,
    occurrenceDate,
  } = props.data;

  const [isOpen, setIsOpen] = useState(false);

  const events = {
    onClick: () => setIsOpen((state) => !state),
  };

  const formatDate = (date) => {
    const [getDate, getTime] = date.split(" ");
    const [year, month, day] = getDate.split("-");

    return `${day}/${month}/${year} ${getTime}`;
  };

  const handleClose = () => {
    setIsOpen((previous) => !previous);
  };

  const texts = [
    [address, "Endereço:"],
    [regional, "Regional:"],
    [site, "Site:"],
    [installation, "Instalação:"],
    [incidentType, "Tipo de Incidente:"],
    [occurrenceType, "Tipo de Ocorrência:"],
    [criticality, "Criticidade:"],
    [status, "Status:"],
  ];

  return (
    <Marker
      noRedraw={true}
      title={descr}
      key={id}
      position={{ lat: position.latitude, lng: position.longitude }}
      icon={{ url: icon, optimized: false }}
      {...events}
    >
      {isOpen && (
        <InfoWindowComponent
          options={{ maxWidth: 500 }}
          onCloseClick={handleClose}
        >
          <AlertPaper {...events} color="primary">
            <Box component="div">
              <ListContainer>
                <IncidentTextTitle
                  primary={descr}
                  secondary={description}
                  style={{ margin: 0 }}
                />
                {occurrenceDate && (
                  <IncidentTextTime
                    primary={"Ocorrido em " + formatDate(occurrenceDate)}
                  />
                )}
              </ListContainer>

              <ListContainer>
                {texts
                  .filter(([text]) => text)
                  .map(([text, label], index) => (
                    <IncidentTextTitle
                      key={`incident-text-title-${index}`}
                      primary={label}
                      secondary={text}
                    />
                  ))}
              </ListContainer>
            </Box>
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
};

export default CustomCareMarker;
