export function stringfyDate(splitedTime) {
  let timestamp = "0";
  let day, des1, hour, desc2, minute, desc3;
  switch (splitedTime.length) {
    case 6:
      [day, des1, hour, desc2, minute, desc3] = splitedTime;
      timestamp += day > 9 ? `${day}` : `0${day}`;
      timestamp += hour > 9 ? `${hour}` : `0${hour}`;
      timestamp += minute > 9 ? `${minute}` : `0${minute}`;
      break;

    case 4:
      [hour, desc2, minute, desc3] = splitedTime;
      timestamp += hour > 9 ? `${hour}` : `0${hour}`;
      timestamp += minute > 9 ? `${minute}` : `0${minute}`;
      break;

    case 2:
      [minute, desc3] = splitedTime;
      timestamp += minute > 9 ? `${minute}` : `0${minute}`;
      break;

    default:
      timestamp = "0";
  }
  return timestamp;
}

export function convertToLocaleString(date) {
  return new Date(date).toLocaleString("pt-BR");
}
