import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { DialogContent, DialogTitle, IconButton, Dialog } from "@mui/material";
import Table from "./Table";

import {
  LocationOnIcon,
  TitleContainer,
  IconContainer,
  UnitAddress,
  ModalTitle,
  CloseIcon,
  UnitName,
} from "./styles";

import { Creators as CrisisModeCreators } from "../../../store/actions/crisisMode";
import { Creators as FormActions } from "../../../store/actions/menu";
import { selectSortedInformation } from "../../../store/reducers/routesAnalysis";

Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY);
Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");

function RoutesList(props) {
  const {
    open,
    units,
    openPopup,
    information,
    animateMenu,
    crisisModeRoutes,
    customerUnitsAreas,
    setCrisisModeRoutes,
  } = props;

  const [unitName, setUnitName] = useState("");
  const [unitAddress, setUnitAddress] = useState("");
  const [rows, setRows] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (selectedIds.length > 0) {
      const routes = information.filter(({ id }) => selectedIds.includes(id));

      setCrisisModeRoutes(routes);
    } else {
      setCrisisModeRoutes([]);
    }
  }, [selectedIds]);

  const selectAll = () => {
    setCrisisModeRoutes(information);
  };

  const unSelectAll = () => {
    setCrisisModeRoutes([]);
  };

  useEffect(() => {
    if (units.length > 0) {
      const unit = customerUnitsAreas.find(({ id }) => id == units[0]);
      setUnitName(unit.name);
      const [lng, lat] = unit.point.coordinates;
      searchAddress(lat, lng);
    } else {
      setCrisisModeRoutes([]);
    }
  }, [units]);

  useEffect(() => {
    if (information.length > 0) {
      let rows = information
        .filter(({ crisisModule }) => crisisModule)
        .map((item) => {
          return {
            id: item.id,
            position: 0,
            unit: `${item.unitName} | ${item.unitAddress}`,
            time: item.duration_in_traffic,
            distance: item.distance,
          };
        });

      rows.map((item) => {
        item.position = positionNumber(rows, item.id);
        return item;
      });
      setRows(rows);

      information
        .filter(({ crisisModule }) => !crisisModule)
        .map(({ id }) => {
          if (!selectedIds.includes(id)) {
            setSelectedIds([...selectedIds, id]);
          }
        });
    }
  }, [information]);

  const positionNumber = (information, id) => {
    const position = information.map(({ id }) => id).indexOf(id);

    return Number(position + 1);
  };

  const select = (id) => {
    setSelectedIds(
      selectedIds.includes(id)
        ? selectedIds.filter((item) => item !== id)
        : [...selectedIds, id]
    );
  };

  const isSelected = (id) => {
    const ids = crisisModeRoutes.map(({ id }) => id);

    return ids.includes(id);
  };

  const searchAddress = async (lat, lng) => {
    const response = await Geocode.fromLatLng(lat, lng);

    const location = response.results[0];

    if (location) {
      setUnitAddress(location.formatted_address);
    }
  };

  const handleClose = () => {
    openPopup(false);
    animateMenu("ABRIR_MENU_ROTAS_EVASAO");
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{ style: { backgroundColor: "#424242" } }}
    >
      <DialogTitle>
        <TitleContainer component="div">
          <ModalTitle>
            <IconContainer component="div">
              <LocationOnIcon />
            </IconContainer>

            <UnitName>{unitName}</UnitName>

            <UnitAddress>{unitAddress}</UnitAddress>
          </ModalTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </TitleContainer>
      </DialogTitle>
      <DialogContent>
        <Table
          rows={rows}
          select={select}
          isSelected={isSelected}
          selectAll={selectAll}
          unSelectAll={unSelectAll}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.crisisMode.openPopup,
    units: state.crisisMode.units,
    customerUnitsAreas: state.customerUnitsAreas.customerUnitsAreas,
    isLoading: state.routesAnalysis.loading,
    information: selectSortedInformation(state, "duration_in_traffic"),
    crisisModeRoutes: state.crisisMode.routes,
  };
};

const mapDispatchToProps = {
  ...CrisisModeCreators,
  ...FormActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesList);
