import React, { useEffect, useState } from "react";
import { Marker } from "@react-google-maps/api";
import Iframe from "react-iframe";

import { InfoWindowComponent, AlertPaper } from "./styles";

function CustomCameraMarker(props) {
  const { onRef, marker, clusterer } = props;

  const { metadata, area, type, id, pointTypeId } = marker;

  const [isOpen, setIsOpen] = useState(false);
  const [markerRef, setMarkerRef] = useState();

  useEffect(() => {
    if (onRef) {
      setMarkerRef(markerRef);
    }
  }, []);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Marker
      ref={markerRef}
      noRedraw={true}
      title={area.name && area.name}
      key={id}
      position={{
        lat: Number(area.point.coordinates[1]),
        lng: Number(area.point.coordinates[0]),
      }}
      icon={{ url: type.icon, optimized: false }}
      onClick={onToggleOpen}
      clusterer={clusterer}
    >
      {isOpen && (
        <InfoWindowComponent
          options={{ maxWidth: 1000, maxHeigth: 500 }}
          onClick={onToggleOpen}
        >
          <AlertPaper color="primary" onClick={onToggleOpen}>
            <Iframe
              url={metadata.link}
              width={pointTypeId === 206 ? "860px" : "450px"}
              height={pointTypeId === 206 ? "480px" : "450px"}
              id={"idCamera" + id}
              display="initial"
              position="relative"
            />
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
}

export default CustomCameraMarker;
