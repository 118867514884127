export const Types = {
  SET_MARKERS_COORDINATES: "markersDistance/SET_MARKERS_COORDINATES",
};

export const Creators = {
  setMarkersCoordinates: (coordinates) => ({
    type: Types.SET_MARKERS_COORDINATES,
    payload: coordinates,
  }),
};
