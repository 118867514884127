import { Button, ListItemText, Paper, styled } from "@mui/material";
import { InfoWindow } from "@react-google-maps/api";

export const CrisisContainer = styled(Paper)(() => ({
  backgroundColor: "white",
  display: "flex",
  justifyContent: "space-around",
}));

export const CrisisModeButton = styled(Button)(() => ({
  backgroundColor: "#ed4c5c",
  cursor: "pointer",
  color: "#ffffff",
  margin: 10,

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#880010",
  },
}));

export const InfoWindowComponent = styled(InfoWindow)(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  color: "#212121",
}));

export const AlertPaper = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  color: "#212121",
}));

export const IncidentTextTitle = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
  },
  "& p": {
    fontSize: "14px",
    fontWeight: "400",
    color: "black",
  },
}));

export const SubareaButton = styled("button")(() => ({
  backgroundColor: "red",
  color: "#fff",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "30px",

  border: "none",
  outline: "none",
  cursor: "pointer",

  "& svg": {
    marginRight: "0.5rem",
  },

  "&:disabled": {
    backgroundColor: "#c0c0c0",
    cursor: "not-allowed",
    opacity: "0.4",
  },
}));
