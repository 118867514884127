import { Types } from "../actions/unitsAreas";

const INITIAL_STATE = {
  customerUnitsAreas: [],
  loading: false,
  loadingCustomerUnitsAreasFailed: false,
  loaded: false,
  showUnitsLimits: false,
};

export default function getCustomerUnitsAreas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CUSTOMER_UNITS_AREAS:
      return {
        ...state,
        loading: true,
        loadingCustomerUnitsAreasFailed: false,
      };
    case Types.GET_CUSTOMER_UNITS_AREAS_SUCCESS:
      return {
        ...state,
        customerUnitsAreas: action.payload.data,
        loaded: true,
        loading: false,
      };
    case Types.GET_CUSTOMER_UNITS_AREAS_FAILURE:
      return {
        ...state,
        loadingCustomerUnitsAreasFailed: true,
        loading: false,
      };
    case Types.SHOW_CUSTOMER_UNITS_LIMITS:
      return {
        ...state,
        showUnitsLimits: !state.showUnitsLimits,
      };
    default:
      return state;
  }
}
