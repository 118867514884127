import api from "./apiV1";

export const getCategoriesRequest = () => {
  return api.get("incidents-types");
};

export const getClippingsCategoriesRequest = () => {
  return api.get("clippings-types");
};

export const getMediaGroupsRequest = () => {
  return api.get("media-groups");
};
