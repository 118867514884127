import circle from "@turf/circle";

export const getCircleAnalysis = (circleProps) => {
  let isCompleted = true;
  for (let prop in circleProps) {
    if (!circleProps[prop.toString()]) {
      isCompleted = false;
    }
  }

  if (isCompleted) {
    const { lat, lng, radius } = circleProps;

    const center = [lng, lat];
    const options = { steps: 64, units: "meters" };
    const circleAnalysis = circle(center, radius, options);

    return circleAnalysis;
  }

  return undefined;
};
