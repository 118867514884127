import { call, put } from "redux-saga/effects";

import { getPublicEntityTypes as getPublicEntityTypesRequest } from "../services/publicEntityTypes";
import { Creators as filtersActions } from "../actions/publicEntityTypes";

export default function* getPublicEntityTypes() {
  try {
    const list = yield call(getPublicEntityTypesRequest);
    yield put(filtersActions.getPublicEntityTypesSuccess(list));
  } catch (error) {
    yield put(filtersActions.getPublicEntityTypesFailure(error));
  }
}
