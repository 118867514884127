export const Types = {
  ADD_WAYPOINT: "routesAnalysis/ADD_WAYPOINT",
  REMOVE_WAYPOINT_BY_ID: "routesAnalysis/REMOVE_WAYPOINT_BY_ID",
  REMOVE_ALL_WAYPOINTS: "routesAnalysis/REMOVE_ALL_WAYPOINTS",
  CLEAR_WAYPOINTS: "routesAnalysis/CLEAR_WAYPOINTS",
  EDIT_WAYPOINT: "routesAnalysis/EDIT_WAYPOINT",
  ADD_ROUTE: "routesAnalysis/ADD_ROUTE",
  UPDATE_ROUTES: "routesAnalysis/UPDATE_ROUTES",
  CLEAN_ALL: "routeAnalysis/CLEAN_ALL",
  SET_VISIBLE: "routesAnalysis/SET_VISIBLE",
  SET_BOLD: "routesAnalysis/SET_BOLD",
  SET_FOCUS: "routesAnalysis/SET_FOCUS",
  DELETE_ROUTE: "routesAnalysis/DELETE_ROUTE",
  DELETE_CRISIS_MODULE_ROUTES: "routesAnalysis/DELETE_CRISIS_MODULE_ROUTES",
  SET_DRAGGABLE: "routesAnalysis/SET_DRAGGABLE",
  FETCH_ERROR: "routeAnalysis/FETCH_ERROR",
  FETCH_SUCESS: "routeAnalysis/FETCH_SUCESS",
};

export const Creators = {
  addWaypointAction: (waypoint) => ({
    type: Types.ADD_WAYPOINT,
    payload: waypoint,
  }),

  removeWaypointById: (id) => ({
    type: Types.REMOVE_WAYPOINT_BY_ID,
    payload: id,
  }),

  removeAllWaypointsByTimestempId: (waypointTimestempId) => ({
    type: Types.REMOVE_ALL_WAYPOINTS,
    payload: waypointTimestempId,
  }),

  clearWaypoints: () => ({
    type: Types.CLEAR_WAYPOINTS,
  }),

  editWaypoint: (waypoint) => ({
    type: Types.EDIT_WAYPOINT,
    payload: waypoint,
  }),

  addNewRoute: (coords) => ({
    type: Types.ADD_ROUTE,
    payload: coords,
  }),

  updateRoutes: (newData) => ({
    type: Types.UPDATE_ROUTES,
    payload: newData,
  }),

  cleanAll: () => ({
    type: Types.CLEAN_ALL,
  }),

  setVisible: (timestampId) => ({
    type: Types.SET_VISIBLE,
    payload: timestampId,
  }),

  setBold: (timestampId) => ({
    type: Types.SET_BOLD,
    payload: timestampId,
  }),

  setFocus: (timestampId) => ({
    type: Types.SET_FOCUS,
    payload: timestampId,
  }),

  setDraggable: (timestampId) => ({
    type: Types.SET_DRAGGABLE,
    payload: timestampId,
  }),

  deleteRoute: (timestampId) => ({
    type: Types.DELETE_ROUTE,
    payload: timestampId,
  }),

  deleteCrisisModuleRoutes: (address) => ({
    type: Types.DELETE_CRISIS_MODULE_ROUTES,
    payload: address,
  }),

  fetchError: () => ({
    type: Types.FETCH_ERROR,
  }),

  fetchSucess: () => ({
    type: Types.FETCH_SUCESS,
  }),
};
