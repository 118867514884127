export const Types = {
  LOGIN: "auth/LOGIN",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_FAILED: "auth/LOGIN_FAILED",
  LOGOUT: "auth/LOGOUT",
  LOGOUT_SUCCESS: "auth/LOGOUT_SUCCESS",
  GET_USER: "auth/GET_USER",
  GET_USER_FAILED: "auth/GET_USER_FAILED",

  ASYNC_SSO_AUTHENTICATE: "ASYNC_SSO_AUTHENTICATE",
  ASYNC_SSO_REFRESH_AUTHENTICATE: "ASYNC_SSO_REFRESH_AUTHENTICATE",

  SUCCESS_MAIN: "SUCCESS_MAIN",
  FAILURE_MAIN: "FAILURE_MAIN",
};

export const Creators = {
  loginUser: (username, password) => ({
    type: Types.LOGIN,
    username,
    password,
  }),
  loginSuccess: (data) => ({
    type: Types.LOGIN_SUCCESS,
    data,
  }),
  loginFailed: () => ({
    type: Types.LOGIN_FAILED,
  }),
  logout: () => ({
    type: Types.LOGOUT,
  }),
  logoutSuccess: () => ({
    type: Types.LOGOUT_SUCCESS,
  }),
  getUser: (data) => ({
    type: Types.GET_USER,
    data,
  }),
  getUserFailed: () => ({
    type: Types.GET_USER_FAILED,
  }),
};

export const loginSSO = (sso, access_token) => ({
  type: Types.ASYNC_SSO_AUTHENTICATE,
  payload: { sso, access_token },
});
