import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import { Box } from "@mui/material";

import {
  InfoWindowComponent,
  IncidentTextTitle,
  ListContainer,
  AlertPaper,
} from "./styles";

const CustomInstallationMarker = (props) => {
  const {
    id,
    site,
    descr,
    address,
    position,
    regional,
    riskLevel,
    installationType,
    installationCategory,
  } = props.data;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen((previos) => !previos);
  };

  const texts = [
    [address, "Endereço:"],
    [site, "Site:"],
    [regional, "Regional:"],
    [installationType, "Tipo da Instalação:"],
    [installationCategory, "Categoria da Instalação:"],
    [riskLevel, "Nível de risco:"],
  ];

  return (
    <Marker
      noRedraw={true}
      title={descr}
      key={id}
      position={{ lat: position.latitude, lng: position.longitude }}
      onClick={handleClose}
    >
      {isOpen && (
        <InfoWindowComponent
          options={{ maxWidth: 500 }}
          onCloseClick={() => setIsOpen((state) => !state)}
        >
          <AlertPaper color="primary" onClick={handleClose}>
            <Box component="div">
              <ListContainer style={{ marginBottom: "10px" }}>
                <IncidentTextTitle primary={descr} style={{ margin: 0 }} />
              </ListContainer>

              <ListContainer>
                {texts
                  .filter(([text]) => text)
                  .map(([text, label], index) => (
                    <IncidentTextTitle
                      key={`incident-text-title-${index}`}
                      primary={label}
                      secondary={text}
                    />
                  ))}
              </ListContainer>
            </Box>
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
};

export default CustomInstallationMarker;
