import React, { useCallback, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Draggable from "react-draggable";

import Timer from "../Timer";

import { Creators as routesAnalysis } from "../../../../store/actions/routesAnalysis";

import {
  CenterFocusWeak as CenterFocusWeakIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@mui/icons-material";

import {
  InformationContainer,
  EllipsisContainer,
  DraggablePaper,
  MainContainer,
  VerticalLine,
  FloatAddress,
  TimelineIcon,
  AlarmIcon,
  CloseIcon,
  Line,
  Dot,
} from "../routeStyles";
import { Box, Typography } from "@mui/material";

const DraggableRoute = (props) => {
  const {
    origin,
    isBold,
    distance,
    isVisible,
    isOnFocus,
    lineColor,
    waypoints,
    destination,
    timestampId,
    durationInTraffic,
  } = props;

  const dispatch = useDispatch();

  const [showOriginFullAddress, setShowOriginFullAddress] = useState(false);
  const [showDestinationFullAddress, setShowDestinationFullAddress] =
    useState(false);
  const [showDetourFullAddress, setShowDetourFullAddress] = useState(false);

  const handleShowOrigin = () => {
    setShowOriginFullAddress(true);
  };

  const handleHideOrigin = () => {
    setShowOriginFullAddress(false);
  };

  const handleShowDestination = () => {
    setShowDestinationFullAddress(true);
  };

  const handlehideDestination = () => {
    setShowDestinationFullAddress(false);
  };

  const handleShowDetourAddress = () => {
    setShowDetourFullAddress(true);
  };

  const handleHideDetourAddress = () => {
    setShowDetourFullAddress(false);
  };

  const getCurrentTimer = useCallback(() => {
    const initialTimestamp = new Date(timestampId);
    const currentTimestamp = new Date();

    const intervalInSeconds = (currentTimestamp - initialTimestamp) / 1000;
    const secondsPassed = Math.round(intervalInSeconds);

    return { secondsPassed };
  }, [timestampId]);

  const renderWaypoint = (waypoint) => {
    return (
      <InformationContainer>
        <VerticalLine />
        <Dot>
          <Box component="div" />
        </Dot>
        <Box
          component="div"
          onMouseEnter={handleShowDetourAddress}
          onMouseLeave={handleHideDetourAddress}
        >
          <EllipsisContainer>{String(waypoint.location)}</EllipsisContainer>

          {showDetourFullAddress && (
            <FloatAddress>{String(waypoint.location)}</FloatAddress>
          )}
        </Box>
      </InformationContainer>
    );
  };

  return (
    <Draggable>
      <DraggablePaper
        onMouseEnter={() => dispatch(routesAnalysis.setFocus(timestampId))}
        onMouseLeave={() => dispatch(routesAnalysis.setFocus(timestampId))}
        style={isOnFocus && { borderColor: lineColor }}
      >
        <Box component="header">
          <Box component="div">
            <AlarmIcon />
            <Typography component="i">{durationInTraffic}</Typography>
          </Box>
          <Box component="div">
            <TimelineIcon />
            <Typography component="i">{distance}</Typography>
          </Box>
        </Box>
        <button
          onClick={() => dispatch(routesAnalysis.setDraggable(timestampId))}
        >
          <CloseIcon />
        </button>

        <MainContainer>
          <Box component="div">
            <InformationContainer>
              <VerticalLine />
              <Dot>
                <Box component="div" />
              </Dot>

              <Box
                component="div"
                onMouseEnter={handleShowOrigin}
                onMouseLeave={handleHideOrigin}
              >
                <EllipsisContainer style={{ maxWidth: "35ch" }}>
                  {origin}
                </EllipsisContainer>

                {showOriginFullAddress && <FloatAddress>{origin}</FloatAddress>}
              </Box>
            </InformationContainer>

            {waypoints.map(renderWaypoint)}

            <InformationContainer>
              <Dot>
                <Box component="div" />
              </Dot>
              <Box
                component="div"
                onMouseOver={handleShowDestination}
                onMouseLeave={handlehideDestination}
              >
                <EllipsisContainer style={{ maxWidth: "35ch" }}>
                  {destination}
                </EllipsisContainer>

                {showDestinationFullAddress && (
                  <FloatAddress>{destination}</FloatAddress>
                )}
              </Box>
            </InformationContainer>
          </Box>

          <Line />

          <Box component="footer">
            <Timer timePassed={getCurrentTimer()} card={false} />
            <Box component="div">
              <button
                onClick={() => dispatch(routesAnalysis.setVisible(timestampId))}
              >
                {isVisible ? (
                  <VisibilityOutlinedIcon fontSize="large" />
                ) : (
                  <VisibilityOffOutlinedIcon fontSize="large" />
                )}
              </button>
              <button
                onClick={() => dispatch(routesAnalysis.setBold(timestampId))}
              >
                {isBold ? (
                  <CenterFocusStrongIcon fontSize="large" />
                ) : (
                  <CenterFocusWeakIcon fontSize="large" />
                )}
              </button>
            </Box>
          </Box>
        </MainContainer>
      </DraggablePaper>
    </Draggable>
  );
};

const mapStateToProps = (state, ownProps) => {
  let timestamp = ownProps.timestampId;
  const info = state.routesAnalysis.informations.filter(
    (info) => info.id === timestamp
  )[0];
  return (
    state.routesAnalysis.informations.length > 0 && {
      origin: info.start_address,
      destination: info.end_address,
      durationInTraffic: info.duration_in_traffic,
      distance: info.distance,
      lineColor: info.lineColor,
      isVisible: info.isVisible,
      isBold: info.isBold,
      isOnFocus: info.isOnFocus,
      isDraggable: info.isDraggable,
      timestampId: timestamp,
      waypoints: info.waypoints,
      pos: ownProps.pos,
    }
  );
};

export default connect(mapStateToProps, null)(DraggableRoute);
