import React, { useState } from "react";
import { useSelector } from "react-redux";

import CustomInfoWindow from "./CustomInfoWindow";
import AreaLayer from "./AreaLayer";

import { selectRiskAreaChildAreas } from "../../../store/reducers/riskAreas";
import { selectPublicEntityChildAreas } from "../../../store/reducers/public-entities";

const Areas = (props) => {
  const [positionInfoWindow, setPositionInfoWindow] = useState(null);
  const [showChildAreas, setShowChildAreas] = useState(false);

  const areaLayerTypes = [
    "riskArea",
    "publicEntity",
    "cluster",
    "subArea",
    "interestPoint",
    "interestPointsByCustomer",
  ];

  const getChildStrategy = {
    riskArea: selectRiskAreaChildAreas,
    subArea: selectRiskAreaChildAreas,
    publicEntity: selectPublicEntityChildAreas,
  };

  const areaChild = useSelector((state) => {
    const { layerType, id } = props;

    if (Object.keys(getChildStrategy).includes(layerType)) {
      return getChildStrategy[layerType](state, id);
    }

    return undefined;
  });

  const handleChildAreas = () => {
    setShowChildAreas((state) => !state);
    setPositionInfoWindow(null);
  };

  const showWindow = (event) => {
    setPositionInfoWindow({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  const onCloseWindow = () => {
    setPositionInfoWindow(null);
  };

  return (
    <>
      {positionInfoWindow && (
        <CustomInfoWindow
          id={props.id}
          publicEntityId={props.publicEntityId}
          title={props.name}
          position={positionInfoWindow}
          callback={areaChild && handleChildAreas}
          onClose={onCloseWindow}
          areaInformations={props.areaInformations}
          layerType={props.layerType}
          subAreaMode={showChildAreas}
          coordinates={props.coordinates}
          updateDate={props.updateDate}
          nextUpdate={props.nextUpdate}
        />
      )}
      {areaLayerTypes.includes(props.layerType) && (
        <AreaLayer
          showChildAreas={showChildAreas}
          handleInfoWindow={showWindow}
          areaChild={areaChild}
          hideMarker={props.hideMarker}
          polygonProps={{
            type: props.geomType,
            area: props.coordinates,
            options: props.layerType,
            color: props.color,
          }}
          markerProps={{
            id: props.key,
            name: props.name,
            location: props.point?.coordinates
              ? props.point.coordinates
              : undefined,
            icon: props.icon,
          }}
        />
      )}
    </>
  );
};

export default Areas;
