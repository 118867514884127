import React from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Divider } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import {
  NavList,
  TitleText,
  SideFooter,
  MainDrawer,
  ActionButton,
  DrawerHeader,
  IncidentText,
  IncidentItem,
  IncidentGroup,
  MainSideContent,
  IncidentTextTime,
  InciedntTextTitle,
} from "./drawerIncidenteStyles";

import { Creators as FormActions } from "../../store/actions/menu";
import { Creators as Maps } from "../../store/actions/map";

const DrawerIncidente = (props) => {
  const {
    Traffic,
    menuOpen,
    Security,
    incidents,
    clippings,
    formFields,
    animateMenu,
    Contingence,
    occurrenceTypes,
    activeIncidents,
    mapCenter,
  } = props;

  const incidentClick = (location) => {
    mapCenter(location);
  };

  const categoriesFiltered = () => {
    const categoriesFiltered = [];

    Traffic && categoriesFiltered.push("1");
    Security && categoriesFiltered.push("2");
    Contingence && categoriesFiltered.push("7");

    return categoriesFiltered;
  };

  const renderAlertComponent = (marker) => {
    return (
      <div
        key={marker.id}
        onClick={() => {
          incidentClick(marker.location);
        }}
      >
        <IncidentGroup key={marker.id}>
          <IncidentItem component="div">
            <img src={marker.type.icon} alt="Ícone" />
          </IncidentItem>
          <IncidentItem component="div">
            <InciedntTextTitle primary={marker.title} />
            <IncidentText primary={marker.description} />
            {marker.startedAt && (
              <IncidentTextTime
                primary={
                  "Início em " +
                  dayjs(marker.startedAt)
                    .add(3, "hours")
                    .format("DD/MM/YYYY HH:mm")
                }
              />
            )}
            {marker.occurredAt && (
              <IncidentTextTime
                primary={
                  "Ocorrido em " +
                  dayjs(marker.occurredAt)
                    .add(3, "hours")
                    .format("DD/MM/YYYY HH:mm")
                }
              />
            )}
          </IncidentItem>
        </IncidentGroup>
        <Divider />
      </div>
    );
  };

  return (
    <MainDrawer anchor="right" variant="persistent" open={menuOpen}>
      <DrawerHeader>
        <TitleText variant="h6" noWrap>
          Lista de Incidentes
        </TitleText>
        <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
          <CloseIcon />
        </ActionButton>
      </DrawerHeader>
      <Divider />
      <MainSideContent component="div">
        <NavList component="nav">
          {incidents &&
            incidents.map(
              (marker) =>
                ((formFields.incident_type_id &&
                  formFields.incident_type_id.includes(
                    String(marker.incidentTypeId)
                  )) ||
                  categoriesFiltered().includes(
                    String(marker.type.categoryId)
                  )) &&
                renderAlertComponent(marker)
            )}
          {activeIncidents &&
            occurrenceTypes.length > 0 &&
            activeIncidents.map((marker) => renderAlertComponent(marker))}
          {clippings &&
            formFields.clipping_type_id &&
            clippings.map(
              (marker) =>
                formFields.clipping_type_id.includes(marker.clippingTypeId) &&
                renderAlertComponent(marker)
            )}
        </NavList>
      </MainSideContent>
      <SideFooter xs={1} component="div" color="secondary">
        <span>Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;</span>
      </SideFooter>
    </MainDrawer>
  );
};

const mapDispatchtoProps = {
  ...FormActions,
  ...Maps,
};

const mapStateToProps = (state) => ({
  activeIncidents: state.ocurrences.activeIncidents,
  Contingence: state.categories.contingence,
  Security: state.categories.security,
  Traffic: state.categories.traffic,
  incidents: state.ocurrences.incidents,
  clippings: state.ocurrences.clippings,
  occurrenceTypes: state.ocurrences.occurenceTypesIds,
  menuOpen: state.menu.incidentes,
  formFields: getFormValues("ANALISE")(state)
    ? getFormValues("ANALISE")(state)
    : [],
});

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerIncidente);
