import { call, put, take, delay } from "redux-saga/effects";

import moment from "moment";

import { Types } from "../actions/auth";

import * as ssoService from "../services/sso";

function clearAuth() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("@sso_integration");
  localStorage.removeItem("@sso_expires_at");
  localStorage.removeItem("permissions");
}

export default function* refreshSSOAuth() {
  const sso = yield call(ssoService.getSSOIntegration);
  if (sso) {
    while (true) {
      const expiresAt = yield call(ssoService.getSSOExpiresAt);
      const refreshToken = localStorage.getItem("refreshToken");

      if (expiresAt) {
        const diffToExpires = moment(expiresAt).diff(moment(), "minutes");

        if (diffToExpires <= 5) {
          try {
            const { data } = yield call(ssoService.ssoRefresh, {
              sso,
              refresh_token: refreshToken,
            });

            localStorage.setItem("token", data.access_token);
            localStorage.setItem("refreshToken", data.refresh_token);

            yield call(ssoService.setSSOExpiresAt, data.expires_at);

            yield put({ type: Types.SUCCESS_MAIN, payload: data.user });
          } catch (err) {
            clearAuth();
            window.location.href = `${process.env.REACT_APP_SSO_URL}/login?sso=${sso}&redirect=${process.env.REACT_APP_URL_APP}login/sso`;
            yield take("FOREVER");
          }
        }
      }
      yield delay(6000);
    }
  }
}
