export const Types = {
  GET_PUBLIC_ENTITIES: "public-entities/GET_PUBLIC_ENTITIES",
  GET_PUBLIC_ENTITIES_SUCCESS: "public-entities/GET_PUBLIC_ENTITIES_SUCCESS",
  GET_PUBLIC_ENTITIES_FAILURE: "public-entities/GET_PUBLIC_ENTITIES_FAILURE",
  HANDLE_PUBLIC_ENTITY_POLIGON: "public-entities/HANDLE_PUBLIC_ENTITY_POLIGON",
};

export const Creators = {
  getPublicEntities: (filters) => ({
    type: Types.GET_PUBLIC_ENTITIES,
    filters,
  }),
  getPublicEntitiesSuccess: (filters) => ({
    type: Types.GET_PUBLIC_ENTITIES_SUCCESS,
    filters,
  }),
  getPublicEntitiesFailure: (filters) => ({
    type: Types.GET_PUBLIC_ENTITIES_FAILURE,
    filters,
  }),
  handlePublicEntityPoligon: (publicEntityId) => ({
    type: Types.HANDLE_PUBLIC_ENTITY_POLIGON,
    payload: publicEntityId,
  }),
};
