import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point } from "@turf/helpers";

export const filterIncidentsByRadiusIntersection = ({
  incident,
  filterablePolygons,
}) => {
  if (filterablePolygons.length === 0 || !incident) {
    return true;
  }

  const { lat, lng } = incident.location;
  const incidentPoint = point([lng, lat]);

  return filterablePolygons.some((polygon) =>
    booleanPointInPolygon(incidentPoint, polygon)
  );
};
