import { call, put } from "redux-saga/effects";

import { getAreaInformationTypes as getAreaInformationTypesRequest } from "../services/areaInformationTypes";
import { Creators as actions } from "../actions/areaInformationTypes";

export default function* getAreaInformationTypes() {
  try {
    const list = yield call(getAreaInformationTypesRequest);
    yield put(actions.getAreaInformationTypesSucceeded(list));
  } catch (error) {
    yield put(actions.getAreaInformationTypesFailed(error));
  }
}
