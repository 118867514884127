import { Types } from "../actions/interestPoints";

const INITIAL_STATE = {
  interestPointsTypes: [],
  interestPoints: [],
  interestPointsTypesByCustomer: [],
  interestPointsByCustomer: [],
  loadingPointsByCustomer: false,
  loadingPointsFailedByCustomer: false,
  loadingTypesByCustomer: false,
  loadingTypesByCustomerFailed: false,
  loadingPoints: false,
  loadingPointsFailed: false,
  loadingTypes: false,
  loadingTypesFailed: false,
  interestPointsIdsToViewPoligon: [],
  interestPointsByCustomerIdsToViewPoligon: [],
};

export default function getInterestPoints(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_INTEREST_POINTS_TYPES:
      return {
        ...state,
        loadingTypes: true,
        loadingTypesFailed: false,
      };
    case Types.GET_INTEREST_POINTS_TYPES_SUCCESS:
      return {
        ...state,
        interestPointsTypes: action.data.data,
        loadingTypes: false,
      };
    case Types.GET_INTEREST_POINTS_TYPES_FAILURE:
      return {
        ...state,
        interestPointsTypes: [],
        loadingTypes: false,
        loadingTypesFailed: true,
      };
    case Types.GET_INTEREST_POINTS:
      return {
        ...state,
        loadingPoints: true,
        loadingPointsFailed: false,
      };
    case Types.GET_INTEREST_POINTS_SUCCESS:
      return {
        ...state,
        interestPoints: action.data.data,
        loadingPoints: false,
      };
    case Types.GET_INTEREST_POINTS_FAILURE:
      return {
        ...state,
        loadingPointsFailed: true,
        loadingPoints: false,
      };
    case Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER:
      return {
        ...state,
        loadingTypesByCustomer: true,
        loadingTypesByCustomerFailed: false,
      };
    case Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        interestPointsTypesByCustomer: action.data.data,
        loadingTypesByCustomer: false,
      };
    case Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        interestPointsTypesByCustomer: [],
        loadingTypesByCustomer: false,
        loadingTypesByCustomerFailed: true,
      };
    case Types.GET_INTEREST_POINTS_BY_CUSTOMER:
      return {
        ...state,
        loadingPointsByCustomer: true,
        loadingPointsFailedByCustomer: false,
      };
    case Types.GET_INTEREST_POINTS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        interestPointsByCustomer: action.data.data,
        loadingPointsByCustomer: false,
      };
    case Types.GET_INTEREST_POINTS_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        interestPointsByCustomer: [],
        loadingPointsByCustomer: false,
        loadingPointsFailedByCustomer: true,
      };
    case Types.HANDLE_INTEREST_POINTS_POLIGON:
      return {
        ...state,
        interestPointsIdsToViewPoligon:
          state.interestPointsIdsToViewPoligon.includes(action.payload)
            ? state.interestPointsIdsToViewPoligon.filter(
                (item) => item !== action.payload
              )
            : [...state.interestPointsIdsToViewPoligon, action.payload],
      };
    case Types.HANDLE_INTEREST_POINTS_BY_CUSTOMER_POLIGON:
      return {
        ...state,
        interestPointsByCustomerIdsToViewPoligon:
          state.interestPointsByCustomerIdsToViewPoligon.includes(
            action.payload
          )
            ? state.interestPointsByCustomerIdsToViewPoligon.filter(
                (item) => item !== action.payload
              )
            : [
                ...state.interestPointsByCustomerIdsToViewPoligon,
                action.payload,
              ],
      };
    default:
      return state;
  }
}
