import { call, put } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";

import {
  Login as LoginAxios,
  GetPermisstion,
  GetUser,
  Logout,
} from "../services/login";
import { Creators as AuthActions } from "../actions/auth";
import dayjs from "dayjs";
import { toast } from "material-react-toastify";

export default function* loginRequest(params) {
  yield put(startSubmit("LOGIN_FORM"));
  try {
    const data = yield call(LoginAxios, params);
    const token = data.data.access_token;

    const user = yield call(GetUser, token);
    const customer = user.data?.customer;

    if (customer.poc && dayjs(customer.expiration_date).isBefore(new Date())) {
      yield call(Logout, token);
      yield put(stopSubmit("LOGIN_FORM", { username: [], password: [] }));
      yield put(AuthActions.loginFailed());
      toast.error(
        "Verificamos que o seu período de testes terminou, favor entrar em contato com: comercial@grupounicad.com.br"
      );
      return;
    }

    yield put(stopSubmit("LOGIN_FORM"));
    yield put(AuthActions.loginSuccess(data));

    localStorage.setItem("token", token);

    const permissions = yield call(GetPermisstion, token);

    localStorage.setItem("permissions", permissions.data);

    window.location.href = `${process.env.PUBLIC_URL}/`;
  } catch (error) {
    yield put(stopSubmit("LOGIN_FORM", { username: [], password: [] }));
    yield put(AuthActions.loginFailed(error));
    toast.error("Usuário ou senha incorretos");
  }
}
