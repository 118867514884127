import { Types } from "../actions/care";

const INITIAL_STATE = {
  occurrences: [],
  installations: [],
};

const careReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_OCCURRENCES:
      return {
        ...state,
        occurrences: action.payload,
      };

    case Types.SET_INSTALLATIONS:
      return {
        ...state,
        installations: action.payload,
      };

    default:
      return state;
  }
};

export default careReducer;
