import { Types } from "../actions/drawing";

const INITIAL_STATE = {
  drawings: [],
};

const drawingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ADD_DRAWING:
      return {
        ...state,
        drawings: [...state.drawings, action.payload],
      };

    case Types.REMOVE_DRAWING:
      return {
        ...state,
        drawings: state.drawings.filter(
          (drawing) => drawing.id !== action.payload
        ),
      };

    case Types.REMOVE_ALL_DRAWINGS:
      return {
        ...state,
        drawings: [],
      };

    default:
      return state;
  }
};

export default drawingReducer;
