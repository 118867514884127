import { put } from "redux-saga/effects";

import { Creators as AuthActions } from "../actions/auth";

export default function* Logout() {
  try {
    yield put(AuthActions.logoutSuccess());
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("@sso_integration");
    localStorage.removeItem("@sso_expires_at");
    localStorage.removeItem("permissions");
  } catch (error) {
    yield put(AuthActions.logoutSuccess(error));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("@sso_integration");
    localStorage.removeItem("@sso_expires_at");
    localStorage.removeItem("permissions");
  }
}
