import api from "./apiV1";

export const saveScene = (data) => {
  return api.request({
    url: "sceneries",
    method: "POST",
    data,
  });
};
export const getScenarios = () => {
  return api.request({
    url: "sceneries",
    method: "GET",
  });
};

export const deleteScene = (id) => {
  return api.request({
    url: `sceneries/${id}`,
    method: "DELETE",
  });
};
