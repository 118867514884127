import React from "react";
import { useDispatch } from "react-redux";

import {
  ErrorOutlineIcon,
  MessageContainer,
  ActionContainer,
  SecondaryButton,
  TitleContainer,
  TextContainer,
  MainContainer,
  ResolveButton,
  Container,
  Title,
} from "./styles";

import { Creators as MenuActions } from "../../store/actions/menu";
import { Creators as DrawingActions } from "../../store/actions/drawing";

const Warning = (props) => {
  const dispatch = useDispatch();

  const behaviorOptions = {
    callByDrawingManager: {
      text: "Tem certeza que deseja finalizar o módulo de desenho? Você vai perder todos os dados criados.",
      firstButtonText: "Sim",
      secondButtonText: "Não",
      buttonAction: () => {
        dispatch(DrawingActions.removeAllDrawings());
        dispatch(MenuActions.animateMenu("ABRIR_DRAWING_MANAGER"));
      },
    },
    callBySelectingEntities: {
      text: "Para visualizar este item você precisa selecionar alguma unidade de filtro. Deseja aplicar agora?",
      firstButtonText: "Selecionar",
      secondButtonText: "Cancelar",
      buttonAction: () =>
        dispatch(MenuActions.animateMenu("ABRIR_MENU_CLUSTERS")),
    },
    callBySelectingEntitiesAnalysis: {
      text: "Para fazer a análise você precisa selecionar alguma unidade de filtro. Deseja aplicar agora?",
      firstButtonText: "Selecionar",
      secondButtonText: "Cancelar",
      buttonAction: () =>
        dispatch(MenuActions.animateMenu("ABRIR_MENU_CLUSTERS")),
    },
    callBySelectingMediaGroups: {
      text: "Para fazer a análise você precisa selecionar algum grupo de mídia!",
      firstButtonText: "Ok",
      secondButtonText: "Cancelar",
      buttonAction: () =>
        dispatch(MenuActions.animateMenu("ABRIR_MENU_ANALISE")),
    },
  };

  const resolveButton = () => {
    behaviorOptions[props.behavior].buttonAction();
    props.callback(null);
  };

  return (
    <MainContainer>
      <Container>
        <MessageContainer component="div">
          <TitleContainer component="div">
            <Title component="h2" variant="h2">
              Aviso
            </Title>
            <ErrorOutlineIcon fontSize="large" />
          </TitleContainer>
          <TextContainer component="span">
            {behaviorOptions[props.behavior].text}
          </TextContainer>
        </MessageContainer>

        <ActionContainer component="div">
          <ResolveButton onClick={resolveButton}>
            {behaviorOptions[props.behavior].firstButtonText}
          </ResolveButton>
          <SecondaryButton onClick={() => props.callback(null)}>
            {behaviorOptions[props.behavior].secondButtonText}
          </SecondaryButton>
        </ActionContainer>
      </Container>
    </MainContainer>
  );
};

export default Warning;
