export const Types = {
  ADD_DRAWING_ANALYSES: "drawings/ADD_DRAWING_ANALYSES",
  REMOVE_DRAWING_ANALYSES: "drawings/REMOVE_DRAWING_ANALYSES",
  REMOVE_ALL_DRAWINGS_ANALYSES: "drawings/REMOVE_ALL_DRAWINGS_ANALYSES",
};

export const Creators = {
  addDrawingAnalyse: (drawing) => ({
    type: Types.ADD_DRAWING_ANALYSES,
    payload: drawing,
  }),
  removeDrawingAnalyse: (drawingId) => ({
    type: Types.REMOVE_DRAWING_ANALYSES,
    payload: drawingId,
  }),
  removeAllDrawingsAnalyse: () => ({
    type: Types.REMOVE_ALL_DRAWINGS_ANALYSES,
  }),
};
