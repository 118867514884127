import { call, put } from "redux-saga/effects";

import { getAreas as getAreasRequest } from "../services/areas";
import { Creators as filtersActions } from "../actions/areas";
import { Creators as riskAreasActions } from "../actions/riskAreas";

export default function* getAreas(filters) {
  try {
    const list = yield call(getAreasRequest, filters);
    yield put(filtersActions.getAreasSuccess(list));
    yield put(riskAreasActions.getRiskAreas({ areas: list.data }));
  } catch (error) {
    yield put(filtersActions.getAreasFailure(error));
  }
}
