import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Typography } from "@mui/material";

import {
  Progress,
  PaperError,
  ReplayIcon,
  CustomPaper,
  MainContainer,
} from "./style";

import { Creators as Auth } from "../../store/actions/auth";
import { Creators as Actions } from "../../store/actions/ocurrences";
import { Creators as RiskAreas } from "../../store/actions/riskAreas";
import { Creators as Categories } from "../../store/actions/categories";
import { Creators as InterestPoints } from "../../store/actions/interestPoints";
import { Creators as PublicEntities } from "../../store/actions/public-entities";
import { Creators as PublicEntityTypes } from "../../store/actions/publicEntityTypes";

const MessageBar = (props) => {
  const {
    care,
    rotas,
    cameras,
    analise,
    loading,
    clusters,
    informacoes,
    rotasEvasao,
    loadingAreas,
    loadingTypes,
    loadingAnalisys,
    loadingRiskAreas,
    loadingCategories,
    loadingTypesFailed,
    loadingMediaGroups,
    loadingAreasFailed,
    loadingRiskAreaTypes,
    loadingInterestPoints,
    loadingPublicEntities,
    loadingTypesByCustomer,
    loadingRiskAreasFailed,
    loadingCategoriesFailed,
    loadingMediaGroupsFailed,
    loadingPublicEntityTypes,
    loadingRiskAreaTypesFailed,
    loadingClippingsCategories,
    loadingInterestPointsFailed,
    loadingPublicEntitiesFailed,
    loadingTypesByCustomerFailed,
    loadingPointsByCustomerFailed,
    loadingPublicEntityTypesFailed,
    loadingClippingCategoriesFailed,
    getCategories,
    getMediaGroups,
    getPublicEntities,
    getInterestPoints,
    getPublicEntityTypes,
    getClippingCategories,
    getInterestPointsTypes,
    getInterestPointsByCustomer,
    getInterestPointsTypesByCustomer,
  } = props;

  const loadingMessages = [
    loadingCategories,
    loadingClippingsCategories,
    loadingAnalisys,
    loading,
    loadingInterestPoints,
    loadingAreas,
    loadingRiskAreas,
    loadingTypesByCustomer,
    loadingTypes,
    loadingRiskAreaTypes,
    loadingPublicEntities,
    loadingPublicEntityTypes,
    loadingMediaGroups,
  ];

  const failureMessages = [
    [loadingAreasFailed],
    [loadingRiskAreasFailed],
    [loadingRiskAreaTypesFailed],
    [loadingCategoriesFailed, getCategories],
    [loadingMediaGroupsFailed, getMediaGroups],
    [loadingTypesFailed, getInterestPointsTypes],
    [loadingPublicEntitiesFailed, getPublicEntities],
    [loadingInterestPointsFailed, getInterestPoints],
    [loadingPublicEntityTypesFailed, getPublicEntityTypes],
    [loadingClippingCategoriesFailed, getClippingCategories],
    [loadingPointsByCustomerFailed, getInterestPointsByCustomer],
    [loadingTypesByCustomerFailed, getInterestPointsTypesByCustomer],
  ];

  return (
    <MainContainer
      component="div"
      expanded={
        rotas ||
        care ||
        cameras ||
        analise ||
        clusters ||
        rotasEvasao ||
        informacoes
      }
    >
      {loadingMessages
        .filter((message) => message)
        .map((message, index) => (
          <CustomPaper key={"alert-" + index}>
            <Typography variant="h6" component="h3">
              {message} <Progress />
            </Typography>
          </CustomPaper>
        ))}

      {failureMessages
        .filter(([message]) => message)
        .map(([message, handleClick], index) => (
          <PaperError key={`error-message-${index}`}>
            <Typography variant="h6" component="h3">
              {message}
              {handleClick && (
                <ReplayIcon color="secondary" onClick={handleClick} />
              )}
            </Typography>
          </PaperError>
        ))}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    rotas: state.menu.rotas,
    cameras: state.menu.cameras,
    analise: state.menu.analise,
    clusters: state.menu.clusters,
    care: state.menu.careIncidents,
    informacoes: state.menu.informacoes,
    rotasEvasao: state.menu.rotasEvasao,
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],

    loadingCategories:
      state.categories.loadingCategories &&
      "Carregando Categorias de Incidentes",
    loadingCategoriesFailed:
      state.categories.loadingCategoriesFailed &&
      "Erro ao carregar Categorias de Incidentes",

    loadingClippingsCategories:
      state.categories.loadingClippingCategories &&
      "Carregando Categorias de Clippings",
    loadingClippingCategoriesFailed:
      state.categories.loadingClippingCategoriesFailed &&
      "Erro ao carregar Categorias de Clippings",

    loadingMediaGroups:
      state.categories.loadingMediaGroups && "Carregando Grupos de Mídia",
    loadingMediaGroupsFailed:
      state.categories.loadingClippingCategoriesFailed &&
      "Erro ao carregar Grupos de Mídia",

    loadingAnalisys:
      state.ocurrences.loadingAnalisys && "Pesquisando Análise e Inteligência",

    loading: state.ocurrences.loading && "Carregando Incidentes",

    loadingInterestPoints:
      state.interestPoints.loadingPoints &&
      "Carregando Pontos de Interesse Coletivos",
    loadingInterestPointsFailed:
      state.interestPoints.loadingPointsFailed &&
      "Erro ao carregar Pontos de Interesse Coletivos",

    loadingPointsByCustomer:
      state.interestPoints.loadingPointsByCustomer &&
      "Carregando Pontos de Interesse Exclusivos",
    loadingPointsByCustomerFailed:
      state.interestPoints.loadingPointsFailedByCustomer &&
      "Erro ao carregar Pontos de Interesse Exclusivos",

    loadingTypesByCustomer:
      state.interestPoints.loadingTypesByCustomer &&
      "Carregando Categorias de Pontos de Interesse Exclusivos",
    loadingTypesByCustomerFailed:
      state.interestPoints.loadingTypesByCustomerFailed &&
      "Erro ao carregar Categorias de Pontos de Interesse Exclusivos",

    loadingTypes:
      state.interestPoints.loadingTypes &&
      "Carregando Categorias de Pontos de Interesse Coletivos",
    loadingTypesFailed:
      state.interestPoints.loadingTypesFailed &&
      "Erro ao carregar Categorias de Pontos de Interesse Coletivos",

    loadingAreas: state.areas.loading && "Pesquisando Área",
    loadingAreasFailed:
      state.areas.loadingAreasFailed && "Erro ao pesquisar Área",

    loadingRiskAreas: state.riskAreas.loading && "Carregando Áreas de risco",
    loadingRiskAreasFailed:
      state.riskAreas.loadingFailed && "Erro ao pesquisar Áreas de risco",

    loadingRiskAreaTypes:
      state.riskAreas.loadingRiskAreaTypes &&
      "Carregando Tipos de áreas de risco",
    loadingRiskAreaTypesFailed:
      state.riskAreas.loadingRiskAreaTypesFailed &&
      "Erro ao pesquisar Tipos de área de risco",

    loadingPublicEntities:
      state.publicEntities.loadingPublicEntities &&
      "Carregando Entidades públicas",
    loadingPublicEntitiesFailed:
      state.publicEntities.loadingPublicEntitiesFailed &&
      "Erro ao pesquisar Entidades públicas",

    loadingPublicEntityTypes:
      state.publicEntities.loadingPublicEntityTypes &&
      "Carregando Tipos de Entidades públicas",
    loadingPublicEntityTypesFailed:
      state.publicEntityTypes.loadingPublicEntityTypesFailed &&
      "Erro ao pesquisar Tipos de Entidades públicas",
  };
};

const mapDispatchtoProps = {
  ...PublicEntityTypes,
  ...PublicEntities,
  ...InterestPoints,
  ...Categories,
  ...RiskAreas,
  ...Actions,
  ...Auth,
};

export default connect(mapStateToProps, mapDispatchtoProps)(MessageBar);
