import {
  Box,
  styled,
  AppBar,
  Select,
  Button,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { Search, SwapHoriz } from "@mui/icons-material";

export const MainBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "visible",
})(({ visible }) => ({
  width: "auto",
  left: "10%",
  boxShadow: "none",
  background: "transparent",
  marginTop: visible ? "5px" : "-100px",
  transition: "margin .5s linear",
}));

export const AppBarContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  textAlign: "center",
  color: "#212121",
}));

export const ActionBar = styled(AppBar)(() => ({
  width: "164px",
  color: "#212121",
  marginRight: "90px",
  background: "transparent",
  boxShadow: "0px 4px 4px 0px",
  borderRadius: "0 0 20px 20px ",
}));

export const FilterButtonContainer = styled(Box)(() => ({
  width: "100%",
  height: "40px",
  color: "white",
  display: "flex",
  cursor: "pointer",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1CA495",
  borderRadius: "0 0 20px 20px ",

  transition: "all .3s linear",
  "&:hover": {
    color: "#212121",
  },
}));

export const FormContainer = styled(FormControl)(() => ({
  color: "#212121",
  marginTop: "10px",
  marginRight: "15px",
  borderRadius: "6px",
  backgroundColor: "white",
  boxShadow: "0px 4px 4px 0px",
  "& fieldset": {
    border: "none",
  },
  "& svg": {
    color: "#212121",
  },
}));

export const CustomerSelect = styled(Select)(() => ({
  height: "48px",
  minWidth: "80px",
  color: "#212121",
  borderRadius: "6px",
}));

export const SearchContainer = styled(Box)(() => ({
  width: "350px",
  height: "50px",
  color: "#212121",
  display: "flex",
  marginTop: "12px",
  "& input": {
    color: "#212121",
    width: "100%",
  },
  "& div:first-of-type": {
    width: "100%",
    padding: "3px 20px 10px 10px ",
  },
}));

export const PublicPlaceSearch = styled(Box)(() => ({
  marginTop: "11px",
  marginRight: "6px",
  width: "100%",
  maxWidth: "615px",
  height: "50px",
  color: "#212121",
  display: "flex",
  "& input": {
    color: "#212121",
    width: "100%",
  },
  "& div:first-of-type": {
    width: "100%",
    padding: "3px 10px 10px 10px ",
  },
}));

export const SearchOriginContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})(({ maxWidth }) => ({
  maxWidth: maxWidth || "250px",
  marginLeft: "-10px",
  marginTop: "-2px",
}));

export const SwapHorizIcon = styled(SwapHoriz)(() => ({
  margin: 0,
  padding: 0,
  width: "45px",
  height: "40px",
  color: "black",
  position: "relative",
  background: "transparent",
  zIndex: 10,
}));

export const DeleteButton = styled(Button)(() => ({
  height: "50px",
  padding: "10px",
  color: "#212121",
  backgroundColor: "#FFF",
  display: "flex",
  alignSelf: "flex-end",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 4px 4px 0px",
  marginLeft: "15px",
  cursor: "pointer",
  textTransform: "none",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#FFF",
  },
}));

export const RadiusFormContainer = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "marginLeft",
})(({ marginLeft }) => ({
  backgroundColor: "white",
  color: "#212121",
  marginLeft: marginLeft,
  marginTop: "8px",
  borderRadius: "6px",
  boxShadow: "0px 4px 4px 0px",
  "& fieldset": {
    border: "none",
  },
  "& svg": {
    color: "#212121",
  },
}));

export const SelectRadius = styled(Select)(() => ({
  height: "48px",
  minWidth: "80px",
  borderRadius: "6px",
}));

export const MarkRadiusContainer = styled(Box)(() => ({
  color: "#212121",
  height: "50px",
  minWidth: "145px",
  marginLeft: "25px",
  marginTop: "10px",
  boxShadow: "0px 4px 4px 0px",
  backgroundColor: "white",
  borderRadius: "6px",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#1CA495",
  },
}));

export const MarkRadiusItem = styled(Box)(() => ({
  color: "#212121",
  marginTop: "16px",
  fontSize: "1.2rem",
}));

export const SearchItem = styled(Box)(() => ({
  width: "50px",
  height: "50px",
  backgroundColor: "#1CA495",
  color: "#212121",
  alignSelf: "flex-end",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  boxShadow: "0px 4px 4px 0px",
  marginLeft: "15px",
  cursor: "pointer",
}));

export const CircularProgressIcon = styled(CircularProgress)(() => ({
  margin: "0px 15px",
  height: "30px !important",
  width: "30px !important",
  paddingRight: "0px !important",
  marginTop: "10px",
}));

export const SearchIcon = styled(Search)(() => ({
  fontSize: "1.7rem",
  color: "white",
  height: "35px",

  transition: "all .3s linear",
  "&:hover": {
    color: "#212121",
  },
}));

export const DrawingContainer = styled(Box)(() => ({
  width: "50px",
  height: "50px",
  backgroundColor: "#FFF",
  color: "#212121",
  alignSelf: "flex-end",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  boxShadow: "0px 4px 4px 0px",
  marginLeft: "15px",
  cursor: "pointer",
}));
