import React, { Component } from "react";
import { connect } from "react-redux";

import { reduxForm, getFormValues } from "redux-form";

import { Box, Divider } from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

import {
  ExpansionPanelDetail,
  ExpasionPanelTitle,
  ListItemComponent,
  ExpansionHeading,
  MainSideContent,
  ExpansionPanel,
  ExpandMoreIcon,
  IncidentsCount,
  ExpansionList,
  ActionButton,
  CustomSwitch,
  DrawerHeader,
  MoreVertIcon,
  SideFooter,
  MainDrawer,
  MoreButton,
  PaperItem,
  TitleText,
  FormLabel,
  ItemText,
} from "./drawerInfoStyles";

import { orderArray } from "../../common/Utils";

import { Creators as FormActions } from "../../store/actions/menu";
import InputSwitch from "../../common/InputSwitch";

const camerasIds = process.env.REACT_APP_CAMERAS_IDS.split(",").map((e) =>
  Number(e)
);

class DrawerInfo extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.formInfoFields.riskAreaTypes !==
      nextProps.formInfoFields.riskAreaTypes
    ) {
      const {
        formInfoFields: { riskAreaTypes },
      } = nextProps;
      const { callback } = this.props;

      if (riskAreaTypes === undefined) callback("removeRiskAreas");
      if (riskAreaTypes && riskAreaTypes.length > 0) callback("addRiskAreas");
    }
  }

  getRiskAreasCount(type) {
    const { formInfoFields } = this.props;

    if (!formInfoFields || !formInfoFields.risk_area) return 0;

    return (
      formInfoFields.risk_area.filter(
        (area) => Number(type.id) === Number(area.riskZone.typeId)
      )?.length || 0
    );
  }

  getRiskAreasCountBadge(type) {
    return (
      this.getRiskAreasCount(type) > 0 && (
        <IncidentsCount component="span">
          {this.getRiskAreasCount(type)}
        </IncidentsCount>
      )
    );
  }

  renderFormItem(area, name) {
    return (
      area.id && (
        <Box component="div" key={`area-${area.id}`}>
          <InputSwitch keyValue={name} label={area.type} value={area.id} />
          <Divider />
        </Box>
      )
    );
  }

  renderFormItemEntity(entity, name) {
    return (
      entity.id && (
        <Box component="div" key={`area-${entity.id}`}>
          <InputSwitch
            keyValue={name}
            label={entity.entity}
            value={entity.id}
          />
          <Divider />
        </Box>
      )
    );
  }

  callUnitWarning() {
    if (
      !this.props.formFields.Units ||
      this.props.formFields.Units.length === 0
    )
      this.props.setWarning("callBySelectingEntities");
  }

  toggleRiskAreasType(type) {
    this.callUnitWarning();
    const value = this.props.formInfoFields.riskAreaTypes || [];
    if (!value.includes(type.id)) {
      this.props.array.push("riskAreaTypes", type.id);
    } else {
      const newValue = value.filter((e) => Number(e) !== Number(type.id));
      this.props.change(
        "riskAreaTypes",
        newValue.length > 0 ? newValue : undefined
      );
    }
  }

  state = {
    publicEntityChecked: false,
  };

  togglepublicEntityChecked = () => {
    this.setState({ publicEntityChecked: !this.state.publicEntityChecked });
    if (!this.state.publicEntityChecked)
      this.props.callback("addPublicEntities");
    if (this.state.publicEntityChecked)
      this.props.callback("removePublicEntities");
  };

  riskAreasExpand = () => {
    this.props.animateMenu("EXPAND_RISK_AREAS_MENU");
  };

  render() {
    const { menuOpen, animateMenu, formInfoFields } = this.props;

    return (
      <MainDrawer anchor="left" variant="persistent" open={menuOpen}>
        <DrawerHeader>
          <TitleText variant="h6" noWrap>
            Camadas de Informações
          </TitleText>
          <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
            <CloseIcon />
          </ActionButton>
        </DrawerHeader>
        <Divider />

        <MainSideContent component="div">
          <PaperItem>
            <ExpansionPanel>
              <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                <ExpansionHeading>Entidades públicas</ExpansionHeading>
              </ExpasionPanelTitle>
              <ExpansionPanelDetail>
                <ExpansionList>
                  {orderArray(this.props.publicEntityTypes, "entity").map(
                    (entity) =>
                      this.renderFormItemEntity(entity, "publicEntityType")
                  )}
                </ExpansionList>
              </ExpansionPanelDetail>
            </ExpansionPanel>
          </PaperItem>

          <PaperItem>
            <ExpansionPanel>
              <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                <ExpansionHeading>Tipos de área de risco</ExpansionHeading>
              </ExpasionPanelTitle>
              <ExpansionPanelDetail>
                <ExpansionList>
                  {orderArray(this.props.riskAreaTypes, "name").map(
                    (type, index) =>
                      type && (
                        <Box component="div" key={`risk-area-type-${index}`}>
                          <ListItemComponent>
                            <ItemText
                              control={
                                <CustomSwitch
                                  disabled={this.getRiskAreasCount(type) > 0}
                                  color="secondary"
                                  onClick={() => this.toggleRiskAreasType(type)}
                                  checked={
                                    (formInfoFields.riskAreaTypes &&
                                      formInfoFields.riskAreaTypes.includes(
                                        type.id
                                      )) ||
                                    false
                                  }
                                  name="riskAreaTypes"
                                />
                              }
                              label={
                                <FormLabel
                                  onClick={
                                    this.getRiskAreasCount(type) > 0
                                      ? this.riskAreasExpand
                                      : undefined
                                  }
                                >
                                  {type.name}
                                  {this.getRiskAreasCount(type) > 0 &&
                                    this.getRiskAreasCountBadge(type)}
                                </FormLabel>
                              }
                              labelPlacement="start"
                            />
                          </ListItemComponent>
                          <Divider />
                        </Box>
                      )
                  )}
                  <ListItemComponent key="riskAreasExpand">
                    <ItemText
                      value="start"
                      control={
                        <MoreButton onClick={this.riskAreasExpand}>
                          <MoreVertIcon />
                        </MoreButton>
                      }
                      label={<FormLabel>Opções Avançadas</FormLabel>}
                      labelPlacement="start"
                    />
                  </ListItemComponent>
                </ExpansionList>
              </ExpansionPanelDetail>
            </ExpansionPanel>
          </PaperItem>

          <PaperItem>
            <ExpansionPanel>
              <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                <ExpansionHeading>
                  Pontos de Interesse Coletivos
                </ExpansionHeading>
              </ExpasionPanelTitle>
              <ExpansionPanelDetail>
                <ExpansionList>
                  {orderArray(this.props.interestPointsTypes, "type").map(
                    (points) => this.renderFormItem(points, "interestPoint")
                  )}
                </ExpansionList>
              </ExpansionPanelDetail>
            </ExpansionPanel>
          </PaperItem>

          <PaperItem>
            <ExpansionPanel>
              <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                <ExpansionHeading>
                  Pontos de Interesse Exclusivos
                </ExpansionHeading>
              </ExpasionPanelTitle>
              <ExpansionPanelDetail>
                <ExpansionList>
                  {orderArray(
                    this.props.interestPointsTypesByCustomer,
                    "type"
                  ).map((points) =>
                    this.renderFormItem(points, "interestPointByCustomer")
                  )}
                </ExpansionList>
              </ExpansionPanelDetail>
            </ExpansionPanel>
          </PaperItem>
        </MainSideContent>
        <SideFooter xs={1} component="div" color="secondary">
          <Box component="span">
            Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
          </Box>
        </SideFooter>
      </MainDrawer>
    );
  }
}

DrawerInfo = reduxForm({
  form: "INFO",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerInfo);

const mapDispatchtoProps = {
  ...FormActions,
};

const mapStateToProps = (state) => {
  return {
    formInfoFields: getFormValues("INFO")(state)
      ? getFormValues("INFO")(state)
      : [],
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    menuOpen: state.menu.informacoes,
    coletivos: state.inputs.coletivo,
    individual: state.inputs.individual,
    interestPointsTypes: state.interestPoints.interestPointsTypes.filter(
      ({ id }) => !camerasIds.includes(id)
    ),
    riskAreas: state.riskAreas.riskAreas.map((riskArea) => ({
      ...riskArea,
      type: riskArea.name,
    })),
    riskAreaTypes: state.riskAreas.riskAreaTypes.map((riskAreaType) => ({
      ...riskAreaType,
      type: riskAreaType.name,
    })),
    interestPointsTypesByCustomer:
      state.interestPoints.interestPointsTypesByCustomer,
    publicEntityTypes: state.publicEntityTypes.publicEntityTypes,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerInfo);
