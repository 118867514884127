import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "material-react-toastify";

import "material-react-toastify/dist/ReactToastify.css";

import Main from "./components/App";
import store from "./store";
import { AppTheme } from "./AppTheme";
import { NodeEnvContainer } from "./components/styles";

function App() {
  const showDevTag = process.env.NODE_ENV !== "production";

  return (
    <Provider store={store}>
      <AppTheme>
        {showDevTag && (
          <NodeEnvContainer>{`ENV: ${process.env.NODE_ENV} | Branch: `}</NodeEnvContainer>
        )}
        <Main />
      </AppTheme>
      <ToastContainer position="top-right" />
    </Provider>
  );
}

export default App;
