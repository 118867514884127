import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { lineString } from "@turf/helpers";
import buffer from "@turf/buffer";
import {
  Box,
  List,
  Button,
  Dialog,
  ListItem,
  Typography,
  IconButton,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { Delete, Error, Explore, WarningOutlined } from "@mui/icons-material";

import { getRoutes, deleteRoute } from "../../../store/services/route";
import { Creators as RouteAnalysisCreators } from "../../../store/actions/routesAnalysis";
import { Creators as DrawingCreators } from "../../../store/actions/drawing";

const RouteListModal = (props) => {
  const { open, closeRouteList } = props;

  const dispatch = useDispatch();

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [deleteFailure, setDeleteFailure] = useState(false);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const getRoutesRequest = async () => {
      try {
        setLoading(true);
        setFailure(false);
        const { status, data } = await getRoutes();

        if (status === 200) {
          setRoutes(data);
        } else {
          setFailure(true);
        }
      } catch {
        setFailure(true);
      } finally {
        setLoading(false);
        setUpdate(false);
      }
    };

    if (open || update) getRoutesRequest();
  }, [open, update]);

  const handleOpenRoute = (id) => {
    const { metadata } = routes.find((route) => route.id === id);

    const information = metadata.information;

    information["decodedPolyline"] =
      window.google.maps.geometry.encoding.decodePath(
        information.decodedPolyline
      );

    dispatch(RouteAnalysisCreators.updateRoutes(information));
    metadata.waypoints.map((waypoint) =>
      dispatch(RouteAnalysisCreators.addWaypointAction(waypoint))
    );

    const coordsRoute = information.decodedPolyline;
    const decodedCoords = coordsRoute.map((coord) => [
      coord.lng(),
      coord.lat(),
    ]);

    const drawing = {
      id: information.id,
      polygon: null,
    };

    const linestring1 = lineString(decodedCoords);

    const buffered = buffer(linestring1, 1000, {
      units: "meters",
    });
    drawing.polygon = buffered;

    dispatch(DrawingCreators.addDrawing(drawing));
  };

  const handleDeleteRoute = async (id) => {
    try {
      setLoading(true);
      setDeleteFailure(false);

      const { status } = await deleteRoute(id);

      if (status === 204) {
        setUpdate(true);
      } else {
        setDeleteFailure(true);
      }
    } catch {
      setDeleteFailure(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Lista de Rotas</DialogTitle>
      {failure && (
        <DialogContent>
          <WarningOutlined style={{ margin: "0 10px" }} />
          Falha ao buscar rotas!
        </DialogContent>
      )}
      {deleteFailure && (
        <DialogContent>
          <Error style={{ margin: "0 10px" }} />
          Falha ao tentar deletar rota!
        </DialogContent>
      )}
      <DialogContent>
        <Box component="div">
          <List>
            {routes.map(({ id, name, description }) => (
              <ListItem key={id}>
                <ListItemText
                  primary={name}
                  secondary={`Descrição: ${description}`}
                />
                <IconButton
                  aria-label="Abrir"
                  onClick={() => handleOpenRoute(id)}
                >
                  <Explore />
                </IconButton>
                <IconButton
                  aria-label="Apagar"
                  onClick={() => handleDeleteRoute(id)}
                >
                  <Delete />
                </IconButton>
              </ListItem>
            ))}
          </List>
          {!loading && routes.length === 0 && (
            <Typography component="p">Nenhum rota foi encontrada!</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress />}
        <Button onClick={closeRouteList}>Sair</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RouteListModal;
