export const Types = {
  GET_RISK_AREAS: "riskAreas/GET_RISK_AREAS",
  GET_RISK_AREAS_SUCCEEDED: "riskAreas/GET_RISK_AREAS_SUCCEEDED",
  GET_RISK_AREAS_FAILED: "riskAreas/GET_RISK_AREAS_FAILED",
  GET_RISK_AREA_TYPES: "riskAreas/GET_RISK_AREA_TYPES",
  GET_RISK_AREA_TYPES_SUCCEEDED: "riskAreas/GET_RISK_AREA_TYPES_SUCCEEDED",
  GET_RISK_AREA_TYPES_FAILED: "riskAreas/GET_RISK_AREA_TYPES_FAILED",
  GET_CRIMINAL_FACTIONS: "GET_CRIMINAL_FACTIONS",
  GET_CRIMINAL_FACTIONS_SUCESS: "GET_CRIMINAL_FACTIONS_SUCESS",
  GET_CRIMINAL_FACTIONS_FAILED: "GET_CRIMINAL_FACTIONS_FAILED",
  RENDER_PARTIAL_RISK_AREAS_SUCCEEDED:
    "riskAreas/RENDER_PARTIAL_RISK_AREAS_SUCCEEDED",
  HANDLE_RISK_AREAS_POLIGON: "riskAreas/HANDLE_RISK_AREAS_POLIGON",
};

export const Creators = {
  getRiskAreas: (filters) => ({
    type: Types.GET_RISK_AREAS,
    filters,
  }),
  getRiskAreasSucceeded: (riskAreas) => ({
    type: Types.GET_RISK_AREAS_SUCCEEDED,
    riskAreas,
  }),
  renderPartialRiskAreasSucceeded: () => ({
    type: Types.RENDER_PARTIAL_RISK_AREAS_SUCCEEDED,
  }),
  getRiskAreasFailed: (error) => ({
    type: Types.GET_RISK_AREAS_FAILED,
    error,
  }),
  getRiskAreaTypes: () => ({
    type: Types.GET_RISK_AREA_TYPES,
  }),
  getRiskAreasTypesSucceeded: (riskAreaTypes) => ({
    type: Types.GET_RISK_AREA_TYPES_SUCCEEDED,
    riskAreaTypes,
  }),
  getRiskAreaTypesFailed: (error) => ({
    type: Types.GET_RISK_AREA_TYPES_FAILED,
    error,
  }),
  getCriminalFactions: () => ({
    type: Types.GET_CRIMINAL_FACTIONS,
  }),
  getCriminalFactionsSucceeded: (data) => ({
    type: Types.GET_CRIMINAL_FACTIONS_SUCESS,
    data,
  }),
  getCriminalFactionsFailed: (error) => ({
    type: Types.GET_CRIMINAL_FACTIONS_FAILED,
    error,
  }),
  handleRiskAreasPoligon: (riskAreaId) => ({
    type: Types.HANDLE_RISK_AREAS_POLIGON,
    payload: riskAreaId,
  }),
};
