import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import DrawerAvancadasClippings from "../SideMenu/DrawerAvancadasClippings";
import DrawerClusters from "../SideMenu/DrawerClusters.js";
import DrawerAvancadas from "../SideMenu/DrawerAvancadas";
import DrawerIncidente from "../SideMenu/DrawerIncidente";
import DrawerAnalise from "../SideMenu/DrawerAnalise";
import DrawerLegenda from "../SideMenu/DrawerLegenda";
import DrawerFilter from "../SideMenu/DrawerFilter";
import DrawerRoutes from "../SideMenu/DrawerRoutes/Index";
import DrawerEvasionRoutes from "../SideMenu/DrawerEvasionRoutes";
import DrawerMapTypes from "../SideMenu/DrawerMapTypes";
import RightMenuTabs from "../SideMenu/RightMenuTabs";
import LeftMenuTabs from "../SideMenu/LeftMenuTabs";
import DrawerCamera from "../SideMenu/DrawerCamera";
import DrawerInfo from "../SideMenu/DrawerInfo";
import DrawerRiskAreasExpand from "../SideMenu/DrawerRiskAreasExpand";
import DrawerCare from "../SideMenu/DrawerCare";
import TopAppBar from "../TopAppBar/TopAppBar";
import Dashboards from "../Dashboard";
import MyMapComponent from "../Map/index.js";
import Message from "../Message";
import Draggable from "../SideMenu/DrawerRoutes/Draggable/Container";
import Warning from "../Warning/index";
import { Box, Snackbar } from "@mui/material";

import { groupIncidents, filterInterestPoints } from "../../common/Utils";
import {
  filterRiskAreasByUnits,
  filterRiskAreasByTypes,
  filterRiskAreasByRelation,
  filterCriminalFactionsByRelation,
  filterRiskAreasByCriminalFactions,
  filterRiskAreasByRadiusIntersection,
} from "../../common/RiskAreasFilters";

import { filterIncidentsByRadiusIntersection } from "../../common/IncidentsFilters";

import {
  unlinkByTypes,
  filterPOIByRelation,
  filterPointsOfInterestByUnits,
  filterPointsOfInterestByTypes,
  filterPointsOfInterestByRadiusIntersection,
} from "../../common/PointsOfInterestFilters";

import {
  filterPublicEntitiesByUnits,
  filterPublicEntitiesByTypes,
  filterPublicEntitiesByRelation,
  filterPublicEntitiesByRadiusIntersection,
} from "../../common/PublicEntitiesFilters";

import { Creators as Categories } from "../../store/actions/categories";
import { Creators as Actions } from "../../store/actions/ocurrences";
import { Creators as Auth } from "../../store/actions/auth";
import { Creators as InterestPoints } from "../../store/actions/interestPoints";
import { Creators as RiskAreas } from "../../store/actions/riskAreas";
import { Creators as PublicEntities } from "../../store/actions/public-entities";
import { Creators as PublicEntityTypes } from "../../store/actions/publicEntityTypes";
import { Creators as AreaInformationTypes } from "../../store/actions/areaInformationTypes";
import { Creators as CustomerUnitsAreas } from "../../store/actions/unitsAreas";
import { selectRiskAreasMergedIcon } from "../../store/reducers/riskAreas";
import { getFilterablePolygons } from "../../common/Utils";
import html2canvas from "html2canvas";
import LogoImage from "../../assets/images/geo_map_logo.png";
import { GeoLogo } from "../styles";

const Main = (props) => {
  const {
    units,
    drawings,
    riskAreas,
    ocurrences,
    interestPoint,
    riskAreaTypes,
    interestPoints,
    publicEntities,
    publicEntityType,
    customerUnitsAreas,
    criminalFactionsFilters,
    interestPointByCustomer,
    interestPointsByCustomer,
    interestPointsTypesByCustomer,
    getInterestPointsTypesByCustomer,
    getInterestPointsByCustomer,
    getPublicEntitiesFailure,
    getAreaInformationTypes,
    getInterestPointsTypes,
    getClippingCategories,
    getCustomerUnitsAreas,
    getPublicEntityTypes,
    getCriminalFactions,
    getPublicEntities,
    getInterestPoints,
    getRiskAreaTypes,
    getMediaGroups,
    getCategories,
    getRiskAreas,
  } = props;

  const tokenAccess = localStorage.getItem("token");

  const [buildingPDF, setBuildingPDF] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [refreshEvents, setRefreshEvents] = useState(true);
  const [stateRiskAreas, setStateRiskAreas] = useState([]);
  const [warningPattern, setWarningPattern] = useState(null);
  const [snackbarElement, setSnackbarElement] = useState("");

  useEffect(() => {
    if (tokenAccess) {
      getInterestPointsTypesByCustomer();
      getInterestPointsByCustomer();
      getAreaInformationTypes();
      getInterestPointsTypes();
      getClippingCategories();
      getCustomerUnitsAreas();
      getPublicEntityTypes();
      getCriminalFactions();
      getInterestPoints();
      getPublicEntities();
      getRiskAreaTypes();
      getMediaGroups();
      getCategories();
      getRiskAreas();
      getEvents();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ocurrences.occurenceTypesIds.length > 0 && props.mode !== "report") {
        getEvents();
      }

      setRefreshEvents((previous) => !previous);
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshEvents, ocurrences.occurenceTypesIds, props.mode]);

  const getEvents = () => {
    props.getIncidents();
  };

  const callbackShow = (show) => {
    switch (show) {
      case "addRiskAreas":
        break;
      case "removeRiskAreas":
        setStateRiskAreas([]);
        break;
      case "addPublicEntities":
        getPublicEntities();
        break;
      case "removePublicEntities":
        getPublicEntitiesFailure();
        break;
      default:
        getPublicEntitiesFailure();
        setStateRiskAreas([]);
        break;
    }
  };

  const handleSetWarning = (behavior) => {
    setWarningPattern(behavior);
  };

  const handleMapPrint = async () => {
    try {
      const map = document.getElementById("mapContainer");

      setBuildingPDF(true);

      const canvas = await html2canvas(map, {
        useCORS: true,
        allowTaint: false,
        scale: 3,
      });

      const URI = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "download");
      downloadLink.setAttribute("href", URI);
      downloadLink.click();
    } finally {
      setBuildingPDF(false);
    }
  };

  const handleSnackbar = (element) => {
    if (!showSnackbar) {
      setSnackbarElement(element[0] ? element[0].type : "");
      setShowSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const filterablePolygons = getFilterablePolygons();

  const customerUnitIdsSelecteds = units ?? [];

  const customerUnitsSelecteds = props.customerUnitsAreas.filter(
    (customerUnit) => {
      return customerUnitIdsSelecteds.includes(customerUnit.id);
    }
  );

  const filteredItens = useMemo(() => {
    return groupIncidents(ocurrences.incidents, ocurrences.clippings);
  }, [ocurrences.incidents, ocurrences.clippings, drawings]);

  const filteredActiveItens = useMemo(() => {
    const filteredItens = ocurrences.activeIncidents
      ? ocurrences.activeIncidents.filter((incident) =>
          filterIncidentsByRadiusIntersection({
            incident,
            filterablePolygons,
          })
        )
      : [];

    return groupIncidents(filteredItens, []);
  }, [ocurrences.activeIncidents, drawings]);

  const filteredInterestPoints = useMemo(() => {
    const filteredItems = unlinkByTypes(
      filterPointsOfInterestByUnits({
        interestPoints,
        selectedUnits: customerUnitsSelecteds,
      })
        .filter((filteredPointOfInterest) =>
          filterPointsOfInterestByTypes({
            filteredPointOfInterest,
            pointsOfInterestTypesSelected: interestPoint,
          })
        )
        .filter((filteredPointOfInterest) =>
          filterPointsOfInterestByRadiusIntersection({
            pointOfInterest: filteredPointOfInterest,
            filterablePolygons,
          })
        )
    );

    const relatedItems = filterPOIByRelation({
      units: customerUnitsAreas.filter(({ id }) => units?.includes(id)),
      interestPoint,
      interestPoints,
    });

    relatedItems.forEach((element) => {
      if (!filteredItems[element.pointTypeId]) {
        filteredItems[element.pointTypeId] = [];
      }
      filteredItems[element.pointTypeId].push(element);
    });

    return filteredItems;
  }, [drawings, interestPoint, interestPoints, customerUnitsSelecteds]);

  const filteredInterestPointsByCustomer = useMemo(() => {
    const filteredItems = filterInterestPoints(
      interestPointsByCustomer,
      interestPointByCustomer || []
    );

    if (interestPointByCustomer?.length > 0) {
      interestPointByCustomer.forEach((item) => {
        if (!filteredItems[item]) {
          handleSnackbar(
            interestPointsTypesByCustomer.filter(
              (element) => element.id === item
            )
          );
        }
      });
    }

    return filteredItems;
  }, [interestPointsByCustomer, interestPointByCustomer]);

  useEffect(() => {
    const filteredItems = filterRiskAreasByUnits({
      riskAreas,
      selectedUnits: units || [],
      selectedCriminalFaction: [],
    }).filter((filteredRiskArea) => {
      const hasPointsOfInterestInTypes = filterRiskAreasByTypes({
        filteredRiskArea,
        riskAreasTypesSelected: riskAreaTypes || [],
      });

      if (!hasPointsOfInterestInTypes) {
        return false;
      }

      const hasInAreasByRadiusIntersection =
        filterRiskAreasByRadiusIntersection({
          riskArea: filteredRiskArea,
          filterablePolygons,
        });

      return hasInAreasByRadiusIntersection;
    });

    const relatedItems = filterRiskAreasByRelation({
      riskAreas,
      riskAreaTypes,
      units: customerUnitsAreas.filter(({ id }) => units?.includes(id)),
    });

    setStateRiskAreas([...filteredItems, ...relatedItems]);
  }, [units, riskAreaTypes, drawings]);

  const stateCriminalFactions = useMemo(() => {
    const filteredItems = filterRiskAreasByUnits({
      riskAreas,
      selectedUnits: units || [],
      selectedCriminalFaction: [],
    }).filter((filteredRiskArea) => {
      const hasRiskAreaInCriminalFactions = filterRiskAreasByCriminalFactions({
        filteredRiskArea,
        criminalFactionsSelected: criminalFactionsFilters || [],
      });

      if (!hasRiskAreaInCriminalFactions) {
        return false;
      }

      const hasRiskAreaInRadiusIntersection =
        filterRiskAreasByRadiusIntersection({
          riskArea: filteredRiskArea,
          filterablePolygons,
        });

      return hasRiskAreaInRadiusIntersection;
    });

    const relatedItems = filterCriminalFactionsByRelation({
      riskAreas,
      criminalFactionsFilters,
      units: customerUnitsAreas.filter(({ id }) => units?.includes(id)),
    });

    return [...filteredItems, ...relatedItems];
  }, [units, criminalFactionsFilters, drawings]);

  const filteredPublicEntities = useMemo(() => {
    const filteredItems = filterPublicEntitiesByUnits({
      publicEntities,
      selectedUnits: units ?? [],
    }).filter((filteredPublicEntity) => {
      const hasPointsOfInterestInTypes = filterPublicEntitiesByTypes({
        filteredPublicEntity,
        publicEntitiesTypesSelected: publicEntityType,
      });

      if (!hasPointsOfInterestInTypes) {
        return false;
      }

      return filterPublicEntitiesByRadiusIntersection({
        publicEntity: filteredPublicEntity,
        filterablePolygons,
      });
    });

    const relatedItems = filterPublicEntitiesByRelation({
      publicEntities,
      publicEntityType,
      units: customerUnitsAreas.filter(({ id }) => units?.includes(id)),
    });

    return [...filteredItems, ...relatedItems];
  }, [units, publicEntityType, drawings]);

  const permissions = localStorage.getItem("permissions")?.split(",") ?? [];

  return (
    <Box component="div">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={1000}
        message={`Não há ${snackbarElement} cadastrados(a)`}
      />
      <LeftMenuTabs />
      <RightMenuTabs />
      <TopAppBar
        setWarning={handleSetWarning}
        onMapPrint={handleMapPrint}
        buildingPDF={buildingPDF}
      />
      <DrawerCamera setWarning={handleSetWarning} />
      <DrawerInfo
        callback={(show) => callbackShow(show)}
        setWarning={handleSetWarning}
      />
      <DrawerAnalise setWarning={handleSetWarning} />
      <DrawerClusters />
      <DrawerLegenda />
      <DrawerFilter />
      <DrawerRoutes filteredPublicEntities={filteredPublicEntities} />
      <DrawerEvasionRoutes />
      <DrawerMapTypes />
      <DrawerIncidente
        filteredItens={filteredItens}
        filteredActiveItens={filteredActiveItens}
      />
      <DrawerIncidente filteredItens={filteredItens} filteredActiveItens={[]} />
      <DrawerAvancadas />
      <DrawerAvancadasClippings />
      <DrawerRiskAreasExpand />
      {permissions.includes("can-view-care-incidents") && <DrawerCare />}
      <Draggable />
      <MyMapComponent
        riskAreas={stateRiskAreas}
        ocurrences={filteredItens}
        activeOcurrences={filteredActiveItens}
        interestPoints={filteredInterestPoints}
        publicEntities={filteredPublicEntities}
        criminalFactions={stateCriminalFactions}
        interestPointsByCustomer={filteredInterestPointsByCustomer}
      />
      <Message />
      <GeoLogo src={LogoImage} alt="Logo Geoanalytics" />
      {warningPattern && (
        <Warning behavior={warningPattern} callback={handleSetWarning} />
      )}
      {props.mode === "report" && <Dashboards />}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  units: getFormValues("ANALISE")(state)?.Units,
  interestPoint: getFormValues("ANALISE")(state)?.interestPoint,
  interestPointByCustomer:
    getFormValues("ANALISE")(state)?.interestPointByCustomer,
  publicEntityType: getFormValues("ANALISE")(state)?.publicEntityType,
  riskAreaTypes: getFormValues("INFO")(state)?.riskAreaTypes,
  criminalFactionsFilters:
    getFormValues("INFO")(state)?.criminalFactionsFilters,
  ocurrences: state.ocurrences,
  interestPoints: state.interestPoints.interestPoints,
  interestPointsByCustomer: state.interestPoints.interestPointsByCustomer,
  riskAreas: selectRiskAreasMergedIcon(state),
  customerUnitsAreas: state.customerUnitsAreas.customerUnitsAreas,
  publicEntities: state.publicEntities.publicEntities,
  mode: state.Main.mode,
  drawings: state.drawing.drawings,
  interestPointsTypesByCustomer:
    state.interestPoints.interestPointsTypesByCustomer,
});

const mapDispatchtoProps = {
  ...AreaInformationTypes,
  ...CustomerUnitsAreas,
  ...PublicEntityTypes,
  ...PublicEntities,
  ...InterestPoints,
  ...RiskAreas,
  ...Categories,
  ...Actions,
  ...Auth,
};

export default connect(mapStateToProps, mapDispatchtoProps)(Main);
