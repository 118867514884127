import { styled } from "@mui/material";
import { DateTimePicker as DateTimePickerComponent } from "@mui/x-date-pickers/DateTimePicker";

export const DateTimePicker = styled(DateTimePickerComponent)(() => ({
  width: "90%",

  "& input": {
    color: "#fff",
  },

  "& label": {
    color: "#fff",
  },

  "& svg": {
    color: "#fff",
  },

  "& fieldset": {
    borderColor: "#fff",
  },
}));
