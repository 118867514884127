import React, { Component } from "react";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  ItemText,
  CustomSwitch,
  CustomListItem,
  CustomFormLabel,
} from "../components/SideMenu/drawerClusterStyles";

class InputSwitch extends Component {
  state = {
    checked: false,
    value: this.props.formFields["Units"] ? this.props.formFields["Units"] : [],
  };

  componentDidUpdate() {
    if (this.props.uncheck && this.state.checked) {
      this.setState({ checked: false });
    }
  }

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked }, () => {
      if (this.state.checked === true) {
        this.props.array.push(["Units"], this.props.value);
      } else {
        let newValue = this.props.formFields["Units"].filter(
          (unit) => unit !== this.props.value
        );
        if (newValue.length > 0) {
          this.props.change(["Units"], newValue);
        } else {
          this.props.change(["Units"], undefined);
        }
      }
    });
  };

  render() {
    return (
      <CustomListItem key={this.props.keyValue}>
        <ItemText
          control={
            <CustomSwitch
              color="secondary"
              disabled={this.props.disabled}
              onClick={this.toggleChecked}
              checked={this.state.checked}
              name={this.props.keyValue}
            />
          }
          label={<CustomFormLabel>{this.props.label}</CustomFormLabel>}
          labelPlacement="start"
        />
      </CustomListItem>
    );
  }
}

InputSwitch.propTypes = {
  keyValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  uncheckAll: PropTypes.bool,
};

InputSwitch = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(InputSwitch);

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
  };
};

export default connect(mapStateToProps, null)(InputSwitch);
