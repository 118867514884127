export const Types = {
  GET_INTEREST_POINTS_TYPES: "points/GET_INTEREST_POINTS_TYPES",
  GET_INTEREST_POINTS_TYPES_SUCCESS: "points/GET_INTEREST_POINTS_TYPES_SUCCESS",
  GET_INTEREST_POINTS_TYPES_FAILURE: "points/GET_INTEREST_POINTS_TYPES_FAILURE",
  GET_INTEREST_POINTS: "points/GET_INTEREST_POINTS",
  GET_INTEREST_POINTS_SUCCESS: "points/GET_INTEREST_POINTS_SUCCESS",
  GET_INTEREST_POINTS_FAILURE: "points/GET_INTEREST_POINTS_FAILURE",
  HANDLE_INTEREST_POINTS_POLIGON: "riskAreas/HANDLE_INTEREST_POINTS_POLIGON",

  GET_INTEREST_POINTS_TYPES_BY_CUSTOMER:
    "points/GET_INTEREST_POINTS_TYPES_BY_CUSTOMER",
  GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_SUCCESS:
    "points/GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_SUCCESS",
  GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_FAILURE:
    "points/GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_FAILURE",
  GET_INTEREST_POINTS_BY_CUSTOMER: "points/GET_INTEREST_POINTS_BY_CUSTOMER",
  GET_INTEREST_POINTS_BY_CUSTOMER_SUCCESS:
    "points/GET_INTEREST_POINTS_BY_CUSTOMER_SUCCESS",
  GET_INTEREST_POINTS_BY_CUSTOMER_FAILURE:
    "points/GET_INTEREST_POINTS_BY_CUSTOMER_FAILURE",
  HANDLE_INTEREST_POINTS_BY_CUSTOMER_POLIGON:
    "riskAreas/HANDLE_INTEREST_POINTS_BY_CUSTOMER_POLIGON",
};

export const Creators = {
  //GET ALL POINTS
  getInterestPointsTypes: (filters) => ({
    type: Types.GET_INTEREST_POINTS_TYPES,
    filters,
  }),
  getInterestPointsTypesSuccess: (data) => ({
    type: Types.GET_INTEREST_POINTS_TYPES_SUCCESS,
    data,
  }),
  getInterestPointsTypesFailure: () => ({
    type: Types.GET_INTEREST_POINTS_TYPES_FAILURE,
  }),

  getInterestPoints: (filters) => ({
    type: Types.GET_INTEREST_POINTS,
    filters,
  }),
  getInterestPointsSuccess: (data) => ({
    type: Types.GET_INTEREST_POINTS_SUCCESS,
    data,
  }),
  getInterestPointsFailure: () => ({
    type: Types.GET_INTEREST_POINTS_FAILURE,
  }),

  //GET POINTS BY CUSTOMER
  getInterestPointsTypesByCustomer: (filters) => ({
    type: Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER,
    filters,
  }),
  getInterestPointsTypesByCustomerSuccess: (data) => ({
    type: Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_SUCCESS,
    data,
  }),
  getInterestPointsTypesByCustomerFailure: () => ({
    type: Types.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER_FAILURE,
  }),

  getInterestPointsByCustomer: (filters) => ({
    type: Types.GET_INTEREST_POINTS_BY_CUSTOMER,
    filters,
  }),
  getInterestPointsByCustomerSuccess: (data) => ({
    type: Types.GET_INTEREST_POINTS_BY_CUSTOMER_SUCCESS,
    data,
  }),
  getInterestPointsByCustomerFailure: () => ({
    type: Types.GET_INTEREST_POINTS_BY_CUSTOMER_FAILURE,
  }),
  handleInterestPointsPoligon: (interestPointId) => ({
    type: Types.HANDLE_INTEREST_POINTS_POLIGON,
    payload: interestPointId,
  }),
  handleInterestPointsByCustomerPoligon: (interestPointByCustomerId) => ({
    type: Types.HANDLE_INTEREST_POINTS_BY_CUSTOMER_POLIGON,
    payload: interestPointByCustomerId,
  }),
};
