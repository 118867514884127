import { Types } from "../actions/public-entities";

const INITIAL_STATE = {
  publicEntities: [],
  loadingPublicEntities: false,
  loadingPublicEntitiesFailed: false,
  loadingPublicEntitiesLoaded: false,
  loaded: false,
  publicEntitiesIdsToViewPoligon: [],
};

export default function getPublicEntities(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PUBLIC_ENTITIES:
      return {
        ...state,
        loadingPublicEntities: true,
        loadingPublicEntitiesFailed: false,
      };
    case Types.GET_PUBLIC_ENTITIES_SUCCESS:
      return {
        ...state,
        publicEntities: action.filters.data,
        loadingPublicEntitiesLoaded: true,
        loadingPublicEntities: false,
        loadingPublicEntitiesFailed: false,
      };
    case Types.GET_PUBLIC_ENTITIES_FAILURE:
      return {
        ...state,
        publicEntities: [],
        loadingPublicEntitiesLoaded: false,
        loadingPublicEntities: false,
        loadingPublicEntitiesFailed: true,
      };
    case Types.HANDLE_PUBLIC_ENTITY_POLIGON:
      return {
        ...state,
        publicEntitiesIdsToViewPoligon:
          state.publicEntitiesIdsToViewPoligon.includes(action.payload)
            ? state.publicEntitiesIdsToViewPoligon.filter(
                (item) => item !== action.payload
              )
            : [...state.publicEntitiesIdsToViewPoligon, action.payload],
      };
    default:
      return state;
  }
}

export const selectPublicEntityChildAreas = (state, id) => {
  const childExists = state.publicEntities.publicEntities.filter(
    (publicE) => publicE.publicEntity.parentId === id
  );

  return childExists.length > 0 ? childExists : undefined;
};
