import React, { useState, useCallback } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { remove } from "lodash";
import {
  Box,
  Button,
  Select,
  ListItem,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { searchAddress } from "../../../common/googleHelper";
import Timer from "./Timer";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Folder as FolderIcon,
  FlipToBack as FlipToBackIcon,
  FlipToFront as FlipToFrontIcon,
  AssessmentOutlined as ReportIcon,
  DeleteOutline as DeleteOutlineIcon,
  CenterFocusWeak as CenterFocusWeakIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@mui/icons-material";

import {
  FlexBox,
  UnitName,
  LineIcon,
  ListText,
  RouteInfo,
  RouteDetail,
  SelectInput,
  ActiveButton,
  ContainerFlag,
  FlagSharpIcon,
  RouteContainer,
  CustomTextField,
  ActionContainer,
  DetourContainer,
  AddressContainer,
  EllipsisContainer,
  CustomFormControl,
  DetourTextContainer,
  MoreDetailContainer,
  KeyboardArrowUpIcon,
  KeyboardArrowDownIcon,
} from "./routeStyles";

import { detoursMode as detoursOptions } from "../../../common/enums";

import { Creators as Detour } from "../../../store/actions/detour";
import { Creators as Drawing } from "../../../store/actions/drawing";
import { Creators as crisisModeActions } from "../../../store/actions/crisisMode";
import { Creators as routesAnalysis } from "../../../store/actions/routesAnalysis";

import ReportModal from "./ReportModal";
import InputSearchBox from "./InputSearchBox";
import EditWaypointModal from "./EditWaypointModal";

let RouteComponent = (props) => {
  const {
    pos,
    origin,
    coords,
    isBold,
    duration,
    distance,
    lineColor,
    isVisible,
    waypoints,
    isUpdating,
    formFields,
    notVisible,
    changeMode,
    timestampId,
    destination,
    isDraggable,
    detoursMode,
    crisisModule,
    waypointsInfo,
    infosForDetour,
    crisisModeRoutes,
    durationInTraffic,
    crosshairForDetour,
    filteredPublicEntities,
  } = props;

  const dispatch = useDispatch();

  const isLastRouteFromOrigin = useSelector(
    (state) =>
      state.routesAnalysis.informations.filter(
        (route) => route.crisisModule && route.start_address === origin
      ).length === 1
  );

  const [time, setTime] = useState(0);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [waypointId, setWaypointId] = useState(0);
  const [submitting, setSubmitting] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showOrigin, setShowOrigin] = useState(false);
  const [showDetour, setShowDetour] = useState(false);
  const [modeDetail, setMoreDetail] = useState(false);
  const [detourAddress, setDetourAddress] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showDestination, setShowDestination] = useState(false);

  const handleOpenEditModal = (id) => {
    toggleOpenEditModal();
    setWaypointId(id);
  };

  const toggleOpenEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const cleanField = useCallback(
    (setField) => {
      if (submitting) {
        setField("");
        setSubmitting(false);
      }
    },
    [submitting, setSubmitting]
  );

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const AddDetour = async () => {
    const [result] = await geocodeByAddress(detourAddress);

    const { formatted_address, place_id } = result;
    const { lat, lng } = await getLatLng(result);

    props.change(["coordsForDetour"], { lat, lng });

    dispatch(
      routesAnalysis.addWaypointAction({
        id: place_id,
        timestampId: timestampId,
        location: formatted_address,
        position: {
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
        },
        stopover: detoursMode === detoursOptions.stopover,
        duration: time,
        title: detourTitle(),
      })
    );

    await addDetourInRoute(infosForDetour, formatted_address);
    dispatch(Detour.desactivateCoordsForDetour());
    setLoading(true);
    await sleep(5000);
    setLoading(false);
  };

  const detourTitle = () => {
    const { value } = document.getElementById("detourTitle");
    return value;
  };

  const addDetourInRoute = async (
    infosForDetour,
    formatted_address,
    routeLocation,
    waypointList
  ) => {
    if (infosForDetour.routeCoords) {
      let stopovers = waypoints.filter(
        (waypointFilter) => waypointFilter.timestampId === timestampId
      );

      let filteredWaypoints = waypoints
        .filter((waypointFilter) => waypointFilter.timestampId === timestampId)
        .map((waypoint) => ({
          location: waypoint.location,
          stopover: false,
        }));

      if (formatted_address) {
        filteredWaypoints.push({
          location: formatted_address,
          stopover: false,
        });
        stopovers.push({
          stopover: detoursMode === detoursOptions.stopover,
          duration: time,
        });
      } else if (waypointList) {
        filteredWaypoints = waypointList.map((item) => {
          return {
            location: item.location,
            stopover: false,
          };
        });

        stopovers = waypointList.map((item) => {
          return {
            location: item.location,
            stopover: false,
            duration: item.duration,
          };
        });
      } else {
        filteredWaypoints = filteredWaypoints.filter(
          ({ location }) => location !== routeLocation
        );
      }

      const routeParams = {
        ...infosForDetour,
        radius: formFields.radius,
      };

      try {
        const { drawing, extraInformation } = await searchAddress(
          timestampId,
          routeParams,
          filteredWaypoints,
          stopovers
        );

        dispatch(crisisModeActions.updateRoute(extraInformation));
        dispatch(routesAnalysis.updateRoutes(extraInformation));

        dispatch(Drawing.removeDrawing(timestampId));
        dispatch(Drawing.addDrawing(drawing));
      } catch (e) {
        console.error(`error fetching directions ${e}`);
      }
    }
  };

  const deleteWaypoint = (id, location) => {
    dispatch(routesAnalysis.removeWaypointById(id));
    addDetourInRoute(infosForDetour, false, location);
  };

  const updateRoute = () => {
    const updateOnState = {
      isUpdate: true,
      origin: coords.origin,
      destination: coords.destination,

      lineColor,
      isVisible,
      isBold,
      isDraggable,
      crisisModule: crisisModule,
    };

    dispatch(routesAnalysis.addNewRoute(updateOnState));
    dispatch(routesAnalysis.deleteRoute(timestampId));
  };

  const handleOnMouseOverOrigin = useCallback(() => {
    setShowOrigin(true);
  }, []);

  const handleOnMouseLeaveOrigin = useCallback(() => {
    setShowOrigin(false);
  }, []);

  const handleOnMouseOverDetour = useCallback(() => {
    setShowDetour(true);
  }, []);

  const handleOnMouseLeaveDetour = useCallback(() => {
    setShowDetour(false);
  }, []);

  const handleOnMouseOverDestination = useCallback(() => {
    setShowDestination(true);
  }, []);

  const handleOnMouseLeaveDestination = useCallback(() => {
    setShowDestination(false);
  }, []);

  const getCurrentTimer = useCallback(() => {
    const initialTimestamp = new Date(timestampId);
    const currentTimestamp = new Date();

    const intervalInSeconds = (currentTimestamp - initialTimestamp) / 1000;
    const secondsPassed = Math.round(intervalInSeconds);

    return { secondsPassed };
  }, [timestampId]);

  const handleDelete = useCallback(() => {
    if (isLastRouteFromOrigin) {
      return;
    }

    if (!isUpdating) {
      dispatch(Detour.desactivateCoordsForDetour());
      dispatch(Drawing.removeDrawing(timestampId));
      dispatch(crisisModeActions.deleteRoute(timestampId));
      dispatch(routesAnalysis.removeAllWaypointsByTimestempId(timestampId));
      if (!crisisModeRoutes.crisisModule) {
        dispatch(routesAnalysis.deleteRoute(timestampId));
      }
    }
  }, [dispatch, isUpdating, isLastRouteFromOrigin]);

  const renderWaypoint = (waypoint) => {
    const waypointsInfoCheck =
      waypointsInfo.length > 0 ? waypoint.location : "Nenhum desvio na rota";

    return (
      <RouteDetail component="div">
        <Box component="div">
          <strong>Desvio: </strong>
        </Box>
        <AddressContainer
          component="div"
          display={showDetour ? "inline" : "none"}
        >
          {String(waypoint.location)}
        </AddressContainer>
        <EllipsisContainer
          component="div"
          onMouseOver={handleOnMouseOverDetour}
          onMouseLeave={handleOnMouseLeaveDetour}
        >
          <Typography component="i">{waypointsInfoCheck}</Typography>
        </EllipsisContainer>
      </RouteDetail>
    );
  };

  const handleDesvioButton = () => {
    dispatch(Detour.addInfosForDetour(props));
    if (crosshairForDetour) dispatch(Detour.desactivateCoordsForDetour());
    else dispatch(Detour.activateCoordsForDetour());
  };

  const onDragEnd = useCallback(
    (result, arrayToBeModified) => {
      if (!result.destination) {
        return;
      }

      const selectedWaypoint = remove(
        waypoints,
        ({ id }) => id === result.draggableId
      );

      waypoints.splice(result.destination.index, 0, selectedWaypoint[0]);

      dispatch(routesAnalysis.removeAllWaypointsByTimestempId(timestampId));

      waypoints.forEach((item) => {
        dispatch(
          routesAnalysis.addWaypointAction({
            id: item.id,
            timestampId: item.timestampId,
            location: item.location,
            position: {
              latitude: item.position.latitude,
              longitude: item.position.longitude,
            },
            title: item.title,
          })
        );
      });

      addDetourInRoute(infosForDetour, false, false, waypoints);
    },

    [waypoints, infosForDetour, dispatch, addDetourInRoute]
  );

  const handleChange = (e) => {
    changeMode(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(parseInt(e.target.value));
  };

  const disableAddDetourButton = () => {
    if (detoursMode === detoursOptions.stopover) {
      if (!detourAddress.length || parseInt(time) < 1) {
        return true;
      }
    } else {
      if (!detourAddress.length || loading) {
        return true;
      }
    }

    return false;
  };

  if (notVisible) {
    return <></>;
  }

  return (
    <RouteContainer component="div">
      <RouteInfo component="div">
        <UnitName>{crisisModeRoutes.unitName}</UnitName>
        <FlexBox>
          <Box component="div">
            <RouteDetail component="div">
              <Box component="div">
                <strong>Tempo padrão: </strong>
              </Box>
              <Box component="div">{duration}</Box>
            </RouteDetail>
            <RouteDetail component="div">
              <Box component="div">
                <strong>Tempo atual: </strong>
              </Box>
              <Box component="div">{durationInTraffic}</Box>
            </RouteDetail>
          </Box>
          {pos === 0 && (
            <ContainerFlag component="div">
              <Box component="div">
                <Box component="div">
                  <FlagSharpIcon />
                </Box>
              </Box>
            </ContainerFlag>
          )}
        </FlexBox>

        <MoreDetailContainer component="div">
          <Box component="span">{modeDetail ? "Ver Menos" : "Ver Mais"}</Box>
          <IconButton onClick={() => setMoreDetail(!modeDetail)}>
            {modeDetail ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </MoreDetailContainer>
      </RouteInfo>
      {modeDetail && [
        <RouteDetail component="div">
          <Box component="div">
            <strong>Origem: </strong>
          </Box>
          <AddressContainer
            component="div"
            display={showOrigin ? "inline" : "none"}
          >
            <Typography component="p">{origin}</Typography>
          </AddressContainer>
          <EllipsisContainer
            component="div"
            onMouseOver={handleOnMouseOverOrigin}
            onMouseLeave={handleOnMouseLeaveOrigin}
          >
            <Typography component="i">{origin}</Typography>
          </EllipsisContainer>
        </RouteDetail>,
        <Box component="div">{waypointsInfo.map(renderWaypoint)}</Box>,
        <RouteDetail>
          <Box component="div">
            <strong>Destino: </strong>
          </Box>
          <AddressContainer
            component="div"
            display={showDestination ? "inline" : "none"}
          >
            <Typography component="p">{destination}</Typography>
          </AddressContainer>
          <EllipsisContainer
            component="div"
            onMouseOver={handleOnMouseOverDestination}
            onMouseLeave={handleOnMouseLeaveDestination}
          >
            <Typography component="i">{destination}</Typography>
          </EllipsisContainer>
        </RouteDetail>,
        <RouteDetail component="div">
          <Box component="div">
            <strong>Distância: </strong>
          </Box>
          <Box component="div">{distance}</Box>
        </RouteDetail>,
        <RouteDetail component="div">
          <Box component="div">
            <strong>Cor da linha: </strong>
          </Box>
          <LineIcon component="div" backgroundColor={lineColor} />
        </RouteDetail>,
        <RouteDetail component="div">
          <Box component="div">
            <strong>Atualiza em: </strong>
          </Box>
          <Timer updater={updateRoute} timePassed={getCurrentTimer()} />
        </RouteDetail>,
        <ActionContainer component="div">
          <ActiveButton onClick={handleDesvioButton} title={"Desvio"}>
            {<AddIcon fontSize="large" />}
          </ActiveButton>
          <ActiveButton
            onClick={() => dispatch(routesAnalysis.setVisible(timestampId))}
            title={isVisible ? "Ocultar" : "Mostrar"}
          >
            {isVisible ? (
              <VisibilityOutlinedIcon fontSize="large" />
            ) : (
              <VisibilityOffOutlinedIcon fontSize="large" />
            )}
          </ActiveButton>
          <ActiveButton
            onClick={() => dispatch(routesAnalysis.setBold(timestampId))}
            title={isBold ? "Normalizar" : "Destacar"}
          >
            {isBold ? (
              <CenterFocusStrongIcon fontSize="large" />
            ) : (
              <CenterFocusWeakIcon fontSize="large" />
            )}
          </ActiveButton>
          <ActiveButton
            onClick={() => dispatch(routesAnalysis.setDraggable(timestampId))}
            title={isDraggable ? "Anexar" : "Soltar"}
          >
            {isDraggable ? (
              <FlipToFrontIcon fontSize="large" />
            ) : (
              <FlipToBackIcon fontSize="large" />
            )}
          </ActiveButton>
          <ActiveButton
            onClick={() => setOpenModal(true)}
            title="Visualizar Relatório"
          >
            <ReportIcon fontSize="large" />
          </ActiveButton>
          <ActiveButton
            style={{
              cursor:
                isLastRouteFromOrigin && props.crisisModule && "not-allowed",
            }}
            onClick={handleDelete}
            title="Deletar"
          >
            <DeleteOutlineIcon fontSize="large" />
          </ActiveButton>
        </ActionContainer>,
        crosshairForDetour && (
          <DetourContainer component="div">
            <CustomFormControl variant="outlined">
              <Select
                color="secondary"
                value={detoursMode}
                onChange={handleChange}
                input={<SelectInput name="analysis" />}
              >
                <MenuItem value={detoursOptions.waypoint}>
                  Ponto de Desvio
                </MenuItem>
                <MenuItem value={detoursOptions.stopover}>
                  Ponto de Parada
                </MenuItem>
              </Select>
            </CustomFormControl>
            <Box component="div">
              <Typography component="label" style={{ margin: "10px" }}>
                Endereço:
              </Typography>
              <InputSearchBox
                name={"address"}
                placeholder={"Endereço"}
                cleanField={cleanField}
                value={address}
                setValue={setAddress}
                detour={true}
                setDetourAddress={setDetourAddress}
              />
            </Box>
            <DetourTextContainer component="div" style={{ marginTop: "20px" }}>
              {detoursMode === detoursOptions.stopover && (
                <CustomTextField
                  width="30%"
                  label="Tempo"
                  type="number"
                  placeholder="Em minutos"
                  value={time}
                  onChange={handleTimeChange}
                />
              )}
              <CustomTextField id="detourTitle" label="Título" />
            </DetourTextContainer>
            <Button
              color="secondary"
              disabled={disableAddDetourButton()}
              onClick={AddDetour}
            >
              Adicionar
            </Button>
            {loading && <CircularProgress />}
          </DetourContainer>
        ),
      ]}
      {waypoints?.length > 0 && (
        <DragDropContext
          onDragEnd={(result, provided) => onDragEnd(result, provided)}
        >
          <Droppable droppableId="waypoints">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {provided.placeholder}
                {waypoints
                  .filter((waypoint) => waypoint.timestampId === timestampId)
                  .map(({ id, title, location, stopover, duration }, index) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          key={`${id}-${index}`}
                        >
                          <ListText
                            primary={
                              stopover ? `${title} (${duration} min)` : title
                            }
                            secondary={location}
                          />
                          <IconButton
                            edge="end"
                            aria-label="Editar"
                            color="secondary"
                            onClick={() => handleOpenEditModal(id)}
                          >
                            <FolderIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="Deletar"
                            color="secondary"
                            onClick={() => deleteWaypoint(id, location)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <EditWaypointModal
        id={waypointId}
        open={openEditModal}
        closeEditModal={toggleOpenEditModal}
      />
      <ReportModal
        id={timestampId}
        open={openModal}
        origin={origin}
        destination={destination}
        publicEntities={filteredPublicEntities}
        handleClose={() => setOpenModal(false)}
      />
    </RouteContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const info = state.routesAnalysis.informations.find(
    (info) => info.id === ownProps.timestampId
  );
  const isUpdating = state.routesAnalysis.updating;

  return {
    notVisible: !info,
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    origin: info?.start_address,
    destination: info?.end_address,
    coords: { origin: info?.origin, destination: info?.destination },
    durationInTraffic: info?.duration_in_traffic,
    duration: info?.duration,
    distance: info?.distance,
    lineColor: info?.lineColor,
    isVisible: info?.isVisible,
    isBold: info?.isBold,
    isOnFocus: info?.isOnFocus,
    isDraggable: info?.isDraggable,
    crisisModule: info?.crisisModule,
    unitName: info?.unitName,
    unitAddress: info?.unitAddress,
    timestampId: ownProps.timestampId,
    pos: ownProps.pos,
    isUpdating,
    crosshairForDetour: state.detours.crosshairForDetour,
    detoursMode: state.detours.mode,
    routeCoords: state.routesAnalysis.routeCoords,
    information: state.routesAnalysis,
    infoFormFields: getFormValues("INFO")(state)
      ? getFormValues("INFO")(state)
      : [],
    waypoints: state.routesAnalysis.waypoints ?? [],
    waypointsInfo: info?.waypoints ?? [],
    crisisModeRoutes: state.crisisMode.routes.find(
      ({ id }) => id === ownProps.timestampId
    ),
    infosForDetour: state.detours.infosForDetour,
  };
};

RouteComponent = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(RouteComponent);

const mapDispatchtoProps = {
  ...Detour,
};

export default connect(mapStateToProps, mapDispatchtoProps)(RouteComponent);
