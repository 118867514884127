import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { bindActionCreators } from "redux";
import { Divider, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import {
  NavList,
  PaperItem,
  TitleText,
  SideFooter,
  MainDrawer,
  ActionButton,
  DrawerHeader,
  ExpansionPanel,
  ExpandMoreIcon,
  MainSideContent,
  ExpansionHeading,
  ExpasionPanelTitle,
  ExpansionPanelDetail,
} from "./drawerFilterStyles";

import { Creators as FormActions } from "../../store/actions/menu";

class DrawerFilter extends Component {
  state = {
    customerUnits: this.props.userData ? this.props.userData.units : null,
  };

  handleCustomerUnits(index) {
    const unit = this.state.customerUnits.filter(
      (element) => element.area_id == index
    );
    let name = unit[0] ? unit[0].unit_name : "Object name not found";
    return name;
  }

  handleIconCustomerUnits(index) {
    const unit = this.state.customerUnits.filter(
      (element) => element.area_id == index
    );
    let icon = unit[0] ? unit[0].icon : "";
    return icon;
  }

  handlePublicEntityTypes(index) {
    const types = this.props.publicEntityTypes.filter(
      (element) => element.id == index
    );
    let name = types[0] ? types[0].entity : "Object name not found";
    return name;
  }

  handleIconPublicEntityTypes(index) {
    const types = this.props.publicEntityTypes.filter(
      (element) => element.id == index
    );
    let icon = types[0] ? types[0].icon : "";
    return icon;
  }

  handleRiskAreaTypes(index) {
    const area = this.props.riskAreaTypes.filter(
      (element) => element.id == index
    );
    let name = area[0] ? area[0].name : "Object name not found";
    return name;
  }

  handleIconRiskAreaTypes(index) {
    const area = this.props.riskAreaTypes.filter(
      (element) => element.id == index
    );
    let icon = area[0] ? area[0].icon : "";
    return icon;
  }

  handleCriminalFactions(index) {
    const area = this.props.criminalFactions.filter(
      (element) => element.id == index
    );
    let name = area[0] ? area[0].name : "Object name not found";
    return name;
  }

  handleIconCriminalFactions(index) {
    const area = this.props.criminalFactions.filter(
      (element) => element.id == index
    );
    let icon = area[0] ? area[0].icon : "";
    return icon;
  }

  handleInterestPointsTypesByCustomer(index) {
    const types = this.props.interestPointsTypesByCustomer.filter(
      (element) => element.id == index
    );
    let name = types[0] ? types[0].type : "Object name not found";
    return name;
  }

  handleIconInterestPointsTypesByCustomer(index) {
    const types = this.props.interestPointsTypesByCustomer.filter(
      (element) => element.id == index
    );
    let icon = types[0] ? types[0].icon : "";
    return icon;
  }

  handleInterestPointsTypes(index) {
    const types = this.props.interestPointsTypes.filter(
      (element) => element.id == index
    );
    let name = types[0] ? types[0].type : "Object name not found";
    return name;
  }

  handleIconInterestPointsTypes(index) {
    const types = this.props.interestPointsTypes.filter(
      (element) => element.id == index
    );
    let icon = types[0] ? types[0].icon : "";
    return icon;
  }

  render() {
    const { menuOpen, formFields, formInfo } = this.props;

    return (
      <MainDrawer anchor="right" variant="persistent" open={menuOpen}>
        <DrawerHeader>
          <TitleText variant="h6" noWrap>
            Marcadores Selecionados
          </TitleText>
          <ActionButton onClick={() => this.props.animateMenu("FECHA_MENU")}>
            <CloseIcon />
          </ActionButton>
        </DrawerHeader>
        <Divider />
        <MainSideContent component="div">
          {formFields.Units && (
            <PaperItem key={"Clusters"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>Unidades do Usuário</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formFields.Units.map((item, index) => (
                      <ListItem key={`unit-${index}`} disablePadding>
                        <ListItemIcon>
                          <img src={this.handleIconCustomerUnits(item)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handleCustomerUnits(item)}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
          {formFields.publicEntityType && (
            <PaperItem key={"PublicEntity"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>Entidades Públicas</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formFields.publicEntityType.map((item, index) => (
                      <ListItem
                        key={`public-entity-type-${index}`}
                        disablePadding
                      >
                        <ListItemIcon>
                          <img src={this.handleIconPublicEntityTypes(item)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handlePublicEntityTypes(item)}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
          {formInfo?.riskAreaTypes && (
            <PaperItem key={"RiskAreas"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>Áreas de Risco</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formInfo.riskAreaTypes.map((item, index) => (
                      <ListItem key={`risk-area-${index}`} disablePadding>
                        <ListItemIcon>
                          <img src={this.handleIconRiskAreaTypes(item)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handleRiskAreaTypes(item)}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
          {formInfo?.criminalFactionsFilters && (
            <PaperItem key={"CriminalFactions"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>Facções Criminosas</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formInfo.criminalFactionsFilters.map((item, index) => (
                      <ListItem
                        key={`criminal-faction-${index}`}
                        disablePadding
                      >
                        <ListItemIcon>
                          <img src={this.handleIconCriminalFactions(item)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handleCriminalFactions(item)}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
          {formFields.interestPoint && (
            <PaperItem key={"InterestPoint"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>
                    Pontos de Interesse Coletivos
                  </ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formFields.interestPoint.map((item, index) => (
                      <ListItem key={`interest-point-${index}`} disablePadding>
                        <ListItemIcon>
                          <img src={this.handleIconInterestPointsTypes(item)} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handleInterestPointsTypes(item)}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
          {formFields.interestPointByCustomer && (
            <PaperItem key={"InterestPointByCustomer"}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>
                    Pontos de Interesse Exclusivos
                  </ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <NavList component="nav">
                    {formFields.interestPointByCustomer.map((item, index) => (
                      <ListItem
                        key={`interest-point-ny-customer-${index}`}
                        disablePadding
                      >
                        <ListItemIcon>
                          <img
                            src={this.handleIconInterestPointsTypesByCustomer(
                              item
                            )}
                            alt="poi-logo"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.handleInterestPointsTypesByCustomer(
                            item
                          )}
                        />
                      </ListItem>
                    ))}
                  </NavList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          )}
        </MainSideContent>
        <SideFooter xs={1} component="div" color="secondary">
          <span>
            Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
          </span>
        </SideFooter>
      </MainDrawer>
    );
  }
}

const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(FormActions, dispatch);

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.filters,
    formFields: getFormValues("ANALISE")(state),
    formInfo: getFormValues("INFO")(state),
    interestPointsTypesByCustomer:
      state.interestPoints.interestPointsTypesByCustomer,
    interestPointsTypes: state.interestPoints.interestPointsTypes,
    publicEntityTypes: state.publicEntityTypes.publicEntityTypes,
    riskAreaTypes: state.riskAreas.riskAreaTypes,
    criminalFactions: state.riskAreas.criminalFactions,
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerFilter);
