import { Types } from "../actions/drawingAnalyse";

const INITIAL_STATE = {
  drawingsAnalyses: [],
};

const drawingAnalysesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ADD_DRAWING_ANALYSES:
      return {
        ...state,
        drawingsAnalyses: action.payload,
      };

    case Types.REMOVE_DRAWING_ANALYSES:
      return {
        ...state,
        drawingsAnalyses: state.drawingsAnalyses.filter(
          (drawing) => drawing.id !== action.payload
        ),
      };

    case Types.REMOVE_ALL_DRAWINGS_ANALYSES:
      return {
        ...state,
        drawingsAnalyses: [],
      };

    default:
      return state;
  }
};

export default drawingAnalysesReducer;
