import React from "react";
import { connect } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { convertToLocaleString } from "../../../common/dateHelper";

import { Creators as crisisModeActions } from "../../../store/actions/crisisMode";
import { Creators as publicEntitiesActions } from "../../../store/actions/public-entities";
import { Creators as riskAreasActions } from "../../../store/actions/riskAreas";
import { Creators as MenuActions } from "../../../store/actions/menu";
import { Creators as RoutesAnalysisActions } from "../../../store/actions/routesAnalysis";

import { BrokenImage as BrokenImageIcon } from "@mui/icons-material";

import {
  AlertPaper,
  SubareaButton,
  CrisisContainer,
  CrisisModeButton,
  IncidentTextTitle,
  InfoWindowComponent,
} from "./styles";

const CustomInfoWindow = (props) => {
  const {
    id,
    title,
    onClose,
    position,
    userData,
    callback,
    cleanAll,
    updateDate,
    toggleMode,
    nextUpdate,
    subAreaMode,
    animateMenu,
    coordinates,
    publicEntityId,
    crisisModeUnits,
    areaInformations,
    informationTypes,
    handleRiskAreasPoligon,
    riskAreasIdsToViewPoligon,
    handlePublicEntityPoligon,
    publicEntitiesIdsToViewPoligon,
  } = props;

  const getformationTypeName = (areaInformation) => {
    const informationType = informationTypes.find(
      (type) => type.id === areaInformation.informationTypeId
    );
    return informationType ? informationType.informationType : "";
  };

  const buttonDescription = () => {
    const publicEntitiesIds =
      process.env.REACT_APP_PUBLIC_ENTITIES_IDS.split(",");

    return publicEntitiesIds.includes(`${publicEntityId}`)
      ? "Circunscrição"
      : "Abrangência";
  };

  const handleEvasionPointsUnits = () => {
    cleanAll();
    toggleMode(id);

    if (crisisModeUnits.includes(id)) animateMenu("FECHA_MENU");
  };

  const clusterProvider = () => {
    const evasionPoints = userData.units.find(
      ({ area_id }) => area_id === `${id}`
    );

    if (evasionPoints?.evasion_points?.length > 0) {
      return (
        <CrisisContainer>
          <CrisisModeButton
            size="medium"
            type="button"
            onClick={handleEvasionPointsUnits}
          >
            Módulo Crise
          </CrisisModeButton>
        </CrisisContainer>
      );
    }
  };

  const publicEntityProvider = (polygonCoordinates) => {
    return (
      polygonCoordinates && (
        <CrisisContainer>
          <Button
            style={{ margin: "15px" }}
            type="button"
            variant="contained"
            onClick={() => handlePublicEntityPoligon(id)}
            color="primary"
            size="medium"
          >
            {publicEntitiesIdsToViewPoligon.includes(id)
              ? `Ocultar ${buttonDescription()}`
              : `Visualizar ${buttonDescription()}`}
          </Button>
        </CrisisContainer>
      )
    );
  };

  const riskAreaProvider = (polygonCoordinates) => {
    return (
      polygonCoordinates && (
        <CrisisContainer>
          <Button
            style={{ margin: "15px" }}
            type="button"
            variant="contained"
            onClick={() => handleRiskAreasPoligon(id)}
            color="primary"
            size="medium"
          >
            {!riskAreasIdsToViewPoligon.includes(id)
              ? "Ocultar Abrangência"
              : "Visualizar Abrangência"}
          </Button>
        </CrisisContainer>
      )
    );
  };

  const actionButton = {
    riskArea: riskAreaProvider,
    publicEntity: publicEntityProvider,
    cluster: clusterProvider,
  };

  const validateNextUpdate = (date) => {
    const currentDate = new Date();
    const compareDate = new Date(date);

    return compareDate > currentDate;
  };

  return (
    <InfoWindowComponent
      options={{ maxWidth: 500 }}
      onCloseClick={onClose}
      position={position}
    >
      <AlertPaper color="primary">
        <IncidentTextTitle primary={title} />
        {areaInformations && areaInformations.length > 0 ? (
          <Box component="div">
            <table>
              <tbody>
                {areaInformations &&
                  areaInformations.map((areaInformation) => (
                    <tr>
                      <td>
                        <strong>
                          {getformationTypeName(areaInformation)}:
                        </strong>
                      </td>
                      <td>{areaInformation.information}</td>
                    </tr>
                  ))}
                {updateDate && (
                  <tr>
                    <td>
                      <strong>Atualizado em:</strong>
                    </td>
                    <td>{convertToLocaleString(updateDate)}</td>
                  </tr>
                )}
                {nextUpdate && (
                  <tr>
                    <td>
                      <strong>Próxima atualização:</strong>
                    </td>
                    <td
                      style={{
                        color: validateNextUpdate(nextUpdate) ? "blue" : "red",
                      }}
                    >
                      {convertToLocaleString(nextUpdate)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        ) : (
          <Typography component="p">
            <Typography component="i">
              * Esta entidade ainda não possui informações adicionais
            </Typography>
          </Typography>
        )}
        {actionButton[props.layerType] &&
          actionButton[props.layerType](coordinates)}
        {props.callback && (
          <SubareaButton onClick={callback}>
            <BrokenImageIcon />
            {subAreaMode ? "Ocultar sub-áreas" : "Mostrar sub-áreas"}
          </SubareaButton>
        )}
      </AlertPaper>
    </InfoWindowComponent>
  );
};

const mapStateToProps = (state) => ({
  crisisMode: state.crisisMode,
  userData: state.auth.userData,
  publicEntitiesIdsToViewPoligon:
    state.publicEntities.publicEntitiesIdsToViewPoligon,
  riskAreasIdsToViewPoligon: state.riskAreas.riskAreasIdsToViewPoligon,
  informationTypes: state.areaInformationTypes.areaInformationTypes,
  crisisModeUnits: state.crisisMode.units,
});

const mapDispatchToProps = {
  ...MenuActions,
  ...riskAreasActions,
  ...crisisModeActions,
  ...publicEntitiesActions,
  ...RoutesAnalysisActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomInfoWindow);
