import { call, put } from "redux-saga/effects";

import { getCriminalFactions as getCriminalFactionsRequest } from "../services/riskAreas";
import { Creators as actions } from "../actions/riskAreas";

export default function* getCriminalFactions() {
  try {
    const list = yield call(getCriminalFactionsRequest);
    yield put(actions.getCriminalFactionsSucceeded(list));
  } catch (error) {
    yield put(actions.getCriminalFactionsFailed(error));
  }
}
