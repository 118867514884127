import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateTimePicker } from "./inputDateStyles";

const InputDate = ({ value, label, name, changeField }) => {
  const [newValue, setNewValue] = useState(dayjs(new Date().toString()));

  useEffect(() => {
    if (value) {
      setNewValue(value);
    }
  }, [value]);

  const handleDateChange = (date) => {
    changeField(name, date.$d);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid>
        <DateTimePicker
          label={label}
          inputFormat="DD/MM/YYYY HH:mm"
          value={newValue}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              name={name}
              id={"date-picker-dialog-" + name}
              {...params}
            />
          )}
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default InputDate;
