import React from "react";
import { connect } from "react-redux";
import { Close, ListAlt, DeleteOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import {
  TitleText,
  MainDrawer,
  SideFooter,
  ExploreIcon,
  ActionButton,
  DrawerHeader,
  RadiusWarning,
  OpenListButton,
  MainSideContent,
  SliderContainer,
  ButtonContainer,
  RemoveRouteButton,
} from "./styles";

import { Creators as MenuCreators } from "../../../store/actions/menu";
import { Creators as CrisisModeCreators } from "../../../store/actions/crisisMode";
import { Creators as RouteAnalysisCreators } from "../../../store/actions/routesAnalysis";
import { Creators as DrawingsCreators } from "../../../store/actions/drawing";

import Card from "../DrawerRoutes/Card";

const DrawerEvasionRoutes = (props) => {
  const {
    routes,
    menuOpen,
    cleanAll,
    openPopup,
    isUpdating,
    animateMenu,
    crisisModeUnits,
    removeAllDrawings,
    setCrisisModeRoutes,
  } = props;

  const handleOpenRoutesList = () => {
    openPopup(true);
  };

  const handleDeleteRoutes = () => {
    setCrisisModeRoutes([]);
    cleanAll();
    removeAllDrawings();
  };

  return (
    <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
      <DrawerHeader>
        <TitleText variant="h5" noWrap>
          Módulo Crise - Rotas de Evasão
        </TitleText>
        <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
          <Close />
        </ActionButton>
      </DrawerHeader>

      <MainSideContent>
        <SliderContainer>
          {routes.length > 0
            ? routes.map((route, index) => (
                <Card key={route.id} pos={index} timestampId={route.id} />
              ))
            : !isUpdating && (
                <RadiusWarning component="div">
                  <Typography>
                    <ExploreIcon />
                  </Typography>
                  <Typography variant="h7">
                    Você não possui nenhuma roteirização.
                  </Typography>
                </RadiusWarning>
              )}
          {crisisModeUnits?.length > 0 && [
            <ButtonContainer component="div">
              <OpenListButton onClick={handleOpenRoutesList}>
                <ListAlt /> {" Abrir Lista de Rotas"}
              </OpenListButton>
            </ButtonContainer>,
          ]}
          {routes?.length > 0 && (
            <ButtonContainer component="div">
              <RemoveRouteButton onClick={handleDeleteRoutes}>
                <DeleteOutline />
                {" Remover Rotas"}
              </RemoveRouteButton>
            </ButtonContainer>
          )}
        </SliderContainer>
      </MainSideContent>
      <SideFooter xs={1} component="div">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.rotasEvasao,
    isUpdating: state.routesAnalysis.updating,
    routes: state.crisisMode.routes.filter(({ crisisModule }) => crisisModule),
    crisisModeUnits: state.crisisMode.units,
  };
};

const mapDispatchToProps = {
  ...MenuCreators,
  ...DrawingsCreators,
  ...CrisisModeCreators,
  ...RouteAnalysisCreators,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerEvasionRoutes);
