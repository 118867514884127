import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = null;

if (process.env.NODE_ENV === "development") {
  store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
  );
} else {
  store = createStore(reducers, applyMiddleware(...middlewares));
}

sagaMiddleware.run(sagas);

export default store;
