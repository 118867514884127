import { Types } from "../actions/areas";

const INITIAL_STATE = {
  areas: false,
  loading: false,
  loadingAreasFailed: false,
  loaded: false,
};

export default function getAreas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_AREA_SELECTED:
      return {
        ...state,
        loading: true,
        loadingFailed: false,
      };
    case Types.GET_AREA_SELECTED_SUCCESS:
      return {
        ...state,
        areas: action.data.data,
        loaded: true,
        loading: false,
        loadingFailed: false,
      };
    case Types.GET_AREA_SELECTED_FAILURE:
      return {
        ...state,
        areas: false,
        loaded: false,
        loading: false,
        loadingFailed: true,
      };
    case Types.REMOVE_AREA_FILTERS:
      return {
        ...state,
        areas: false,
        loading: false,
        loadingAreasFailed: false,
        loaded: false,
      };
    default:
      return state;
  }
}
