import React from "react";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ItemText,
  CustomSwitch,
  CustomListItem,
  CustomFormLabel,
} from "../components/SideMenu/drawerCameraStyles";

function InputSwitch(props) {
  const [checked, setChecked] = React.useState(false);

  const value = props.formFields[props.keyValue]
    ? props.formFields[props.keyValue]
    : [];

  const toggleChecked = () => {
    callUnitWarning();
    setChecked((prev) => !prev);
    if (checked === false) {
      props.array.push([props.keyValue], props.value);
    } else {
      if (
        props.keyValue === "Units" ||
        props.keyValue === "interestPoint" ||
        props.keyValue === "interestPointByCustomer" ||
        props.keyValue === "riskAreas" ||
        props.keyValue === "publicEntityType"
      ) {
        var newValue = [];
        newValue = value.filter((e) => e !== props.value);
        newValue.length > 0
          ? props.change([props.keyValue], newValue)
          : props.change([props.keyValue], undefined);
      } else {
        props.array.change
          ? props.array.change([props.keyValue], undefined)
          : props.change([props.keyValue], undefined);
      }
    }
  };

  const callUnitWarning = () => {
    if (!props.formFields.Units || props.formFields.Units.length === 0) {
      props.setWarning("callBySelectingEntities");
    }
  };

  return (
    <CustomListItem key={props.keyValue}>
      <ItemText
        control={
          <CustomSwitch
            color="secondary"
            disabled={props.disabled}
            onClick={toggleChecked}
            checked={checked}
            name={props.keyValue}
          />
        }
        label={<CustomFormLabel>{props.label}</CustomFormLabel>}
        labelPlacement="start"
      />
    </CustomListItem>
  );
}

InputSwitch.propTypes = {
  keyValue: PropTypes.string,
  label: PropTypes.string.isRequired,
};

// eslint-disable-next-line
InputSwitch = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(InputSwitch);

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
  };
};

export default connect(mapStateToProps, null)(InputSwitch);
