import decodePoly from "decode-google-map-polyline";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point } from "@turf/helpers";

import _ from "lodash";

export const filterRiskAreasByUnits = ({
  riskAreas,
  selectedUnits,
  selectedCriminalFaction,
}) => {
  let filteredRiskAreas = [];
  if (riskAreas?.length > 0) {
    if (selectedUnits?.length > 0) {
      filteredRiskAreas = riskAreas.filter((ra) =>
        selectedUnits.includes(ra.unit_id)
      );
    }
    if (selectedCriminalFaction?.length > 0) {
      filteredRiskAreas = riskAreas.filter(({ riskZone }) => {
        if (riskZone) {
          return selectedCriminalFaction.includes(riskZone.criminalFactionId);
        }
        return false;
      });
    }
  }

  return filteredRiskAreas;
};

export const filterRiskAreasByTypes = ({
  filteredRiskArea,
  riskAreasTypesSelected = [],
}) => {
  if (!riskAreasTypesSelected.length > 0) {
    return false;
  }

  return riskAreasTypesSelected.includes(filteredRiskArea.riskZone.typeId);
};

export const filterRiskAreasByCriminalFactions = ({
  filteredRiskArea,
  criminalFactionsSelected = [],
}) => {
  if (!criminalFactionsSelected.length > 0) {
    return false;
  }

  return criminalFactionsSelected.includes(
    filteredRiskArea.riskZone.criminalFactionId
  );
};

export const filterRiskAreasByRadiusIntersection = ({
  riskArea,
  filterablePolygons,
}) => {
  if (filterablePolygons.length === 0 || !riskArea) {
    return true;
  }

  const points = [];

  if (riskArea.polygon) {
    let parsedCoords;

    if (riskArea.polygon.type === "Polygon") {
      parsedCoords = decodePoly(riskArea.polygon.coordinates[0]);
    } else {
      parsedCoords = _.flattenDeep(
        (riskArea.polygon.coordinates ?? []).map((positionArray) =>
          decodePoly(positionArray[0])
        )
      );
    }

    for (const coord of parsedCoords) {
      points.push(point([coord["lng"], coord["lat"]]));
    }
  } else {
    const [lng, lat] = riskArea.point.coordinates;
    points.push(point([lng, lat]));
  }

  return points.some((point) => {
    return filterablePolygons.some((polygon) =>
      booleanPointInPolygon(point, polygon)
    );
  });
};

export const filterRiskAreasByRelation = ({
  units,
  riskAreas,
  riskAreaTypes,
}) => {
  const relatedUnit = {};
  units
    .filter(({ areaRelated }) => areaRelated.length > 0)
    .forEach(({ areaRelated }) => {
      areaRelated.forEach((element) => {
        relatedUnit[element.relatedAreaId] = element;
      });
    });

  return riskAreas.filter(
    ({ id, riskZone }) =>
      relatedUnit[id] && riskAreaTypes?.includes(riskZone.typeId)
  );
};

export const filterCriminalFactionsByRelation = ({
  units,
  riskAreas,
  criminalFactionsFilters,
}) => {
  const relatedUnit = {};
  units
    .filter(({ areaRelated }) => areaRelated.length > 0)
    .forEach(({ areaRelated }) => {
      areaRelated.forEach((element) => {
        relatedUnit[element.relatedAreaId] = element;
      });
    });

  return riskAreas.filter(
    ({ id, riskZone }) =>
      relatedUnit[id] &&
      criminalFactionsFilters?.includes(riskZone.criminalFactionId)
  );
};
