import { takeLatest, all, fork } from "redux-saga/effects";

import { Types as ListTypes } from "../actions/ocurrences";
import { Types as CategoriesTypes } from "../actions/categories";
import { Types as AuthTypes } from "../actions/auth";
import { Types as Areas } from "../actions/areas";
import { Types as InterestPoints } from "../actions/interestPoints";
import { Types as RiskAreas } from "../actions/riskAreas";
import { Types as PublicEntities } from "../actions/public-entities";
import { Types as PublicEntityTypes } from "../actions/publicEntityTypes";
import { Types as AreaInformationTypes } from "../actions/areaInformationTypes";
import { Types as CriminalFactions } from "../actions/riskAreas";
import { Types as CustomerUnitsAreas } from "../actions/unitsAreas";

import interestPointsTypesByCustomer from "./interestPointsTypesByCustomer";
import interestPointsByCustomer from "./interestPointsByCustomer";
import getClippingCategories from "./clippingsCategories";
import interestPointsTypes from "./interestPointsTypes";
import interestPoints from "./interestPoints";
import getIncidenteRequest from "./incidents";
import getAnaliseRequest from "./analysis";
import getAnaliseCompareRequest from "./analysisCompare";
import refreshSSOAuth from "./refreshSSO";
import getCategories from "./categories";
import getMediaGroups from "./mediaGroups";
import ssoAuthenticate from "./sso";
import loginRequest from "./auth";
import getUser from "./getUser";
import getAreas from "./areas";
import getPublicEntities from "./public-entities";
import getPublicEntityTypes from "./publicEntityTypes";
import Logout from "./logout";
import getRiskAreas from "./riskAreas";
import getRiskAreaTypes from "./riskAreaTypes";
import areaInformationTypes from "./areaInformationTypes";
import criminalFactions from "./criminalFactions";
import getCustomerUnitsAreas from "./unitsAreas";

export default function* rootSaga() {
  return yield all([
    takeLatest(
      InterestPoints.GET_INTEREST_POINTS_TYPES_BY_CUSTOMER,
      interestPointsTypesByCustomer
    ),
    takeLatest(
      InterestPoints.GET_INTEREST_POINTS_BY_CUSTOMER,
      interestPointsByCustomer
    ),
    takeLatest(CategoriesTypes.GET_CLIPPING_CATEGORIES, getClippingCategories),
    takeLatest(CategoriesTypes.GET_MEDIA_GROUP, getMediaGroups),
    takeLatest(InterestPoints.GET_INTEREST_POINTS_TYPES, interestPointsTypes),
    takeLatest(RiskAreas.GET_RISK_AREA_TYPES, getRiskAreaTypes),
    takeLatest(ListTypes.GET_INCIDENTS, getIncidenteRequest),
    takeLatest(ListTypes.GET_ANALYSIS, getAnaliseRequest),
    takeLatest(ListTypes.GET_ANALYSIS_COMPARE, getAnaliseCompareRequest),
    takeLatest(AuthTypes.ASYNC_SSO_AUTHENTICATE, ssoAuthenticate),
    takeLatest(InterestPoints.GET_INTEREST_POINTS, interestPoints),
    takeLatest(CategoriesTypes.GET_CATEGORIES, getCategories),
    takeLatest(AuthTypes.LOGIN, loginRequest),
    takeLatest(
      CustomerUnitsAreas.GET_CUSTOMER_UNITS_AREAS,
      getCustomerUnitsAreas
    ),
    takeLatest(Areas.GET_AREA_SELECTED, getAreas),
    takeLatest(PublicEntities.GET_PUBLIC_ENTITIES, getPublicEntities),
    takeLatest(PublicEntityTypes.GET_PUBLIC_ENTITY_TYPES, getPublicEntityTypes),
    takeLatest(AuthTypes.GET_USER, getUser),
    takeLatest(AuthTypes.LOGOUT, Logout),
    takeLatest(RiskAreas.GET_RISK_AREAS, getRiskAreas),
    takeLatest(
      AreaInformationTypes.GET_ALL_AREA_INFORMATION_TYPES,
      areaInformationTypes
    ),
    takeLatest(CriminalFactions.GET_CRIMINAL_FACTIONS, criminalFactions),
    fork(refreshSSOAuth),
  ]);
}
