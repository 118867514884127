import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../components/User/Login";
import LoginSSO from "../components/User/LoginSSO";
import Main from "../components/App/Main.js";
import Private from "./private";

export default (props) => (
  <div>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/login/sso" exact component={LoginSSO} />
        <Private path="/" exact component={Main} />
      </Switch>
    </BrowserRouter>
  </div>
);
