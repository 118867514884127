export const Types = {
  GET_CUSTOMER_UNITS_AREAS: "GET_CUSTOMER_UNITS_AREAS",
  GET_CUSTOMER_UNITS_AREAS_SUCCESS: "GET_CUSTOMER_UNITS_AREAS_SUCCESS",
  GET_CUSTOMER_UNITS_AREAS_FAILURE: "GET_CUSTOMER_UNITS_FAILURE",
  SHOW_CUSTOMER_UNITS_LIMITS: "SHOW_CUSTOMER_UNITS_LIMITS",
};

export const Creators = {
  getCustomerUnitsAreas: (filters) => ({
    type: Types.GET_CUSTOMER_UNITS_AREAS,
    filters,
  }),
  getCustomerUnitsAreasSucess: (units) => ({
    type: Types.GET_CUSTOMER_UNITS_AREAS_SUCCESS,
    payload: units,
  }),
  getCustomerUnitsAreasFailure: () => ({
    type: Types.GET_CUSTOMER_UNITS_AREAS_FAILURE,
  }),
  showUnitsLimits: () => ({
    type: Types.SHOW_CUSTOMER_UNITS_LIMITS,
  }),
};
