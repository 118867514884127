import { Types } from "../actions/publicEntityTypes";

const INITIAL_STATE = {
  publicEntityTypes: [],
  loadingPublicEntityTypes: false,
  loadingPublicEntityTypesFailed: false,
  loadingPublicEntityTypesLoaded: false,
  loaded: false,
};

export default function getPublicEntityTypes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PUBLIC_ENTITY_TYPES:
      return {
        ...state,
        loadingPublicEntityTypes: true,
        loadingPublicEntityTypesFailed: false,
      };
    case Types.GET_PUBLIC_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        publicEntityTypes: action.filters.data,
        loadingPublicEntityTypesLoaded: true,
        loadingPublicEntityTypes: false,
        loadingPublicEntityTypesFailed: false,
      };
    case Types.GET_PUBLIC_ENTITY_TYPES_FAILURE:
      return {
        ...state,
        publicEntityTypes: [],
        loadingPublicEntityTypesLoaded: false,
        loadingPublicEntityTypes: false,
        loadingPublicEntityTypesFailed: true,
      };
    default:
      return state;
  }
}
