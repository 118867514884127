import { apiSSO } from "./api";

export const setSSOIntegration = async (sso) =>
  localStorage.setItem("@sso_integration", sso);
export const getSSOIntegration = async () =>
  localStorage.getItem("@sso_integration");
export const removeSSOIntegration = async () =>
  localStorage.removeItem("@sso_integration");

export const setSSOExpiresAt = async (expires_at) =>
  localStorage.setItem("@sso_expires_at", expires_at);
export const getSSOExpiresAt = async () =>
  localStorage.getItem("@sso_expires_at");

export const ssoSession = (token) =>
  apiSSO().get("/session", { params: { token } });

export const ssoRefresh = ({ refresh_token, sso }) =>
  apiSSO().request({
    url: "login/refresh",
    method: "POST",
    data: { refresh_token, sso },
  });
