import { call, put } from "redux-saga/effects";

import { getInterestPointsByCustomer as getInterestPointsByCustomerRequest } from "../services/points-of-interest";
import { Creators as interestActions } from "../actions/interestPoints";

export default function* getInterestPointsByCustomer() {
  try {
    const list = yield call(getInterestPointsByCustomerRequest);
    yield put(interestActions.getInterestPointsByCustomerSuccess(list));
  } catch (error) {
    yield put(interestActions.getInterestPointsByCustomerFailure(error));
  }
}
