import api from "./apiV1";

export const getInterestPointsTypes = () => {
  return api.get("points-of-interest-types");
};

export const getInterestPoints = () => {
  return api.get("points-of-interest");
};

export const getInterestPointsByCustomer = () => {
  return api.get("customer-points-of-interest");
};

export const getInterestPointsTypesByCustomer = () => {
  return api.get("customer-points-of-interest-types");
};
