import { call, put } from "redux-saga/effects";
import { getMediaGroupsRequest } from "../services/categories";
import { Creators as CategoriesActions } from "../actions/categories";

export default function* getMediaGroups(filters) {
  try {
    const list = yield call(getMediaGroupsRequest, filters);
    yield put(CategoriesActions.getMediaGroupsSuccess(list));
  } catch (error) {
    yield put(CategoriesActions.getMediaGroupsFailure(error));
  }
}
