import { Types } from "../actions/routesAnalysis";
import { stringfyDate } from "../../common/dateHelper";

const INITIAL_STATE = {
  routeCoords: null,
  informations: [],
  waypoints: [],
  loading: false,
  updating: false,
  fetchError: false,
  fetchSucess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ADD_WAYPOINT:
      return {
        ...state,
        waypoints: [...state.waypoints, action.payload],
      };
    case Types.REMOVE_WAYPOINT_BY_ID:
      return {
        ...state,
        waypoints: state.waypoints.filter(({ id }) => id !== action.payload),
      };
    case Types.REMOVE_ALL_WAYPOINTS:
      return {
        ...state,
        waypoints: state.waypoints.filter(
          ({ timestampId }) => timestampId !== action.payload
        ),
      };
    case Types.CLEAR_WAYPOINTS:
      return {
        ...state,
        waypoints: [],
      };
    case Types.EDIT_WAYPOINT:
      return {
        ...state,
        waypoints: [
          ...state.waypoints.filter(({ id }) => id !== action.payload.id),
          action.payload,
        ],
      };
    case Types.ADD_ROUTE:
      return {
        ...state,
        routeCoords: action.payload,
        loading:
          action.payload.isUpdate || action.payload.isCrisisModule
            ? false
            : true,
        updating:
          action.payload.isUpdate || action.payload.isCrisisModule
            ? true
            : false,
        fetchSucess: false,
        fetchError: false,
      };
    case Types.UPDATE_ROUTES:
      return {
        ...state,
        informations: [
          ...state.informations.filter(({ id }) => id !== action.payload.id),
          action.payload,
        ],
        fetchSucess: true,
        fetchError: false,
      };
    case Types.CLEAN_ALL:
      return {
        ...state,
        routeCoords: null,
        informations: [],
      };
    case Types.SET_VISIBLE:
      return {
        ...state,
        informations: state.informations.map((element) => {
          if (element.id !== action.payload) {
            return element;
          }

          return {
            ...element,
            isVisible: element.isVisible ? false : true,
          };
        }),
      };
    case Types.SET_BOLD:
      return {
        ...state,
        informations: state.informations.map((element) => {
          if (element.id !== action.payload) {
            return element;
          }

          return {
            ...element,
            isBold: element.isBold ? false : true,
          };
        }),
      };
    case Types.DELETE_ROUTE:
      return {
        ...state,
        informations: state.informations.filter(
          ({ id }) => id !== action.payload
        ),
      };
    case Types.DELETE_CRISIS_MODULE_ROUTES:
      return {
        ...state,
        informations: state.informations.filter(
          (route) => route.crisisModule?.evasionPoint !== action.payload
        ),
      };
    case Types.SET_DRAGGABLE:
      return {
        ...state,
        informations: state.informations.map((element) => {
          if (element.id !== action.payload) {
            return element;
          }

          return {
            ...element,
            isDraggable: element.isDraggable ? false : true,
          };
        }),
      };
    case Types.SET_FOCUS:
      return {
        ...state,
        informations: state.informations.map((element) => {
          if (element.id !== action.payload) {
            return element;
          }

          return {
            ...element,
            isOnFocus: element.isOnFocus ? false : true,
          };
        }),
      };
    case Types.FETCH_ERROR:
      return {
        ...state,
        fetchError: true,
        fetchSucess: false,
        loading: false,
        updating: false,
      };
    case Types.FETCH_SUCESS:
      return {
        ...state,
        fetchError: false,
        fetchSucess: true,
        loading: false,
        updating: false,
      };
    default:
      return state;
  }
};

export const selectSortedInformation = (state, filter) => {
  const newArray = state.routesAnalysis.informations.map((item) => {
    const splitedTime = item[filter].split(" ");

    item.timestamp = Number(stringfyDate(splitedTime));

    return item;
  });

  return newArray.sort((a, b) => a.timestamp - b.timestamp);
};

export const isLastFromOrigin = (state, origin) => {
  return (
    state.routesAnalysis.informations.filter(
      (info) => info.start_address === origin
    ).length === 1
  );
};
