import React, { useMemo } from "react";
import { connect } from "react-redux";
import { staticMapUrl } from "static-google-map";
import { Text, Page, View, Image, Document } from "@react-pdf/renderer";
import { Dialog, Button } from "@mui/material";

import { ContentPaper, ActionPaper } from "./routeStyles";

import { PDFContainer } from "./routeStyles";

import { style } from "./reportStyles";

const ReportModal = ({
  id,
  open,
  origin,
  routes,
  destination,
  handleClose,
  publicEntities,
  informationTypes,
}) => {
  const informationTypesName = informationTypes.reduce((accum, curr) => {
    accum[curr.id] = curr.informationType;

    return accum;
  }, {});

  const route = routes.find((route) => route.id === id);

  const encRoutePolyline = useMemo(() => {
    if (route?.decodedPolyline && Array.isArray(route.decodedPolyline)) {
      return window.google.maps.geometry.encoding.encodePath(
        route.decodedPolyline
      );
    }

    return [];
  }, [route]);

  const buildMarkers = () => {
    const markers = [];

    if (route?.origin) {
      markers.push({
        location: route.origin,
        color: "red",
        size: "mid",
        label: "A",
      });
    }

    // eslint-disable-next-line no-unused-expressions
    publicEntities?.forEach((element) => {
      markers.push({
        location: {
          lat: element.area?.point?.coordinates[1] || 0,
          lng: element.area?.point?.coordinates[0] || 0,
        },
        size: "mid",
        iconURL: element.publicEntity?.icon,
      });
    });

    if (route?.destination) {
      markers.push({
        location: route.destination,
        color: "red",
        size: "mid",
        label: "B",
      });
    }

    return markers;
  };

  const url = staticMapUrl({
    key: process.env.REACT_APP_MAPS_KEY,
    paths: [
      {
        points: `enc:${encRoutePolyline}`,
      },
    ],
    markers: buildMarkers(),
    size: "800x400",
    scale: 2,
    maptype: "roadmap",
    format: "jpg",
    language: "pt",
  });

  const informationDetail = (areaInformation, typeId) => {
    const responsableInformation = areaInformation.find(
      ({ informationTypeId }) => informationTypeId === typeId
    );

    if (informationTypesName[responsableInformation?.informationTypeId])
      return (
        <View style={style.row}>
          <Text style={style.rowTitle}>
            {informationTypesName[responsableInformation?.informationTypeId]}
          </Text>
          <Text style={style.rowText}>
            {responsableInformation?.information}
          </Text>
        </View>
      );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <ContentPaper>
        <PDFContainer size="A4">
          <Document title="Rotograma">
            <Page>
              <View style={style.header}>
                <Text style={style.textEntity}>Rota</Text>
                <View style={style.row}>
                  <Text style={style.headerTitle}>Origem:</Text>
                  <Text style={style.headerText}>{origin}</Text>
                </View>
                <View style={style.row}>
                  <Text style={style.headerTitle}>Destino:</Text>
                  <Text style={style.headerText}>{destination}</Text>
                </View>
              </View>
              <View>
                <Image style={style.imageReport} source={url} />
              </View>
              <View style={style.reportContainer}>
                {publicEntities?.map((publicEntity) => (
                  <View style={style.reportCard}>
                    <Text style={style.textEntity}>
                      {publicEntity.publicEntity?.entity}
                    </Text>
                    <Text style={style.textEntityName}>
                      {publicEntity.area?.name}
                    </Text>
                    {informationDetail(publicEntity.area?.areaInformation, 2)}
                    {informationDetail(publicEntity.area?.areaInformation, 4)}
                    {informationDetail(publicEntity.area?.areaInformation, 5)}
                    {informationDetail(publicEntity.area?.areaInformation, 7)}
                  </View>
                ))}
              </View>
            </Page>
          </Document>
        </PDFContainer>
      </ContentPaper>
      <ActionPaper>
        <Button color="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </ActionPaper>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  informationTypes: state.areaInformationTypes.areaInformationTypes,
  routes: state.routesAnalysis.informations,
});

export default connect(mapStateToProps, null)(ReportModal);
