import api from "./apiV1";

export const getClippings = ({ filters }) => {
  return api.request({
    url: "clippings",
    method: "GET",
    params: filters,
  });
};

export const getIncidentes = ({ filters }) => {
  return api.request({
    url: "incidents",
    method: "GET",
    params: filters,
  });
};
