import { styled } from "@mui/material";
import { Marker } from "@react-google-maps/api";

export const UnitMarker = styled(Marker)(() => ({
  zIndex: "20",
}));

export const containerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};
