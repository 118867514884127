import React, { useState, useEffect } from "react";

const INITIAL_MINUTE = 5;

const Timer = ({ updater, timePassed = { secondsPassed: 0 } }) => {
  const [minutes, setMinutes] = useState(INITIAL_MINUTE);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const myInterval = setTimeout(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          updater && updater();
          return;
        }
        setSeconds(59);
        setMinutes((minutes) => minutes - 1);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);

    return () => clearTimeout(myInterval);
  }, [seconds, minutes, updater]);

  useEffect(() => {
    if (timePassed.secondsPassed > 0 && timePassed.secondsPassed < 60) {
      setMinutes(INITIAL_MINUTE - 1);
      setSeconds(60 - timePassed.secondsPassed);
    } else if (
      timePassed.secondsPassed > 60 &&
      timePassed.secondsPassed < 120
    ) {
      setMinutes(INITIAL_MINUTE - 2);
      setSeconds(120 - timePassed.secondsPassed);
    } else if (
      timePassed.secondsPassed > 120 &&
      timePassed.secondsPassed < 180
    ) {
      setMinutes(INITIAL_MINUTE - 3);
      setSeconds(180 - timePassed.secondsPassed);
    } else if (
      timePassed.secondsPassed > 180 &&
      timePassed.secondsPassed < 240
    ) {
      setMinutes(INITIAL_MINUTE - 4);
      setSeconds(240 - timePassed.secondsPassed);
    } else if (timePassed.secondsPassed > 240) {
      setMinutes(INITIAL_MINUTE - 5);
      setSeconds(300 - timePassed.secondsPassed);
    } else {
      setMinutes(INITIAL_MINUTE);
      setSeconds(0);
    }
  }, [timePassed.secondsPassed]);

  return (
    <>
      0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}s
    </>
  );
};

export default Timer;
