import { IconButton, Toolbar, styled } from "@mui/material";

export const CustomToolBar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "left",
})(({ left }) => ({
  left,
  display: "block",
  padding: "0 !important",
  marginTop: "100px",
  width: "50px",
  zIndex: 6,
}));

export const ActionButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  color: selected ? "#212121" : "#fff",
  backgroundColor: selected ? "#fff" : "#212121",
  marginRight: "16px",
  marginLeft: "0",
  float: "left",
  padding: "15px 15px",
  borderRadius: "0px",
  marginBottom: "10px",
  boxShadow: "1px 1px 5px black",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "white",
    color: "#212121",
  },
  "&:focus": {
    outline: "none",
  },
}));
