import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, List, Divider } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  NavList,
  PaperItem,
  TitleText,
  MainDrawer,
  SideFooter,
  DrawerHeader,
  ActionButton,
  IncidentItem,
  IncidentGroup,
  ExpansionPanel,
  ExpandMoreIcon,
  MainSideContent,
  ExpansionHeading,
  InciedntTextTitle,
  ExpasionPanelTitle,
  ExpansionPanelDetail,
} from "./drawerLegedaStyles";

import { Creators as FormActions } from "../../store/actions/menu";

const DrawerLegenda = (props) => {
  const { menuOpen, animateMenu, categories, criminalFactions } = props;

  const renderListItem = (id, icon, description) => {
    return (
      <IncidentGroup key={id}>
        <IncidentItem component="div">
          <img src={icon && icon} alt="Ícone" />
        </IncidentItem>
        <IncidentItem component="div">
          <InciedntTextTitle primary={description} />
        </IncidentItem>
      </IncidentGroup>
    );
  };

  const handleClose = () => {
    animateMenu("FECHA_MENU");
  };

  return (
    <MainDrawer anchor="right" variant="persistent" open={menuOpen}>
      <DrawerHeader component="div">
        <TitleText variant="h6" noWrap>
          Legendas
        </TitleText>
        <ActionButton onClick={handleClose}>
          <CloseIcon />
        </ActionButton>
      </DrawerHeader>
      <Divider />
      <MainSideContent component="div">
        {categories?.map((category) => (
          <PaperItem key={category.id + "_categories"}>
            <ExpansionPanel>
              <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                <ExpansionHeading>{category.category}</ExpansionHeading>
              </ExpasionPanelTitle>
              <ExpansionPanelDetail>
                <List component="nav">
                  {category.types?.map(({ id, icon, type }) =>
                    renderListItem(id, icon, type)
                  )}
                </List>
              </ExpansionPanelDetail>
            </ExpansionPanel>
          </PaperItem>
        ))}
        <PaperItem key="faccoes-criminosas">
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Facções Criminosas</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <NavList component="nav">
                {criminalFactions?.map(({ id, icon, name }) =>
                  renderListItem(id, icon, name)
                )}
              </NavList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>
      </MainSideContent>
      <SideFooter xs={1} component="div" color="secondary">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(FormActions, dispatch);

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.legendas,
    categories: state.categories.categoriesList,
    criminalFactions: state.riskAreas.criminalFactions,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerLegenda);
