import { call, put } from "redux-saga/effects";

import { getInterestPointsTypes as getInterestPointsTypesRequest } from "../services/points-of-interest";
import { Creators as interestActions } from "../actions/interestPoints";

export default function* getInterestPointsTypes() {
  try {
    const list = yield call(getInterestPointsTypesRequest);
    yield put(interestActions.getInterestPointsTypesSuccess(list));
  } catch (error) {
    yield put(interestActions.getInterestPointsTypesFailure(error));
  }
}
