import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import BoxFilters from "../Drawing/filters";
import { Creators as DrawingCreators } from "../../../store/actions/drawingAnalyse";
import { Creators as MarkersDistanceActions } from "../../../store/actions/markersDistance";
import {
  handleDrawerFunction,
  computeDistanceBetweenInMeters,
} from "../../../common/handleDrawer";
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

let Drawing = (props) => {
  const { drawingManager, isVisible, formFields, setMarkersCoordinates } =
    props;
  const drawingAnalyses = formFields.drawingAnalyses;
  const [overlays, setOverlays] = useState([]);

  useEffect(() => {
    !drawingManager &&
      overlays.length > 0 &&
      deleteAllOverlays() &&
      setOverlays([]);
  }, [drawingManager, overlays]);

  useEffect(() => {
    if (drawingAnalyses) {
      const points = drawingAnalyses.filter(({ type }) => type === "marker");

      let pointsDistance = [];
      if (points.length > 0) {
        for (const item in points) {
          const distance = computeDistanceBetweenInMeters(
            points[0],
            points[item]
          );
          pointsDistance = [...pointsDistance, distance];
        }
      }

      setMarkersCoordinates(pointsDistance);
    } else {
      setMarkersCoordinates([]);
    }
  }, [drawingAnalyses]);

  const handleDrawerComplete = (e) => {
    const timestampId = new Date().getTime();

    e.overlay.addListener("click", () => {
      setOverlays((overlays) => {
        const overlay = overlays.find((overlay) => overlay.id === timestampId);

        if (overlay) {
          overlay.isVisible = true;
        }

        return [
          ...overlays.filter((overlay) => overlay.id !== timestampId),
          overlay,
        ];
      });
    });

    const drawerFunction = handleDrawerFunction(e, timestampId);

    setOverlays([
      ...overlays,
      {
        type: e.type,
        dropFunction: () => {
          props.removeDrawingAnalyse(timestampId);
          e.overlay.setMap(null);
        },
        id: timestampId,
        position: drawerFunction["drawing"].props.position,
        isVisible: false,
        ref: e.overlay,
      },
    ]);

    if (props.formFields.drawingAnalyses) {
      props.change("drawingAnalyses", [
        ...props.formFields.drawingAnalyses,
        drawerFunction["drawing"],
      ]);
    } else {
      props.change("drawingAnalyses", [drawerFunction["drawing"]]);
    }
  };

  const deleteAllOverlays = () => {
    overlays.forEach((overlay) => {
      overlay.ref.setMap(null);
    });
  };

  const callbackClose = (id) => {
    setOverlays((state) => {
      const updateAt = state.find((overlay) => overlay.id === id);
      const updatedInfo = Object.assign(updateAt, { isVisible: false });

      return [...state.filter((overlay) => overlay.id !== id), updatedInfo];
    });
  };

  return (
    <>
      {isVisible && (
        <DrawingManager
          onOverlayComplete={(e) => handleDrawerComplete(e)}
          defaultOptions={{
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
              drawingModes: [
                window.google.maps.drawing.OverlayType.CIRCLE,
                window.google.maps.drawing.OverlayType.POLYGON,
                window.google.maps.drawing.OverlayType.POLYLINE,
                window.google.maps.drawing.OverlayType.RECTANGLE,
                window.google.maps.drawing.OverlayType.MARKER,
              ],
            },
          }}
        />
      )}
      {overlays.length > 0 && (
        <BoxFilters overlays={overlays} callback={callbackClose} />
      )}
    </>
  );
};

Drawing = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(Drawing);

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    drawingManager: state.menu.drawingManagerAnalyses,
    isVisible: state.menu.drawingManagerAnalyses,
  };
};

const mapDispatchToProps = {
  ...DrawingCreators,
  ...MarkersDistanceActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawing);
