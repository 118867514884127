import React from "react";
import { connect } from "react-redux";
import CustomInstallationMarker from "./CustomMarker/CustomerInstallationMarker";

const CareInstallationMarkers = ({ installations }) => {
  return installations.map((element) => (
    <CustomInstallationMarker
      key={`care-installation-${element.id}`}
      data={element}
    />
  ));
};

const mapStateToProps = (state) => ({
  installations: state.care.installations,
});

export default connect(mapStateToProps, null)(CareInstallationMarkers);
