import { Types } from "../actions/riskAreas";

const INITIAL_STATE = {
  riskAreas: [],
  loading: false,
  loaded: false,
  loadingFailed: false,
  partialRenderSucceeded: false,
  fetching: false,
  riskAreaTypes: [],
  loadingRiskAreaTypes: false,
  loadingRiskAreaTypesFailed: false,
  riskAreaTypesLoaded: false,
  criminalFactions: [],
  loadingCriminalFactions: false,
  loadingCriminalFactionsFailed: false,
  loadingCriminalFactionsSucceeded: false,
  riskAreasIdsToViewPoligon: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_RISK_AREAS:
      return {
        ...state,
        loading: true,
        loaded: false,
        loadingFailed: false,
        partialRenderSucceeded: false,
        fetching: true,
      };
    case Types.GET_RISK_AREAS_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        loadingFailed: true,
      };
    case Types.GET_RISK_AREAS_SUCCEEDED:
      return {
        ...state,
        riskAreas: [...state.riskAreas, ...action.riskAreas.data],
        loading: false,
        loaded: true,
        loadingFailed: false,
        fetching: action.riskAreas.data.length > 0,
      };
    case Types.RENDER_PARTIAL_RISK_AREAS_SUCCEEDED:
      return {
        ...state,
        partialRenderSucceeded: true,
      };
    case Types.GET_RISK_AREA_TYPES:
      return {
        ...state,
        loadingRiskAreaTypes: true,
        riskAreaTypesLoaded: false,
        loadingRiskAreaTypesFailed: false,
      };
    case Types.GET_RISK_AREA_TYPES_FAILED:
      return {
        ...state,
        loadingRiskAreaTypes: false,
        riskAreaTypesLoaded: false,
        loadingRiskAreaTypesFailed: true,
      };
    case Types.GET_RISK_AREA_TYPES_SUCCEEDED:
      return {
        ...state,
        riskAreaTypes: action.riskAreaTypes.data,
        loadingRiskAreaTypes: false,
        riskAreaTypesLoaded: true,
        loadingRiskAreaTypesFailed: false,
      };
    case Types.GET_CRIMINAL_FACTIONS:
      return {
        ...state,
        loadingCriminalFactions: true,
        loadingCriminalFactionsFailed: false,
        loadingCriminalFactionsSucceeded: false,
      };
    case Types.GET_CRIMINAL_FACTIONS_SUCESS:
      return {
        ...state,
        criminalFactions: action.data.data,
        loadingCriminalFactionsSucceeded: true,
        loadingCriminalFactions: false,
        loadingCriminalFactionsFailed: false,
      };
    case Types.GET_CRIMINAL_FACTIONS_FAILED:
      return {
        ...state,
        criminalFactions: [],
        loadingCriminalFactionsSucceeded: false,
        loadingCriminalFactions: false,
        loadingCriminalFactionsFailed: true,
      };
    case Types.HANDLE_RISK_AREAS_POLIGON:
      return {
        ...state,
        riskAreasIdsToViewPoligon: state.riskAreasIdsToViewPoligon.includes(
          action.payload
        )
          ? state.riskAreasIdsToViewPoligon.filter(
              (item) => item !== action.payload
            )
          : [...state.riskAreasIdsToViewPoligon, action.payload],
      };
    default:
      return state;
  }
};

var standardIcon =
  "https://www.grupounicad.com.br/cgi-bin/PortalUmais/MonitIncid/Files/Icones/20160104_164754_area_de_risco.png";

export const selectRiskAreasMergedIcon = (state) => {
  return getCriminalFactionIcon(
    state.riskAreas.riskAreas,
    state.riskAreas.criminalFactions,
    state.riskAreas.riskAreaTypes
  ).filter((ra) => ra.polygon.type);
};

export const selectRiskAreaChildAreas = (state, id) => {
  const childExists = state.riskAreas.riskAreas.filter(
    (ra) => ra.riskZone.parentId === id
  );

  if (childExists.length > 0) {
    return getCriminalFactionIcon(
      childExists,
      state.riskAreas.criminalFactions,
      state.riskAreas.riskAreaTypes
    );
  }

  return undefined;
};

const getCriminalFactionIcon = (
  riskAreas,
  criminalFactions,
  riskAreasTypes
) => {
  return riskAreas.map((ra) => {
    const associatedCriminalFaction = criminalFactions.find(
      (cr) => cr.id === ra.riskZone.criminalFactionId
    );
    const associatedRiskAreasType = riskAreasTypes.find(
      ({ id }) => id === ra.riskZone.typeId
    );

    if (associatedCriminalFaction && associatedCriminalFaction.icon)
      return Object.assign(ra, { icon: associatedCriminalFaction.icon });

    if (associatedRiskAreasType && associatedRiskAreasType.icon)
      return Object.assign(ra, { icon: associatedRiskAreasType.icon });

    return Object.assign(ra, { icon: standardIcon });
  });
};
