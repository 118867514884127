import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { orderArray } from "../../common/Utils";
import {
  Close as CloseIcon,
  Folder as FolderIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Gesture as GestureIcon,
  MoreVert as MoreVertIcon,
  PostAddOutlined as PostAddIcon,
  CheckOutlined as CheckOutlinedIcon,
  WarningOutlined as WarningOutlinedIcon,
  CreateNewFolderOutlined as CreateNewFolderIcon,
} from "@mui/icons-material";

import {
  Box,
  Grid,
  List,
  Dialog,
  Button,
  Divider,
  ListItem,
  MenuItem,
  TextField,
  IconButton,
  DialogTitle,
  ListItemText,
  OutlinedInput,
  DialogContent,
  DialogActions,
  DialogContentText,
  ListItemSecondaryAction,
} from "@mui/material";

import {
  ExpansionPanelDetail,
  ExpasionPanelTitle,
  CustomFormControl,
  ListItemComponent,
  DateTimeContainer,
  ProviderContainer,
  ExpansionHeading,
  MainSideContent,
  ActionContainer,
  PeriodContainer,
  ExpandMoreIcon,
  ExpansionPanel,
  IncidentsCount,
  ExpansionList,
  SelectPeriod,
  ActionButton,
  SelectButton,
  CustomSwitch,
  DrawerHeader,
  SelectLabel,
  SaveButton,
  MainDrawer,
  SideFooter,
  MoreButton,
  FormLabel,
  PaperItem,
  TitleText,
  ItemText,
  Progress,
} from "./drawerAnaliseStyles";

import { Creators as CategoriesActions } from "../../store/actions/categories";
import { Creators as ListActions } from "../../store/actions/ocurrences";
import { Creators as FormActions } from "../../store/actions/menu";
import { Creators as Filters } from "../../store/actions/filters";
import { Creators as Main } from "../../store/actions/main";
import { Creators as DrawingAnalyse } from "../../store/actions/drawingAnalyse";
import InputDate from "../../common/InputDate";
import {
  saveScene,
  deleteScene,
  getScenarios,
} from "../../store/services/scene";

const today = new Date();

class DrawerAnalise extends Component {
  state = {
    periodo: this.props.formFields?.periodo
      ? this.props.formFields.periodo
      : "periodo",
    year: this.props.formFields?.year
      ? this.props.formFields.year
      : today.getFullYear(),
    years: [],
    analysisLabel: this.props.formFields?.analysisLabel
      ? this.props.formFields.analysisLabel
      : "Mês",
    analysisType: this.props.formFields?.analysisType
      ? this.props.formFields.analysisType
      : 1,
    analysis: this.props.formFields?.analysis
      ? this.props.formFields.analysis
      : 1,
    tipoCrime: false,
    openPopup: false,
    sceneName: "",
    sceneDescription: "",
    loadingSceneInfo: false,
    saveSceneSucessfull: false,
    saveSceneFailure: false,
    openSceneryList: false,
    loadSceneryListSucessfull: false,
    loadSceneryListFailure: false,
    deleteSceneryListFailure: false,
    rowsDataScenery: [],
    secondary: false,
    mediaGroupsLoaded: false,
  };

  constructor(props) {
    super(props);
    this.handleSaveScene = this.handleSaveScene.bind(this);
    this.handleDeleteScene = this.handleDeleteScene.bind(this);
  }

  componentDidMount() {
    let options = [];
    const initialValue = today.getFullYear() - 5;
    const ref = today.getFullYear();

    for (let i = initialValue; i < ref; i++) {
      options.push(i);
    }

    this.setState({ years: options });
    this.props.change("periodo", this.state.periodo);
    this.props.change("year", today.getFullYear());
    this.props.change("analysisType", this.state.analysisType);
    this.props.change("analysis", this.state.analysis);
  }

  componentDidUpdate() {
    this.props.mediaGroups &&
      this.props.mediaGroups.length > 0 &&
      !this.state.mediaGroupsLoaded &&
      this.props.change(
        "mediaGroupsIds",
        this.props.mediaGroups.map(({ id }) => id)
      ) &&
      this.setState({ mediaGroupsLoaded: true });
  }

  returnTypes(category) {
    const types = [];
    category.types &&
      category.types.map((type) => {
        return types.push(type.id);
      });
    category.groups &&
      category.groups.map(
        (group) => group.types && group.types.map((type) => types.push(type.id))
      );
    return types;
  }

  returnClippingTypes(category) {
    const MainTypes = [];
    category &&
      category.map((type) => {
        return MainTypes.push(type.id);
      });
    return MainTypes;
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.change([event.target.name], event.target.value);
  };

  resetFilters = () => {
    this.setState({
      periodo: "periodo",
      tipoCrime: false,
    });

    this.props.animateMenu("FECHAR_DRAWING_MANAGER_ANALYSES");
    this.props.change("drawingAnalyses", []);
    this.props.removeAllDrawingsAnalyse();
    this.props.clearAnalysis();
    this.props.toggleCategories("Reset");

    this.props.formFields.categoryClipping &&
      this.props.change("categoryClipping", undefined);
    this.props.formFields.clipping_category &&
      this.props.change("clipping_category", undefined);
    this.props.formFields.Traffic && this.props.change("Traffic", undefined);
    this.props.formFields.incident_type_id &&
      this.props.change("incident_type_id", undefined);
    this.props.formFields.clipping_type_id &&
      this.props.change("clipping_type_id", undefined);
  };

  getIncidentsFilterCountSpan(types) {
    const { formFields } = this.props;
    return (
      formFields.incident_type_id.filter((x) => types.includes(Number(x)))
        .length > 0 && (
        <IncidentsCount component="span">
          {
            formFields.incident_type_id.filter((x) => types.includes(Number(x)))
              .length
          }
        </IncidentsCount>
      )
    );
  }

  getIncidentsFilterCount(types) {
    const { formFields } = this.props;
    if (formFields && formFields.incident_type_id) {
      return (
        formFields.incident_type_id.filter((x) => types.includes(Number(x)))
          .length > 0 &&
        formFields.incident_type_id.filter((x) => types.includes(Number(x)))
          .length
      );
    } else {
      return 0;
    }
  }

  getClippingsFilterCountSpan(types) {
    const { formFields } = this.props;
    return (
      formFields.clipping_type_id.filter((x) => types.includes(Number(x)))
        .length > 0 && (
        <IncidentsCount component="span">
          {
            formFields.clipping_type_id.filter((x) => types.includes(Number(x)))
              .length
          }
        </IncidentsCount>
      )
    );
  }

  getClippingsFilterCount(types) {
    const { formFields } = this.props;
    if (formFields && formFields.clipping_type_id) {
      return (
        formFields.clipping_type_id.filter((x) => types.includes(Number(x)))
          .length > 0 &&
        formFields.clipping_type_id.filter((x) => types.includes(Number(x)))
          .length
      );
    } else {
      return 0;
    }
  }

  toggleCategories(category, arrayIds) {
    const hasIncidentFilter =
      this.props.formFields && this.props.formFields.incident_type_id
        ? true
        : false;
    const canChange =
      hasIncidentFilter &&
      this.getIncidentsFilterCount(this.returnTypes(category)) > 0
        ? false
        : true;
    this.props.change(category.categoryEn, undefined);
    if (canChange) {
      this.props.toggleCategories(category.categoryEn);
      if (!this.props[category.categoryEn]) {
        this.props.change(category.categoryEn, arrayIds);
      }
    }
  }

  toggleMediaGroups(mediaGroup) {
    let ids = [];
    if (this.props.formFields.mediaGroupsIds) {
      ids = this.props.formFields.mediaGroupsIds.includes(mediaGroup.id)
        ? this.props.formFields.mediaGroupsIds.filter(
            (item) => item !== mediaGroup.id
          )
        : [...this.props.formFields.mediaGroupsIds, mediaGroup.id];
    }
    this.props.formFields.mediaGroupsIds
      ? this.props.change("mediaGroupsIds", ids)
      : this.props.change("mediaGroupsIds", [mediaGroup.id]);
  }

  toggleClippingCategories(category) {
    const categoryClipping =
      this.props.formFields && this.props.formFields.categoryClipping
        ? this.props.formFields.categoryClipping
        : [];
    if (!this.props.formFields || !categoryClipping.includes(category.id)) {
      this.props.array.push("categoryClipping", category.id);
      let newValueType = this.props.formFields.clipping_category
        ? this.props.formFields.clipping_category
        : [];
      newValueType[category.id] = this.returnTypes(category);
      this.props.change(
        "clipping_category",
        newValueType.filter((clippings) => clippings !== null)
      );
    } else {
      let newValue = [];
      newValue = this.props.formFields.categoryClipping.filter(
        (x) => category.id !== x
      );
      newValue.length > 0
        ? this.props.change("categoryClipping", newValue)
        : this.props.change("categoryClipping", undefined);

      let newValueType = this.props.formFields.clipping_category;
      newValueType[category.id] = undefined;
      this.props.change(
        "clipping_category",
        newValueType.filter((clippings) => clippings !== null)
      );
    }
  }

  ocurrencesParameter = (parameters) => {
    const {
      Units,
      mediaGroupsIds,
      categoryClipping,
      clipping_category,
      periodo,
      after_date,
      before_date,
      year,
      analysis,
      analysisType,
      Traffic,
      Security,
      Contingence,
      clipping_type_id,
      incident_type_id,
    } = parameters;

    return {
      Units,
      mediaGroupsIds,
      categoryClipping,
      clipping_category,
      periodo,
      after_date,
      before_date,
      year,
      analysis,
      analysisType,
      Traffic,
      Security,
      Contingence,
      clipping_type_id,
      incident_type_id,
    };
  };

  ocurrencesSearch = () => {
    if (
      !this.props.formFields.Units ||
      this.props.formFields.Units.length === 0
    ) {
      this.props.setWarning("callBySelectingEntitiesAnalysis");
    } else if (
      !this.props.formFields.mediaGroupsIds ||
      this.props.formFields.mediaGroupsIds.length === 0
    ) {
      this.props.setWarning("callBySelectingMediaGroups");
    } else {
      const parameters = this.ocurrencesParameter(this.props.formFields);
      this.props.getAnalysis(parameters);
    }
  };

  changeField = (field, value) => {
    this.props.change([field], value);
  };

  renderAvancadas = () => {
    this.props.animateMenu("ABRIR_AVANCADAS");
  };

  renderAvancadasClippings = () => {
    this.props.animateMenu("ABRIR_AVANCADAS_CLIPPINGS");
  };

  handleSaveScene = async () => {
    const body = {
      name: this.state.sceneName,
      description: this.state.sceneDescription,
      metadata: {
        ...this.props.formFields,
        drawingAnalyses: this.props.drawings,
      },
    };

    try {
      this.setState({ loadingSceneInfo: true });

      const { status } = await saveScene(body);

      status === 201
        ? this.setState({ saveSceneSucessfull: true })
        : this.setState({ saveSceneFailure: true });
    } catch {
      this.setState({ saveSceneFailure: true });
    } finally {
      this.setState({ loadingSceneInfo: false });
    }
  };

  handleClosePopup = () => {
    this.setState({
      saveSceneSucessfull: false,
      saveSceneFailure: false,
      openPopup: false,
    });
  };

  handleGetScenarios = async () => {
    this.setState({
      loadSceneryListFailure: false,
      openSceneryList: true,
      deleteSceneryListFailure: false,
    });

    try {
      const { status, data } = await getScenarios();

      status === 200 && this.setState({ loadSceneryListSucessfull: true });
      this.setState({ rowsDataScenery: data });
    } catch {
      this.setState({ loadSceneryListFailure: true });
    }
  };

  handleDeleteScene = async (id) => {
    try {
      this.setState({
        loadSceneryListSucessfull: false,
        deleteSceneryListFailure: false,
      });
      const { status } = await deleteScene(id);
      status === 204 && this.handleGetScenarios();
    } catch {
      this.setState({ deleteSceneryListFailure: true });
    }
  };

  filterDetails = (jsonDetail) => {
    return {
      Security: jsonDetail.Security,
      Traffic: jsonDetail.Traffic,
      periodo: jsonDetail.periodo,
      after_date: jsonDetail.after_date,
      before_date: jsonDetail.before_date,
      year: jsonDetail.year,
      analysisType: jsonDetail.analysisType,
      analysis: jsonDetail.analysis,
      Units: jsonDetail.Units,
      before_date: jsonDetail.before_date,
      categoryClipping: jsonDetail.categoryClipping,
      clipping_category: jsonDetail.clipping_category?.filter(
        (clippings) => clippings !== null
      ),
      Contingence: jsonDetail.Contingence,
      incident_type_id: jsonDetail.incident_type_id,
      mediaGroupsIds: jsonDetail.mediaGroupsIds,
    };
  };

  handleGetSceneDetail = async (row) => {
    try {
      this.setState({ loadSceneryListSucessfull: false });

      const { metadata } = row;
      const details = this.filterDetails(metadata);

      this.setState({ periodo: details.periodo });

      this.props.change("categoryClipping", details.categoryClipping);

      details.Traffic && this.props.toggleCategories("Traffic");
      details.Security && this.props.toggleCategories("Security");
      details.Contingence && this.props.toggleCategories("Contingence");

      details.incident_type_id &&
        this.props.change("incident_type_id", details.incident_type_id);

      this.props.drawingManagerAnalyses &&
        this.props.change("drawingAnalyses", []) &&
        this.props.animateMenu("FECHAR_DRAWING_MANAGER_ANALYSES");

      metadata.drawingAnalyses &&
        this.props.addDrawingAnalyse(metadata.drawingAnalyses);

      this.props.getAnalysis(details);

      this.setState({ openSceneryList: false });
    } catch {
      this.setState({ loadSceneryListFailure: true });
    }
  };

  mensalOptionsProvider = () => {
    const options = [
      { value: 1, label: "Janeiro" },
      { value: 2, label: "Fevereiro" },
      { value: 3, label: "Março" },
      { value: 4, label: "Abril" },
      { value: 5, label: "Maio" },
      { value: 6, label: "Junho" },
      { value: 7, label: "Julho" },
      { value: 8, label: "Agosto" },
      { value: 9, label: "Setembro" },
      { value: 10, label: "Outubro" },
      { value: 11, label: "Novembro" },
      { value: 12, label: "Dezembro" },
    ];

    return (
      <ProviderContainer key="mensal-provider">
        <SelectLabel variant="h6" noWrap>
          Mês:
        </SelectLabel>
        <CustomFormControl>
          <SelectPeriod
            value={this.state.analysis}
            onChange={this.handleChange}
            input={<OutlinedInput name="analysis" />}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem
                key={`month-option-${index}`}
                selected={index === 0}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </SelectPeriod>
        </CustomFormControl>
      </ProviderContainer>
    );
  };

  bimestralOptionsProvider = () => {
    const options = [
      { value: 1, label: "1º Bimestre" },
      { value: 2, label: "2º Bimestre" },
      { value: 3, label: "3º Bimestre" },
      { value: 4, label: "4º Bimestre" },
      { value: 5, label: "5º Bimestre" },
      { value: 6, label: "6º Bimestre" },
    ];

    return (
      <ProviderContainer key="bimestral-provider">
        <SelectLabel variant="h6" noWrap>
          Bimestre:
        </SelectLabel>
        <CustomFormControl>
          <SelectPeriod
            value={this.state.analysis}
            onChange={this.handleChange}
            input={<OutlinedInput name="analysis" />}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem
                key={`bimestral-option-${index}`}
                selected={index === 0}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </SelectPeriod>
        </CustomFormControl>
      </ProviderContainer>
    );
  };

  trimestralOptionsProvider = () => {
    const options = [
      { value: 1, label: "1º Trimestre" },
      { value: 2, label: "2º Trimestre" },
      { value: 3, label: "3º Trimestre" },
      { value: 4, label: "4º Trimestre" },
    ];

    return (
      <ProviderContainer key="trimestral-provider">
        <SelectLabel variant="h6" noWrap>
          Trimestre:
        </SelectLabel>
        <CustomFormControl>
          <SelectPeriod
            value={this.state.analysis}
            onChange={this.handleChange}
            displayEmpty
            input={<OutlinedInput name="analysis" />}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem
                key={`trimestral-option-${index}`}
                selected={index === 0}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </SelectPeriod>
        </CustomFormControl>
      </ProviderContainer>
    );
  };

  semestralOptionsProvider = () => {
    const options = [
      { value: 1, label: "1º Semestre" },
      { value: 2, label: "2º Semestre" },
    ];

    return (
      <ProviderContainer key="semestral-provider">
        <SelectLabel variant="h6" noWrap>
          Semestre:
        </SelectLabel>
        <CustomFormControl>
          <SelectPeriod
            value={this.state.analysis}
            onChange={this.handleChange}
            displayEmpty
            input={<OutlinedInput name="analysis" />}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem
                key={`semestral-option-${index}`}
                selected={index === 0}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </SelectPeriod>
        </CustomFormControl>
      </ProviderContainer>
    );
  };

  openDashboard() {
    this.props.animateMenu("FECHA_MENU");
    this.props.activateReport();
  }

  render() {
    const {
      clippingsCategories,
      loadingAnalisys,
      handleSubmit,
      animateMenu,
      mediaGroups,
      submitting,
      categories,
      formFields,
      incidents,
      clippings,
      pristine,
      menuOpen,
    } = this.props;

    const analysisProvider = {
      1: this.mensalOptionsProvider,
      2: this.bimestralOptionsProvider,
      3: this.trimestralOptionsProvider,
      6: this.semestralOptionsProvider,
    };

    const hasIncidentFilter =
      formFields && formFields.incident_type_id ? true : false;
    const hasClippingCategoryFilter =
      formFields && formFields.clipping_category ? true : false;
    const hasSelectedDate = formFields && formFields.periodo ? true : false;
    const hasClippingFilter =
      (formFields && formFields.clipping_type_id) ||
      (formFields && formFields.Traffic) ||
      (formFields && formFields.Security) ||
      (formFields && formFields.Contingence)
        ? true
        : false;

    const fieldsSelected =
      (hasIncidentFilter || hasClippingFilter || hasClippingCategoryFilter) &&
      hasSelectedDate;

    return (
      <>
        <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
          <DrawerHeader>
            <TitleText variant="h6" noWrap>
              Análise e Inteligência
            </TitleText>
            <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
              <CloseIcon />
            </ActionButton>
          </DrawerHeader>

          <Divider />

          <MainSideContent component="div">
            <form onSubmit={handleSubmit}>
              <ActionContainer component="div">
                <SelectButton
                  startIcon={<GestureIcon />}
                  onClick={() => {
                    this.props.animateMenu("ABRIR_DRAWING_MANAGER_ANALYSES");
                    if (this.props.drawingManagerAnalyses) {
                      this.props.change("drawingAnalyses", []);
                    }
                  }}
                >
                  Editar Cenário
                </SelectButton>
                <SelectButton
                  startIcon={<FolderIcon />}
                  onClick={this.handleGetScenarios}
                >
                  Abrir Cenário
                </SelectButton>
                {(incidents || clippings) && [
                  <SelectButton
                    startIcon={<PostAddIcon variant="outlined" />}
                    onClick={() => this.openDashboard()}
                  >
                    Abrir Dashboard
                  </SelectButton>,
                  <SaveButton
                    startIcon={<CreateNewFolderIcon />}
                    onClick={() => this.setState({ openPopup: true })}
                  >
                    Salvar Cenário
                  </SaveButton>,
                ]}
              </ActionContainer>

              <PaperItem>
                <ExpansionPanel>
                  <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                    <ExpansionHeading>Período</ExpansionHeading>
                  </ExpasionPanelTitle>
                  <ExpansionPanel>
                    <PeriodContainer component="div">
                      <SelectLabel variant="h6" noWrap>
                        Filtrar por:
                      </SelectLabel>
                      <CustomFormControl>
                        <SelectPeriod
                          value={this.state.periodo}
                          onChange={this.handleChange}
                          input={<OutlinedInput name="periodo" />}
                        >
                          <MenuItem
                            key="period-option-periodo"
                            value="periodo"
                            selected
                          >
                            Período
                          </MenuItem>
                          <MenuItem key="period-option-data" value="custom">
                            Data
                          </MenuItem>
                        </SelectPeriod>
                      </CustomFormControl>
                      {this.state.periodo !== "custom" ? (
                        <>
                          <SelectLabel variant="h6" noWrap>
                            Ano:
                          </SelectLabel>
                          <CustomFormControl>
                            <SelectPeriod
                              value={this.state.year}
                              onChange={this.handleChange}
                              input={<OutlinedInput name="year" />}
                            >
                              {this.state.years.map((year, index) => (
                                <MenuItem
                                  key={`year-option-${year}-${index}`}
                                  value={year}
                                >
                                  {year}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key="default-year"
                                selected
                                value={today.getFullYear()}
                              >
                                {today.getFullYear()}
                              </MenuItem>
                            </SelectPeriod>
                          </CustomFormControl>

                          <SelectLabel variant="h6" noWrap>
                            Análise:
                          </SelectLabel>
                          <CustomFormControl>
                            <SelectPeriod
                              value={this.state.analysisType}
                              onChange={this.handleChange}
                              input={<OutlinedInput name="analysisType" />}
                            >
                              <MenuItem key="mensal-option" value={1} selected>
                                Mensal
                              </MenuItem>
                              <MenuItem key="bimestral-option" value={2}>
                                Bimestral
                              </MenuItem>
                              <MenuItem key="trimestral-option" value={3}>
                                Trimestral
                              </MenuItem>
                              <MenuItem key="semetral-option" value={6}>
                                Semestral
                              </MenuItem>
                            </SelectPeriod>
                          </CustomFormControl>

                          {analysisProvider[this.state.analysisType] &&
                            analysisProvider[this.state.analysisType]()}
                        </>
                      ) : (
                        <DateTimeContainer>
                          <ExpansionList
                            sx={{
                              margin: "20px 0",
                              padding: "0",
                            }}
                          >
                            <InputDate
                              name="after_date"
                              label="De"
                              value={formFields.after_date}
                              changeField={this.changeField}
                            />
                          </ExpansionList>
                          <ExpansionList>
                            <InputDate
                              name="before_date"
                              label="Até"
                              value={formFields.before_date}
                              changeField={this.changeField}
                            />
                          </ExpansionList>
                        </DateTimeContainer>
                      )}
                    </PeriodContainer>
                  </ExpansionPanel>
                </ExpansionPanel>
              </PaperItem>

              <PaperItem>
                <ExpansionPanel>
                  <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                    <ExpansionHeading>Tipos de Ocorrências</ExpansionHeading>
                  </ExpasionPanelTitle>
                  <ExpansionPanelDetail>
                    <ExpansionList>
                      {orderArray(clippingsCategories, "category")?.map(
                        (category, index) =>
                          category && (
                            <ProviderContainer key={`occurrence-type-${index}`}>
                              <ListItemComponent>
                                <ItemText
                                  control={
                                    <CustomSwitch
                                      disabled={
                                        this.getClippingsFilterCount(
                                          this.returnTypes(category)
                                        ) > 0
                                      }
                                      color="secondary"
                                      onClick={() => {
                                        this.toggleClippingCategories(category);
                                      }}
                                      checked={
                                        this.props.formFields.categoryClipping
                                          ? this.props.formFields.categoryClipping.includes(
                                              category.id
                                            )
                                          : false
                                      }
                                      name="clippingsCategory"
                                    />
                                  }
                                  label={
                                    <FormLabel
                                      onClick={
                                        this.getClippingsFilterCount(
                                          this.returnTypes(category)
                                        ) > 0
                                          ? this.renderAvancadasClippings
                                          : undefined
                                      }
                                    >
                                      {category.category}
                                      {this.getClippingsFilterCount(
                                        this.returnTypes(category)
                                      ) > 0 &&
                                        this.getClippingsFilterCountSpan(
                                          this.returnTypes(category)
                                        )}
                                    </FormLabel>
                                  }
                                  labelPlacement="start"
                                />
                              </ListItemComponent>

                              <Divider />
                            </ProviderContainer>
                          )
                      )}
                      <ListItemComponent key="avancadas">
                        <ItemText
                          value="start"
                          control={
                            <MoreButton onClick={this.renderAvancadasClippings}>
                              <MoreVertIcon />
                            </MoreButton>
                          }
                          label={<FormLabel>Opções Avançadas</FormLabel>}
                          labelPlacement="start"
                        />
                      </ListItemComponent>
                    </ExpansionList>
                  </ExpansionPanelDetail>
                </ExpansionPanel>
              </PaperItem>

              <PaperItem>
                <ExpansionPanel>
                  <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                    <ExpansionHeading>Análise de Alertas</ExpansionHeading>
                  </ExpasionPanelTitle>
                  <ExpansionPanelDetail>
                    <ExpansionList>
                      {categories &&
                        categories.map((category, index) => {
                          return (
                            <ListItemComponent
                              key={category.categoryEn + index}
                            >
                              <ItemText
                                control={
                                  <CustomSwitch
                                    disabled={
                                      this.getIncidentsFilterCount(
                                        this.returnTypes(category)
                                      ) > 0
                                    }
                                    color="secondary"
                                    onClick={() => {
                                      this.toggleCategories(
                                        category,
                                        this.returnTypes(category)
                                      );
                                    }}
                                    checked={this.props[category.categoryEn]}
                                    name={category.categoryEn}
                                    value={category.id}
                                  />
                                }
                                label={
                                  <FormLabel
                                    onClick={
                                      this.getIncidentsFilterCount(
                                        this.returnTypes(category)
                                      ) > 0
                                        ? this.renderAvancadas
                                        : undefined
                                    }
                                  >
                                    {category.category}
                                    {hasIncidentFilter &&
                                      this.getIncidentsFilterCountSpan(
                                        this.returnTypes(category)
                                      )}
                                  </FormLabel>
                                }
                                labelPlacement="start"
                              />
                            </ListItemComponent>
                          );
                        })}
                      <ListItemComponent key="avancadas">
                        <ItemText
                          value="start"
                          control={
                            <MoreButton onClick={this.renderAvancadas}>
                              <MoreVertIcon />
                            </MoreButton>
                          }
                          label={<FormLabel>Opções Avançadas</FormLabel>}
                          labelPlacement="start"
                        />
                      </ListItemComponent>
                    </ExpansionList>
                  </ExpansionPanelDetail>
                </ExpansionPanel>
              </PaperItem>

              <PaperItem>
                <ExpansionPanel>
                  <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                    <ExpansionHeading>Fontes</ExpansionHeading>
                  </ExpasionPanelTitle>
                  <ExpansionPanelDetail>
                    <ExpansionList>
                      {mediaGroups?.map((mediaGroup, index) => {
                        return (
                          <ListItemComponent key={mediaGroup.groupName + index}>
                            <ItemText
                              control={
                                <CustomSwitch
                                  disabled={
                                    this.getIncidentsFilterCount(
                                      this.returnTypes(mediaGroup)
                                    ) > 0
                                  }
                                  color="secondary"
                                  onClick={() => {
                                    this.toggleMediaGroups(mediaGroup);
                                  }}
                                  checked={
                                    this.props.formFields.mediaGroupsIds
                                      ? this.props.formFields.mediaGroupsIds.includes(
                                          mediaGroup.id
                                        )
                                      : false
                                  }
                                  name={mediaGroup.groupName}
                                  value={mediaGroup.id}
                                />
                              }
                              label={
                                <FormLabel>{mediaGroup.groupName}</FormLabel>
                              }
                              labelPlacement="start"
                            />
                          </ListItemComponent>
                        );
                      })}
                    </ExpansionList>
                  </ExpansionPanelDetail>
                </ExpansionPanel>
              </PaperItem>

              <ActionContainer component="div" style={{ marginTop: "20px" }}>
                <SaveButton
                  startIcon={<SearchIcon />}
                  disabled={submitting || pristine || !fieldsSelected}
                  onClick={this.ocurrencesSearch}
                >
                  Efetuar Pesquisa
                </SaveButton>
                {loadingAnalisys && <Progress color="secondary" />}
                <SelectButton
                  startIcon={<DeleteIcon />}
                  disabled={submitting}
                  onClick={this.resetFilters}
                >
                  Limpar Pesquisa
                </SelectButton>
              </ActionContainer>
            </form>
          </MainSideContent>

          <SideFooter xs={1} component="div">
            <Box component="span">
              Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
            </Box>
          </SideFooter>
        </MainDrawer>

        <Box component="div">
          <Dialog open={this.state.openPopup}>
            <DialogTitle>Salvar Cenário</DialogTitle>

            <DialogContent>
              {this.state.saveSceneSucessfull && (
                <DialogContentText>
                  <CheckOutlinedIcon />
                  Cenário Salvo com sucesso!!!
                </DialogContentText>
              )}
              {this.state.saveSceneFailure && (
                <DialogContentText>
                  <WarningOutlinedIcon />
                  Falha ao tentar salvar o cenário, verifique os dados e tente
                  novamente!!!
                </DialogContentText>
              )}
              <TextField
                onChange={(e) => this.setState({ sceneName: e.target.value })}
                autoFocus
                margin="dense"
                id="name"
                label="Nome do Cenário"
                type="email"
                fullWidth
                variant="standard"
                required
              />
              <TextField
                onChange={(e) =>
                  this.setState({ sceneDescription: e.target.value })
                }
                autoFocus
                margin="dense"
                id="description"
                label="Descrição"
                multiline
                rows={4}
                fullWidth
                variant="standard"
              />
            </DialogContent>

            <DialogActions>
              {this.state.loadingSceneInfo && <Progress color="secondary" />}
              <Button onClick={() => this.setState({ openPopup: false })}>
                Cancelar
              </Button>
              {this.state.saveSceneSucessfull ? (
                <Button onClick={this.handleClosePopup}>Sair</Button>
              ) : (
                <Button onClick={this.handleSaveScene}>Salvar</Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>

        <Box component="div">
          {this.state.openSceneryList && (
            <Dialog open={this.state.openSceneryList}>
              <DialogTitle>Lista de Cenários</DialogTitle>
              {this.state.loadSceneryListFailure && (
                <DialogContentText>
                  <center>
                    <WarningOutlinedIcon />
                    Falha ao carregar cenários
                  </center>
                </DialogContentText>
              )}
              {this.state.deleteSceneryListFailure && (
                <DialogContentText>
                  <center>
                    <WarningOutlinedIcon />
                    Falha ao deletar cenário
                  </center>
                </DialogContentText>
              )}
              {this.state.loadSceneryListSucessfull ? (
                <DialogContent>
                  <Grid item xs={12} md={12}>
                    {this.state.rowsDataScenery.length !== 0 ? (
                      <Box component="div">
                        <List>
                          {this.state.rowsDataScenery.map((row, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                style={{ width: "500px", fontSize: "10" }}
                                onClick={() =>
                                  this.setState({
                                    secondary: !this.state.secondary,
                                  })
                                }
                                primary={row.name}
                                secondary={
                                  this.state.secondary ? row.description : null
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => this.handleGetSceneDetail(row)}
                                  style={{ left: "30px" }}
                                >
                                  <FolderIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => this.handleDeleteScene(row.id)}
                                  style={{ left: "30px" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    ) : (
                      <DialogContentText>
                        Nenhum cenário encontrado!
                      </DialogContentText>
                    )}
                  </Grid>
                </DialogContent>
              ) : (
                <center>
                  <Progress color="secondary" />
                </center>
              )}

              <DialogActions>
                <Button
                  onClick={() => this.setState({ openSceneryList: false })}
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </>
    );
  }
}

DrawerAnalise = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerAnalise);

const mapDispatchtoProps = {
  ...CategoriesActions,
  ...FormActions,
  ...ListActions,
  ...Filters,
  ...Main,
  ...DrawingAnalyse,
};

const mapStateToProps = (state) => ({
  drawingAnalyses: state.drawingAnalyses.drawingsAnalyses,
  drawingManagerAnalyses: state.menu.drawingManagerAnalyses,
  clippingsCategories: state.categories.clippingsCategories,
  mediaGroups: state.categories.mediaGroups,
  loadingAnalisys: state.ocurrences.loadingAnalisys,
  categories: state.categories.categoriesList,
  Contingence: state.categories.contingence,
  Security: state.categories.security,
  Traffic: state.categories.traffic,
  menuOpen: state.menu.analise,
  incidents: state.ocurrences.incidents,
  clippings: state.ocurrences.clippings,
  drawings: state.drawing.drawings,
  formFields: getFormValues("ANALISE")(state)
    ? getFormValues("ANALISE")(state)
    : [],
  initialValues: {
    after_date: today.toISOString(),
    before_date: today.toISOString(),
    drawingAnalyses: [],
  },
});

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerAnalise);
