import { call, put } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";

import { getClippings, getIncidentes } from "../services/analisys";
import { Creators as AlertActions } from "../actions/ocurrences";

export default function* getAnaliseRequest(filters) {
  yield put(startSubmit("ANALISE"));
  try {
    const list = {
      incidents: yield call(getIncidentes, filters),
      clippings: yield call(getClippings, filters),
    };
    list.incidents = list.incidents.data;
    list.clippings = list.clippings.data;
    yield put(stopSubmit("ANALISE"));
    yield put(AlertActions.getAnalysisSuccess(list));
  } catch (error) {
    yield put(stopSubmit("ANALISE", { error: [] }));
    yield put(AlertActions.getAnalysisFailure(error));
  }
}
