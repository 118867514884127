import { lineString } from "@turf/helpers";
import buffer from "@turf/buffer";

export const searchAddress = async (
  timestampId,
  infosForDetour,
  filteredWaypoints,
  stopovers
) => {
  const date = new Date();

  const response = await new Promise((resolve, reject) => {
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(
          infosForDetour.coords.origin?.lat,
          infosForDetour.coords.origin?.lng
        ),
        destination: new window.google.maps.LatLng(
          infosForDetour.coords.destination?.lat,
          infosForDetour.coords.destination?.lng
        ),
        waypoints: filteredWaypoints.length > 0 ? filteredWaypoints : null,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        transitOptions: {
          departureTime: date,
        },
        drivingOptions: {
          departureTime: date,
          trafficModel: "bestguess",
        },
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const {
            start_location,
            end_location,
            distance,
            duration,
            duration_in_traffic,
            start_address,
            end_address,
          } = result.routes[0].legs[0];

          const {
            radius,
            isBold,
            unitName,
            lineColor,
            isOnFocus,
            isDraggable,
            crisisModule,
            unitAddress,
          } = infosForDetour;

          const extraInformation = {
            id: timestampId,
            origin: {
              lat: start_location.lat(),
              lng: start_location.lng(),
            },
            destination: {
              lat: end_location.lat(),
              lng: end_location.lng(),
            },
            distance: distance?.text,
            duration: duration?.text,
            duration_in_traffic: countDurantionTime(
              duration_in_traffic?.value,
              stopovers
            ),
            start_address: start_address,
            end_address: end_address,
            decodedPolyline: window.google.maps.geometry.encoding.decodePath(
              result.routes[0].overview_polyline
            ),
            waypoints: filteredWaypoints,
            isVisible: true,
            isBold: isBold ? true : false,
            isOnFocus: isOnFocus ? true : false,
            isDraggable: isDraggable ? true : false,
            crisisModule: crisisModule || null,
            unitName: unitName,
            unitAddress: unitAddress,
            lineColor: lineColor,
          };

          const drawing = {
            id: timestampId,
            polygon: null,
          };
          const coordsRoute = extraInformation.decodedPolyline;
          const decodedCoords = coordsRoute.map((coord) => [
            coord.lng(),
            coord.lat(),
          ]);

          const linestring1 = lineString(decodedCoords);

          const buffered = buffer(linestring1, radius || 1000, {
            units: "meters",
          });
          drawing.polygon = buffered;

          resolve({
            drawing,
            extraInformation,
          });
        } else {
          reject(result);
        }
      }
    );
  });

  return response;
};

const countDurantionTime = (time, stopovers) => {
  if (!time) {
    return "0 min";
  }

  if (stopovers?.length > 0) {
    const stopoversTime = stopovers
      .filter(({ stopover }) => stopover)
      .map(({ duration }) => duration)
      .reduce((acum, curr) => acum + curr, time / 60);

    return `${parseInt(stopoversTime)} min`;
  }

  return `${parseInt(time / 60)} min`;
};
