import React from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";
import { Box, Divider } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

import {
  ExpansionPanelDetail,
  ExpasionPanelTitle,
  ExpansionHeading,
  ListItemComponent,
  MainSideContent,
  ExpansionPanel,
  ExpandMoreIcon,
  ExpansionList,
  DrawerHeader,
  ActionButton,
  CustomSwitch,
  MainDrawer,
  SideFooter,
  PaperItem,
  TitleText,
  FormLabel,
  ItemText,
} from "./drawerRiskAreasStyles";

import { Creators as FormActions } from "../../store/actions/menu";

let DrawerRiskAreasExpand = (props) => {
  const { menuOpen, infoFormFields, criminalFactions, change, animateMenu } =
    props;

  const toggleCriminalFaction = (factionId) => {
    const list = infoFormFields?.criminalFactionsFilters || [];
    const isSelected = list.find((id) => id === factionId);

    !isSelected
      ? change("criminalFactionsFilters", [...list, factionId])
      : change(
          "criminalFactionsFilters",
          infoFormFields.criminalFactionsFilters.filter(
            (id) => id !== factionId
          )
        );
  };

  const toggleCriminalFactionConf = () => {
    const newValue = infoFormFields?.hideCriminalFactionsMarkers || false;

    change("hideCriminalFactionsMarkers", !newValue);
  };

  return (
    <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
      <DrawerHeader component="div">
        <ActionButton onClick={() => animateMenu("SHRINK_RISK_AREAS_MENU")}>
          <ChevronLeftIcon />
        </ActionButton>
        <TitleText variant="h6" noWrap>
          Voltar
        </TitleText>
      </DrawerHeader>

      <Divider />
      <MainSideContent component="div">
        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Facções criminosas</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                {criminalFactions?.map((cf) => (
                  <Box component="div" key={`criminal-faction-${cf.id}`}>
                    <ListItemComponent>
                      <ItemText
                        control={
                          <CustomSwitch
                            color="secondary"
                            onClick={() => {
                              toggleCriminalFaction(cf.id);
                            }}
                            checked={infoFormFields?.criminalFactionsFilters?.includes(
                              cf.id
                            )}
                          />
                        }
                        label={<FormLabel>{cf.name}</FormLabel>}
                        labelPlacement="start"
                      />
                    </ListItemComponent>
                    <Divider />
                  </Box>
                ))}
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Configurações</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        onClick={toggleCriminalFactionConf}
                        checked={
                          infoFormFields?.hideCriminalFactionsMarkers || false
                        }
                      />
                    }
                    label={<FormLabel>Ocultar Marcadores</FormLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>
                <Divider />
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>
      </MainSideContent>
      <SideFooter xs={1} component="div" color="secondary">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

DrawerRiskAreasExpand = reduxForm({
  form: "INFO",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerRiskAreasExpand);

const mapStateToProps = (state) => ({
  menuOpen: state.menu.showRiskAreasExpanded,
  infoFormFields: getFormValues("INFO")(state),
  criminalFactions: state.riskAreas.criminalFactions,
});

const mapDispatchtoProps = {
  ...FormActions,
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DrawerRiskAreasExpand);
