import { Types } from "../actions/markersDistance";

const INITIAL_STATE = {
  markersCoordinates: [],
};

export default function markersDistance(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_MARKERS_COORDINATES:
      return {
        ...state,
        markersCoordinates: action.payload,
      };
    default:
      return state;
  }
}
