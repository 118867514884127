module.exports = [
  "rgb(255, 215, 0)",
  "rgb(107, 142, 35)",
  "rgb(0, 0, 128)",
  "rgb(0, 139, 139)",
  "rgb(139, 69, 19)",
  "rgb(70, 130, 180)",
  "rgb(75, 0, 130)",
  "rgb(255, 69, 0)",
  "rgb(255, 0, 255)",
  "rgb(0, 125, 122)",
  "rgb(12, 143, 140)",
  "rgb(20, 162, 222)",
  "rgb(25, 162, 158)",
  "rgb(36, 206, 130)",
  "rgb(37, 180, 175)",
  "rgb(49, 198, 193)",
  "rgb(51, 212, 5)",
  "rgb(55, 185, 230)",
  "rgb(68, 218, 155)",
  "rgb(82, 223, 37)",
  "rgb(90, 209, 239)",
  "rgb(99, 231, 179)",
  "rgb(102, 5, 150)",
  "rgb(113, 234, 68)",
  "rgb(121, 26, 169)",
  "rgb(125, 232, 247)",
  "rgb(131, 104, 0)",
  "rgb(131, 243, 204)",
  "rgb(140, 47, 188)",
  "rgb(144, 244, 100)",
  "rgb(151, 121, 7)",
  "rgb(159, 68, 206)",
  "rgb(160, 255, 255)",
  "rgb(161, 111, 213)",
  "rgb(162, 255, 228)",
  "rgb(165, 232, 67)",
  "rgb(171, 139, 14)",
  "rgb(175, 255, 131)",
  "rgb(178, 89, 225)",
  "rgb(185, 135, 224)",
  "rgb(188, 238, 93)",
  "rgb(191, 156, 21)",
  "rgb(208, 160, 234)",
  "rgb(210, 244, 119)",
  "rgb(211, 173, 28)",
  "rgb(232, 184, 245)",
  "rgb(233, 249, 144)",
  "rgb(255, 208, 255)",
  "rgb(255, 255, 170)",
];
