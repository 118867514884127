import { Replay } from "@mui/icons-material";
import { Box, CircularProgress, Paper, styled } from "@mui/material";

export const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})(({ expanded }) => ({
  position: "absolute",
  left: "15px",
  bottom: "15px",
  marginLeft: expanded ? "360px" : 0,
  zIndex: 9,
}));

export const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  backgroundColor: "white",
  color: "#212121",
  marginBottom: "15px",
}));

export const Progress = styled(CircularProgress)(() => ({
  margin: "0px 15px",
  height: "20px !important",
  width: "20px !important",
  paddingRight: "0px !important",
  marginTop: "0px",
  float: "right",
  color: "#fff",
}));

export const PaperError = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  backgroundColor: "#212121",
  color: "#cc6666",
  marginBottom: "15px",
}));

export const ReplayIcon = styled(Replay)(() => ({
  margin: "0px 15px",
  height: "20px !important",
  width: "20px !important",
  paddingRight: "0px !important",
  marginTop: "0px",
  color: "#cc6666",
  cursor: "pointer",
  float: "right",
}));
