import { ErrorOutline } from "@mui/icons-material";
import { Box, Typography, styled } from "@mui/material";

export const MainContainer = styled(Box)(() => ({
  position: "absolute",
  zIndex: "10",
  left: "0",
  top: "0",
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
}));

export const Container = styled(Box)(() => ({
  backgroundColor: "#212121",
  borderWidth: "0.8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "405px",
  height: "177px",
  position: "absolute",
  bottom: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(50%)",
  borderRadius: "4px",
  padding: "12px",
  borderColor: "#FFF",
  color: "#c0c0c0",
  zIndex: 15,
}));

export const MessageContainer = styled(Box)(() => ({
  height: "20%",
  display: "flex",
  flexDirection: "column",
}));

export const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  marginBottom: "8px",
}));

export const Title = styled(Typography)(() => ({
  fontSize: "21px",
  marginRight: "4px",
}));

export const TextContainer = styled(Box)(() => ({
  fontSize: "14px",
}));

export const ActionContainer = styled(Box)(() => ({
  width: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  alignSelf: "flex-end",

  "& button": {
    backgroundColor: "green",
    width: "46%",
    height: "30px",
    outline: "none",
    border: "none",
    color: "#FFF",
    fontWeight: "700",
    cursor: "pointer",
  },
}));

export const ResolveButton = styled("button")(() => ({
  backgroundColor: "green",
}));

export const SecondaryButton = styled("button")(() => ({
  backgroundColor: "red !important",
}));

export const ErrorOutlineIcon = styled(ErrorOutline)(() => ({
  color: "#fff",
}));
