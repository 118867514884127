import React, { Component } from "react";

import { connect } from "react-redux";

import { Creators as Auth } from "../../store/actions/auth";

import AppRouter from "../../routes";
import LoadingScreen from "../screenLoading";

class App extends Component {
  componentDidMount = () => {
    this.props.getUser();
  };

  componentDidUpdate = () => {
    setInterval(() => {
      this.props.getUser();
    }, 120000);
  };

  render() {
    const { loggingUser } = this.props;

    if (loggingUser) {
      return <LoadingScreen />;
    }
    return <AppRouter />;
  }
}

const mapStatesToProps = (state) => ({
  userLogged: state.auth.userLogged,
  loggingUser: state.auth.loggingUser,
});

const mapDispatchtoProps = {
  ...Auth,
};

export default connect(mapStatesToProps, mapDispatchtoProps)(App);
