import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { bindActionCreators } from "redux";
import { Box, Divider, IconButton } from "@mui/material";

import {
  ItemText,
  FormLabel,
  PaperItem,
  TitleText,
  MainDrawer,
  SideFooter,
  CustomSwitch,
  DrawerHeader,
  ExpansionList,
  ExpandMoreIcon,
  ExpansionPanel,
  ChevronLeftIcon,
  MainSideContent,
  ExpansionHeading,
  ListItemComponent,
  ExpasionPanelTitle,
  ExpansionPanelDetail,
} from "./drawerAnaliseStyles";

import { Creators as FormActions } from "../../store/actions/menu";

let DrawerAvancadas = (props) => {
  const { array, state, menuOpen, categories, animateMenu, change } = props;

  const toggleType = (id) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "incident_type_id")
      ? selector(state, "incident_type_id")
      : [];
    if (!value.includes(id)) {
      array.push("incident_type_id", id);
    } else {
      const newValue = value.filter((e) => e !== id);

      newValue.length > 0
        ? change("incident_type_id", newValue)
        : change("incident_type_id", undefined);
    }
  };

  const renderFormItem = (option, categories) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "incident_type_id")
      ? selector(state, "incident_type_id")
      : [];

    return (
      <Box component="div" key={categories + "-" + option.type}>
        <ListItemComponent>
          <ItemText
            control={
              <CustomSwitch
                color="secondary"
                onClick={() => toggleType(option.id)}
                checked={value.includes(option.id)}
                name="incident_type_id"
              />
            }
            label={<FormLabel>{option.type}</FormLabel>}
            labelPlacement="start"
          />
        </ListItemComponent>
        <Divider />
      </Box>
    );
  };

  return (
    <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
      <DrawerHeader>
        <TitleText variant="h6" noWrap>
          <IconButton onClick={() => animateMenu("OCULTA_AVANCADAS")}>
            <ChevronLeftIcon />
          </IconButton>
          Voltar
        </TitleText>
      </DrawerHeader>

      <Divider />

      <MainSideContent component="div">
        {categories?.map((categories, index) => (
          <PaperItem key={"categories-" + index}>
            {categories !== "" && (
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>{categories.category}</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <ExpansionList>
                    {categories.types &&
                      categories.types.map((tipo) =>
                        renderFormItem(tipo, categories.category)
                      )}
                  </ExpansionList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            )}
          </PaperItem>
        ))}
      </MainSideContent>

      <SideFooter xs={1} component="div" color="secondary">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

DrawerAvancadas = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerAvancadas);

const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(FormActions, dispatch);

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.avancadas,
    categories: state.categories.categoriesList,
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerAvancadas);
