import { call, put } from "redux-saga/effects";

import { getRiskAreas as getRiskAreasRequest } from "../services/riskAreas";
import { Creators as actions } from "../actions/riskAreas";

export default function* getRiskAreas(filters) {
  try {
    const list = yield call(getRiskAreasRequest, filters);
    yield put(actions.getRiskAreasSucceeded(list));
  } catch (error) {
    yield put(actions.getRiskAreasFailed(error));
  }
}
