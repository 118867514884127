import { polygon } from "@turf/helpers";
import circle from "@turf/circle";
import centerOfMass from "@turf/center-of-mass";

export const handleDrawerFunction = (e, timestampId) => {
  const drawing = {
    id: timestampId,
    type: "",
    props: {},
  };

  const circleProvider = () => {
    const lat = e.overlay.getCenter().lat();
    const lng = e.overlay.getCenter().lng();
    const radius = e.overlay.radius;

    drawing.type = "circle";
    drawing.props = {
      position: { lat, lng },
      radius,
    };
    drawing.polygon = circle([lng, lat], radius, { units: "meters" });
  };

  const markerProvider = () => {
    const lat = e.overlay.position.lat();
    const lng = e.overlay.position.lng();

    drawing.type = "marker";
    drawing.props = {
      position: { lat, lng },
    };
  };

  const polygonProvider = () => {
    drawing.type = "polygon";

    const path = e.overlay.getPath();
    const mapCoords = [];

    const encodedPath = window.google.maps.geometry.encoding.encodePath(path);

    for (let i = 0; i < path.length; i++) {
      mapCoords.push([path.getAt(i).lng(), path.getAt(i).lat()]);
    }
    mapCoords.push(mapCoords[0]);
    const poly = polygon([mapCoords]);
    const center = centerOfMass(poly);

    drawing.props = {
      position: {
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0],
      },
      path: encodedPath,
    };

    drawing.polygon = poly;
  };

  const polylineProvider = () => {
    drawing.type = "polyline";
    const path = e.overlay.getPath().getArray();
    const centerOfPolyline = (path.length / 2).toFixed(0);
    const lat = path[centerOfPolyline].lat();
    const lng = path[centerOfPolyline].lng();
    const encodedPath = window.google.maps.geometry.encoding.encodePath(
      e.overlay.getPath()
    );
    drawing.props = {
      position: { lat, lng },
      path: encodedPath,
    };
  };

  const rectangleProvider = () => {
    drawing.type = "rectangle";
    const bounds = e.overlay.getBounds();
    const boundString = JSON.stringify(bounds);

    drawing.props = {
      position: {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      },
      bounds: JSON.parse(boundString),
    };
  };

  const drawingsProviders = {
    circle: circleProvider,
    marker: markerProvider,
    polygon: polygonProvider,
    polyline: polylineProvider,
    rectangle: rectangleProvider,
  };

  drawingsProviders[e.type]();

  return {
    timestampId,
    drawing,
  };
};

export const computeDistanceBetweenInMeters = (fromPoint, toPoint) => {
  if (fromPoint && toPoint) {
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        fromPoint.props.position,
        toPoint.props.position
      );
    return {
      id: toPoint.id,
      from: fromPoint.props.position,
      to: toPoint.props.position,
      distance: parseInt(distance),
    };
  } else {
    return null;
  }
};
