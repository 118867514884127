import React, { useState } from "react";
import { Typography } from "@mui/material";

import {
  GroupName,
  GroupQuantity,
  GroupContainer,
  GroupDescription,
  FloatGroupDescription,
} from "./dashboardStyles";

function InfoGroup(props) {
  const { ocurrence, addFilter } = props;
  const { name, value } = ocurrence;

  const [showName, setShowName] = useState(false);

  const filter = () => {
    addFilter(ocurrence);
  };

  const handleMouseOver = () => {
    setShowName(true);
  };

  const handleMouseLeave = () => {
    setShowName(false);
  };

  return (
    <GroupContainer component="span" onClick={filter}>
      <FloatGroupDescription display={showName ? "inline" : "none"}>
        <Typography component="p">{name}</Typography>
      </FloatGroupDescription>
      <GroupDescription
        component="div"
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <GroupQuantity>{value}</GroupQuantity>
        <GroupName component="i">{name}</GroupName>
      </GroupDescription>
    </GroupContainer>
  );
}

export default InfoGroup;
