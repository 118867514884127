import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { Box, Button, MenuItem, OutlinedInput } from "@mui/material";
import { geolocated } from "react-geolocated";
import InputSearchBox from "../SideMenu/DrawerRoutes/InputSearchBox";
import { toast } from "material-react-toastify";
import {
  Gesture as GestureIcon,
  AddAPhoto as AddAPhotoIcon,
  Autorenew as AutorenewIcon,
  DeleteSweep as DeleteSweepIcon,
} from "@mui/icons-material";

import {
  MainBar,
  ActionBar,
  SearchIcon,
  SearchItem,
  SelectRadius,
  DeleteButton,
  SwapHorizIcon,
  FormContainer,
  MarkRadiusItem,
  CustomerSelect,
  AppBarContainer,
  SearchContainer,
  DrawingContainer,
  PublicPlaceSearch,
  MarkRadiusContainer,
  RadiusFormContainer,
  CircularProgressIcon,
  SearchOriginContainer,
  FilterButtonContainer,
} from "./styles";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { Creators as Maps } from "../../store/actions/map";
import { Creators as Areas } from "../../store/actions/areas";
import { Creators as RiskAreas } from "../../store/actions/riskAreas";
import { Creators as Filters } from "../../store/actions/filters";
import { Creators as ListActions } from "../../store/actions/ocurrences";
import { Creators as MenuActions } from "../../store/actions/menu";
import { Creators as PublicEntities } from "../../store/actions/public-entities";
import { Creators as PublicEntityTypes } from "../../store/actions/publicEntityTypes";
import { Creators as RoutesAnalysis } from "../../store/actions/routesAnalysis";
import { Creators as FormActions } from "../../store/actions/menu";

let TopAppBar = (props) => {
  const {
    areas,
    loading,
    formFields,
    onMapPrint,
    buildingPDF,
    placeOnChange,
    drawingManager,
    searchingByMarker,
    removeAreaFilters,
    activateCoords,
    setPlaceChange,
    animateMenu,
    addNewRoute,
    setWarning,
    mapCenter,
    getAreas,
    change,
  } = props;
  const [origin, setOrigin] = useState("");
  const [location, setLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [submitting, setSubmitting] = useState("");
  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    if (areas[0] && formFields.areaType) {
      change("areaId", areas[0].id);
    }
  }, [areas[0], formFields?.areaType]);

  useEffect(() => {
    if (placeOnChange && formFields?.coords) {
      activateCoords("MARKER_SELECTED");
      mapCenter(formFields.coords);
      setPlaceChange();
      searchArea(formFields);
    }
  }, [placeOnChange, formFields?.coords]);

  const handleChange = (event) => {
    if (event.target.name === "areaType") {
      if (event.target.value !== "radius") {
        change("radius", undefined);
        activateCoords("REMOVE_MARKER");
      } else {
        change("radius", 1000);
        activateCoords("MARKER_SELECTED");
      }
      if (event.target.value === false) {
        change("areaId", undefined);
      }
    }
    if (event.target.value === false) {
      change([event.target.name], undefined);
    } else {
      change([event.target.name], event.target.value);
    }
  };

  const cleanField = useCallback(
    (setField) => {
      if (submitting) {
        setField("");
        setSubmitting(false);
      }
    },
    [submitting, setSubmitting]
  );

  const searchByAdress = async () => {
    const validOPtions = [
      "radius",
      "states",
      "cities",
      "countries",
      "neighborhoods",
    ];

    if (!formFields.areaType || !validOPtions.includes(formFields.areaType)) {
      return toast.error(
        "Selecione um tipo de área válida que deseja fazer a pesquisa"
      );
    }

    if (!location) {
      return toast.error("Informe um endereço para poder executar a buscar");
    }

    const address = location;
    const result = await geocodeByAddress(address);

    const { lat, lng } = await getLatLng(result[0]);
    setSubmitting(true);
    change("coords", { lat: lat, lng: lng });
    if (formFields.areaType !== "radius") {
      setPlaceChange();
    }
  };

  const generateRoute = async () => {
    const originCoords = await geocodeByAddress(origin);
    const destinationCoords = await geocodeByAddress(destination);
    const originLatLng = await getLatLng(originCoords[0]);
    const destinationLatLng = await getLatLng(destinationCoords[0]);

    addNewRoute({
      origin: originLatLng,
      destination: destinationLatLng,
    });
    animateMenu("ABRIR_MENU_ROTAS");
  };

  const handleDrawingManager = () => {
    if (drawingManager) {
      setWarning("callByDrawingManager");
      return;
    }

    animateMenu("ABRIR_DRAWING_MANAGER");
  };

  const handleRemoveFilters = () => {
    removeAreaFilters();
  };

  const handleShowFilters = () => {
    setShowFilters((previous) => !previous);
  };

  const searchArea = ({ coords, areaType }) => {
    const filters = { coords, areaType };

    getAreas(filters);
  };

  return (
    <>
      <MainBar visible={showFilters}>
        <AppBarContainer>
          <FormContainer variant="filled">
            <CustomerSelect
              value={formFields?.areaType || !formFields}
              onChange={handleChange}
              displayEmpty
              input={<OutlinedInput name="areaType" />}
            >
              <MenuItem value={false}>Limitar por</MenuItem>
              <MenuItem key="raio" value={"radius"}>
                Raio
              </MenuItem>
              <MenuItem key="bairro" value={"neighborhoods"}>
                Bairro
              </MenuItem>
              <MenuItem key="cidade" value={"cities"}>
                Cidade
              </MenuItem>
              <MenuItem key="estado" value={"states"}>
                Estado
              </MenuItem>
              <MenuItem key="logradouro" value={"publicPlace"}>
                Logradouro
              </MenuItem>
            </CustomerSelect>
          </FormContainer>

          {formFields?.areaType !== "publicPlace" && (
            <SearchContainer xs={3}>
              <SearchOriginContainer maxWidth="500px">
                <InputSearchBox
                  name={"local"}
                  placeholder={"Localização"}
                  cleanField={cleanField}
                  value={location}
                  setValue={setLocation}
                />
              </SearchOriginContainer>
            </SearchContainer>
          )}

          {formFields?.areaType === "publicPlace" && (
            <PublicPlaceSearch xs={3}>
              <SearchOriginContainer>
                <InputSearchBox
                  name={"origin"}
                  placeholder={"Origem"}
                  cleanField={cleanField}
                  value={origin}
                  setValue={setOrigin}
                />
              </SearchOriginContainer>
              <Button type="button" title="Inverter ponto de origem e destino">
                <SwapHorizIcon />
              </Button>
              <SearchOriginContainer>
                <InputSearchBox
                  name={"destino"}
                  placeholder={"Destino"}
                  cleanField={cleanField}
                  value={destination}
                  setValue={setDestination}
                />
              </SearchOriginContainer>
            </PublicPlaceSearch>
          )}
          {areas &&
            formFields?.areaType !== "radius" &&
            formFields?.areaType !== "publicPlace" && (
              <DeleteButton onClick={handleRemoveFilters}>
                <DeleteSweepIcon style={{ marginRight: "4px" }} />
                Limpar
              </DeleteButton>
            )}
          {formFields?.areaType &&
            formFields?.areaType !== "neighborhoods" &&
            formFields?.areaType !== "cities" &&
            formFields?.areaType !== "states" && (
              <RadiusFormContainer
                variant="outlined"
                marginLeft={formFields?.areaType === "publicPlace" ? 0 : 20}
              >
                <SelectRadius
                  displayEmpty
                  value={formFields?.radius}
                  onChange={handleChange}
                  input={<OutlinedInput name="radius" />}
                >
                  <MenuItem
                    key="0KM"
                    value={null}
                    selected
                    style={{ height: "30px" }}
                  >
                    {""}
                  </MenuItem>
                  <MenuItem key="1KM" value={1000} selected>
                    1KM
                  </MenuItem>
                  <MenuItem key="2KM" value={2000}>
                    2KM
                  </MenuItem>
                  <MenuItem key="3KM" value={3000}>
                    3KM
                  </MenuItem>
                  <MenuItem key="4KM" value={4000}>
                    4KM
                  </MenuItem>
                  <MenuItem key="5KM" value={5000}>
                    5KM
                  </MenuItem>
                  <MenuItem key="10KM" value={10000}>
                    10KM
                  </MenuItem>
                  <MenuItem key="20KM" value={20000}>
                    20KM
                  </MenuItem>
                  <MenuItem key="20KM" value={50000}>
                    50KM
                  </MenuItem>
                </SelectRadius>
              </RadiusFormContainer>
            )}
          {formFields?.areaType === "radius" && (
            <MarkRadiusContainer>
              <MarkRadiusItem
                onClick={() => {
                  searchingByMarker && activateCoords("CANCEL_SELECT_MARKER");
                  activateCoords("SELECT_MARKER_POINT");
                }}
              >
                Marcar no mapa
              </MarkRadiusItem>
            </MarkRadiusContainer>
          )}
          <SearchItem
            title="Buscar"
            onClick={
              formFields?.areaType === "publicPlace"
                ? generateRoute
                : searchByAdress
            }
          >
            {loading ? (
              <CircularProgressIcon color="secondary" />
            ) : (
              <SearchIcon fontSize="large" />
            )}
          </SearchItem>
          <DrawingContainer
            component="div"
            title="Gerar imagem"
            onClick={onMapPrint}
          >
            {buildingPDF ? <AutorenewIcon /> : <AddAPhotoIcon />}
          </DrawingContainer>
          <DrawingContainer
            title="Filtrar por desenho"
            onClick={handleDrawingManager}
          >
            <GestureIcon />
          </DrawingContainer>
        </AppBarContainer>
      </MainBar>
      <ActionBar>
        <Box component="div">
          {showFilters ? (
            <FilterButtonContainer onClick={handleShowFilters}>
              Ocultar Filtros
            </FilterButtonContainer>
          ) : (
            <FilterButtonContainer onClick={handleShowFilters}>
              Exibir Filtros
            </FilterButtonContainer>
          )}
        </Box>
      </ActionBar>
    </>
  );
};

const mapDispatchtoProps = {
  ...Filters,
  ...ListActions,
  ...PublicEntities,
  ...PublicEntityTypes,
  ...Areas,
  ...RiskAreas,
  ...Maps,
  ...MenuActions,
  ...RoutesAnalysis,
  ...FormActions,
};

const mapStateToProps = (state) => ({
  formFields: getFormValues("ANALISE")(state),
  areas: state.areas.areas,
  loading: state.areas.loading,
  searchingByMarker: state.filters.searchingByMarker,
  marker: state.filters.marker,
  placeOnChange: state.map.placeOnChange,
  drawingManager: state.menu.drawingManager,
});

TopAppBar = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(TopAppBar);

TopAppBar = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(TopAppBar);

export default connect(mapStateToProps, mapDispatchtoProps)(TopAppBar);
