import { Box, Button, styled } from "@mui/material";

export const InnerContainer = styled(Box)(() => ({
  "& header": {
    marginBottom: "3.5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "& ul": {
    margin: "0",
    padding: "6px",
    listStyle: "none",
  },
}));

export const DeleteButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "29px",
  marginTop: "4.6px",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  fontWeight: "700",
  textDecoration: "underline",
  cursor: "pointer",
  "& svg": { marginRight: "2px" },
}));
