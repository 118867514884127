export const Types = {
  GET_PUBLIC_ENTITY_TYPES: "public-entities/GET_PUBLIC_ENTITY_TYPES",
  GET_PUBLIC_ENTITY_TYPES_SUCCESS:
    "public-entities/GET_PUBLIC_ENTITY_TYPES_SUCCESS",
  GET_PUBLIC_ENTITY_TYPES_FAILURE:
    "public-entities/GET_PUBLIC_ENTITY_TYPES_FAILURE",
};

export const Creators = {
  getPublicEntityTypes: (filters) => ({
    type: Types.GET_PUBLIC_ENTITY_TYPES,
    filters,
  }),
  getPublicEntityTypesSuccess: (filters) => ({
    type: Types.GET_PUBLIC_ENTITY_TYPES_SUCCESS,
    filters,
  }),
  getPublicEntityTypesFailure: (filters) => ({
    type: Types.GET_PUBLIC_ENTITY_TYPES_FAILURE,
    filters,
  }),
};
