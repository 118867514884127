export const Types = {
  GET_ALL_AREA_INFORMATION_TYPES: "GET_ALL_AREA_INFORMATION_TYPES",
  GET_ALL_AREA_INFORMATION_TYPES_SUCESS:
    "GET_ALL_AREA_INFORMATION_TYPES_SUCESS",
  GET_ALL_AREA_INFORMATION_TYPES_FAILURE:
    "GET_ALL_AREA_INFORMATION_TYPES_FAILURE",
};

export const Creators = {
  getAreaInformationTypes: () => ({
    type: Types.GET_ALL_AREA_INFORMATION_TYPES,
  }),
  getAreaInformationTypesSucceeded: (data) => ({
    type: Types.GET_ALL_AREA_INFORMATION_TYPES_SUCESS,
    data,
  }),
  getAreaInformationTypesFailure: () => ({
    type: Types.GET_ALL_AREA_INFORMATION_TYPES_FAILURE,
  }),
};
