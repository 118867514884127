import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Button,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

import { saveRoute } from "../../../store/services/route";

const SaveRouteModal = (props) => {
  const { open, routes, waypoints, closeSaveRoute } = props;

  const [loading, setLoading] = useState(false);
  const [sucessfull, setSucessfull] = useState(false);
  const [failure, setFailure] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const [firstRoute] = routes;

    if (firstRoute) setSelectedItem(firstRoute.id);
  }, [routes]);

  const handleSelect = (event) => {
    setSelectedItem(event.target.value);
  };

  const optionAddress = (route) => {
    const { start_address, end_address } = route;

    return `
        ${start_address.substring(0, 30)}... - 
        ${end_address.substring(0, 30)}...`;
  };

  const handleSave = async () => {
    const formatedRoutes = routes.map((route) => {
      route.decodedPolyline = window.google.maps.geometry.encoding.encodePath(
        route.decodedPolyline
      );
      return route;
    });

    const data = {
      name,
      description,
      metadata: {
        information: formatedRoutes.find(({ id }) => id === selectedItem),
        waypoints: waypoints.filter(
          ({ timestampId }) => timestampId === selectedItem
        ),
      },
    };

    try {
      setLoading(true);
      setFailure(false);
      setSucessfull(false);

      const { status } = await saveRoute(data);

      if (status === 201) {
        setSucessfull(true);
        setFailure(false);
      } else {
        setFailure(true);
      }
    } catch {
      setFailure(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Salvar Rota</DialogTitle>
      <DialogContent>
        {sucessfull && (
          <Typography component="p">Rota salva com sucesso!</Typography>
        )}
        {failure && (
          <Typography component="p">
            Falha ao tentar salvar rota, verifique os dados e tente novamente.
          </Typography>
        )}
      </DialogContent>
      <DialogContent>
        <FormControl>
          <InputLabel
            variant="standard"
            style={{ color: "#fff", padding: "5px" }}
          >
            Rota a ser salva:
          </InputLabel>
          <Select
            value={selectedItem}
            onChange={handleSelect}
            displayEmpty
            input={<OutlinedInput name="route" />}
          >
            {routes.map((route, index) => (
              <MenuItem key={`route-${index}`} value={route.id}>
                {optionAddress(route)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <TextField
          onChange={(e) => setName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Nome da Rota"
          fullWidth
          variant="standard"
          required
        />
        <TextField
          onChange={(e) => setDescription(e.target.value)}
          margin="dense"
          id="description"
          label="Descrição da Rota"
          fullWidth
          variant="standard"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress />}
        <Button onClick={closeSaveRoute}>Sair</Button>
        <Button onClick={handleSave} disabled={name.length === 0 || sucessfull}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  routes: state.routesAnalysis.informations.filter(
    ({ crisisModule }) => !crisisModule
  ),
  waypoints: state.routesAnalysis.waypoints,
});

export default connect(mapStateToProps, null)(SaveRouteModal);
