import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";

import { Creators as MainCreators } from "../../store/actions/main";
import { Creators as ListActions } from "../../store/actions/ocurrences";

import Card from "./Card";
import { language } from "./languageHelper";
import { clippingBuilder, incidentsBuilder } from "./builder";

import brImage from "../../assets/images/br.svg";
import enImage from "../../assets/images/us.svg";

import { enumLanguages } from "../../common/language";

import {
  FlexBox,
  MainAppBar,
  LanguageLogo,
  ToolbarContainer,
} from "./dashboardStyles";

function Dashboard(props) {
  const {
    incidents,
    clippings,
    formFields,
    activateMap,
    incidentsCompare,
    clippingsCompare,
    incidentsCategories,
    clippingsCategories,
    clearAnalysisCompare,
  } = props;

  const [clippingCategories, setClippingCategories] = useState([]);
  const [incidentCategories, setIncidentCategories] = useState([]);

  useEffect(() => {
    if (clippings) {
      const clippingCategoriesIds = clippings
        .map(({ category }) => category.id)
        .filter(function (elemt, index, items) {
          return index === items.indexOf(elemt);
        });

      setClippingCategories(
        clippingsCategories.filter(({ id }) =>
          clippingCategoriesIds.includes(id)
        )
      );
    }

    if (incidents) {
      const incidentsCategoriesIds = incidents
        .map(({ type }) => type.categoryId)
        .filter(function (element, index, items) {
          return index === items.indexOf(element);
        });

      setIncidentCategories(
        incidentsCategories.filter(({ id }) =>
          incidentsCategoriesIds.includes(id)
        )
      );
    }
  }, [formFields.language]);

  const returnToMap = () => {
    clearAnalysisCompare();
    activateMap();
  };

  const renderFlag = {
    [enumLanguages.pt]: enImage,
    [enumLanguages.en]: brImage,
  };

  const handleLanguage = (e) => {
    let newValue;

    if (formFields.language === enumLanguages.pt) newValue = enumLanguages.en;

    if (formFields.language === enumLanguages.en) newValue = enumLanguages.pt;

    props.change("language", newValue);
  };

  const sourceGroups = (groups) => {
    const datas = {};

    groups.forEach(({ id, group, groupEn }) => {
      if (id && group && groupEn) {
        datas[id] = {
          name: group,
          nameEn: groupEn,
        };
      }
    });

    return datas;
  };

  return (
    <MainAppBar>
      <ToolbarContainer>
        <FlexBox component="div">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Voltar"
            onClick={returnToMap}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography component="h1" variant="h3" color="inherit" noWrap>
            {language[formFields.language].title}
          </Typography>
        </FlexBox>
        <Box component="div">
          <IconButton
            edge="start"
            color="inherit"
            name="language"
            onClick={handleLanguage}
          >
            <LanguageLogo
              src={renderFlag[formFields.language]}
              alt="language logo"
            />
          </IconButton>
        </Box>
      </ToolbarContainer>
      {clippingCategories.map((item, index) => (
        <Card
          key={`clipping-category-${index}`}
          category={item}
          occurrences={clippingBuilder[formFields.language](
            clippings,
            sourceGroups(item.groups)
          )}
          occurrencesCompare={clippingBuilder[formFields.language](
            clippingsCompare,
            sourceGroups(item.groups)
          )}
        />
      ))}
      {incidentCategories.map((item, index) => (
        <Card
          key={`incident-category-${index}`}
          category={item}
          occurrences={incidentsBuilder[formFields.language](incidents)}
          occurrencesCompare={incidentsBuilder[formFields.language](
            incidentsCompare
          )}
          hideGraphics
        />
      ))}
    </MainAppBar>
  );
}

Dashboard = reduxForm({
  form: "SETTINGS",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(Dashboard);

const mapStateToProps = (state) => {
  return {
    incidents: state.ocurrences.incidents,
    incidentsCompare: state.ocurrences.incidentsCompare,
    incidentsCategories: state.categories.categoriesList,
    clippings: state.ocurrences.clippings,
    clippingsCompare: state.ocurrences.clippingsCompare,
    clippingsCategories: state.categories.clippingsCategories,
    formFields: getFormValues("SETTINGS")(state)
      ? getFormValues("SETTINGS")(state)
      : [],
  };
};

const mapDispatchToProps = {
  ...MainCreators,
  ...ListActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
