import { call, put } from "redux-saga/effects";

import { getIncidentes } from "../services/incidents";
import { Creators as AlertActions } from "../actions/ocurrences";

export default function* getIncidenteRequest(filters) {
  try {
    const list = yield call(getIncidentes, filters);
    yield put(AlertActions.getIncidentsSuccess(list));
  } catch (error) {
    yield put(AlertActions.getIncidentsFailure(error));
  }
}
