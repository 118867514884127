import { isString } from "lodash";
import { point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

export const parseArea = (coords) => {
  try {
    if (isString(coords)) {
      const decodedPath =
        window.google.maps.geometry.encoding.decodePath(coords);
      return decodedPath.map((path) => {
        return { lat: path.lat(), lng: path.lng() };
      });
    }

    if (coords.length === 1) {
      const decodedPath = window.google.maps.geometry.encoding.decodePath(
        coords[0][0]
      );
      return decodedPath.map((path) => {
        return { lat: path.lat(), lng: path.lng() };
      });
    }

    const decodedPolygons = coords.map((coord) =>
      window.google.maps.geometry.encoding.decodePath(coord[0])
    );
    return decodedPolygons.map((poly) =>
      poly.map((path) => {
        return { lat: path.lat(), lng: path.lng() };
      })
    );
  } catch (error) {
    console.log("This coords are going wrong: ", coords);
    console.log("Error description: ", error);
  }
};

export const getPolygonAnalysis = (polyProps) => {
  if (polyProps.length > 1) {
    return parseArea(polyProps);
  }
  return parseArea(polyProps[0]);
};

export const pointIsInPolygon = (coords, lat, lng) => {
  const pt = point([lng, lat]);
  return booleanPointInPolygon(pt, coords);
};
