module.exports = {
  riskArea: (color) => {
    return {
      clickable: true,
      strokeColor: color || "#c23616",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || "#e84118",
      fillOpacity: 0.5,
    };
  },
  publicEntity: (color) => {
    return {
      clickable: true,
      strokeColor: color || "#116CA6",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || "#116CA6",
      fillOpacity: 0.1,
    };
  },
  cluster: (color) => {
    return {
      clickable: false,
      strokeColor: color || "#00a68d",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: color || "#00a68d",
      fillOpacity: 0.2,
    };
  },
  interestPoint: (color) => {
    return {
      clickable: false,
      strokeColor: color || "#00a68d",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || "#00a68d",
      fillOpacity: 0.1,
    };
  },
  interestPointsByCustomer: (color) => {
    return {
      clickable: false,
      strokeColor: color || "#00a68d",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || "#00a68d",
      fillOpacity: 0.1,
    };
  },
  subArea: (color) => {
    return {
      clickable: true,
      strokeColor: color || "#ffef49",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || "#ffef49",
      fillOpacity: 0.1,
    };
  },
};
