import React from "react";
import { connect } from "react-redux";
import CustomCareMarker from "./CustomMarker/CustomCareMarker";

const CareOccurrenceMarkers = ({ occurrences }) => {
  return occurrences.map((element) => (
    <CustomCareMarker key={`care-occurrence-${element.id}`} data={element} />
  ));
};

const mapStateToProps = (state) => ({
  occurrences: state.care.occurrences,
});

export default connect(mapStateToProps, null)(CareOccurrenceMarkers);
