import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
  Box,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Table as TableForm,
} from "@mui/material";
import {
  TextCell,
  TextHeader,
  GetAppIcon,
  TableContainer,
  ContainerTable,
  FilterTextField,
  ActionContainer,
  FilterContainer,
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
} from "./dashboardStyles";

import { language } from "./languageHelper";

function Table(props) {
  const { ocurrences, category, formFields } = props;

  const {
    city,
    sources,
    seeDatas,
    hideDatas,
    exportData,
    description,
    coordanates,
    neighborhood,
    occurenceDate,
    date: dateText,
    typicityFilter,
    occurenceTitle,
    occurenceFilter,
    category: categoryText,
    typicity: typicityText,
  } = language[formFields.language];

  const [visible, setVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [ocurrence, setOcurrence] = useState("");
  const [typicity, setTypicity] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (ocurrences?.length > 0) {
      const datas = [];
      ocurrences.forEach((element) => {
        datas.push(...element.items);
      });

      setRows(datas);
      setFilteredRows(datas);
      setOcurrence("");
      setTypicity("");
    }
  }, [ocurrences]);

  useEffect(() => {
    let filteredOcurrences = [...rows];

    filteredOcurrences =
      ocurrence?.length > 0
        ? filteredOcurrences.filter(({ title }) =>
            title.toLowerCase().includes(ocurrence.toLowerCase())
          )
        : filteredOcurrences;

    filteredOcurrences =
      typicity?.length > 0
        ? filteredOcurrences.filter(({ type }) =>
            type.type.toLowerCase().includes(typicity.toLowerCase())
          )
        : filteredOcurrences;

    setFilteredRows(filteredOcurrences);
  }, [ocurrence, typicity]);

  const handleVisible = () => {
    setVisible(!visible);
  };

  const renderLocaleDate = (date) => new Date(date).toLocaleString("pt-BR");

  const exportToXLSX = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const date = new Date().toLocaleString("pt-BR").replace(/[^\d]/g, "_");
    const fileName = `${date}_${category}`;
    const datas = rows.map((row) => {
      return {
        [dateText]: renderLocaleDate(row.occurrenceTime),
        [neighborhood]: row.neighborhood || "",
        [city]: row.city || "",
        [categoryText]: row.type.type,
        [coordanates]: `${row.location.lat},${row.location.lng}`,
        [description]: row.description,
      };
    });

    const ws = XLSX.utils.json_to_sheet(datas);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <TableContainer>
      <ActionContainer>
        <Box>
          <IconButton onClick={handleVisible}>
            {visible ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          {visible ? hideDatas : seeDatas}
        </Box>
        {visible && (
          <FilterContainer>
            <FilterTextField
              size="large"
              variant="standard"
              label={occurenceFilter}
              onChange={(e) => setOcurrence(e.target.value)}
              value={ocurrence}
            />
            <FilterTextField
              label={typicityFilter}
              variant="standard"
              onChange={(e) => setTypicity(e.target.value)}
              value={typicity}
            />
          </FilterContainer>
        )}
        <Box>
          {exportData}
          <IconButton onClick={exportToXLSX}>
            <GetAppIcon />
          </IconButton>
        </Box>
      </ActionContainer>
      <ContainerTable visible={visible}>
        {visible && (
          <TableForm>
            <TableHead>
              <TableRow>
                <TextHeader>{occurenceTitle}</TextHeader>
                <TextHeader>{occurenceDate}</TextHeader>
                <TextHeader>{sources}</TextHeader>
                <TextHeader>{typicityText}</TextHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, index) => (
                <TableRow key={`${row.id}-${index}`}>
                  <TextCell>{row.title}</TextCell>
                  <TextCell>{renderLocaleDate(row.occurrenceTime)}</TextCell>
                  <TextCell>{row.media.name}</TextCell>
                  <TextCell>{row.type.type}</TextCell>
                </TableRow>
              ))}
            </TableBody>
          </TableForm>
        )}
      </ContainerTable>
    </TableContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("SETTINGS")(state)
      ? getFormValues("SETTINGS")(state)
      : [],
  };
};

export default connect(mapStateToProps, null)(Table);
