import React, { Component, Fragment } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends Component {
  render() {
    const { userLogged,loggingUser, ...rest } = this.props;
    if (!loggingUser) {
      return !userLogged ? (
        <Redirect to="/login/sso"/>
      ) : (
        <Fragment>
          <Route
            {...rest}
            render={routeProps => <Component {...routeProps} />}
          />
        </Fragment>
      );
    }
    return null;
  }
}

const mapStatesToProps = state => ({
  userLogged: state.auth.userLogged,
  loggingUser: state.auth.loggingUser,
});

export default withRouter(connect(mapStatesToProps)(PrivateRoute));
