import React from "react";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";

export const AppTheme = ({ children }) => {
  const theme = createTheme({
    palette: {
      type: "dark",
      primary: {
        light: "#4d4d4d",
        main: "#212121",
        dark: "#171717",
        contrastText: "#fff",
      },
      secondary: {
        light: "#33ab9f",
        main: "#009688",
        dark: "#00695f",
        contrastText: "#fff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
