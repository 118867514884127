import React from "react";
import LoadingScreen from "react-loading-screen";

import LogoImage from "../../assets/images/geo_minimal.png";

const loadingScreen = () => {
  return (
    <div>
      <LoadingScreen
        loading={true}
        bgColor="#202020"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={LogoImage}
      >
        <div>Loadable content</div>
      </LoadingScreen>
    </div>
  );
};

export default loadingScreen;
