import {
  Box,
  List,
  styled,
  Drawer,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
} from "@mui/material";

export const MainDrawer = styled(Drawer)(() => ({
  width: "300px",
  flexShrink: 0,
  ".MuiDrawer-paper": {
    width: "300px",
    color: "white",
    background: "#212121",
    visibility: "visible",
    overflow: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#212121",
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      backgroundColor: "#212121",
      paddingRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,.3)",
      backgroundColor: "#424242",
    },
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  marginBottom: "8px",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  color: "#fff",
  background: "#1A1C1D",
  minHeight: "64px",
  position: "fixed",
  width: "300px",
  zIndex: 9,
}));

export const TitleText = styled(Typography)(() => ({
  width: "100%",
}));

export const ActionButton = styled(IconButton)(() => ({
  color: "#fff",
}));

export const MainSideContent = styled(Box)(() => ({
  flex: "1",
  width: "300px",
  padding: "65px 0",
  background: "#212121",
}));

export const NavList = styled(List)(() => ({
  color: "#fff",
}));

export const SideFooter = styled(Box)(() => ({
  width: "300px",
  left: "0px",
  bottom: "10px",
  padding: "10px 0px",
  textAlign: "center",
  background: "#212121",
  color: "#fff",
}));

export const IncidentGroup = styled(ListItem)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const IncidentItem = styled(Box)(() => ({
  alignSelf: "normal",
}));

export const InciedntTextTitle = styled(ListItemText)(() => ({
  color: "white",
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
  },
}));

export const IncidentText = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "12px",
    fontWeight: "400",
  },
}));

export const IncidentTextTime = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "12px",
    fontWeight: "300",
  },
}));
