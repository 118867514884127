import React from "react";
import { connect } from "react-redux";
import InputSwitch from "../../common/InputSwitch";
import { Box, Divider } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { Creators as FormActions } from "../../store/actions/menu";

import {
  PaperItem,
  TitleText,
  CustomList,
  MainDrawer,
  SideFooter,
  WarningIcon,
  DrawerHeader,
  ActionButton,
  WarningMessage,
  MainSideContent,
  WarningContainer,
} from "./drawerCameraStyles";

const camerasIds = process.env.REACT_APP_CAMERAS_IDS.split(",").map((e) =>
  Number(e)
);

function DrawerCamera(props) {
  const { cameras, menuOpen, animateMenu, setWarning } = props;

  return (
    <MainDrawer anchor="left" variant="persistent" open={menuOpen}>
      <DrawerHeader>
        <TitleText variant="h6" noWrap>
          Câmeras
        </TitleText>
        <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
          <CloseIcon />
        </ActionButton>
      </DrawerHeader>
      <Divider />
      <MainSideContent component="div">
        <PaperItem>
          <CustomList>
            {cameras.length === 0 && (
              <WarningContainer>
                <WarningIcon />
                <WarningMessage>
                  O seu usuário não possui nenhum pacote de câmeras contratado.
                </WarningMessage>
              </WarningContainer>
            )}
            {cameras.map((type, index) => (
              <Box key={"switchCamera" + index}>
                <InputSwitch
                  setWarning={setWarning}
                  key={"switchCamera" + index}
                  keyValue="interestPoint"
                  label={type.type}
                  value={type.id}
                />
                <Divider />
              </Box>
            ))}
          </CustomList>
        </PaperItem>
      </MainSideContent>
      <SideFooter xs={1} component="div" color="secondary">
        <span>Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;</span>
      </SideFooter>
    </MainDrawer>
  );
}

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.cameras,
    cameras: state.interestPoints.interestPointsTypes.filter(({ id }) =>
      camerasIds.includes(id)
    ),
  };
};

const mapDispatchtoProps = {
  ...FormActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerCamera);
