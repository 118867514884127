import { call, put } from "redux-saga/effects";
import { toast } from "material-react-toastify";

import { getUser as userAxios } from "../services/user";
import { Creators as AuthActions } from "../actions/auth";

export default function* getUserRequest(params) {
  try {
    const { data } = yield call(userAxios, params);
    if (
      Array.isArray(data?.customer?.applications) &&
      data.customer.applications.length !== 0
    ) {
      localStorage.setItem("user", JSON.stringify(data));
      yield put(AuthActions.loginSuccess(data));
      return;
    }
    toast.error("Usuário não está associado a esta aplicação.");
  } catch (error) {
    if (error?.response?.status !== 401)
      toast.error("Falha ao buscar os dados do usuário.");
  }

  yield put(AuthActions.logout());
  yield put(AuthActions.loginFailed());
  yield put(AuthActions.getUserFailed());
}
