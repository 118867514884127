import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BoxFilters from "./filters";
import { Creators as DrawingCreators } from "../../../store/actions/drawing";
import { handleDrawerFunction } from "../../../common/handleDrawer";
import { DrawingManager } from "@react-google-maps/api";

const DrawingBox = (props) => {
  const { drawingManager, isVisible } = props;

  const [overlays, setOverlays] = useState([]);

  useEffect(() => {
    !drawingManager &&
      overlays.length > 0 &&
      deleteAllOverlays() &&
      setOverlays([]);
  }, [drawingManager, overlays]);

  const handleDrawerComplete = (e) => {
    const timestampId = new Date().getTime();

    e.overlay.addListener("click", (e) => {
      setOverlays((overlays) => {
        const overlay = overlays.find((overlay) => overlay.id === timestampId);

        if (overlay) {
          overlay.isVisible = true;
        }

        return [
          ...overlays.filter((overlay) => overlay.id !== timestampId),
          overlay,
        ];
      });
    });

    const drawerFunction = handleDrawerFunction(e, timestampId);

    props.addDrawing(drawerFunction["drawing"]);

    setOverlays([
      ...overlays,
      {
        type: e.type,
        dropFunction: (formFieldsInjection) => {
          props.removeDrawing(timestampId);
          e.overlay.setMap(null);
        },
        id: timestampId,
        position: drawerFunction["drawing"].props.position,
        coords: drawerFunction["drawing"].props.radius,
        isVisible: false,
        ref: e.overlay,
      },
    ]);
  };

  const deleteAllOverlays = () => {
    overlays.forEach((overlay) => {
      overlay.ref.setMap(null);
    });
  };

  const callbackClose = (id) => {
    setOverlays((state) => {
      const updateAt = state.find((overlay) => overlay.id === id);
      const updatedInfo = Object.assign(updateAt, { isVisible: false });

      return [...state.filter((overlay) => overlay.id !== id), updatedInfo];
    });
  };

  return (
    <>
      {isVisible && (
        <DrawingManager
          defaultDrawingMode={window.google.maps.drawing.CIRCLE}
          onOverlayComplete={(e) => handleDrawerComplete(e)}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              drawingControl: true,
              position: window.google.maps.ControlPosition.LEFT_TOP,
              drawingModes: [
                window.google.maps.drawing.OverlayType.CIRCLE,
                window.google.maps.drawing.OverlayType.POLYGON,
                window.google.maps.drawing.OverlayType.MARKER,
              ],
            },
            circleOptions: {
              fillColor: `#ffff00`,
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: true,
              editable: false,
              zIndex: 1,
            },
            polygonOptions: {},
            polylineOptions: {},
            rectangleOptions: {},
            markerOptions: {},
          }}
        />
      )}
      {overlays.length > 0 && (
        <BoxFilters overlays={overlays} callback={callbackClose} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drawingManager: state.menu.drawingManager,
    drawings: state.drawing.drawings,
    isVisible: state.menu.drawingManager,
  };
};

const mapDispatchToProps = {
  ...DrawingCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawingBox);
