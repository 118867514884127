import { call, put, all } from "redux-saga/effects";

import { Types } from "../actions/auth";

import * as ssoService from "../services/sso";

function clearAuth() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("@sso_integration");
  localStorage.removeItem("@sso_expires_at");
  localStorage.removeItem("permissions");
}

export default function* ssoAuthenticate({ payload }) {
  yield call(ssoService.setSSOIntegration, payload.sso);
  try {
    const { data } = yield call(ssoService.ssoSession, payload.access_token);

    localStorage.setItem("token", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);

    yield call(ssoService.setSSOExpiresAt, data.expires_at);

    yield all([put({ type: Types.SUCCESS_MAIN, payload: data.user })]);

    window.location.replace(process.env.PUBLIC_URL + "/");
  } catch (err) {
    clearAuth();
  }
}
