import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import CustomMarker from "./CustomMarker/CustomMarker";
import CustomClippingMarker from "./CustomMarker/CustomClippingMarker";
import CustomInterestPointMarker from "./CustomMarker/CustomInterestPointMarker";
import CustomCameraMarker from "./CustomMarker/CustomCameraMarker";
import { MarkerClusterer, Marker } from "@react-google-maps/api";
import Areas from "../Map/Areas/Area";

import { ocurrencesInPoligon } from "../../common/ocurrencesHelper";

class Markers extends PureComponent {
  constructor(props) {
    super(props);
    this.addMarkerToOMS = this.addMarkerToOMS.bind(this);
  }

  addMarkerToOMS(markerRef) {
    if (this.props.oms) {
      this.props.oms.addMarker(markerRef.current.state[Marker]);
    }
  }

  renderClippings(clippings) {
    const { ocurrences, filterablePolygons } = ocurrencesInPoligon(clippings);

    return filterablePolygons.length > 0
      ? this.renderClippingsByPolyline(ocurrences)
      : this.props.formFields.agroupClippingsMarkers
      ? this.allGroupedClippings(clippings)
      : this.allClippings(clippings);
  }

  allClippings(clippings) {
    return clippings.map((cluster) =>
      cluster.map((marker) => (
        <CustomClippingMarker key={marker.id} marker={marker} />
      ))
    );
  }

  allGroupedClippings(clippings) {
    const gridSize = this.props.mode === "map" ? 120 : 120;

    return (
      <MarkerClusterer
        averageCenter
        gridSize={gridSize}
        minimumClusterSize={10}
        defaultImageExtension="png"
        imageExtension="png"
      >
        {(clusterer) => (
          <>
            {clippings.map((cluster) =>
              cluster.map((marker) => (
                <CustomClippingMarker marker={marker} clusterer={clusterer} />
              ))
            )}

            {clippings.map((cluster) =>
              cluster.map((marker) =>
                marker.area?.polygon?.coordinates?.map((area, index) => (
                  <Areas
                    key={marker.id + index + "-clippings-marker"}
                    name={marker.area.name}
                    riskArea={false}
                    cluster={true}
                    point={marker.area.point || {}}
                    area={
                      marker.area.polygon.coordinates.length === 1
                        ? window.google.maps.geometry.encoding.decodePath(
                            Array.isArray(area) ? area[0] : area
                          )
                        : window.google.maps.geometry.encoding.decodePath(
                            area[0]
                          )
                    }
                  />
                ))
              )
            )}
          </>
        )}
      </MarkerClusterer>
    );
  }

  renderClippingsByPolyline(clippings) {
    return clippings.map((marker) => (
      <CustomClippingMarker key={marker.id} marker={marker} />
    ));
  }

  renderIncidents(incidents) {
    const { ocurrences, filterablePolygons } = ocurrencesInPoligon(incidents);

    return filterablePolygons.length > 0
      ? this.renderIncidentsByPolyline(ocurrences)
      : this.props.formFields.agroupIncidentsMarkers
      ? this.allGroupedIncidents(incidents)
      : this.allIncidents(incidents);
  }

  allIncidents(incidents) {
    return incidents.map((cluster) =>
      cluster.map((marker) => (
        <CustomMarker key={marker.id} marker={marker} active={marker.active} />
      ))
    );
  }

  allGroupedIncidents(incidents) {
    const gridSize = this.props.mode === "map" ? 120 : 120;
    const icon = [];
    icon[1] = [
      "https://www.grupounicad.com.br/cgi-bin/PortalUmais/MonitIncid/Files/Icones/20160104_164826_pino.png",
      "Trânsito",
    ];
    icon[2] = [
      "https://www.grupounicad.com.br/cgi-bin/PortalUmais/MonitIncid/Files/Icones/20160104_164908_pino.png",
      "Segurança",
    ];
    icon[7] = [
      "https://www.grupounicad.com.br/cgi-bin/PortalUmais/MonitIncid/Files/Icones/20160104_164934_pino.png",
      "Contigência",
    ];

    return (
      <MarkerClusterer
        averageCenter
        gridSize={gridSize}
        minimumClusterSize={10}
        defaultImageExtension="png"
        imageExtension="png"
      >
        {(clusterer) => (
          <>
            {incidents.map((cluster) =>
              cluster.map((marker) =>
                !marker.active ? (
                  <CustomMarker
                    key={marker.id}
                    marker={marker}
                    active={marker.active}
                    clusterer={clusterer}
                  />
                ) : (
                  <></>
                )
              )
            )}
          </>
        )}
      </MarkerClusterer>
    );
  }

  renderIncidentsByPolyline(incidents) {
    return incidents.map((marker) => {
      if (!marker.active) {
        return (
          <CustomMarker
            key={marker.id}
            marker={marker}
            active={marker.active}
          />
        );
      }
      return null;
    });
  }

  render() {
    const markersFiltered = {
      incidents: this.props.incidents,
      clippings: this.props.clippings,
    };

    const interestPoints = this.props.interestPoints;
    const interestPointsByCustomer = this.props.interestPointsByCustomer;
    const camerasArrayTypes = process.env.REACT_APP_CAMERAS_IDS.split(",").map(
      (e) => Number(e)
    );

    const interestPointsTypesData = this.props.interestPointsTypes.reduce(
      (accu, curr) => {
        accu[curr.id] = curr;

        return accu;
      },
      {}
    );

    const interestPointsTypesByCustomerData =
      this.props.interestPointsTypesByCustomer.reduce((accu, curr) => {
        accu[curr.id] = curr;

        return accu;
      }, {});

    const gridSize = this.props.mode === "map" ? 120 : 120;

    return (
      <div>
        {markersFiltered.incidents.map((cluster) =>
          cluster
            .filter(({ active }) => active)
            .map((marker) => (
              <CustomMarker
                key={marker.id}
                marker={marker}
                active={marker.active}
              />
            ))
        )}
        {this.renderIncidents(markersFiltered.incidents)}
        {this.renderClippings(markersFiltered.clippings)}
        {this.props.formFields.agroupPOIMarkers ? (
          <MarkerClusterer
            averageCenter
            gridSize={gridSize}
            minimumClusterSize={10}
            defaultImageExtension="png"
            imageExtension="png"
          >
            {(clusterer) => (
              <>
                {interestPoints.map((cluster) =>
                  camerasArrayTypes.includes(cluster[0]?.pointTypeId) ? (
                    <>
                      {cluster.map((marker) => (
                        <CustomCameraMarker
                          onRef={this.addMarkerToOMS}
                          marker={marker}
                          clusterer={clusterer}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {cluster.map((marker) => (
                        <CustomInterestPointMarker
                          key={marker.id}
                          marker={marker}
                          layerType={"interestPoint"}
                          clusterer={clusterer}
                        />
                      ))}
                    </>
                  )
                )}

                {interestPoints.map((cluster) =>
                  cluster.map((marker) =>
                    marker.area?.polygon?.coordinates?.map((area, index) => (
                      <Areas
                        id={marker.id}
                        key={marker.id + index + "-interest-points"}
                        name={marker.area.name}
                        layerType={"interestPoint"}
                        geomType={
                          !this.props.interestPointsIdsToViewPoligon.includes(
                            marker.id
                          ) && marker.area.polygon?.type
                        }
                        icon={marker.type.icon}
                        coordinates={area}
                        color={
                          interestPointsTypesData[cluster[0].pointTypeId].color
                        }
                      />
                    ))
                  )
                )}
              </>
            )}
          </MarkerClusterer>
        ) : (
          interestPoints.map((cluster) => (
            <>
              {cluster.map((marker) =>
                marker.area.polygon?.coordinates.map((area, index) => {
                  return (
                    <Areas
                      id={marker.id}
                      key={marker.id + index + "-interest-points"}
                      name={marker.area.name}
                      layerType={"interestPoint"}
                      geomType={
                        !this.props.interestPointsIdsToViewPoligon.includes(
                          marker.id
                        ) && marker.area.polygon?.type
                      }
                      icon={marker.type.icon}
                      coordinates={area}
                      color={
                        interestPointsTypesData[cluster[0].pointTypeId].color
                      }
                    />
                  );
                })
              )}
              {!camerasArrayTypes.includes(cluster[0]?.pointTypeId) &&
                cluster.map((marker) => (
                  <CustomInterestPointMarker
                    key={marker.id}
                    marker={marker}
                    layerType={"interestPoint"}
                  />
                ))}
              {camerasArrayTypes.includes(cluster[0]?.pointTypeId) &&
                cluster.map((marker) => (
                  <CustomCameraMarker
                    onRef={this.addMarkerToOMS}
                    key={cluster[0]?.pointTypeId + marker.id}
                    marker={marker}
                  />
                ))}
            </>
          ))
        )}
        {this.props.formFields.agroupExclusivePOIMarkers ? (
          <MarkerClusterer
            averageCenter
            gridSize={gridSize}
            minimumClusterSize={10}
            defaultImageExtension="png"
            imageExtension="png"
          >
            {(clusterer) => (
              <>
                {interestPointsByCustomer
                  .filter(
                    (cluster) => Array.isArray(cluster) && cluster.length > 0
                  )
                  .map((cluster) =>
                    cluster.map((marker) =>
                      marker.area?.polygon?.coordinates?.map((area, index) => (
                        <Areas
                          id={marker.id}
                          key={
                            marker.id + index + "cluster-interestpoint-customer"
                          }
                          name={marker.area.name}
                          layerType={"interestPointsByCustomer"}
                          geomType={
                            !this.props.interestPointsByCustomerIdsToViewPoligon.includes(
                              marker.id
                            ) && marker.area.polygon?.type
                          }
                          icon={marker.type.icon}
                          coordinates={area}
                          color={
                            interestPointsTypesByCustomerData[
                              cluster[0].customerPointTypeId
                            ].color
                          }
                        />
                      ))
                    )
                  )}

                {interestPointsByCustomer
                  .filter(
                    (cluster) => Array.isArray(cluster) && cluster.length > 0
                  )
                  .map((cluster) =>
                    cluster.map((marker) => (
                      <CustomInterestPointMarker
                        key={marker.id}
                        marker={marker}
                        clusterer={clusterer}
                        layerType={"interestPointsByCustomer"}
                      />
                    ))
                  )}
              </>
            )}
          </MarkerClusterer>
        ) : (
          interestPointsByCustomer
            .filter((cluster) => Array.isArray(cluster) && cluster.length > 0)
            .map((cluster) => (
              <>
                {cluster.map((marker) => {
                  if (marker.area.polygon) {
                    return (
                      marker.area.polygon &&
                      marker.area.polygon.coordinates.map((area, index) => {
                        return (
                          <Areas
                            id={marker.id}
                            key={
                              marker.id +
                              index +
                              "cluster-interestpoint-customer"
                            }
                            name={marker.area.name}
                            layerType={"interestPointsByCustomer"}
                            geomType={
                              !this.props.interestPointsByCustomerIdsToViewPoligon.includes(
                                marker.id
                              ) && marker.area.polygon?.type
                            }
                            icon={marker.type.icon}
                            coordinates={area}
                            color={
                              interestPointsTypesByCustomerData[
                                cluster[0].customerPointTypeId
                              ]?.color || "yellow"
                            }
                          />
                        );
                      })
                    );
                  }
                  return null;
                })}
                {cluster.map((marker) => (
                  <CustomInterestPointMarker
                    key={marker.id}
                    marker={marker}
                    layerType={"interestPointsByCustomer"}
                  />
                ))}
              </>
            ))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formFields: getFormValues("SETTINGS")(state)
    ? getFormValues("SETTINGS")(state)
    : [],
  interestPointsTypes: state.interestPoints.interestPointsTypes,
  interestPointsTypesByCustomer:
    state.interestPoints.interestPointsTypesByCustomer,
  interestPointsIdsToViewPoligon:
    state.interestPoints.interestPointsIdsToViewPoligon,
  interestPointsByCustomerIdsToViewPoligon:
    state.interestPoints.interestPointsByCustomerIdsToViewPoligon,
});

export default connect(mapStateToProps, null)(Markers);
