import api from "./apiV1";

export const getRiskAreas = () => {
  return api.get("risk-areas-points");
};

export const getRiskAreaTypes = () => {
  return api.get("risk-areas");
};

export const getCriminalFactions = () => {
  return api.get("criminal-factions");
};
