import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { Box, Divider, RadioGroup, FormControlLabel } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import {
  ItemText,
  RadioIcon,
  RadioItem,
  PaperItem,
  TitleText,
  FornLabel,
  SideFooter,
  MainDrawer,
  CustomSwitch,
  ActionButton,
  DrawerHeader,
  ExpansionList,
  ExpandMoreIcon,
  ExpansionPanel,
  MainSideContent,
  RadioCheckedIcon,
  ExpansionHeading,
  SelectFormControl,
  ListItemComponent,
  ExpasionPanelTitle,
  ExpansionPanelDetail,
} from "./drawerMapStyles";

import { enumLanguages } from "../../common/language";

import { Creators as FormActions } from "../../store/actions/menu";
import { Creators as MainActions } from "../../store/actions/main";
import { Creators as ListActions } from "../../store/actions/ocurrences";

let DrawerMaptypes = ({
  menuOpen,
  formFields,
  categories,
  trafficEvents,
  occurrenceTypes,
  updateIncidentId,
  startTraffic,
  stopTraffic,
  animateMenu,
  switchMap,
  change,
}) => {
  const [mapType, setMapType] = useState("MAP_ROADMAP");

  const occurrenceOptions = useMemo(() => {
    updateIncidentId(categories.map(({ id }) => id));

    return categories.map(({ id, category }) => ({
      id,
      label: category,
    }));
  }, [categories, updateIncidentId]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setMapType(value);
    switchMap(`main/${value}`);
  };

  const handleAgroup = (event) => {
    const {
      target: { name, checked },
    } = event;

    change([name], checked);
  };

  const toggleOptions = (event) => {
    const {
      target: { name },
    } = event;

    change(name, !formFields.manchaTermica);
  };

  const toggleTrafficEvents = () => {
    if (trafficEvents) {
      stopTraffic();
    } else {
      startTraffic();
    }
  };

  const handleUpdateId = (event) => {
    const {
      target: { value, checked },
    } = event;

    const newTypes = checked
      ? [...occurrenceTypes, Number(value)]
      : [...occurrenceTypes.filter((element) => element !== Number(value))];

    updateIncidentId(newTypes);
  };

  return (
    <MainDrawer anchor="right" open={menuOpen} variant="persistent">
      <DrawerHeader>
        <TitleText variant="h6" noWrap>
          Opções de Mapa
        </TitleText>
        <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
          <CloseIcon />
        </ActionButton>
      </DrawerHeader>

      <MainSideContent component="div">
        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Selecionar tipo de mapa</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <SelectFormControl variant="outlined">
                <RadioGroup
                  name="gender1"
                  value={mapType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="MAP_SATELITE"
                    control={
                      <RadioItem
                        checkedIcon={<RadioCheckedIcon />}
                        icon={<RadioIcon />}
                      />
                    }
                    label="Satélite"
                  />
                  <FormControlLabel
                    value="MAP_ROADMAP"
                    control={
                      <RadioItem
                        checkedIcon={<RadioCheckedIcon />}
                        icon={<RadioIcon />}
                      />
                    }
                    label="Rodoviário"
                  />
                  <FormControlLabel
                    value="MAP_HYBRID"
                    control={
                      <RadioItem
                        checkedIcon={<RadioCheckedIcon />}
                        icon={<RadioIcon />}
                      />
                    }
                    label="Híbrido"
                  />
                  <FormControlLabel
                    value="MAP_TERRAIN"
                    control={
                      <RadioItem
                        checkedIcon={<RadioCheckedIcon />}
                        icon={<RadioIcon />}
                      />
                    }
                    label="Relevo"
                  />
                </RadioGroup>
              </SelectFormControl>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Agrupar marcadores</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        name="agroupPOIMarkers"
                        color="secondary"
                        onClick={handleAgroup}
                        checked={formFields.agroupPOIMarkers}
                      />
                    }
                    label={<FornLabel>Pontos de interesse Coletivos</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />

                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        onClick={handleAgroup}
                        name="agroupExclusivePOIMarkers"
                        checked={formFields.agroupExclusivePOIMarkers}
                      />
                    }
                    label={
                      <FornLabel>Pontos de interesse Exclusivos</FornLabel>
                    }
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />

                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        onClick={handleAgroup}
                        name="agroupClippingsMarkers"
                        checked={formFields.agroupClippingsMarkers}
                      />
                    }
                    label={<FornLabel>Clippings</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />

                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        onClick={handleAgroup}
                        name="agroupIncidentsMarkers"
                        checked={formFields.agroupIncidentsMarkers}
                      />
                    }
                    label={<FornLabel>Alertas</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Outras Configurações</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        name="manchaTermica"
                        onClick={toggleOptions}
                        checked={formFields?.manchaTermica}
                      />
                    }
                    label={<FornLabel>Mancha Térmica</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />

                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        value={true}
                        color="secondary"
                        name="transitEvents"
                        checked={trafficEvents}
                        onClick={toggleTrafficEvents}
                      />
                    }
                    label={<FornLabel>Trânsito</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>

                <Divider />
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Monitoramento em tempo real</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                {occurrenceOptions.map(({ id, label }) => (
                  <ListItemComponent key={`accurence-types-${id}]`}>
                    <ItemText
                      control={
                        <CustomSwitch
                          value={id}
                          color="secondary"
                          name="occurenceTypesIds"
                          onClick={handleUpdateId}
                          checked={occurrenceTypes.includes(id)}
                        />
                      }
                      label={<FornLabel>{label}</FornLabel>}
                      labelPlacement="start"
                    />
                  </ListItemComponent>
                ))}
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>
      </MainSideContent>

      <SideFooter xs={1} component="div" color="secondary">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

DrawerMaptypes = reduxForm({
  form: "SETTINGS",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerMaptypes);

const mapStateToProps = (state) => {
  return {
    trafficEvents: state.ocurrences.trafficEvents,
    occurrenceTypes: state.ocurrences.occurenceTypesIds,
    menuOpen: state.menu.mapTypesMenu,
    formFieldsAnalise: getFormValues("ANALISE")(state),
    formFields: getFormValues("SETTINGS")(state)
      ? getFormValues("SETTINGS")(state)
      : [],
    initialValues: {
      agroupPOIMarkers: false,
      agroupClippingsMarkers: false,
      agroupIncidentsMarkers: false,
      agroupExclusivePOIMarkers: false,
      language: enumLanguages.pt,
    },
    categories: state.categories.categoriesList,
  };
};

const mapDispatchtoProps = {
  ...FormActions,
  ...MainActions,
  ...ListActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerMaptypes);
