import React, { useState } from "react";
import { connect } from "react-redux";
import { Marker } from "@react-google-maps/api";
import { Box, Button, Typography } from "@mui/material";

import { Creators as InterestPointsActions } from "../../../store/actions/interestPoints";

import {
  AlertPaper,
  IncidentItem,
  IncidentText,
  IncidentIconItem,
  IncidentTextTitle,
  InfoWindowComponent,
  AreaInformationContainer,
} from "./styles";

const CustomMarker = (props) => {
  const {
    marker,
    clusterer,
    layerType,
    handleInterestPointsPoligon,
    interestPointsIdsToViewPoligon,
    handleInterestPointsByCustomerPoligon,
    interestPointsByCustomerIdsToViewPoligon,
  } = props;
  const { id, area, type } = marker;

  const [isOpen, setIsOpen] = useState(false);

  const toggleVisible = () => {
    setIsOpen(!isOpen);
  };

  const renderAreaInformations = (areaInformations) => {
    if (!areaInformations || areaInformations.length === 0) {
      return null;
    }

    return (
      <AreaInformationContainer>
        <strong>Informações:</strong>
        {areaInformations.map((areaInformation) => {
          return (
            <Typography component="p">{areaInformation.information}</Typography>
          );
        })}
      </AreaInformationContainer>
    );
  };

  const interestPointProvider = (polygonCoordinates) => {
    return (
      polygonCoordinates && (
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            type="button"
            size="medium"
            color="primary"
            variant="contained"
            style={{ margin: "15px" }}
            onClick={() => handleInterestPointsPoligon(id)}
          >
            {!interestPointsIdsToViewPoligon.includes(id)
              ? "Ocultar Abrangência"
              : "Visualizar Abrangência"}
          </Button>
        </Box>
      )
    );
  };

  const interestPointByCustomerProvider = (polygonCoordinates) => {
    return (
      polygonCoordinates && (
        <Box
          component="div"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            size="medium"
            type="button"
            color="primary"
            variant="contained"
            style={{ margin: "15px" }}
            onClick={() => handleInterestPointsByCustomerPoligon(id)}
          >
            {!interestPointsByCustomerIdsToViewPoligon.includes(id)
              ? "Ocultar Abrangência"
              : "Visualizar Abrangência"}
          </Button>
        </Box>
      )
    );
  };

  const actionButton = {
    interestPoint: interestPointProvider,
    interestPointsByCustomer: interestPointByCustomerProvider,
  };

  return (
    <Marker
      noRedraw
      title={area.name && area.name}
      key={id}
      position={{
        lat: Number(area.point.coordinates[1]),
        lng: Number(area.point.coordinates[0]),
      }}
      icon={{ url: type.icon, optimized: false }}
      onClick={toggleVisible}
      clusterer={clusterer}
    >
      {isOpen && (
        <InfoWindowComponent options={{ maxWidth: 400 }}>
          <AlertPaper onClick={toggleVisible} color="primary">
            <IncidentIconItem component="div">
              <img src={type.icon} alt="Ícone" />
            </IncidentIconItem>
            <IncidentItem component="div">
              <IncidentTextTitle primary={area.name} />
              <IncidentText primary={type.type} />

              {renderAreaInformations(area.areaInformation)}
            </IncidentItem>
            {actionButton[layerType] &&
              actionButton[layerType](area.polygon?.coordinates)}
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
};

const mapStateToProps = (state) => ({
  interestPointsIdsToViewPoligon:
    state.interestPoints.interestPointsIdsToViewPoligon,
  interestPointsByCustomerIdsToViewPoligon:
    state.interestPoints.interestPointsByCustomerIdsToViewPoligon,
});

const mapDispatchToProps = {
  ...InterestPointsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomMarker);
