import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  List,
  Paper,
  Radio,
  Switch,
  styled,
  Drawer,
  ListItem,
  Accordion,
  IconButton,
  Typography,
  FormControl,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from "@mui/material";

export const MainDrawer = styled(Drawer)(() => ({
  width: "300px",
  flexShrink: 0,
  ".MuiDrawer-paper": {
    width: "300px",
    color: "white",
    background: "#212121",
    visibility: "visible",
    overflow: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#212121",
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      backgroundColor: "#212121",
      paddingRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,.3)",
      backgroundColor: "#424242",
    },
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  marginBottom: "8px",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  color: "#fff",
  background: "#1A1C1D",
  minHeight: "64px",
  position: "fixed",
  width: "300px",
  zIndex: 9,
}));

export const TitleText = styled(Typography)(() => ({
  width: "100%",
}));

export const ActionButton = styled(IconButton)(() => ({
  color: "#fff",
}));

export const MainSideContent = styled(Box)(() => ({
  flex: "1",
  width: "300px",
  padding: "65px 0",
  background: "#212121",
}));

export const PaperItem = styled(Paper)(() => ({
  width: "90%",
  color: "white",
  marginLeft: "auto",
  marginTop: "30px",
  marginRight: "auto",
  backgroundColor: "#1A1C1D",
}));

export const ExpansionPanel = styled(Accordion)(() => ({
  padding: 0,
  fontSize: "0.8rem",
  background: "#1A1C1D",
}));

export const ExpasionPanelTitle = styled(AccordionSummary)(() => ({
  padding: "0 15px",
}));

export const ExpansionHeading = styled(Typography)(() => ({
  fontSize: "1.2rem",
  color: "#fff",
}));

export const ExpansionPanelDetail = styled(AccordionDetails)(() => ({
  padding: "0 15px",
}));

export const ExpansionList = styled(List)(() => ({
  width: "100%",
  padding: 0,
}));

export const ListItemComponent = styled(ListItem)(() => ({
  margin: 0,
  padding: 0,
}));

export const ItemText = styled(FormControlLabel)(() => ({
  fontSize: "0.8rem",
  marginRight: "40px",
  marginLeft: 0,
  marginBottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  padding: "17px 10px",
  width: "100%",
  height: "100%",
}));

export const CustomSwitch = styled(Switch)(() => ({
  right: 0,
  position: "absolute",
}));

export const FornLabel = styled(Typography)(() => ({
  fontSize: "1rem",
  cursor: "pointer",
  color: "#fff",
}));

export const SideFooter = styled(Box)(() => ({
  width: "300px",
  left: "0px",
  bottom: "10px",
  padding: "10px 0px",
  textAlign: "center",
  background: "#212121",
  color: "#fff",
}));

export const SelectFormControl = styled(FormControl)(() => ({
  color: "white",
  marginLeft: "15px",
  "& fieldset": {
    border: "none",
  },
  "& svg": {
    color: "#212121",
  },
}));

export const RadioItem = styled(Radio)(() => ({
  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const RadioIcon = styled("span")(() => ({
  borderRadius: "50% !important",
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  transition: "all .3s linear",
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

export const RadioCheckedIcon = styled("span")(() => ({
  borderRadius: "50% !important",
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",

  transition: "all .3s linear",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
}));

export const ExpandMoreIcon = styled(ExpandMore)(() => ({
  color: "#fff",
}));
