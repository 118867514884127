export const Types = {
  ADD_INFOS_FOR_DETOUR: "ADD_INFOS_FOR_DETOUR",
  CANCEL_SELECT_DETOUR: "CANCEL_SELECT_DETOUR",
  SELECT_DETOUR_POINT: "SELECT_DETOUR_POINT",
  CHANGE_MODE: "CHANGE_MODE",
};

export const Creators = {
  activateCoordsForDetour: () => ({
    type: Types.SELECT_DETOUR_POINT,
  }),
  desactivateCoordsForDetour: () => ({
    type: Types.CANCEL_SELECT_DETOUR,
  }),
  addInfosForDetour: (data) => ({
    type: Types.ADD_INFOS_FOR_DETOUR,
    payload: data,
  }),
  changeMode: (data) => ({
    type: Types.CHANGE_MODE,
    payload: data,
  }),
};
