import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { searchAddress } from "../../../common/googleHelper";
import { Creators as RoutesAnalysis } from "../../../store/actions/routesAnalysis";
import { Creators as CrisisMode } from "../../../store/actions/crisisMode";
import { Creators as Drawing } from "../../../store/actions/drawing";
import { Creators as Detour } from "../../../store/actions/detour";

import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY);
Geocode.setLanguage("pt-BR");
Geocode.setRegion("br");

function StopoverModal(props) {
  const {
    open,
    waypoints,
    formFields,
    closeModal,
    addDrawing,
    updateRoute,
    updateRoutes,
    removeDrawing,
    infosForDetour,
    addWaypointAction,
    desactivateCoordsForDetour,
  } = props;
  const { lat, lng } = props.coordinates;

  const [time, setTime] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (time < 0) setTime(0);
  }, [time]);

  const handleSave = async () => {
    const response = await Geocode.fromLatLng(lat, lng);
    const { formatted_address, place_id } = response.results[0];

    if (infosForDetour.routeCoords) {
      closeModal();

      desactivateCoordsForDetour();

      addWaypointAction({
        id: place_id,
        timestampId: infosForDetour.timestampId,
        location: formatted_address,
        position: {
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
        },
        stopover: true,
        duration: time,
        title: title,
      });

      const stopovers = waypoints.filter(
        ({ timestampId }) => timestampId === infosForDetour.timestampId
      );

      const filteredWaypoints = waypoints
        .filter(({ timestampId }) => timestampId === infosForDetour.timestampId)
        .map(({ location }) => ({
          location,
          stopover: false,
        }));

      stopovers.push({
        id: place_id,
        timestampId: infosForDetour.timestampId,
        location: formatted_address,
        position: {
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
        },
        stopover: true,
        duration: time,
        title: title,
      });

      filteredWaypoints.push({
        location: formatted_address,
        stopover: false,
      });

      try {
        const routeParams = {
          coords: infosForDetour.coords,
          isBold: infosForDetour.isBold,
          unitName: infosForDetour.unitName,
          isOnFocus: infosForDetour.isOnFocus,
          lineColor: infosForDetour.lineColor,
          unitAddress: infosForDetour.unitAddress,
          isDraggable: infosForDetour.isDraggable,
          crisisModule: infosForDetour.crisisModule,
          radius: formFields?.radius || 1000,
        };

        const { drawing, extraInformation } = await searchAddress(
          infosForDetour.timestampId,
          routeParams,
          filteredWaypoints,
          stopovers
        );

        updateRoute(extraInformation);
        updateRoutes(extraInformation);

        removeDrawing(infosForDetour.timestampId);
        addDrawing(drawing);
      } catch (e) {
        console.error(`error fetching directions: ${e}`);
      }
    }
  };

  const disabledButton = () => {
    if (lat && lng && time > 0) {
      return false;
    }

    return true;
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Ponto de Parada</DialogTitle>
      <DialogContent>
        <TextField
          label="Tempo"
          placeholder="Em minutos"
          type="number"
          style={{ margin: "10px", width: "50px" }}
          value={time}
          onChange={(e) => setTime(parseInt(e.target.value))}
        />
        <TextField
          label="Título"
          placeholder="Descrição do ponto de parada"
          style={{ margin: "10px", width: "150px" }}
          type="text"
          variant="standard"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Sair</Button>
        <Button disabled={disabledButton()} onClick={handleSave}>
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StopoverModal = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(StopoverModal);

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    waypoints: state.routesAnalysis.waypoints,
    infosForDetour: state.detours.infosForDetour,
  };
};

const mapDispatchtoProps = {
  ...Detour,
  ...Drawing,
  ...CrisisMode,
  ...RoutesAnalysis,
};

export default connect(mapStateToProps, mapDispatchtoProps)(StopoverModal);
