import React, { useState } from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";
import Card from "./Card";
import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import {
  RemoveRouteButton,
  DeleteOutlineIcon,
  SliderContainer,
  MainSideContent,
  SaveRouteButton,
  ButtonContainer,
  OpenListButton,
  RadiusWarning,
  ActionButton,
  DrawerHeader,
  ExploreIcon,
  ListAltIcon,
  MainDrawer,
  FolderIcon,
  SideFooter,
  TitleText,
} from "./routeStyles";

import { Creators as menu } from "../../../store/actions/menu";
import { Creators as CrisisModeCreators } from "../../../store/actions/crisisMode";
import { Creators as RouteAnalysisCreators } from "../../../store/actions/routesAnalysis";
import { Creators as DrawingsCreators } from "../../../store/actions/drawing";

import RouteListModal from "./RouteListModal";
import SaveRouteModal from "./SaveRouteModal";

function DrawerRoutes({
  routes,
  menuOpen,
  cleanAll,
  openPopup,
  animateMenu,
  informations,
  clearWaypoints,
  crisisModeUnits,
  removeAllDrawings,
  setCrisisModeRoutes,
  filteredPublicEntities,
  ...props
}) {
  const [openList, setOpenList] = useState(false);
  const [openSaveRoute, setOpenSaveRoute] = useState(false);

  const handleDeleteRoutes = () => {
    setCrisisModeRoutes([]);
    clearWaypoints();
    cleanAll();
    removeAllDrawings();
  };

  const closeRouteList = () => setOpenList(false);

  const closeSaveRoute = () => setOpenSaveRoute(false);

  return (
    <MainDrawer variant="persistent" open={menuOpen} anchor="left">
      <DrawerHeader>
        <TitleText variant="h5" noWrap>
          Roteirização e Análise
        </TitleText>
        <ActionButton onClick={() => animateMenu("FECHA_MENU")}>
          <Close />
        </ActionButton>
      </DrawerHeader>
      <MainSideContent>
        <SliderContainer>
          {routes?.length > 0 && (
            <SaveRouteButton
              startIcon={<FolderIcon />}
              onClick={() => setOpenSaveRoute(true)}
            >
              Salvar Rota
            </SaveRouteButton>
          )}
          {routes?.length > 0
            ? routes.map((route, idx) => (
                <Card
                  key={route.id}
                  pos={idx}
                  timestampId={route.id}
                  filteredPublicEntities={filteredPublicEntities}
                />
              ))
            : !props.isUpdating && (
                <RadiusWarning>
                  <Typography>
                    <ExploreIcon />
                  </Typography>
                  <Typography variant="h7">
                    Você não possui nenhuma roteirização.
                  </Typography>
                </RadiusWarning>
              )}
          {routes?.length > 0 && (
            <ButtonContainer>
              <RemoveRouteButton onClick={handleDeleteRoutes}>
                <DeleteOutlineIcon />
                {" Remover Rotas"}
              </RemoveRouteButton>
            </ButtonContainer>
          )}
        </SliderContainer>
        <ButtonContainer>
          <OpenListButton
            startIcon={<ListAltIcon />}
            onClick={() => setOpenList(true)}
          >
            Abrir Lista de Rotas
          </OpenListButton>
        </ButtonContainer>
        <RouteListModal open={openList} closeRouteList={closeRouteList} />
        <SaveRouteModal open={openSaveRoute} closeSaveRoute={closeSaveRoute} />
      </MainSideContent>
      <SideFooter xs={1} component="div">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
}

DrawerRoutes = reduxForm({
  form: "INFO",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerRoutes);

const mapStateToProps = (state) => ({
  formFields: getFormValues("INFO")(state) ? getFormValues("INFO")(state) : [],
  submitError: state.routesAnalysis.fetchError,
  isLoading: state.routesAnalysis.loading,
  isUpdating: state.routesAnalysis.updating,
  menuOpen: state.menu.rotas,
  routes: state.crisisMode.routes.filter(({ crisisModule }) => !crisisModule),
  crisisModeUnits: state.crisisMode.units,
});

const mapDispatchToProps = {
  ...CrisisModeCreators,
  ...RouteAnalysisCreators,
  ...DrawingsCreators,
  ...menu,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerRoutes);
