import { enumLanguages } from "../../common/language";

const groupName = (groups, clipping) => {
  if (groups && clipping?.type?.clippingGroupId) {
    return groups[clipping?.type?.clippingGroupId]
      ? groups[clipping?.type?.clippingGroupId].name
      : null;
  }

  return null;
};

const clippingsPT = (clippings, groups) => {
  let builderClippings = [];

  if (clippings?.length)
    builderClippings = clippings.map((clipping) => {
      return {
        categoryId: clipping.category.id,
        groupId: clipping.type.clippingGroupId,
        groupName: groupName(groups, clipping),
        media: {
          id: clipping.links[0].media.mediaGroups.id,
          name: clipping.links[0].media.mediaGroups.groupName,
        },
        occurrenceTime: clipping.occurredAt,
        description: clipping.description,
        title: clipping.title,
        location: clipping.location,
        neighborhood: clipping.neighborhood,
        city: clipping.city,
        type: {
          ...clipping.type,
          type: clipping.type.type,
        },
      };
    });

  return builderClippings;
};
const groupNameEn = (groups, clipping) => {
  if (groups && clipping?.type?.clippingGroupId) {
    return groups[clipping?.type?.clippingGroupId]
      ? groups[clipping?.type?.clippingGroupId].name
      : null;
  }

  return null;
};

const clippingsEN = (clippings, groups) => {
  let builderClippings = [];

  if (clippings?.length)
    builderClippings = clippings.map((clipping) => {
      return {
        categoryId: clipping.category.id,
        groupId: clipping.type.clippingGroupId,
        groupName: groupNameEn(groups, clipping),
        media: {
          id: clipping.links[0].media.mediaGroups.id,
          name: clipping.links[0].media.mediaGroups.groupNameEn,
        },
        occurrenceTime: clipping.occurredAt,
        description: clipping.descriptionEn,
        title: clipping.titleEn,
        location: clipping.location,
        neighborhood: clipping.neighborhood,
        city: clipping.city,
        type: {
          ...clipping.type,
          type: clipping.type.typeEn,
        },
      };
    });

  return builderClippings;
};

export const clippingBuilder = {
  [enumLanguages.pt]: clippingsPT,
  [enumLanguages.en]: clippingsEN,
};

const incidentsPT = (incidents) => {
  let incidentsBuilder = [];

  if (incidents?.length) {
    incidentsBuilder = incidents.map((incident) => {
      return {
        categoryId: incident.type.categoryId,
        groupId: incident.type.id,
        media: {
          id: incident.links[0]?.id || 9999,
          name: incident.links[0]?.title || "Outras",
        },
        occurrenceTime: incident.startedAt,
        description: incident.description,
        title: incident.title,
        location: incident.location,
        type: {
          ...incident.type,
          type: incident.type.type,
        },
      };
    });
  }

  return incidentsBuilder;
};

const incidentsEN = (incidents) => {
  let incidentsBuilder = [];

  if (incidents?.length) {
    incidentsBuilder = incidents.map((incident) => {
      return {
        categoryId: incident.type.categoryId,
        groupId: incident.type.id,
        media: {
          id: incident.links[0]?.id || 9999,
          name: incident.links[0]?.title || "Others",
        },
        occurrenceTime: incident.startedAt,
        description: incident.descriptionEn,
        title: incident.titleEn,
        location: incident.location,
        type: {
          ...incident.type,
          type: incident.type.typeEn,
        },
      };
    });
  }

  return incidentsBuilder;
};

export const incidentsBuilder = {
  [enumLanguages.pt]: incidentsPT,
  [enumLanguages.en]: incidentsEN,
};
