import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import { Box, Divider } from "@mui/material";
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import {
  ExpansionPanelDetail,
  ExpasionPanelTitle,
  ListItemComponent,
  ExpansionHeading,
  MainSideContent,
  ActionContainer,
  ExpansionPanel,
  ExpandMoreIcon,
  ExpansionList,
  FormContainer,
  ActionButton,
  DrawerHeader,
  CustomSwitch,
  SearchButton,
  ClearButton,
  MainDrawer,
  SideFooter,
  FormTitle,
  PaperItem,
  TitleText,
  FornLabel,
  Progress,
  ItemText,
} from "./drawerCareStyles";

import InputDate from "../../common/InputDate";
import Autocomplete from "./Autocomplete";
import MultiSelect from "./MultiSelect";

import {
  getSites,
  getStatus,
  getRegionais,
  getRiskLevels,
  getOccurrences,
  getInstallations,
  getCriticalities,
  getIncidentTypes,
  getOccurrenceTypes,
  getInstallationTypes,
  getInstallationCategories,
} from "../../store/services/care";

import { Creators as FormActions } from "../../store/actions/menu";
import { Creators as CareActions } from "../../store/actions/care";

const intervalEnvTime = +(process.env.REACT_APP_CARE_UPDATE_TIME ?? "1");
const intervalTime = intervalEnvTime * 60000;

const DrawerCare = (props) => {
  const { menuOpen, animateMenu, setOccurrencesData, setInstallationsData } =
    props;

  const [filters, setFilters] = useState({
    searchOccurrences: false,
    searchInstallations: true,
  });
  const [loading, setLoading] = useState({});
  const [autoUpdate, setAutoUpdate] = useState(false);

  const [sites, setSites] = useState([]);
  const [status, setStatus] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [riskLevels, setRiskLeves] = useState([]);
  const [installations, setInstallations] = useState([]);
  const [criticalities, setCriticalities] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [occurrenceTypes, setOccurrenceTypes] = useState([]);
  const [installationTypes, setInstallationTypes] = useState([]);
  const [installarionsList, setInstallationsList] = useState([]);
  const [installationCategories, setInstallationCategories] = useState([]);

  useEffect(() => {
    const loadStatus = async () => {
      try {
        setLoading((state) => ({
          ...state,
          status: true,
        }));

        const { data } = await getStatus();

        setStatus(data);
      } catch {
        toast.error("Falha ao buscar status!");
      } finally {
        setLoading((state) => ({
          ...state,
          status: false,
        }));
      }
    };

    const loadRegionais = async () => {
      try {
        setLoading((state) => ({
          ...state,
          regionais: true,
        }));

        const { data } = await getRegionais();

        setRegionais(data);
      } catch {
        toast.error("Falha ao buscar as regionais!");
      } finally {
        setLoading((state) => ({
          ...state,
          regionais: false,
        }));
      }
    };

    const loadRisksLevels = async () => {
      try {
        setLoading((state) => ({
          ...state,
          riskLevels: true,
        }));

        const { data } = await getRiskLevels();

        setRiskLeves(data);
      } catch {
        toast.error("Falha ao buscar os níveis de risco");
      } finally {
        setLoading((state) => ({
          ...state,
          riskLevels: false,
        }));
      }
    };

    const loadCriticalities = async () => {
      try {
        setLoading((state) => ({
          ...state,
          criticalities: true,
        }));

        const { data } = await getCriticalities();

        setCriticalities(data);
      } catch {
        toast.error("Falha ao buscar as criticidades!");
      } finally {
        setLoading((state) => ({
          ...state,
          criticalities: false,
        }));
      }
    };

    const loadOccurrenceTypes = async () => {
      try {
        setLoading((state) => ({
          ...state,
          occurrenceTypes: true,
        }));

        const { data } = await getOccurrenceTypes();

        setOccurrenceTypes(data);
      } catch {
        toast.error("Falha ao buscar os tipos de ocorrências!");
      } finally {
        setLoading((state) => ({
          ...state,
          occurrenceTypes: false,
        }));
      }
    };

    const loadInstallationTypes = async () => {
      try {
        setLoading((state) => ({
          ...state,
          installationTypes: true,
        }));

        const { data } = await getInstallationTypes();

        setInstallationTypes(data);
      } catch {
        toast.error("Falha ao buscar tipos de instalações!");
      } finally {
        setLoading((state) => ({
          ...state,
          installationTypes: false,
        }));
      }
    };

    const loadInstallationCategories = async () => {
      try {
        setLoading((state) => ({
          ...state,
          installationCategories: true,
        }));

        const { data } = await getInstallationCategories();

        setInstallationCategories(data);
      } catch (e) {
        toast.error("Falha ao tentar buscar as categorias de intalações!");
      } finally {
        setLoading((state) => ({
          ...state,
          installationCategories: false,
        }));
      }
    };

    const setDate = () => {
      const today = new Date();
      const firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7,
        0,
        0,
        0
      );

      setFilters((state) => ({
        ...state,
        startDate: firstDay,
        endDate: today,
      }));
    };

    setDate();
    loadStatus();
    loadRegionais();
    loadRisksLevels();
    loadCriticalities();
    loadOccurrenceTypes();
    loadInstallationTypes();
    loadInstallationCategories();
  }, []);

  useEffect(() => {
    const loadSites = async () => {
      try {
        setLoading((state) => ({
          ...state,
          sites: true,
        }));

        const { data } = await getSites({ regionalId: filters.regionalId });

        setSites(data);
      } catch {
        toast.error("Falha ao buscar sites!");
      } finally {
        setLoading((state) => ({
          ...state,
          sites: false,
        }));
      }
    };

    if (!!filters.regionalId) {
      loadSites();
    }
  }, [filters.regionalId]);

  useEffect(() => {
    const loadSites = async () => {
      try {
        setLoading((state) => ({
          ...state,
          sitesFilters: true,
        }));

        const { data } = await getSites({
          regionalId: filters.installations.regionalId,
        });

        setSitesList(data);
      } catch {
        toast.error("Falha ao buscar sites!");
      } finally {
        setLoading((state) => ({
          ...state,
          sitesFilters: false,
        }));
      }
    };

    const loadInstallations = async (params) => {
      try {
        setLoading((state) => ({
          ...state,
          installationsFilters: true,
        }));

        const { data } = await getInstallations(params);

        setInstallationsList(data);
      } catch {
        toast.error("Falha ao buscar instalações!");
      } finally {
        setLoading((state) => ({
          ...state,
          installationsFilters: false,
        }));
      }
    };

    if (!!filters.installations?.regionalId) {
      loadSites();
    }

    if (filters.installations) {
      const { regionalId, siteId, installationCategoryId, installationTypeId } =
        filters.installations;

      if (regionalId && siteId) {
        const params = { regionalId, siteId };

        if (installationCategoryId) {
          Object.assign(params, { installationCategoryId });
        }

        if (installationTypeId) {
          Object.assign(params, { installationTypeId });
        }

        loadInstallations(params);
      }
    }
  }, [filters]);

  useEffect(() => {
    const loadIncidentTypes = async () => {
      try {
        setLoading((state) => ({
          ...state,
          incidentTypes: true,
        }));

        const { data } = await getIncidentTypes({
          occurrenceTypeId: filters.occurrenceTypeId,
        });

        setIncidentTypes(data);
      } catch {
        toast.error("Falha ao buscar os tipos de incidentes!");
      } finally {
        setLoading((state) => ({
          ...state,
          incidentTypes: false,
        }));
      }
    };

    if (filters.occurrenceTypeId > 0) {
      loadIncidentTypes();
    }
  }, [filters.occurrenceTypeId]);

  useEffect(() => {
    const {
      endDate,
      statusId,
      startDate,
      criticalityId,
      incidentTypeId,
      installationId,
      occurrenceTypeId,
      installations,
      searchOccurrences,
      searchInstallations,
      ...rest
    } = filters;

    const loadInstallations = async (params) => {
      try {
        setLoading((state) => ({
          ...state,
          installations: true,
        }));

        const { data } = await getInstallations(params);

        setInstallations(data);
      } catch {
        toast.error("Falha ao buscar instalações!");
      } finally {
        setLoading((state) => ({
          ...state,
          installations: false,
        }));
      }
    };

    if (filters.regionalId > 0 && filters.siteId > 0) {
      loadInstallations(rest);
    }
  }, [filters]);

  const handleClose = () => {
    animateMenu("OCULTAR_MENU_CARE");
  };

  const changeField = (field, value) => {
    setFilters((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const handleClick = async () => {
    await fetchData();

    setAutoUpdate(true);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading((state) => ({
        ...state,
        searchOccurrences: true,
      }));

      const {
        searchInstallations,
        searchOccurrences,
        installations,
        ...params
      } = filters;

      if (searchOccurrences && !!params?.regionalId) {
        const { data } = await getOccurrences(params);

        setOccurrencesData(data);
      }

      if (searchInstallations && !!installations?.regionalId) {
        const { data } = await getInstallations(installations);

        setInstallationsData(data);
      }
    } catch {
      toast.error("Falha ao buscar ocorrências do Care!");
    } finally {
      setLoading((state) => ({
        ...state,
        searchOccurrences: false,
      }));
    }
  }, [filters, setInstallationsData, setOccurrencesData]);

  useEffect(() => {
    let intervalId = undefined;

    if (autoUpdate) {
      intervalId = setInterval(async () => {
        await fetchData();
      }, intervalTime);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData, autoUpdate]);

  const resetFilters = () => {
    setFilters({ searchOccurrences: false, searchInstallations: true });
    setOccurrencesData([]);
    setInstallationsData([]);
  };

  const handleAutoComplete = (value, name) => {
    setFilters((previous) => ({
      ...previous,
      [name]: value?.id,
    }));
  };

  const handleAutoCompleteInstallation = (value, name) => {
    setFilters((previous) => ({
      ...previous,
      installations: {
        ...(previous.installations ?? {}),
        [name]: value?.id,
      },
    }));
  };

  const handleMultiSelect = (value, name) => {
    setFilters((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const handleMultiSelectInstallation = (value, name) => {
    setFilters((previous) => ({
      ...previous,
      installations: {
        ...(previous.installations ?? {}),
        [name]: value,
      },
    }));
  };

  const handleChecked = (event) => {
    const {
      target: { name, checked },
    } = event;

    setFilters((state) => ({
      ...state,
      [name]: Boolean(checked),
    }));
  };

  const disableSearch = () => {
    const {
      regionalId,
      installations,
      searchOccurrences,
      searchInstallations,
    } = filters;

    if (searchOccurrences && !searchInstallations && !!regionalId) {
      return false;
    }

    if (
      searchInstallations &&
      !searchOccurrences &&
      !!installations?.regionalId
    ) {
      return false;
    }

    if (
      searchOccurrences &&
      searchInstallations &&
      !!regionalId &&
      !!installations?.regionalId
    ) {
      return false;
    }

    return true;
  };

  return (
    <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
      <DrawerHeader component="div">
        <TitleText variant="h6" noWrap>
          Análise e Inteligência - Care
        </TitleText>
        <ActionButton onClick={handleClose}>
          <CloseIcon />
        </ActionButton>
      </DrawerHeader>

      <Divider />

      <MainSideContent component="div">
        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Tipos de Pesquisa</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        name="searchOccurrences"
                        checked={filters.searchOccurrences}
                        onClick={handleChecked}
                      />
                    }
                    label={<FornLabel>Ocorrências</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>
              </ExpansionList>

              <ExpansionList>
                <ListItemComponent>
                  <ItemText
                    control={
                      <CustomSwitch
                        color="secondary"
                        name="searchInstallations"
                        checked={filters.searchInstallations}
                        onClick={handleChecked}
                      />
                    }
                    label={<FornLabel>Instalações</FornLabel>}
                    labelPlacement="start"
                  />
                </ListItemComponent>
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Período</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <ExpansionList>
                <InputDate
                  name="startDate"
                  label="De"
                  value={filters.startDate}
                  changeField={changeField}
                />
              </ExpansionList>
              <ExpansionList>
                <InputDate
                  name="endDate"
                  label="Até"
                  value={filters.endDate}
                  changeField={changeField}
                />
              </ExpansionList>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Ocorrências</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Regional:
                </FormTitle>
                <MultiSelect
                  name="regionalId"
                  options={regionais}
                  onChange={handleMultiSelect}
                />
              </FormContainer>

              <FormContainer>
                <FormTitle variant="h6" noWrap>
                  Site:
                </FormTitle>
                {loading.sites && <Progress />}
                <Autocomplete
                  name="siteId"
                  data={sites}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Categorias de instalações:
                </FormTitle>
                {loading.installationCategories && <Progress />}
                <Autocomplete
                  name="installationCategoryId"
                  data={installationCategories}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Tipos de instalações:
                </FormTitle>
                {loading.installationTypes && <Progress />}
                <Autocomplete
                  name="installationTypeId"
                  data={installationTypes}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Nível de Risco:
                </FormTitle>
                {loading.riskLevels && <Progress />}
                <Autocomplete
                  name="riskLevelId"
                  data={riskLevels}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Ocorrência:
                </FormTitle>
                {loading.occurrenceTypes && <Progress />}
                <Autocomplete
                  name="occurrenceTypeId"
                  data={occurrenceTypes}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Incidente:
                </FormTitle>
                {loading.incidentTypes && <Progress />}
                <Autocomplete
                  name="incidentTypeId"
                  data={incidentTypes}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Criticidade:
                </FormTitle>
                {loading.criticalities && <Progress />}
                <Autocomplete
                  name="criticalityId"
                  data={criticalities}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Status:
                </FormTitle>
                {loading.status && <Progress />}
                <Autocomplete
                  name="statusId"
                  data={status}
                  onChange={handleAutoComplete}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Instalações:
                </FormTitle>
                {loading.installations && <Progress />}
                <Autocomplete
                  name="installationId"
                  data={installations}
                  onChange={handleAutoComplete}
                />
              </FormContainer>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <PaperItem>
          <ExpansionPanel>
            <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
              <ExpansionHeading>Instalações</ExpansionHeading>
            </ExpasionPanelTitle>
            <ExpansionPanelDetail>
              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Regional:
                </FormTitle>
                <MultiSelect
                  name="regionalId"
                  options={regionais}
                  onChange={handleMultiSelectInstallation}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Site:
                </FormTitle>
                {loading.sitesFilters && <Progress />}
                <Autocomplete
                  name="siteId"
                  data={sitesList}
                  onChange={handleAutoCompleteInstallation}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Categorias de instalações:
                </FormTitle>
                {loading.installationCategories && <Progress />}
                <Autocomplete
                  name="installationCategoryId"
                  data={installationCategories}
                  onChange={handleAutoCompleteInstallation}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Tipos de instalações:
                </FormTitle>
                {loading.installationTypes && <Progress />}
                <Autocomplete
                  name="installationTypeId"
                  data={installationTypes}
                  onChange={handleAutoCompleteInstallation}
                />
              </FormContainer>

              <FormContainer component="div">
                <FormTitle variant="h6" noWrap>
                  Instalações:
                </FormTitle>
                {loading.installationsFilters && <Progress />}
                <Autocomplete
                  name="installationId"
                  data={installarionsList}
                  onChange={handleAutoCompleteInstallation}
                />
              </FormContainer>
            </ExpansionPanelDetail>
          </ExpansionPanel>
        </PaperItem>

        <ActionContainer component="div">
          {loading.searchOccurrences && <Progress />}
          <SearchButton
            startIcon={<SearchIcon />}
            onClick={handleClick}
            disabled={disableSearch()}
          >
            Efetuar Pesquisa
          </SearchButton>
          <ClearButton startIcon={<DeleteIcon />} onClick={resetFilters}>
            Limpar Pesquisa
          </ClearButton>
        </ActionContainer>
      </MainSideContent>

      <SideFooter>
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

const mapStateToProps = (state) => ({
  menuOpen: state.menu.careIncidents,
});

const mapDispatchtoProps = {
  ...FormActions,
  ...CareActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerCare);
