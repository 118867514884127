import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Box, Typography } from "@mui/material";
import {
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";

import { Creators as MainCreators } from "../../store/actions/main";

import {
  mediasColors,
  periodColors,
  weekdaysColors,
  ocurrencesColor,
} from "./piePieceColors";
import Graphics from "./Graphic";
import InfoGroup from "./InfoGroup";
import Compare from "./Compare";
import Table from "./Table";
import { ocurrencesInPoligon } from "../../common/ocurrencesHelper";
import { enumLanguages } from "../../common/language";
import { language } from "./languageHelper";

import {
  MapIcon,
  CardText,
  CardIcon,
  MapButton,
  CardTitle,
  CardContent,
  CompareButton,
  CardInformation,
  GraphicsContainer,
  CompareArrowsIcon,
  GroupMainContainer,
  Card as CardComponent,
} from "./dashboardStyles";

function Card(props) {
  const { category, categoryEn } = props.category;
  const {
    occurrences,
    formFields,
    activateMap,
    occurrencesCompare,
    hideGraphics,
  } = props;

  const {
    weekday,
    dawn,
    night,
    sunday,
    seeMap,
    monday,
    friday,
    tuesday,
    morning,
    sources,
    thursday,
    saturday,
    afternoon,
    wednesday,
    timePeriod,
    comparative,
    highestOcurrence,
    ocurrences: ocurrencesText,
  } = language[formFields.language];

  const [ocurrences, setOcurrences] = useState([]);
  const [ocurrencesCompare, setOcurrencesCompare] = useState([]);
  const [mediasGroups, setMediasGroups] = useState([]);
  const [mediasGroupsCompare, setMediasGroupsCompare] = useState([]);
  const [periodGroups, setPeriodGroups] = useState([]);
  const [periodGroupsCompare, setPeriodGroupsCompare] = useState([]);
  const [weekdaysGrouped, setWeekdaysGrouped] = useState([]);
  const [weekdaysGroupedCompare, setWeekdaysGroupedCompare] = useState([]);
  const [showCompareMenu, setShowCompareMenu] = useState(false);
  const [ocurrencesFilters, setOcurrencesFilters] = useState([]);
  const [ocurrencesFiltersCompare, setOcurrencesFiltersCompare] = useState([]);
  const [mediasFilters, setMediasFilters] = useState([]);
  const [mediasFiltersCompare, setMediasFiltersCompare] = useState([]);
  const [timeFilters, setTimeFilters] = useState([]);
  const [timeFiltersCompare, setTimeFiltersCompare] = useState([]);
  const [weekdaysFilters, setWeekdaysFilters] = useState([]);
  const [weekdaysFiltersCompare, setWeekdaysFiltersCompare] = useState([]);

  useEffect(() => {
    if (verifyArray(occurrences)) {
      const { ocurrences } = ocurrencesInPoligon(occurrences);
      const datas = filterOccurrences(ocurrences);

      const groupedOccurrences = groupOccurrences(datas);
      setOcurrences(groupedOccurrences);

      const mediasOccurrences = groupMediasOccurrences(datas);
      setMediasGroups(mediasOccurrences);

      const periodOccurrences = groupByTime(datas);
      setPeriodGroups(periodOccurrences);

      const groupedWeekdays = groupByWeekDays(datas);
      setWeekdaysGrouped(groupedWeekdays);
    }
  }, [
    occurrences,
    ocurrencesFilters,
    mediasFilters,
    timeFilters,
    weekdaysFilters,
  ]);

  const filterOccurrences = (occurrences) => {
    let datas = [];
    datas = occurrences.filter(
      ({ categoryId }) => categoryId === props.category.id
    );

    datas =
      ocurrencesFilters.length > 0
        ? datas.filter(({ groupId }) => ocurrencesFilters.includes(groupId))
        : datas;

    datas =
      mediasFilters.length > 0
        ? datas.filter(({ media }) => mediasFilters.includes(media.name))
        : datas;

    datas =
      timeFilters.length > 0
        ? datas.filter(({ occurrenceTime }) =>
            timeFilters.includes(verifyPeriod(occurrenceTime))
          )
        : datas;

    datas =
      weekdaysFilters.length > 0
        ? datas.filter(({ occurrenceTime }) =>
            weekdaysFilters.includes(verifyWeekday(occurrenceTime))
          )
        : datas;

    return datas;
  };

  useEffect(() => {
    if (verifyArray(occurrencesCompare)) {
      const datas = filterOccurrencesCompare(occurrencesCompare);

      const groupedOccurrences = groupOccurrences(datas);
      setOcurrencesCompare(groupedOccurrences);

      const formatedMediaOccurrences = groupMediasOccurrences(datas);
      setMediasGroupsCompare(formatedMediaOccurrences);

      const formatedPeriodGroups = groupByTime(datas);
      setPeriodGroupsCompare(formatedPeriodGroups);

      const groupedWeekdays = groupByWeekDays(datas);
      setWeekdaysGroupedCompare(groupedWeekdays);
    }

    if (!occurrencesCompare) {
      setOcurrencesCompare([]);
      setMediasGroupsCompare([]);
      setPeriodGroupsCompare([]);
      setWeekdaysGroupedCompare([]);
    }
  }, [
    occurrencesCompare,
    ocurrencesFiltersCompare,
    mediasFiltersCompare,
    timeFiltersCompare,
    weekdaysFiltersCompare,
  ]);

  const filterOccurrencesCompare = (occurences) => {
    let datas = [];
    datas = occurences.filter(
      ({ categoryId }) => categoryId === props.category.id
    );

    datas =
      ocurrencesFiltersCompare.length > 0
        ? datas.filter(({ groupId }) =>
            ocurrencesFiltersCompare.includes(groupId)
          )
        : datas;

    datas =
      mediasFiltersCompare.length > 0
        ? datas.filter(({ media }) => mediasFiltersCompare.includes(media.name))
        : datas;

    datas =
      timeFiltersCompare.length > 0
        ? datas.filter(({ occurrenceTime }) =>
            timeFiltersCompare.includes(verifyPeriod(occurrenceTime))
          )
        : datas;

    datas =
      weekdaysFiltersCompare.length > 0
        ? datas.filter(({ occurrenceTime }) =>
            weekdaysFiltersCompare.includes(verifyWeekday(occurrenceTime))
          )
        : datas;

    return datas;
  };

  const groupOccurrences = (datas) => {
    let groupedOccurrences = [];

    datas.forEach((occurrence) => {
      const index = groupedOccurrences
        .map(({ id }) => id)
        .indexOf(occurrence.groupId);

      if (index < 0) {
        groupedOccurrences.push({
          id: occurrence.groupId,
          name: occurrence.groupName || occurrence.type.type,
          occurrences: [occurrence],
        });
      } else {
        groupedOccurrences[index].occurrences.push(occurrence);
      }
    });

    if (groupedOccurrences.length === 1) {
      const newDatas = [];
      groupedOccurrences[0].occurrences.forEach((occurrence) => {
        const newIndex = newDatas
          .map(({ id }) => id)
          .indexOf(occurrence.type.id);

        if (newIndex < 0) {
          newDatas.push({
            id: occurrence.type.id,
            groupId: occurrence.groupId,
            name: occurrence.type.type,
            occurrences: [occurrence],
          });
        } else {
          newDatas[newIndex].occurrences.push(occurrence);
        }
      });

      groupedOccurrences = newDatas;
    }

    return groupedOccurrences
      .sort((a, b) => b.occurrences.length - a.occurrences.length)
      .map((occurrence) => {
        return {
          id: occurrence.id,
          groupId: occurrence.groupId,
          type: "ocurrences",
          name: occurrence.name,
          value: occurrence.occurrences.length,
          items: occurrence.occurrences,
        };
      });
  };

  const groupMediasOccurrences = (datas) => {
    let groupedItems = [];

    datas.forEach(({ media }) => {
      const index = groupedItems.map(({ id }) => id).indexOf(media.id);

      if (index < 0) {
        groupedItems.push({
          id: media.id,
          name: media.name,
          medias: [media],
        });
      } else {
        groupedItems[index].medias.push(media);
      }
    });

    return groupedItems
      .sort((a, b) => b.medias.length - a.medias.length)
      .map((media) => {
        return {
          type: "media",
          name: media.name,
          value: media.medias.length,
        };
      });
  };

  const groupByTime = (datas) => {
    const grupedItems = [
      { name: dawn, items: [] },
      { name: morning, items: [] },
      { name: afternoon, items: [] },
      { name: night, items: [] },
    ];

    datas.forEach(({ occurrenceTime }) => {
      const index = verifyPeriod(occurrenceTime);

      grupedItems[index].items.push(occurrenceTime);
    });

    return grupedItems.map((item, index) => {
      return {
        type: "time",
        index,
        name: item.name,
        value: item.items.length,
      };
    });
  };

  const groupByWeekDays = (datas) => {
    const groupedItems = [
      { name: sunday, items: [] },
      { name: monday, items: [] },
      { name: tuesday, items: [] },
      { name: wednesday, items: [] },
      { name: thursday, items: [] },
      { name: friday, items: [] },
      { name: saturday, items: [] },
    ];

    datas.forEach(({ occurrenceTime }) => {
      const index = verifyWeekday(occurrenceTime);

      groupedItems[index].items.push(occurrenceTime);
    });

    return groupedItems.map(({ name, items }, index) => {
      return {
        type: "weekdays",
        index,
        name,
        value: items.length,
      };
    });
  };

  const verifyPeriod = (time) => {
    const value = stringfyTime(time);
    if (value < 60000) {
      return 0;
    } else if (value >= 60000 && value <= 115959) {
      return 1;
    } else if (value >= 120000 && value <= 185959) {
      return 2;
    } else {
      return 3;
    }
  };

  const verifyWeekday = (occurrenceTime) => {
    const date = new Date(occurrenceTime);

    return date.getDay();
  };

  const stringfyTime = (date) => {
    let formatedDate = "0";
    const value = new Date(date).toLocaleString("pt-BR");

    const [, time] = value.split(" ");
    const [hour, minute, second] = time.split(":");

    formatedDate += hour;
    formatedDate += minute;
    formatedDate += second;

    return Number(formatedDate);
  };

  const verifyArray = (array) => {
    if (Array.isArray(array) && array.length > 0) return true;

    return false;
  };

  const addFilter = (row, compareGraphic) => {
    filterProvider[row.type] && filterProvider[row.type](row, compareGraphic);
  };

  const ocurrenceProvider = (row, compareGraphic) => {
    const value = row.groupId || row.id;
    compareGraphic
      ? setOcurrencesFiltersCompare(
          ocurrencesFiltersCompare.length > 0 ? [] : [value]
        )
      : setOcurrencesFilters(ocurrencesFilters.length > 0 ? [] : [value]);
  };

  const mediaProvider = (row, compareGraphic) => {
    compareGraphic
      ? setMediasFiltersCompare(
          mediasFiltersCompare.length > 0 ? [] : [row.name]
        )
      : setMediasFilters(mediasFilters.length > 0 ? [] : [row.name]);
  };

  const timeProvider = (row, compareGraphic) => {
    compareGraphic
      ? setTimeFiltersCompare(timeFiltersCompare.length > 0 ? [] : [row.index])
      : setTimeFilters(timeFilters.length > 0 ? [] : [row.index]);
  };

  const weekdaysProvider = (row, compareGraphic) => {
    compareGraphic
      ? setWeekdaysFiltersCompare(
          weekdaysFiltersCompare.length > 0 ? [] : [row.index]
        )
      : setWeekdaysFilters(weekdaysFilters.length > 0 ? [] : [row.index]);
  };

  const filterProvider = {
    ocurrences: ocurrenceProvider,
    media: mediaProvider,
    time: timeProvider,
    weekdays: weekdaysProvider,
  };

  const highestOccurrence = () => {
    if (verifyArray(periodGroups)) {
      const [ref] = periodGroups
        .map(({ value }) => value)
        .sort((a, b) => b - a);

      const { name } = periodGroups.find(({ value }) => value === ref);

      return name;
    }
    return;
  };

  const categoryTitle = () => {
    if (formFields.language === enumLanguages.pt) {
      return category;
    }

    if (formFields.language === enumLanguages.en) {
      return categoryEn;
    }
  };

  return (
    <Box component="div">
      <CardComponent component="div">
        <CardIcon />
        <CardContent component="div">
          <Box component="div">
            <CardTitle variant="h3">{categoryTitle()}</CardTitle>
            <CardInformation component="div">
              <CardText component="span">
                <TrendingUpIcon />
                <Typography
                  component="span"
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  <strong>{highestOcurrence}:</strong> {ocurrences[0]?.name}
                </Typography>
              </CardText>
              <CardText component="span">
                <AccessTimeIcon />
                <Typography
                  component="span"
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  <strong>{timePeriod}: </strong> {highestOccurrence()}
                </Typography>
              </CardText>
            </CardInformation>
          </Box>
          <Box component="div">
            <CompareButton
              startIcon={<CompareArrowsIcon />}
              onClick={() => setShowCompareMenu(!showCompareMenu)}
            >
              {comparative}
            </CompareButton>
            <MapButton startIcon={<MapIcon />} onClick={activateMap}>
              {seeMap}
            </MapButton>
          </Box>
        </CardContent>
      </CardComponent>
      {verifyArray(ocurrences) && (
        <GroupMainContainer component="div">
          {ocurrences.map((ocurrence, index) => (
            <InfoGroup
              key={`occurrence-group-${index}`}
              ocurrence={ocurrence}
              addFilter={addFilter}
            />
          ))}
        </GroupMainContainer>
      )}
      <GraphicsContainer component="div">
        {verifyArray(ocurrences) && (
          <Graphics
            datas={ocurrences}
            title={ocurrencesText}
            colors={ocurrencesColor}
            addFilter={addFilter}
          />
        )}
        {verifyArray(mediasGroups) && !hideGraphics && (
          <Graphics
            datas={mediasGroups}
            title={sources}
            colors={mediasColors}
            addFilter={addFilter}
          />
        )}
        {verifyArray(periodGroups) && (
          <Graphics
            datas={periodGroups}
            title={timePeriod}
            colors={periodColors}
            addFilter={addFilter}
          />
        )}
        {verifyArray(weekdaysGrouped) && (
          <Graphics
            datas={weekdaysGrouped}
            title={weekday}
            colors={weekdaysColors}
            addFilter={addFilter}
          />
        )}
      </GraphicsContainer>
      <Table ocurrences={ocurrences} category={category} />
      {showCompareMenu && [
        <Compare />,
        <GraphicsContainer component="div">
          {verifyArray(ocurrencesCompare) && (
            <Graphics
              datas={ocurrencesCompare}
              title={ocurrencesText}
              colors={ocurrencesColor}
              addFilter={addFilter}
              compareGraphic={true}
            />
          )}
          {verifyArray(mediasGroupsCompare) && !hideGraphics && (
            <Graphics
              datas={mediasGroupsCompare}
              title={sources}
              colors={mediasColors}
              addFilter={addFilter}
              compareGraphic={true}
            />
          )}
          {verifyArray(periodGroupsCompare) && (
            <Graphics
              datas={periodGroupsCompare}
              title={timePeriod}
              colors={periodColors}
              addFilter={addFilter}
              compareGraphic={true}
            />
          )}
          {verifyArray(weekdaysGroupedCompare) && (
            <Graphics
              datas={weekdaysGroupedCompare}
              title={weekday}
              colors={weekdaysColors}
              addFilter={addFilter}
              compareGraphic={true}
            />
          )}
        </GraphicsContainer>,
      ]}
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("SETTINGS")(state)
      ? getFormValues("SETTINGS")(state)
      : [],
  };
};

const mapDispatchToProps = {
  ...MainCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
