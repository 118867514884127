export const Types = {
  SET_OCCURRENCES: "care/SET_OCCURRENCES",
  SET_INSTALLATIONS: "care/SET_INSTALLATIONS",
};

export const Creators = {
  setOccurrencesData: (data) => ({
    type: Types.SET_OCCURRENCES,
    payload: data,
  }),
  setInstallationsData: (data) => ({
    type: Types.SET_INSTALLATIONS,
    payload: data,
  }),
};
