import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";

import { Divider } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  ItemText,
  PaperItem,
  TitleText,
  CustomList,
  MainDrawer,
  SideFooter,
  CustomSwitch,
  DrawerHeader,
  ActionButton,
  CustomListItem,
  MainSideContent,
  CustomFormLabel,
} from "./drawerClusterStyles";

import NewInputSwitch from "../../common/NewInputSwitch";

import { Creators as UnitsAreas } from "../../store/actions/unitsAreas";
import { Creators as FormActions } from "../../store/actions/menu";

class DrawerClusters extends Component {
  state = {
    allChecked: true,
    showCustomerUnits: this.props.showCustomerUnits,
    customerUnits: this.props.userData ? this.props.userData.units : [],
  };

  showCustomerUnits = () => {
    if (!this.props.customerUnitsAreas.length) {
      this.props.getCustomerUnitsAreas({
        ids: this.state.customerUnits.map((uc) => uc.area_id),
      });
    }
    this.setState({ showCustomerUnits: !this.state.showCustomerUnits });
    this.props.showUnitsLimits();
  };

  getCustomerUnitsIds = () => {
    return this.state.customerUnits.map(({ area_id }) => Number(area_id));
  };

  allUnits = () => {
    this.setState({ allChecked: !this.state.allChecked });

    if (!this.state.allChecked) {
      this.props.change("Units", this.getCustomerUnitsIds());
    } else {
      this.props.change("Units", undefined);
    }
  };

  getAllCheckedState = () => {
    return this.state.allChecked;
  };

  componentDidMount() {
    this.props.change("Units", this.getCustomerUnitsIds());
  }

  render() {
    const { formFields } = this.props;

    return (
      <MainDrawer variant="persistent" anchor="left" open={this.props.menuOpen}>
        <DrawerHeader>
          <TitleText variant="h6" noWrap>
            Unidades de usuário
          </TitleText>
          <ActionButton onClick={() => this.props.animateMenu("FECHA_MENU")}>
            <CloseIcon />
          </ActionButton>
        </DrawerHeader>
        <Divider />
        <MainSideContent component="div">
          <PaperItem>
            <CustomList>
              <CustomListItem key="showCustomerUnits">
                <ItemText
                  control={
                    <CustomSwitch
                      color="secondary"
                      name="showCustomerUnits"
                      disabled={!formFields.Units}
                      onClick={this.showCustomerUnits}
                      checked={this.state.showCustomerUnits}
                    />
                  }
                  label={
                    <CustomFormLabel>
                      Exibir Limites das unidades
                    </CustomFormLabel>
                  }
                  labelPlacement="start"
                />
              </CustomListItem>
            </CustomList>
          </PaperItem>
          <PaperItem>
            <CustomList>
              <div key="Units-All">
                <CustomListItem key="Units">
                  <ItemText
                    control={
                      <CustomSwitch
                        name="Units"
                        color="secondary"
                        defaultChecked={true}
                        onClick={this.allUnits}
                        checked={this.state.allChecked}
                      />
                    }
                    label={<CustomFormLabel>Todos</CustomFormLabel>}
                    labelPlacement="start"
                  />
                </CustomListItem>
                <Divider />
              </div>
              {this.state.customerUnits.map((uc, idx) => (
                <div key={"customer-units - " + idx + uc.id}>
                  <NewInputSwitch
                    key={"customer-units - " + idx + uc.id}
                    disabled={this.state.allChecked && true}
                    value={Number(uc.area_id)}
                    label={uc.unit_name}
                    uncheck={this.state.allChecked}
                  >
                    {() => this.getAllCheckedState}
                  </NewInputSwitch>
                  <Divider />
                </div>
              ))}
            </CustomList>
          </PaperItem>
        </MainSideContent>
        <SideFooter component="div" xs={1}>
          <span>
            Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
          </span>
        </SideFooter>
      </MainDrawer>
    );
  }
}

const mapDispatchtoProps = {
  ...FormActions,
  ...UnitsAreas,
};

DrawerClusters = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerClusters);

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menu.clusters,
    customerUnitsAreas: state.customerUnitsAreas.customerUnitsAreas,
    formFields: getFormValues("ANALISE")(state),
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerClusters);
