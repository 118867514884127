import axios from "./api";

export const getUser = () => {
  return axios.request({
    headers: {
      "x-application-id": 50,
    },
    url: process.env.REACT_APP_PASSPORT_URL + "api/self",
    method: "GET",
  });
};
