import {
  Map,
  GetApp,
  Search,
  FilterList,
  CompareArrows,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  AppBar,
  Button,
  styled,
  Toolbar,
  Typography,
  FormControl,
  Select,
  CircularProgress,
  TextField,
  TableCell,
} from "@mui/material";

export const MainAppBar = styled(AppBar)(() => ({
  position: "absolute",
  minHeight: "100%",
}));

export const FlexBox = styled(Box)(() => ({
  display: "flex",
}));

export const ToolbarContainer = styled(Toolbar)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));

export const LanguageLogo = styled("img")(() => ({
  borderRadius: "5px",
  height: "20px",
  width: "20px",
}));

export const Card = styled(Box)(() => ({
  background: "#000",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "20px 0",
  borderRadius: "15px 16px 0 0",
  margin: "20px 20px 0",
}));

export const CardIcon = styled(Box)(() => ({
  width: "40px",
  height: "110px",
  borderRadius: "0 10px 10px 0",
  background: "rgb(28, 164, 149)",
  marginRight: "15px",
}));

export const CardContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  width: "95%",
}));

export const CardTitle = styled(Typography)(() => ({
  color: "#fff",
  margin: "10px 0",
  fontWeight: "400",
}));

export const CardInformation = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  margin: "10px 0",
}));

export const CardText = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginRight: "30px",
  "& svg": {
    fontSize: "3rem",
    marginRight: "5px",
  },
}));

export const CompareButton = styled(Button)(() => ({
  fontSize: "1rem",
  color: "#fff",
}));

export const MapButton = styled(Button)(() => ({
  background: "#ffff",
  color: "#000000",
  fontSize: "0.9rem",
  margin: "0 15px",
  height: "40px",
  width: "150px",
  paddingLeft: "20px",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "dimgray",
    color: "#ffff",
  },
}));

export const CompareArrowsIcon = styled(CompareArrows)(() => ({
  fontSize: "3.5rem",
  marginRight: "10px",
}));

export const MapIcon = styled(Map)(() => ({
  fontSize: "2rem",
  marginRight: "10px",
}));

export const GroupMainContainer = styled(Box)(() => ({
  display: "flex",
  background: "dimgray",
  justifyContent: "space-around",
  flexWrap: "wrap",
  padding: "10px 0",
  margin: "0 20px",
}));

export const GraphicsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  background: "#343434",
  margin: "0 20px",
}));

export const GroupContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(55, 55, 55, 0.5)",
  borderRadius: "5px",
  margin: "5px",
  width: "200px",
  height: "60px",
  cursor: "pointer",
}));

export const FloatGroupDescription = styled(Box, {
  shouldForwardProp: (prop) => prop !== "display",
})(({ display }) => ({
  display,
  position: "absolute",
  marginTop: "-80px",
  maxWidth: "150px",
  backgroundColor: "rgba(195, 195, 195, 0.75)",
  color: "#000",
  textAlign: "center",
  padding: "0.5px 2.8px",
  borderRadius: "6px",
  border: "none",
  pointerEvents: "none",
}));

export const GroupDescription = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  color: "#fff",
  padding: "5px 0",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "25ch",
  whiteSpace: "nowrap",
  cursor: "pointer",
}));

export const GroupQuantity = styled(Typography)(() => ({
  color: "white",
  fontSize: "1.7rem",
  padding: "5px",
  margin: "0 5px",
  fontWeight: "bold",
}));

export const GroupName = styled(Typography)(() => ({
  overflow: "hidden",
  maxWidth: "110px",
  textOverflow: "ellipsis",
}));

export const GraphicBox = styled(Box)(() => ({
  background: "#343434",
  paddingTop: "20px",
}));

export const GraphicTitle = styled(Typography)(() => ({
  marginLeft: "75px",
}));

export const GraphicLegend = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const GraphicTotal = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "250px",
  height: "250px",
  fontSize: "2rem",
}));

export const LegendItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "2px",
}));

export const LegendIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background",
})(({ background }) => ({
  background,
  width: "20px",
  height: "20px",
  borderRadius: "5px",
  margin: "2px 8px",
}));

export const LegendText = styled(Box)(() => ({
  fontSize: "0.8rem",
  cursor: "pointer",
  maxWidth: "200px",
}));

export const CompareContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  background: "#fff",
  padding: "0 20px",
  margin: "0 20px",
}));

export const CompareText = styled(Typography)(() => ({
  color: "#343434",
  margin: "5px",
}));

export const CustomFormControl = styled(FormControl)(() => ({
  padding: 0,
  margin: "5px 0",
  "& fieldset": {
    border: "none",
  },
}));

export const SelectPeriod = styled(Select)(() => ({
  background: "#c4c4c4",
  color: "black",
  border: "white solid 1px",
  width: "185px",
  borderRadius: "5px",
  padding: "0",
  margin: "10px 0",
  fontWeight: "500",
  textAlign: "center",
}));

export const SelectFilterContainer = styled(Box)(() => ({
  display: "flex",
  margin: "0 15px",
  alignItems: "center",
  "& *": {
    color: "#000",
  },
}));

export const SearchButton = styled(Button)(() => ({
  background: "#1CA495",
  color: "#000000",
  fontSize: "1rem",
  margin: "0 15px",
  height: "50px",
  paddingLeft: "20px",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "#10756a",
    color: "#ffff",
  },
}));

export const ResetFiltersButton = styled(Button)(() => ({
  background: "#c4c4c4",
  color: "#000000",
  fontSize: "0.9rem",
  margin: "0 15px",
  height: "50px",
  paddingLeft: "20px",

  transition: "all .3s linear",
  "&:hover": {
    backgroundColor: "dimgray",
    color: "#ffff",
  },
}));

export const SearchIcon = styled(Search)(() => ({
  fontSize: "2.5rem",
}));

export const FilterListIcon = styled(FilterList)(() => ({
  fontSize: "2.5rem",
}));

export const Progress = styled(CircularProgress)(() => ({
  margin: "0 15px",
  height: "30px !important",
  width: "30px !important",
}));

export const TableContainer = styled(Box)(() => ({
  background: "#343434",
  margin: "0 20px",
}));

export const ActionContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const KeyboardArrowDownIcon = styled(KeyboardArrowDown)(() => ({
  color: "#fff",
}));

export const KeyboardArrowRightIcon = styled(KeyboardArrowRight)(() => ({
  color: "#fff",
}));

export const FilterTextField = styled(TextField)(() => ({
  margin: "20px",
  minWidth: "400px",

  "& fieldset": {
    border: "none",
  },

  "& label": {
    color: "#fff !important",
    marginBottom: "3px",
    fontSize: "14px",
  },

  "& div": {
    marginTop: "5px",
  },

  "& input": {
    color: "#fff",
    height: "30px",
    border: "#757575 solid 0.25px",
  },
}));

export const ContainerTable = styled(Box, {
  shouldForwardProp: (prop) => prop !== "visible",
})(({ visible }) => ({
  marginLeft: visible ? "0" : "-300%",
  transition: "margin 1s linear",
}));

export const TextHeader = styled(TableCell)(() => ({
  fontSize: "1.2rem",
  fontWeight: "500",
  color: "#fff",
}));

export const TextCell = styled(TableCell)(() => ({
  fontSize: "1rem",
  color: "#fff",
}));

export const GetAppIcon = styled(GetApp)(() => ({
  color: "#fff",
}));

export const FilterContainer = styled(Box)(() => ({
  "& *": {
    color: "#fff",
  },
}));
