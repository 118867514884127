import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import { Box, Collapse, IconButton } from "@mui/material";
import StarRatings from "react-star-ratings";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import {
  AlertPaper,
  IncidentItem,
  ReportHeader,
  ItemContainer,
  ImageContainer,
  ReportContainer,
  IncidentTextData,
  IncidentTextTitle,
  IncidentTextEvent,
  InfoWindowComponent,
  IncidentTitleContainer,
} from "./styles";

const CustomMarker = (props) => {
  const {
    marker: {
      id,
      type,
      title,
      links,
      active,
      reports,
      location,
      startedAt,
      grounding,
      credibility,
      description,
    },
    clusterer,
  } = props;

  const options = { hour: "2-digit", minute: "2-digit" };

  const [isOpen, setIsOpen] = useState(false);
  const [hubReportsOpen, setHubReportsOpen] = useState(false);

  const onToggleOpen = () => {
    setIsOpen((previous) => !previous);
  };

  const hubReportsOnToggle = (event) => {
    setHubReportsOpen((previous) => !previous);
    event.stopPropagation();
  };

  const renderLinks = (links) => {
    if (links.length > 0) {
      return (
        <ul>
          <li style={{ fontWeight: "bold" }}>Links</li>
          <ul>
            {links.map((link, index) => (
              <li key={`report-link-${index}`}>
                {!typeof link === "object" ? (
                  <a href={link} target="_blank">
                    {link}
                  </a>
                ) : (
                  <a href={link.url} target="_blank">
                    {link.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </ul>
      );
    }
  };

  const renderReports = (reports) => {
    const remains = reports.filter((item) => item.status === "remains");
    const ended = reports.filter((item) => item.status === "ended");

    return (
      <div>
        {remains.length > 0 && (
          <ul>
            <li style={{ fontWeight: "bold" }}>Permanência</li>
            <ul>
              {remains.map((report, index) => {
                return (
                  <div>
                    <li key={`report-${index}`}>{report.description}</li>
                    {renderLinks(report.links)}
                  </div>
                );
              })}
            </ul>
          </ul>
        )}
        {ended.length > 0 && (
          <ul>
            <li style={{ fontWeight: "bold" }}>Normalização</li>
            <ul>
              {ended.map((report, index) => {
                return (
                  <div>
                    <li key={`report-${index}`}>{report.description}</li>
                    {renderLinks(report.links)}
                  </div>
                );
              })}
            </ul>
          </ul>
        )}
      </div>
    );
  };

  let events = {
    onClick: () => onToggleOpen(),
  };

  const formatedStartedAt = new Date(startedAt);
  formatedStartedAt.setHours(formatedStartedAt.getHours() + 3);

  return (
    <Marker
      noRedraw
      title={title}
      key={id}
      position={location}
      icon={{ url: type.icon, optimized: false }}
      clusterer={clusterer}
      {...events}
    >
      {isOpen && (
        <InfoWindowComponent
          options={{ maxWidth: 500 }}
          onCloseClick={onToggleOpen}
        >
          <AlertPaper {...events} color="primary">
            <IncidentItem component="div">
              <Box style={{ marginBottom: "20px" }}>
                <Box component="div" style={{ float: "right" }}>
                  <label>Credibilidade: </label>
                  <StarRatings
                    rating={credibility}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="yellow"
                  />
                </Box>
              </Box>

              <Box style={{ marginBottom: "40px" }}>
                <ImageContainer border={1}>
                  <img src={type.icon} alt="Ícone" />
                </ImageContainer>

                <IncidentTitleContainer>
                  <IncidentTextTitle primary={title} />
                </IncidentTitleContainer>
              </Box>
              <ItemContainer border={1}>
                <IncidentTextEvent primary="Evento:" secondary={description} />
                {active ? (
                  <IncidentTextData
                    primary="Data:"
                    secondary={formatedStartedAt.toLocaleDateString(
                      "pt-BR",
                      options
                    )}
                  />
                ) : (
                  <IncidentTextData
                    primary="Data:"
                    secondary={formatedStartedAt.toLocaleDateString(
                      "pt-BR",
                      options
                    )}
                  />
                )}
                {type && (
                  <IncidentTextData
                    primary="Tipo de evento:"
                    secondary={type["type"]}
                  />
                )}
              </ItemContainer>
              <ReportHeader border={1}>
                <Box component="span">HUB Reports</Box>

                <IconButton onClick={(event) => hubReportsOnToggle(event)}>
                  {hubReportsOpen ? (
                    <RemoveCircle size={17} />
                  ) : (
                    <AddCircle size={17} />
                  )}
                </IconButton>
              </ReportHeader>
              <Collapse in={hubReportsOpen} collapsedHeight="47px">
                <ReportContainer border={1}>
                  <Box style={{ padding: "5px" }}>
                    <ul>
                      <li style={{ fontWeight: "bold" }}>Fundamentação</li>
                      <ul>
                        <li>{grounding.replace(/<br\s*\/?>/gm, "")}</li>
                      </ul>
                      {renderLinks(links)}
                    </ul>
                    {reports.length > 0 && renderReports(reports)}
                  </Box>
                </ReportContainer>
              </Collapse>
            </IncidentItem>
          </AlertPaper>
        </InfoWindowComponent>
      )}
    </Marker>
  );
};

export default CustomMarker;
