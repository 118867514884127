import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Box,
  Divider,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import {
  ExpansionPanelDetail,
  ExpasionPanelTitle,
  ProviderContainer,
  ExpansionHeading,
  ChevronLeftIcon,
  MainSideContent,
  ExpandMoreIcon,
  ExpansionPanel,
  ExpansionList,
  DrawerHeader,
  MainDrawer,
  SideFooter,
  FormLabel,
  PaperItem,
  TitleText,
} from "./drawerAnaliseStyles";
import { orderArray } from "../../common/Utils";

import { Creators as FormActions } from "../../store/actions/menu";

let DrawerAvancadas = ({
  state,
  array,
  change,
  menuOpen,
  categories,
  animateMenu,
}) => {
  const toggleType = (id) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "clipping_type_id")
      ? selector(state, "clipping_type_id")
      : [];

    if (!value.includes(id)) {
      array.push("clipping_type_id", id);
    } else {
      const newValue = value.filter((e) => e !== id);
      change("clipping_type_id", newValue.length > 0 ? newValue : undefined);
    }
  };

  const toggleGroupType = (e, tipo) => {
    e.stopPropagation();
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "clipping_type_id")
      ? selector(state, "clipping_type_id")
      : [];

    const newValues = tipo.types.map(({ id }) => id);
    const updateValues = value.filter(
      (reduxItem) => !newValues.includes(reduxItem)
    );

    e.target.checked
      ? change(
          "clipping_type_id",
          newValues.length > 0 ? [...value, ...newValues] : value
        )
      : change(
          "clipping_type_id",
          updateValues.length > 0 ? updateValues : undefined
        );
  };

  const groupTypeChecked = (tipo) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "clipping_type_id")
      ? selector(state, "clipping_type_id")
      : [];

    const ids = tipo.types.map(({ id }) => id);
    const selectedItems = value.filter((reduxItem) => ids.includes(reduxItem));

    return selectedItems.length === ids.length && selectedItems.length > 0;
  };

  const groupTypeIndeterminate = (tipo) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "clipping_type_id")
      ? selector(state, "clipping_type_id")
      : [];

    const ids = tipo.types.map(({ id }) => id);
    const selectedItems = value.filter((reduxItem) => ids.includes(reduxItem));

    return selectedItems.length > 0 && selectedItems.length !== ids.length;
  };

  const renderGroupedFormItem = (tipo, categories) => {
    return (
      <ExpansionPanel>
        <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={(e) => toggleGroupType(e, tipo)}
                checked={groupTypeChecked(tipo)}
                indeterminate={groupTypeIndeterminate(tipo)}
                name="clipping_type_id"
              />
            }
            label={<FormLabel>{tipo.group}</FormLabel>}
          />
        </ExpasionPanelTitle>
        <ExpansionPanelDetail>
          <ExpansionList>
            {tipo.types.map((type) => renderGroupedItem(type, categories))}
          </ExpansionList>
        </ExpansionPanelDetail>
      </ExpansionPanel>
    );
  };

  const renderGroupedItem = (type, categories) => {
    const selector = formValueSelector("ANALISE");
    const value = selector(state, "clipping_type_id")
      ? selector(state, "clipping_type_id")
      : [];

    return (
      <Box
        component="div"
        key={`categories-${type.type}`}
        style={{ margin: "5px 0 5px 10px" }}
      >
        <ExpansionList key="clipping_type_id">
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={() => toggleType(type.id)}
                checked={value.includes(type.id)}
                name="clipping_type_id"
              />
            }
            label={<FormLabel>{type.type}</FormLabel>}
          />
        </ExpansionList>
        <Divider />
      </Box>
    );
  };

  return (
    <MainDrawer variant="persistent" anchor="left" open={menuOpen}>
      <DrawerHeader>
        <TitleText variant="h6" noWrap>
          <IconButton onClick={() => animateMenu("OCULTA_AVANCADAS_CLIPPINGS")}>
            <ChevronLeftIcon />
          </IconButton>
          Voltar
        </TitleText>
      </DrawerHeader>

      <Divider />

      <MainSideContent component="div">
        {orderArray(categories, "category")
          ?.filter((element) => element)
          .map((categoryItem, index) => (
            <PaperItem key={"categories-" + index}>
              <ExpansionPanel>
                <ExpasionPanelTitle expandIcon={<ExpandMoreIcon />}>
                  <ExpansionHeading>{categoryItem.category}</ExpansionHeading>
                </ExpasionPanelTitle>
                <ExpansionPanelDetail>
                  <ExpansionList>
                    {orderArray(categoryItem.groups, "group")?.map(
                      (tipo, subIndex) => (
                        <ProviderContainer
                          key={`categories-subitems-${subIndex}`}
                        >
                          {tipo.types.length === 1
                            ? tipo.types.map((item) =>
                                renderGroupedItem(item, categoryItem.category)
                              )
                            : renderGroupedFormItem(
                                tipo,
                                categoryItem.category
                              )}
                        </ProviderContainer>
                      )
                    )}
                  </ExpansionList>
                </ExpansionPanelDetail>
              </ExpansionPanel>
            </PaperItem>
          ))}
      </MainSideContent>

      <SideFooter xs={1} component="div" color="secondary">
        <Box component="span">
          Desenvolvido por Unicadtec {new Date().getFullYear()}&copy;
        </Box>
      </SideFooter>
    </MainDrawer>
  );
};

DrawerAvancadas = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(DrawerAvancadas);

const mapStateToProps = (state) => ({
  menuOpen: state.menu.avancadasClippings,
  categories: state.categories.clippingsCategories,
  state: state,
});

const mapDispatchtoProps = {
  ...FormActions,
};

export default connect(mapStateToProps, mapDispatchtoProps)(DrawerAvancadas);
