import { call, put } from "redux-saga/effects";

import { getClippingsCategoriesRequest } from "../services/categories";
import { Creators as CategoriesActions } from "../actions/categories";

export default function* getClippingCategories(filters) {
  try {
    const list = yield call(getClippingsCategoriesRequest, filters);
    yield put(CategoriesActions.getClippingCategorySuccess(list));
  } catch (error) {
    yield put(CategoriesActions.getClippingCategoryFailure(error));
  }
}
