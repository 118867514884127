const INITIAL_STATE = {
  areaInformationTypes: [],
  loadingAreaInformationTypes: false,
  loadingAreaInformationTypesFailed: false,
  loadingAreaInformationTypesLoaded: false,
  loaded: false,
};

export default function getAreaInformationTypes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_ALL_AREA_INFORMATION_TYPES":
      return {
        ...state,
        loadingAreaInformationTypes: true,
        loadingAreaInformationTypesFailed: false,
      };
    case "GET_ALL_AREA_INFORMATION_TYPES_SUCESS":
      return {
        ...state,
        areaInformationTypes: action.data.data,
        loadingAreaInformationTypesLoaded: true,
        loadingAreaInformationTypes: false,
        loadingAreaInformationTypesFailed: false,
      };
    case "GET_ALL_AREA_INFORMATION_TYPES_FAILURE":
      return {
        ...state,
        areaInformationTypes: [],
        loadingAreaInformationTypesLoaded: false,
        loadingAreaInformationTypes: false,
        loadingAreaInformationTypesFailed: true,
      };
    default:
      return state;
  }
}
