import { getFilterablePolygons } from "./Utils";
import { multiPolygon } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

export const ocurrencesInPoligon = (ocurrences) => {
  const filterablePolygons = getFilterablePolygons();

  const orderedOcurrences = ocurrences.reduce((a, b) => {
    return a.concat(b);
  }, []);

  const coordinates = filterablePolygons
    .filter(({ geometry }) => geometry)
    .map(({ geometry }) => geometry.coordinates);

  const multipolygons = multiPolygon(coordinates);

  if (filterablePolygons.length > 0) {
    const filteredOcurrences = orderedOcurrences.filter(
      ({ location: { lat, lng } }) => {
        const point = [lng, lat];
        return booleanPointInPolygon(point, multipolygons);
      }
    );

    return { ocurrences: filteredOcurrences, filterablePolygons };
  }

  return { ocurrences, filterablePolygons };
};

export const careOccurrencesInPoligon = (occurrences) => {
  const filterablePolygons = getFilterablePolygons();

  const coordinates = filterablePolygons
    .filter(({ geometry }) => geometry)
    .map(({ geometry }) => geometry.coordinates);

  const multipolygons = multiPolygon(coordinates);

  if (filterablePolygons.length > 0) {
    return occurrences.filter(({ position: { latitude, longitude } }) => {
      const point = [longitude, latitude];

      return booleanPointInPolygon(point, multipolygons);
    });
  }

  return occurrences;
};
