import { call, put } from "redux-saga/effects";

import { getCategoriesRequest } from "../services/categories";
import { Creators as CategoriesActions } from "../actions/categories";

export default function* getCategories(filters) {
  try {
    const list = yield call(getCategoriesRequest, filters);
    yield put(CategoriesActions.getCategorySuccess(list));
  } catch (error) {
    yield put(CategoriesActions.getCategoryFailure(error));
  }
}
