import { styled } from "@mui/material";

export const GeoLogo = styled("img")(() => ({
  zIndex: "8",
  position: "absolute",
  left: "30px",
  bottom: "20px",
}));

export const NodeEnvContainer = styled("div")(() => ({
  backgroundColor: "#FFF",
  position: "absolute",
  margin: "5px",
  bottom: 0,
  width: "50%",
  height: "50px",
  color: "#000",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "50px",
  textAlign: "center",
  border: "2px dashed #f69c55",
  zIndex: 7,
}));
