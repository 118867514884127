import { Box, ListItemText, Paper, styled } from "@mui/material";
import { InfoWindow } from "@react-google-maps/api";

export const InfoWindowComponent = styled(InfoWindow)(() => ({
  backgroundColor: "white",
  color: "#212121",
  display: "flex",
  flexDirection: "column",
}));

export const AlertPaper = styled(Paper)(() => ({
  backgroundColor: "white",
  color: "#212121",
  display: "flex",
  flexDirection: "column",
}));

export const IncidentItem = styled(Box)(() => ({
  alignSelf: "normal",
}));

export const ListContainer = styled(Box)(() => ({
  border: "rgb(204, 204, 204) solid 1px",
  marginBottom: "10px",
  borderRadius: "5px",
  padding: "5px 10px",
}));

export const IncidentIconItem = styled(Box)(() => ({
  alignSelf: "center",
  marginRight: "10px",
}));

export const ItemContainer = styled(Box)(() => ({
  paddingLeft: "5px",
  float: "none",
  display: "flex",
  flexDirection: "column",
}));

export const ImageContainer = styled(Box)(() => ({
  display: "flex",
  float: "left",
  borderColor: "#CCC",
  borderRadius: "5px",
  padding: "5px",
}));

export const ReportContainer = styled(Box)(() => ({
  borderColor: "#CCC",
  borderRadius: "0 0 5px 5px",
}));

export const ReportHeader = styled(Box)(() => ({
  position: "relative",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px",
  marginTop: "10px",
  borderRadius: "5px 5px 0 0",
  borderColor: "#ccc",
}));

export const ActionButton = styled("button")(() => ({
  background: "none",
  border: "none",
  outline: "none",
  cursor: "pointer",
}));

export const IncidentTitle = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "15px",
  },
}));

export const IncidentTextTitle = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
  },
  "& p": {
    fontSize: "14px",
    fontWeight: "400",
    color: "black",
  },
}));

export const IncidentTextTime = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "12px",
    fontWeight: "300",
  },
}));

export const AreaInformationContainer = styled(ListItemText)(() => ({
  marginTop: 10,
  marginBottom: 5,
}));

export const IncidentText = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "12px",
    fontWeight: "400",
  },
}));

export const IncidentTitleContainer = styled(Box)(() => ({
  paddingLeft: "5px",
  display: "flex",
  float: "none",
}));

export const IncidentTextEvent = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
  },
  "& p": {
    fontSize: "14px",
    fontWeight: "400",
    color: "black",
  },
}));

export const IncidentTextData = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "14px",
    fontWeight: "600",
    float: "left",
    display: "flex",
    flexDirection: "row",
  },

  "& p": {
    paddingLeft: "5px",
    fontSize: "14px",
    fontWeight: "400",
    color: "black",
    display: "flex",
    flexDirection: "row",
  },
}));
