import { StyleSheet } from "@react-pdf/renderer";

export const style = StyleSheet.create({
  header: {
    margin: "10px",
  },
  imageReport: {
    margin: "10px",
  },
  reportContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  reportCard: {
    margin: "20px 5px",
    width: "30vw",
  },
  textEntity: {
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
    backgroundColor: "grey",
  },
  textEntityName: {
    color: "white",
    padding: "5px",
    fontSize: "9px",
    textAlign: "center",
    backgroundColor: "blue",
  },
  row: {
    padding: "5px",
    backgroundColor: "lightgray",
  },
  headerTitle: {
    fontSize: "10px",
    padding: "0 5px",
  },
  headerText: {
    fontSize: "10px",
    padding: "5px",
    color: "grey",
  },
  rowTitle: {
    fontSize: "8px",
  },
  rowText: {
    fontSize: "8px",
    color: "grey",
  },
});
