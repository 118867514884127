import React, { useEffect, useState } from "react";
import { HeatmapLayer } from "@react-google-maps/api";

import {
  ocurrencesInPoligon,
  careOccurrencesInPoligon,
} from "../../common/ocurrencesHelper";

const ScriptingHeatmap = (props) => {
  const { activeIncidents, incidents, clippings, careOccurrences } = props;

  const [collection, setColletion] = useState([]);

  useEffect(() => {
    destructuringOccurrences(activeIncidents);
    destructuringOccurrences(incidents);
    destructuringOccurrences(clippings);
    destructuringCare(careOccurrences);
  }, []);

  const destructuringOccurrences = (occurrences) => {
    const { ocurrences: filteredOcurrences } = ocurrencesInPoligon(occurrences);

    filteredOcurrences.forEach((groupItem) => {
      if (Array.isArray(groupItem)) {
        groupItem.forEach((item) => {
          addItemToColletion(item);
        });
      } else {
        addItemToColletion(groupItem);
      }
    });
  };

  const destructuringCare = (occurrences) => {
    const filteredOcurrences = careOccurrencesInPoligon(occurrences);

    filteredOcurrences.forEach(({ position: { latitude, longitude } }) => {
      setColletion((previous) => {
        return [
          ...previous,
          new window.google.maps.LatLng(latitude, longitude),
        ];
      });
    });
  };

  const addItemToColletion = ({ location: { lat, lng } }) => {
    setColletion((previous) => {
      return [...previous, new window.google.maps.LatLng(lat, lng)];
    });
  };

  const maxIntensity = parseInt(collection.length * 0.007);
  const radius = 15;

  return <HeatmapLayer options={{ maxIntensity, radius }} data={collection} />;
};

export default ScriptingHeatmap;
