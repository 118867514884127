import { call, put } from "redux-saga/effects";

import { getRiskAreaTypes as getRiskAreaTypesRequest } from "../services/riskAreas";
import { Creators as actions } from "../actions/riskAreas";

export default function* getRiskAreaTypes() {
  try {
    const list = yield call(getRiskAreaTypesRequest);
    yield put(actions.getRiskAreasTypesSucceeded(list));
  } catch (error) {
    yield put(actions.getRiskAreasTypesFailed(error));
  }
}
