import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { Box, MenuItem, OutlinedInput } from "@mui/material";

import {
  Progress,
  SearchIcon,
  CompareText,
  SearchButton,
  SelectPeriod,
  FilterListIcon,
  CompareContainer,
  CustomFormControl,
  ResetFiltersButton,
  SelectFilterContainer,
} from "./dashboardStyles";

import InputDate from "../../common/InputDate";

import { Creators as ListActions } from "../../store/actions/ocurrences";

function Compare(props) {
  const {
    formFields,
    loadingAnalisys,
    getAnalysisCompare,
    clearAnalysisCompare,
  } = props;

  const [periodo, setPeriodo] = useState("periodo");
  const [afterDate, setAfterDate] = useState(new Date());
  const [beforeDate, setBeforeDate] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const [analysisType, setAnalysisType] = useState(1);
  const [analysis, setAnalysis] = useState(1);

  const ocurrencesSearch = () => {
    const filters = formFields;
    filters["periodo"] = periodo;
    filters["after_date"] = afterDate;
    filters["before_date"] = beforeDate;
    filters["year"] = year;
    filters["analysisType"] = analysisType;
    filters["analysis"] = analysis;

    getAnalysisCompare(filters);
  };

  const resetFilters = () => {
    setYear(new Date().getFullYear());
    setPeriodo("periodo");
    setAnalysis(1);
    setAfterDate(new Date());
    setBeforeDate(new Date());
    setAnalysisType(1);

    clearAnalysisCompare();
  };

  const handleChangePeriodo = (e) => {
    setPeriodo(e.target.value);
  };

  const handleChangeAfterDate = (field, value) => {
    setAfterDate(value);
  };

  const handleChangeBeforeDate = (field, value) => {
    setBeforeDate(value);
  };

  const renderYearOption = () => {
    const currentYear = new Date().getFullYear();
    let options = [];
    for (let i = currentYear - 5; i <= currentYear; i++) {
      options.push(i);
    }

    return options.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  const handleChangeAnalysisType = (e) => {
    setAnalysisType(e.target.value);
  };

  const handleChangeAnalysis = (e) => {
    setAnalysis(e.target.value);
  };

  const mensallOptionsProvider = () => {
    return [
      <CompareText variant="h6">Mensal:</CompareText>,
      <CustomFormControl variant="outlined">
        <SelectPeriod
          value={analysis}
          onChange={handleChangeAnalysis}
          input={<OutlinedInput name="analysis" />}
        >
          <MenuItem value={1} selected>
            Janeiro
          </MenuItem>
          <MenuItem value={2}>Fevereiro</MenuItem>
          <MenuItem value={3}>Março</MenuItem>
          <MenuItem value={4}>Abril</MenuItem>
          <MenuItem value={5}>Maio</MenuItem>
          <MenuItem value={6}>Junho</MenuItem>
          <MenuItem value={7}>Julho</MenuItem>
          <MenuItem value={8}>Agosto</MenuItem>
          <MenuItem value={9}>Setembro</MenuItem>
          <MenuItem value={10}>Outubro</MenuItem>
          <MenuItem value={11}>Novembro</MenuItem>
          <MenuItem value={12}>Dezembro</MenuItem>
        </SelectPeriod>
      </CustomFormControl>,
    ];
  };

  const bimestralOptionsProvider = () => {
    return [
      <CompareText variant="h6">Bimestre:</CompareText>,
      <CustomFormControl variant="outlined">
        <SelectPeriod
          value={analysis}
          onChange={handleChangeAnalysis}
          input={<OutlinedInput name="analysis" />}
        >
          <MenuItem value={1} selected>
            1º Bimestre
          </MenuItem>
          <MenuItem value={2}>2º Bimestre</MenuItem>
          <MenuItem value={3}>3º Bimestre</MenuItem>
          <MenuItem value={4}>4º Bimestre</MenuItem>
          <MenuItem value={5}>5º Bimestre</MenuItem>
          <MenuItem value={6}>6º Bimestre</MenuItem>
        </SelectPeriod>
      </CustomFormControl>,
    ];
  };

  const trimestralOptionsProvider = () => {
    return [
      <CompareText variant="h6">Trimestre:</CompareText>,
      <CustomFormControl variant="outlined">
        <SelectPeriod
          displayEmpty
          value={analysis}
          onChange={handleChangeAnalysis}
          input={<OutlinedInput name="analysis" />}
        >
          <MenuItem value={1} selected>
            1º Trimestre
          </MenuItem>
          <MenuItem value={2}>2º Trimestre</MenuItem>
          <MenuItem value={3}>3º Trimestre</MenuItem>
          <MenuItem value={4}>4º Trimestre</MenuItem>
        </SelectPeriod>
      </CustomFormControl>,
    ];
  };

  const semestralOptionsProvider = () => {
    return [
      <CompareText variant="h6">Semestre:</CompareText>,
      <CustomFormControl variant="outlined">
        <SelectPeriod
          displayEmpty
          value={analysis}
          onChange={handleChangeAnalysis}
          input={<OutlinedInput name="analysis" />}
        >
          <MenuItem value={1} selected>
            1º Semestre
          </MenuItem>
          <MenuItem value={2}>2º Semestre</MenuItem>
        </SelectPeriod>
      </CustomFormControl>,
    ];
  };

  const analysisProvider = {
    1: mensallOptionsProvider,
    2: bimestralOptionsProvider,
    3: trimestralOptionsProvider,
    6: semestralOptionsProvider,
  };

  return (
    <CompareContainer component="div">
      <CompareText component="span" variant="h6">
        Filtrar por:
      </CompareText>
      <CustomFormControl variant="outlined">
        <SelectPeriod
          value={periodo}
          onChange={handleChangePeriodo}
          input={<OutlinedInput name="periodo" />}
        >
          <MenuItem value="periodo" selected>
            Período
          </MenuItem>
          <MenuItem value="custom">Data</MenuItem>
        </SelectPeriod>
      </CustomFormControl>
      {periodo === "custom"
        ? [
            <SelectFilterContainer component="span">
              <CompareText component="span" variant="h6">
                De:
              </CompareText>
              <InputDate
                name="afterDate"
                value={afterDate}
                changeField={handleChangeAfterDate}
              />
            </SelectFilterContainer>,
            <SelectFilterContainer component="span">
              <CompareText component="span" variant="h6">
                Até:
              </CompareText>
              <InputDate
                name="beforeDate"
                value={beforeDate}
                changeField={handleChangeBeforeDate}
              />
            </SelectFilterContainer>,
          ]
        : [
            <SelectFilterContainer component="span">
              <CompareText component="span" variant="h6">
                Ano:
              </CompareText>
              <CustomFormControl variant="outlined">
                <SelectPeriod
                  value={year}
                  onChange={handleChangeYear}
                  input={<OutlinedInput name="year" />}
                >
                  {renderYearOption()}
                </SelectPeriod>
              </CustomFormControl>
            </SelectFilterContainer>,
            <SelectFilterContainer component="span">
              <CompareText component="span" variant="h6">
                Análise:
              </CompareText>
              <CustomFormControl variant="outlined">
                <SelectPeriod
                  value={analysisType}
                  onChange={handleChangeAnalysisType}
                  input={<OutlinedInput name="analysisType" />}
                >
                  <MenuItem value={1}>Mensal</MenuItem>
                  <MenuItem value={2}>Bimestral</MenuItem>
                  <MenuItem value={3}>Trimestral</MenuItem>
                  <MenuItem value={6}>Semestral</MenuItem>
                </SelectPeriod>
              </CustomFormControl>
              {analysisProvider[analysisType] && (
                <SelectFilterContainer component="span">
                  {analysisProvider[analysisType]()}
                </SelectFilterContainer>
              )}
            </SelectFilterContainer>,
          ]}
      <Box component="span">
        <SearchButton
          startIcon={<SearchIcon />}
          disabled={loadingAnalisys}
          onClick={ocurrencesSearch}
        />
        {loadingAnalisys && <Progress color="secondary" />}
      </Box>
      <Box component="span">
        <ResetFiltersButton
          startIcon={<FilterListIcon variant="Clear" />}
          disabled={loadingAnalisys}
          onClick={resetFilters}
        >
          Limpar Filtro
        </ResetFiltersButton>
      </Box>
    </CompareContainer>
  );
}

Compare = reduxForm({
  form: "ANALISE",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(Compare);

const mapStateToProps = (state) => {
  return {
    formFields: getFormValues("ANALISE")(state)
      ? getFormValues("ANALISE")(state)
      : [],
    loadingAnalisys: state.ocurrences.loadingAnalisys,
  };
};

const mapDispatchToProps = {
  ...ListActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
