import { point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import decodePoly from "decode-google-map-polyline";
import _ from "lodash";

export const filterPublicEntitiesByUnits = ({
  publicEntities,
  selectedUnits,
}) => {
  let filteredPublicEntities = [];
  if (publicEntities.length > 0) {
    filteredPublicEntities = publicEntities.filter((publicE) =>
      selectedUnits.includes(publicE.unit_id)
    );
  }

  return filteredPublicEntities;
};

export const filterPublicEntitiesByTypes = ({
  filteredPublicEntity,
  publicEntitiesTypesSelected = [],
}) => {
  if (!publicEntitiesTypesSelected.length > 0) {
    return false;
  }

  return publicEntitiesTypesSelected.includes(
    filteredPublicEntity.publicEntityId
  );
};

export const filterPublicEntitiesByRadiusIntersection = ({
  publicEntity,
  filterablePolygons,
}) => {
  if (filterablePolygons.length === 0 || !publicEntity) {
    return true;
  }

  const points = [];
  if (publicEntity.area.polygon) {
    let parsedCoords;

    if (publicEntity.area.polygon.type === "Polygon") {
      parsedCoords = decodePoly(publicEntity.area.polygon.coordinates[0]);
    } else {
      parsedCoords = _.flattenDeep(
        publicEntity.area.polygon.coordinates.map((positionArray) =>
          decodePoly(positionArray[0])
        )
      );
    }

    for (const coord of parsedCoords) {
      points.push(point([coord["lng"], coord["lat"]]));
    }
  } else {
    const [lng, lat] = publicEntity.area.point.coordinates;
    points.push(point([lng, lat]));
  }

  return points.some((point) => {
    return filterablePolygons.some((polygon) =>
      booleanPointInPolygon(point, polygon)
    );
  });
};

export const filterPublicEntitiesByRelation = ({
  publicEntities,
  publicEntityType,
  units,
}) => {
  const relatedUnit = {};
  units
    .filter(({ areaRelated }) => areaRelated.length > 0)
    .forEach(({ areaRelated }) => {
      areaRelated.forEach((element) => {
        relatedUnit[element.relatedAreaId] = element;
      });
    });

  return publicEntities
    .filter(({ areaId }) => relatedUnit[areaId])
    .filter(({ publicEntityId }) => publicEntityType?.includes(publicEntityId));
};
