export const ocurrencesColor = [
  "#14A2DE",
  "#37B9E6",
  "#5AD1EF",
  "#7DE8F7",
  "#A0FFFF",
  "#31C6C1",
  "#25B4AF",
  "#19A29E",
  "#0C8F8C",
  "#007D7A",
  "#24CE82",
  "#44DA9B",
  "#63E7B3",
  "#83F3CC",
  "#A2FFE4",
  "#33D405",
  "#52DF25",
  "#71EA44",
  "#90F464",
  "#AFFF83",
  "#B259E1",
  "#9F44CE",
  "#8C2FBC",
  "#791AA9",
  "#660596",
  "#A16FD5",
  "#B987E0",
  "#D0A0EA",
  "#E8B8F5",
  "#FFD0FF",
];

export const mediasColors = [
  "#B259E1",
  "#9F44CE",
  "#8C2FBC",
  "#791AA9",
  "#660596",
  "#A16FD5",
  "#B987E0",
  "#D0A0EA",
  "#E8B8F5",
  "#FFD0FF",
];
export const periodColors = ["#FFD0FF", "#D0A0EA", "#A16FD5", "#B259E1"];

export const weekdaysColors = [
  "#14A2DE",
  "#37B9E6",
  "#5AD1EF",
  "#7DE8F7",
  "#A0FFFF",
  "#31C6C1",
  "#25B4AF",
];
