import { call, put } from "redux-saga/effects";

import { getPublicEntities as getPublicEntitiesRequest } from "../services/public-entities";
import { Creators as filtersActions } from "../actions/public-entities";

export default function* getPublicEntities() {
  try {
    const list = yield call(getPublicEntitiesRequest);
    yield put(filtersActions.getPublicEntitiesSuccess(list));
  } catch (error) {
    yield put(filtersActions.getPublicEntitiesFailure(error));
  }
}
