import React from "react";
import { connect } from "react-redux";

import {
  Map as MapIcon,
  ExitToApp as ExitToAppIcon,
  InfoOutlined as InfoOutlinedIcon,
  FilterListOutlined as FilterListOutlinedIcon,
  NotificationImportantOutlined as NotificationImportantOutlinedIcon,
} from "@mui/icons-material";

import { ActionButton, CustomToolBar, ExitButton } from "./rightMenuStyles";

import { Creators as FormActions } from "../../store/actions/menu";
import { Creators as MainActions } from "../../store/actions/main";
import { Creators as Auth } from "../../store/actions/auth";

const RightMenuTabs = (props) => {
  const { logout, legendas, incidentes, mapTypesMenu, filters, animateMenu } =
    props;

  return (
    <CustomToolBar expanded={legendas || incidentes || mapTypesMenu || filters}>
      <ActionButton
        edge="start"
        color="inherit"
        selected={legendas}
        onClick={() => animateMenu("ABRIR_MENU_LEGENDAS")}
      >
        <InfoOutlinedIcon />
      </ActionButton>
      <ActionButton
        edge="start"
        color="inherit"
        selected={incidentes}
        onClick={() => animateMenu("ABRIR_MENU_INCIDENTES")}
      >
        <NotificationImportantOutlinedIcon />
      </ActionButton>
      <ActionButton
        edge="start"
        color="inherit"
        selected={mapTypesMenu}
        onClick={() => animateMenu("ABRIR_MENU_MAP_TYPES")}
      >
        <MapIcon />
      </ActionButton>
      <ActionButton
        edge="start"
        color="inherit"
        selected={filters}
        onClick={() => animateMenu("ABRIR_MENU_FILTERS")}
      >
        <FilterListOutlinedIcon />
      </ActionButton>
      <ExitButton edge="start" title="Sair" color="inherit" onClick={logout}>
        <ExitToAppIcon />
      </ExitButton>
    </CustomToolBar>
  );
};

const mapDispatchtoProps = {
  ...FormActions,
  ...MainActions,
  ...Auth,
};

const mapStateToProps = (state) => {
  return {
    legendas: state.menu.legendas,
    incidentes: state.menu.incidentes,
    mapTypesMenu: state.menu.mapTypesMenu,
    filters: state.menu.filters,
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(RightMenuTabs);
