import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  MenuItem,
  Checkbox,
  FormControl,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

import { MultiSelectComponent } from "./drawerCareStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultiSelect = (props) => {
  const { name, options, onChange } = props;

  const theme = useTheme();

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(typeof value === "string" ? value.split(",") : value);
    onChange(value.join(","), name);
  };

  return (
    <FormControl sx={{ width: 250 }}>
      <MultiSelectComponent
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
      >
        {options.map(({ id, descr }) => (
          <MenuItem
            key={`option-item-${id}`}
            value={id}
            style={getStyles(id, personName, theme)}
          >
            {descr}
          </MenuItem>
        ))}
      </MultiSelectComponent>
    </FormControl>
  );
};

export default MultiSelect;
