import api from "./apiV1";

export const getRegionais = () => {
  return api.request({
    url: "/care-regionais",
    method: "GET",
  });
};

export const getSites = (params) => {
  return api.request({
    url: "/care-sites",
    method: "GET",
    params,
  });
};

export const getInstallationCategories = () => {
  return api.request({
    url: "/care-installation-categories",
    method: "GET",
  });
};

export const getInstallationTypes = () => {
  return api.request({
    url: "/care-installation-types",
    method: "GET",
  });
};

export const getRiskLevels = () => {
  return api.request({
    url: "/care-risk-levels",
    method: "GET",
  });
};

export const getOccurrenceTypes = () => {
  return api.request({
    url: "/care-occurrence-types",
    method: "GET",
  });
};

export const getIncidentTypes = (params) => {
  return api.request({
    url: "/care-incident-types",
    method: "GET",
    params,
  });
};

export const getCriticalities = () => {
  return api.request({
    url: "/care-criticalities",
    method: "GET",
  });
};

export const getStatus = () => {
  return api.request({
    url: "/care-status",
    method: "GET",
  });
};

export const getInstallations = (params = {}) => {
  return api.request({
    url: "/care-installations",
    method: "GET",
    params,
  });
};

export const getOccurrences = (params = {}) => {
  return api.request({
    url: "/care-search-occurrences",
    method: "GET",
    params,
  });
};
