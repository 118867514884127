import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import decodePoly from "decode-google-map-polyline";
import _ from "lodash";

export const unlinkByTypes = (filteredPointsOfInterest) => {
  const pointsOfInterestMounted = [];
  filteredPointsOfInterest.map((interestPoint) =>
    pointsOfInterestMounted[interestPoint.type.id]
      ? pointsOfInterestMounted[interestPoint.type.id].push(interestPoint)
      : (pointsOfInterestMounted[interestPoint.type.id] = [interestPoint])
  );

  return pointsOfInterestMounted;
};

export const filterPointsOfInterestByUnits = ({
  interestPoints,
  selectedUnits,
}) => {
  if (selectedUnits.length === 0) {
    return [];
  }

  let filteredPointsOfInterest = [];

  if (interestPoints.length > 0) {
    filteredPointsOfInterest = interestPoints.filter((interestPoint) => {
      const turfPoint = point(interestPoint.area.point.coordinates);
      return selectedUnits.some((unit) =>
        unit.turfPolygonsCoordinates?.some((coords) =>
          booleanPointInPolygon(turfPoint, polygon([coords]))
        )
      );
    });
  }

  return filteredPointsOfInterest;
};

export const filterPointsOfInterestByTypes = ({
  filteredPointOfInterest,
  pointsOfInterestTypesSelected = [],
}) => {
  if (pointsOfInterestTypesSelected.length === 0) {
    return false;
  }

  return pointsOfInterestTypesSelected.includes(
    filteredPointOfInterest.type.id
  );
};

export const filterPointsOfInterestByRadiusIntersection = ({
  pointOfInterest,
  filterablePolygons,
}) => {
  if (filterablePolygons.length === 0 || !pointOfInterest) {
    return true;
  }

  const points = [];

  if (pointOfInterest.area.polygon) {
    const parsedCoords = decodePoly(
      pointOfInterest.area.polygon.coordinates[0]
    );

    for (const coord of parsedCoords) {
      points.push(point([coord["lng"], coord["lat"]]));
    }
  } else {
    const [lng, lat] = pointOfInterest.area.point.coordinates;
    points.push(point([lng, lat]));
  }

  return points.some((point) => {
    for (const polygon of filterablePolygons) {
      if (booleanPointInPolygon(point, polygon)) {
        return true;
      }
    }

    return false;
  });
};

export const filterPOIByRelation = ({
  units,
  interestPoint,
  interestPoints,
}) => {
  const relatedUnit = {};
  units
    .filter(({ areaRelated }) => areaRelated.length > 0)
    .forEach(({ areaRelated }) => {
      areaRelated.forEach((element) => {
        relatedUnit[element.relatedAreaId] = element;
      });
    });

  return interestPoints.filter(
    ({ areaId, pointTypeId }) =>
      relatedUnit[areaId] && interestPoint?.includes(pointTypeId)
  );
};
