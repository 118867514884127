import axios from "./api";

export const Login = (data) => {
  return axios.request({
    url: process.env.REACT_APP_PASSPORT_URL + "/oauth/token",
    method: "POST",
    data: {
      grant_type: "password",
      client_id: process.env.REACT_APP_PASSPORT_CLIENT_ID,
      client_secret: process.env.REACT_APP_PASSPORT_CLIENT_SECRET,
      username: data.username,
      password: data.password,
    },
  });
};

export const GetUser = (token) => {
  return axios.request({
    url: process.env.REACT_APP_PASSPORT_URL + "api/self",
    method: "GET",
    params: {
      application_id: 50,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetPermisstion = (token) => {
  return axios.request({
    url: process.env.REACT_APP_PASSPORT_URL + "api/self/permissions",
    method: "GET",
    params: {
      application_id: 50,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const Logout = (token) => {
  return axios.request({
    url: process.env.REACT_APP_PASSPORT_URL + "api/oauth/token",
    method: "DELETE",
    params: {
      application_id: 50,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
