export const Types = {
  GET_AREA_SELECTED: "areas/GET_AREA_SELECTED",
  GET_AREA_SELECTED_SUCCESS: "areas/GET_AREA_SELECTED_SUCCESS",
  GET_AREA_SELECTED_FAILURE: "areas/GET_AREA_SELECTED_FAILURE",
  GET_AREA_PUBLIC_ENTITIES: "areas/GET_AREA_PUBLIC_ENTITIES",
  GET_AREA_PUBLIC_ENTITIES_SUCCESS: "areas/GET_AREA_PUBLIC_ENTITIES_SUCCESS",
  GET_AREA_PUBLIC_ENTITIES_FAILURE: "areas/GET_AREA_PUBLIC_ENTITIES_FAILURE",
  REMOVE_AREA_FILTERS: "areas/REMOVE_AREA_FILTERS",
};

export const Creators = {
  getAreas: (filters) => ({
    type: Types.GET_AREA_SELECTED,
    filters,
  }),
  getAreasSuccess: (data) => ({
    type: Types.GET_AREA_SELECTED_SUCCESS,
    data,
  }),
  getAreasFailure: () => ({
    type: Types.GET_AREA_SELECTED_FAILURE,
  }),
  removeAreaFilters: () => ({
    type: Types.REMOVE_AREA_FILTERS,
  }),
};
