import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  Paper,
  Switch,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableSortLabel,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { FlagSharp as FlagSharpIcon } from "@mui/icons-material";
import { stringfyDate } from "../../../common/dateHelper";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function orderByDistance(array, order) {
  array.sort((a, b) => {
    let [distanceA] = a.distance.split(" ");
    let [distanceB] = b.distance.split(" ");
    distanceA = Number(distanceA.replace(",", "."));
    distanceB = Number(distanceB.replace(",", "."));

    if (distanceB < distanceA) return order === "desc" ? -1 : 1;
    if (distanceB > distanceA) return order === "desc" ? 1 : -1;

    return 0;
  });
  return array;
}

function orderByTime(array, order) {
  const newArray = array.map((item) => {
    const splitedTime = item.time.split(" ");

    item.timestamp = Number(stringfyDate(splitedTime));

    return item;
  });

  newArray.sort((a, b) =>
    order === "desc" ? b.timestamp - a.timestamp : a.timestamp - b.timestamp
  );

  return newArray;
}

function orderbyName(array, order) {
  return array.sort((a, b) =>
    order === "desc" ? b.position - a.position : a.position - b.position
  );
}

const orderByProvider = {
  distance: orderByDistance,
  time: orderByTime,
  name: orderbyName,
};

function stableSort(array, comparator, order, orderBy) {
  if (orderByProvider[orderBy]) {
    return orderByProvider[orderBy](array, order);
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Posição",
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "Unidade",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Tempo",
  },
  {
    id: "distance",
    numeric: false,
    disablePadding: false,
    label: "Distância",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#424242" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="secondary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Selecione todas as unidades",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            align="left"
            key={headCell.id}
            sx={{ color: "#fff" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  select,
  selectAll,
  unSelectAll,
  isSelected,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("time");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      selectAll();
      return;
    }
    setSelected([]);
    unSelectAll();
  };

  const handleRowClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    select(id);
    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody style={{ backgroundColor: "#424242" }}>
              {stableSort(
                rows,
                getComparator(order, orderBy),
                order,
                orderBy
              ).map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleRowClick(event, row.position, row.id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.position}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="secondary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ color: "#fff" }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.position}
                      {row.position <= 3 && (
                        <FlagSharpIcon
                          style={{ marginLeft: "20px", color: "#1CA495" }}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="left">
                      {row.unit}
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="left">
                      {row.time}
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="left">
                      {row.distance}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FormControlLabel
        style={{ color: "#fff" }}
        control={
          <Switch
            color="secondary"
            checked={dense}
            onChange={handleChangeDense}
          />
        }
        label="Agrupar"
      />
      {rows.length === 0 && <CircularProgress />}
    </Box>
  );
}
