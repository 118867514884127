import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { Creators as RouteAnalysisActions } from "../../../store/actions/routesAnalysis";

const EditWaypointModal = (props) => {
  const { id, open, closeEditModal, waypoints, editWaypoint } = props;

  const [waypointTitle, setWaypointTitle] = useState({});

  useEffect(() => {
    if (open) {
      const { title } = searchWaypoint();
      setWaypointTitle(title);
    }
  }, [open]);

  const searchWaypoint = () => {
    return waypoints.find((waypoint) => waypoint.id === id);
  };

  const handleSave = () => {
    const waypoint = searchWaypoint();
    waypoint.title = waypointTitle;

    editWaypoint(waypoint);
    closeEditModal();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Editar Desvio</DialogTitle>
      <DialogContent>
        <TextField
          value={waypointTitle}
          onChange={(e) => setWaypointTitle(e.target.value)}
          id="waypointTitle"
          label="Título"
          variant="standard"
          style={{ width: "300px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeEditModal}>Sair</Button>
        <Button onClick={handleSave}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    waypoints: state.routesAnalysis.waypoints,
  };
};

const mapDispatchToProps = {
  ...RouteAnalysisActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWaypointModal);
